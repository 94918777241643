import React from 'react'

import LandingPageNavbar from '../components/LandingPageNavbar'
import Home from './LandingContent1'
import '../style.css'
import LandingContent2 from './LandingContent2'
import LandingContent3 from './LandingContent3'
import LandingContent4 from './LandingContent4'

function LandingPage() {
  return (
    <div>
        <LandingPageNavbar />
        <Home />
        <LandingContent2 /> 
        <LandingContent3 />
        <LandingContent4 />
    </div>
  )
}

export default LandingPage