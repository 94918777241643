import React from 'react';
import { Container, Row, Col, Button } from "@themesberg/react-bootstrap";
import homeLogo from "../assets/img/home-main2.png";
import laptop1 from "../assets/img/laptop1.png";
import laptop2 from "../assets/img/laptop2.png";

function LandingContent3() {
  return (
    <div className='w-100'>
      <section>
        <Container fluid className="home-section" style={{ paddingBottom: 0, margin: 0 }} id="home">
          <Container style={{ paddingTop:'90px'}}>
          <h3 className="text-center" style={{ color: 'white', fontFamily: "monospace" }}>Destiny isn't A.I , it's the result of human.</h3><br />
            <p className="text-center" style={{ color: 'gray', fontFamily: 'monospace', margin: '0 auto', maxWidth: '550px' }}>Your images may change size depending on the screen size being used. Larger images retain image quality when they shrink. However, smaller images tend to become pixelated when they are enlarged. With this in mind. </p>

            <Row className="mt-4" style={{ marginBottom:'100px', paddingTop:'50px'  }}>
            <Col md={12} lg={4} className="mb-4 text-center" >
  <img
    src={homeLogo}
    alt="home pic"
    className="img-fluid home-main-image"
    style={{ maxHeight: "150px", margin: '0 auto' }}
  />
  <h4 style={{ color: 'white', textAlign: 'center' }}>Highly Responsive</h4>
  <h6 style={{ color: 'gray', maxWidth: '300px', textAlign: 'center', margin: '0 auto' }}>
    We hope that designing experiences in the Ion platform is so flexible that you no longer need to make page mockups outside of the platform.
  </h6>
</Col >

              <Col md={12} lg={4} className="mb-4 text-center" >
  <img
    src={homeLogo}
    alt="home pic"
    className="img-fluid home-main-image"
    style={{ maxHeight: "150px", margin: '0 auto' }}
  />
  <h4 style={{ color: 'white', textAlign: 'center' }}>Highly Responsive</h4>
  <h6 style={{ color: 'gray', maxWidth: '300px', textAlign: 'center', margin: '0 auto' }}>
    We hope that designing experiences in the Ion platform is so flexible that you no longer need to make page mockups outside of the platform.
  </h6>
</Col>

              <Col md={12} lg={4} className="mb-4 text-center" >
         <img
    src={homeLogo}
    alt="home pic"
    className="img-fluid home-main-image"
    style={{ maxHeight: "150px", margin: '0 auto' }}
  />
  <h4 style={{ color: 'white', textAlign: 'center' }}>Highly Responsive</h4>
  <h6 style={{ color: 'gray', maxWidth: '300px', textAlign: 'center', margin: '0 auto' }}>
    We hope that designing experiences in the Ion platform is so flexible that you no longer need to make page mockups outside of the platform.
  </h6>
</Col>

            </Row>






          </Container>
        </Container>





        <Container fluid className="home-section1" style={{ paddingBottom: 0, margin: 0 }} id="home">
          <Container>
          



            <Row className="mt-4" style={{ paddingTop:'30px'}}>
              <Col md={12} lg={6} className="mb-4">
                <img
                  src={laptop1}
                  alt="home pic"
                  className="img-fluid home-main-image"
                  style={{ maxHeight: "250px", margin: '0 auto' }}
                />
              </Col>

              <Col md={12} lg={6} className="mb-4">
                <h1 style={{ paddingBottom: 15 }} className="heading">
                  Design for startups & Brands {" "}
                </h1>

                <h1 className="heading-name">
                  <h5 className="main-name" style={{ color: 'white', fontSize: "15px" }}>Talent meets opportunity. As a leading recruitment platform, Jobber connects employers with skilled professionals, streamlining the hiring process for businesses and empowering individuals to discover fulfilling careers.</h5>
                </h1>
                </Col>
            </Row>


            <Row className="mt-4" style={{ paddingTop:"50px", paddingBottom:'30px'}}>
              <Col md={12} lg={6} className="mb-4">
              <h1 style={{ paddingBottom: 15, marginTop:'30px' }} className="heading">
                  Design for startups & Brands {" "}
                </h1>

                <h1 className="heading-name">
                  <h5 className="main-name" style={{ color: 'white', fontSize: "15px" }}>Talent meets opportunity. As a leading recruitment platform, Jobber connects employers with skilled professionals, streamlining the hiring process for businesses and empowering individuals to discover fulfilling careers.</h5>
                </h1>
              </Col>

              <Col md={12} lg={6} className="mb-4" >
                
                  <img
                  src={laptop1}
                  alt="home pic"
                  className="img-fluid home-main-image"
                  style={{ maxHeight: "250px", margin: '0 auto', textAlign:'center', paddingLeft:'50px' }}
                />
                </Col>
            </Row>







           
          </Container>
        </Container>
      </section>
    </div>
  );
}

export default LandingContent3;
