import * as React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "react-bootstrap";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Collapse from "@mui/material/Collapse";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "universal-cookie";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { Circles } from "react-loader-spinner";
import AxiosInstance from "../../config/AxiosInstance";
import toast, { Toaster } from "react-hot-toast";
// import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useHistory } from "react-router-dom";
import user from "../../assets/img/user.png";

const label = { inputProps: { "aria-label": "Switch demo" } };

const headCells = [
  {
    label: "Client",
  },
  {
    label: "Date",
  },
  {
    label: "Subject",
  },
  {
    label: "CONTACT",
  },
  {
    label: "Total",
  },
];

function Row(props) {
  const { row, handleClick, isItemSelected, labelId, seletedEditData } = props;
  const [open, setOpen] = React.useState(false);

  const [accessType, setAccessType] = React.useState(null);
  const history = useHistory();

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={() =>
          history.push(`/superadmin/invoicedetails/${row.invoice_id}`)
        }
        style={{ cursor: "pointer" }}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.machineTypeId}
        selected={isItemSelected}
      >
        {/* <TableCell align="center" padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell> */}

        {/* <TableCell align="center">{ row + 1}</TableCell> */}
        <TableCell align="left" className="fw-bold">
          <img
            src={user}
            alt="Icon"
            style={{
              width: "15px",
              height: "15px",
              margin: "5px",
            }}
          />
          &nbsp; &nbsp;{row.client?.first_name} {row?.client?.last_name} <br />{" "}
        </TableCell>
        <TableCell align="left">{row?.invoice_issued_date}</TableCell>
        <TableCell align="left">{row?.invoice_subject}</TableCell>
        <TableCell align="left">
          {row?.client?.phone}
          <br />
          {row?.client?.email}
        </TableCell>

        <TableCell align="left" className="fw-bold">
          €{row.product_servise_total}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Invoices() {
  let cookies = new Cookies();
  const history = useHistory();
  React.useEffect(() => {
    const userData = cookies.get("token");
    if (!userData) {
      history.push("/sign-in");
    }
  }, [cookies]);

  const [requestData, setRequestData] = useState([]);
  let [loader, setLoader] = React.useState(true);
  let [countData, setCountData] = useState(0);

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const getData = async () => {
    const token = cookies.get("token");
    try {
      const res = await AxiosInstance.get("/invoice/invoice", {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
        },
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
      });
      setLoader(false);
      setRequestData(res.data.data);
      setCountData(res.data.count); // Make sure to adjust the key here
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = requestData?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Delete selected
  var handleDelete = () => {
    swal("Are You Sure You Want TO Delete ?", {
      buttons: ["No", "Yes"],
    }).then(async (buttons) => {
      if (buttons === true) {
        AxiosInstance.delete("/industry/industry", {
          data: selected,
        }).then((response) => {
          if (response.data.statusCode === 200) {
            getData();
            setSelected([]);
            toast.success(response.data?.message);
          }
        });
      }
    });
  };

  const [searchLoader, setSearchLoader] = useState(false);
  let handleSearchData = async (values) => {
    setSearchLoader(true);
    let res = await AxiosInstance.post("/industry/search/industry", {
      search: values,
    });
    if (res.data.statusCode === 200) {
      if (values !== "") {
        setSearchLoader(false);
        setRequestData(res.data.data);
        setCountData(res.data.count);
      } else {
        setSearchLoader(false);
        getData();
      }
    }
  };

  //   edit machine-type here
  let [modalShowForPopupForm, setModalShowForPopupForm] = React.useState(false);
  let [id, setId] = React.useState();

  var handleSubmit;

  if (!id) {
    handleSubmit = async (values) => {
      try {
        const res = await AxiosInstance.post("/industry/industry", values);

        if (res.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          toast.success(res.data?.message);
        } else {
          toast.error(res.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error);
      }
    };
  } else {
    handleSubmit = async (values) => {
      try {
        const response = await AxiosInstance.put(
          `/industry/industry/${id}`, // Use template literals to include the id
          values
          //   {
          //     headers: {
          //       Authorization: `Bearer ${token}`,
          //     },
          //   }
        );

        if (response.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          toast.success(response.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error);
      }
    };
  }

  //    // "add fom logic"
  let [editData, setEditData] = React.useState({});

  //   auto form fill up in edit
  let seletedEditData = async (datas) => {
    setModalShowForPopupForm(true);
    setId(datas._id);
    setEditData(datas);
  };

  // ===================== Dropdawn Filter =========================================
  let [searchData, setSearchData] = useState({ filter: "" });

  const updateInputs = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSearchData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const [filterLoader, setFilterLoader] = useState(false);
  var filterDate = async () => {
    try {
      setFilterLoader(true);
      let response = await AxiosInstance.post(
        "/invoice/filter",
        {
          filter: searchData.filter,
        }
      );

      if (response.data.statusCode === 200) {
        setFilterLoader(false);
        setRequestData(response.data.data);
        setCountData(response.data.count);
      } else {
        setFilterLoader(false);

        // Handle other status codes or conditions if needed
        console.error("Unexpected status code:", response.data.statusCode);
      }
    } catch (error) {
      // Handle errors that occurred during the request
      console.error("Error filtering data:", error.message);
    }
  };

  React.useEffect(() => {
    if (searchData) {
      filterDate();
    }
    // if (searchData.filter !== 'Custome') {
    //   filterDate();
    // } else {
    //   // Handle other filter options if needed
    //   getData();
    // }
  }, [searchData]);
  // ====================================================================

  return (
    <>
      <div className="signup-container shadow p-3 rounded">
        <div id="main-btn-add-machinetype">
          {/* <div className="d-flex justify-content-end mb-2">
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel>Date</InputLabel>
              <Select
                label="Date"
                name="filter"
                value={searchData?.filter}
                onChange={updateInputs}
                MenuProps={{
                  style: {
                    maxHeight: 410,
                  },
                }}
              >
                <MenuItem
                  value={"All"}
                  onClick={() => {
                    setSearchData({
                      filter: "",
                    });
                    getData();
                  }}
                >
                  All
                </MenuItem>
                <MenuItem value={"This Month"}>This Month</MenuItem>
                <MenuItem value={"Last 30 Days"}>Last 30 Days</MenuItem>
                <MenuItem value={"Last Month"}>Last Month</MenuItem>
                <MenuItem value={"This Year"}>This Year</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          <Paper
            sx={{
              width: "100%",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <Toolbar
              className="border-top border-bottom"
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                bgcolor: "#4A5073", // Set the background color here
                color: "white", // Set the font color to white
              }}
            >
              {/* {selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : ( */}
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Invoice
              </Typography>
              {/* )} */}

              {/* <form className="form-inline">
              <input
                id="serchbar-size"
                className="form-control mr-sm-2"
                type="search"
                onChange={(e) => handleSearchData(e.target.value)}
                placeholder="Search"
                aria-label="Search"
              />
            </form> */}

              {/* <>
              {selected.length > 0 ? (
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete()}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </> */}
            </Toolbar>
            {loader || searchLoader || filterLoader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <Circles
                  height="50"
                  width="50"
                  color="#4A5073"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center" padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < requestData?.length
                        }
                        checked={
                          requestData?.length > 0 &&
                          selected.length === requestData?.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell> */}

                      {headCells.map((headCell, id) => {
                        return (
                          <>
                            <TableCell
                              key={id}
                              className="fw-bold"
                              align="left"
                            >
                              {headCell.label}
                            </TableCell>
                          </>
                        );
                      })}

                      {/* <TableCell className="fw-bold" align="center">
                      Action
                    </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requestData?.map((row, index) => {
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <Row
                          key={row.machineTypeId}
                          row={row}
                          isItemSelected={isItemSelected}
                          labelId={labelId}
                          handleClick={handleClick}
                          selected={selected}
                          index={index}
                          seletedEditData={seletedEditData}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={countData}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            )}
          </Paper>
        </div>
      </div>
      <Toaster />
    </>
  );
}
