import {
  Nav,
  Dropdown,
  Container,
  ListGroup,
  ToggleButtonGroup,
  ToggleButton,
  FormCheck,
} from "@themesberg/react-bootstrap";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBriefcase,
  faPlus,
  faUser,
  faHome,
  faArrowLeft,
  faMailBulk,
  faEllipsisH,
  faAddressBook,
  faEnvelope,
  faPen,
  faChevronDown,
  faDownload,
  faEquals,
  faFileInvoice,
  faTasks,
  faCalendar,
  faTag,
  faComment,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import location from "../../assets/img/location.png";
import hammer from "../../assets/img/hammer.png";
import left_quotes from "../../assets/img/left-quotes-sign.png";
import delivery from "../../assets/img/delivery.png";
import download from "../../assets/img/clientsummary.png";
import invoice from "../../assets/img/invoice.png";
import user from "../../assets/img/user.png";
import star from "../../assets/img/star.png";
import AxiosInstance from "./../../config/AxiosInstance";

// import { faBuilding, faAngleRight ,faUser } from "@fortawesome/free-solid-svg-icons";
// import "./style.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.6.1/css/bootstrap4-toggle.min.css"; // Import the Bootstrap Toggle CSS
import {
  Col,
  Row,
  Form,
  Button,
  InputGroup,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Card } from "react-bootstrap";
import { ButtonGroup } from "@themesberg/react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import toast, { Toaster } from "react-hot-toast";
//   import { Routes } from "../routes";
import { useHistory, useParams } from "react-router-dom";
import axiosInstance from "../../config/AxiosInstance";
import { Circles } from "react-loader-spinner";

function ClientSummary() {
  let [loader, setLoader] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const TabControl = () => {};

  const { client_id } = useParams();
  const [selectedColumn, setSelectedColumn] = useState(null);

  const handleColumnClick = (columnId) => {
    setSelectedColumn(columnId);
  };

  const content = selectedColumn
    ? `Content for Column ${selectedColumn}`
    : "Please select a column";

  const getBorderStyle = (column) => {
    return {
      borderBottom: selectedColumn === column ? "2px solid green" : "none",
    };
  };

  const { clientId } = useParams();
  const history = useHistory();
  const [properties, setProperties] = useState([]);
  const [clientData, setClientData] = useState([]);
  console.log(clientData, "clientData");
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/properties/properties_data/${client_id}`
      );
      setProperties(response.data.data);

      const response2 = await axiosInstance.get(
        `/client/client_record/${client_id}`
      );
      setClientData(response2.data.data[0]);
    } catch (error) {
      console.log("error in fetchdata in company details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [propertyRequestData, setPropertyRequestData] = useState();
  // const { request_id } = useParams();
  // const propertyid = "1703306087846zr8pl9pi6697307010";
  const getpropertyDetails = async () => {
    try {
      const res = await AxiosInstance.get(`/request/request_data/${client_id}`);
      setPropertyRequestData(res.data.data);
      console.log(res.data.data, "------------------------------------------");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getpropertyDetails();
  }, []);

  const [QuoteData, setQuoteData] = useState();
  // const { request_id } = useParams();
  // const quoteid = "1703138304798fd57hf9j5406954087";
  const getquostDetails = async () => {
    try {
      setLoader(true);
      const res = await AxiosInstance.get(`/quotes/quotes_client/${client_id}`);
      setQuoteData(res.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getquostDetails();
  }, []);
  // =====================================================================================

  const [jobData, setJobData] = useState();
  // const { request_id } = useParams();
  // const quoteid = "1703138304798fd57hf9j5406954087";
  const getJobtDetails = async () => {
    try {
      setLoader(true);
      const res = await AxiosInstance.get(`/job/job_client/${client_id}`);
      setJobData(res.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getJobtDetails();
  }, []);
  // ==========================================================

  const [invoiceData, setInvoiceData] = useState();
  // const { request_id } = useParams();
  // const quoteid = "1703138304798fd57hf9j5406954087";
  const getInvoiceDetails = async () => {
    try {
      setLoader(true);
      const res = await AxiosInstance.get(
        `/invoice/invoice_client/${client_id}`
      );
      setInvoiceData(res.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getInvoiceDetails();
  }, []);

  const StarRating = ({ rating }) => {
    const renderStars = () => {
      const stars = [];
      for (let i = 0; i < rating; i++) {
        stars.push(
          <img
            key={i}
            src={star}
            alt="Star"
            style={{
              width: "20px",
              height: "20px",
              // margin: "5px",
            }}
          />
        );
      }
      return stars;
    };

    return <div>{renderStars()}</div>;
  };

  return (
    <div className="signup-container shadow p-2 rounded">
      {loader ? (
        <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
          <Circles
            height="50"
            width="50"
            color="#4A5073"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="main-sec">
            <div className="row justify-content-around">
              <div className="col-sm-12 col-md-6 mt-3">
                <div className="card h-100">
                  <div className="card-body">
                    <div
                      className="street-sign d-flex align-items-center"
                      style={{ marginLeft: "10px" }}
                    >
                      <div
                        style={{
                          backgroundColor: "#4A5073",
                          borderRadius: "50%",
                          padding: "5px",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          src={user}
                          alt="Icon"
                          style={{ width: "30px", height: "30px" }}
                        />
                      </div>
                      <div className="first-two-items">
                        <b className="h5">
                          {clientData?.first_name + " " + clientData?.last_name}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 mt-3">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title">Contact info</h5>

                    <div className="info-section">
                      <div className="mb-1">
                        <p className="mb-0">Company name</p>
                        <p>{clientData.company_name}</p>
                      </div>
                      <hr className="my-2" />

                      <div className="mb-1">
                        <p className="mb-0">Number</p>
                        <p>{clientData?.phone}</p>
                      </div>
                      <hr className="my-2" />

                      <div className="mb-1">
                        <p className="mb-0">Email</p>
                        <p>{clientData?.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col xs={12} sm={12} lg={12} style={{ marginTop: "20px" }}>
              <Card className="p-3">
                <h5
                  className="card-title"
                  style={{
                    backgroundColor: "#4A5073",
                    padding: "10px",
                    color: "white",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #ced4da",
                  }}
                >
                  Properties
                </h5>
                <hr />
                <div>
                  {properties?.length === 0 || properties === undefined ? (
                    <div className="d-flex align-items-center mt-3">
                      <div
                        className="p-3 rounded-circle d-flex justify-content-center align-items-center"
                        style={{
                          backgroundColor: "#eef0f2",
                          width: "60px",
                          height: "60px",
                          marginRight: "20px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faHome}
                          style={{ color: "black" }}
                        />
                      </div>
                      <div>
                        <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                          No properties
                        </h5>
                        <p>No properties listed for this client yet</p>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Street 1</th>
                            <th>Street 2</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zipcode</th>
                            {/* Add other table headers as needed */}
                          </tr>
                        </thead>
                        <tbody>
                          {properties?.map((property, index) => (
                            <tr
                              key={index}
                              onClick={() =>
                                history.push(
                                  `/superadmin/propertydetails/${property.property_id}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <td>{property.street_1}</td>
                              <td>{property.street_2}</td>
                              <td>{property.city}</td>
                              <td>{property.state}</td>
                              <td>{property.zipcode}</td>
                              {/* Render other properties as needed */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-1 mt-3">
            <Col>
              <Card className="p-3">
                <h5
                  className="card-title"
                  style={{
                    backgroundColor: "#4A5073",
                    padding: "10px",
                    color: "white",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #ced4da",
                  }}
                >
                  Overview
                </h5>
                <hr />
                <div style={{ fontFamily: "Arial, sans-serif" }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      onClick={() => handleTabClick(1)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor:
                          activeTab === 1 ? "lightblue" : "white",
                        color: activeTab === 1 ? "black" : "gray",
                        cursor: "pointer",
                        marginBottom: "10px", // Add some space between buttons
                      }}
                    >
                      Active Work
                    </button>
                    <button
                      onClick={() => handleTabClick(2)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor:
                          activeTab === 2 ? "lightblue" : "white",
                        color: activeTab === 2 ? "black" : "gray",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      Requests
                    </button>
                    <button
                      onClick={() => handleTabClick(3)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor:
                          activeTab === 3 ? "lightblue" : "white",
                        color: activeTab === 3 ? "black" : "gray",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      Quotes
                    </button>
                    <button
                      onClick={() => handleTabClick(4)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor:
                          activeTab === 4 ? "lightblue" : "white",
                        color: activeTab === 4 ? "black" : "gray",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      Jobs
                    </button>

                    <button
                      onClick={() => handleTabClick(5)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor:
                          activeTab === 5 ? "lightblue" : "white",
                        color: activeTab === 5 ? "black" : "gray",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      Invoices
                    </button>
                  </div>

                  <div
                    style={{
                      border: "1px solid lightblue",
                      borderRadius: "0 0 5px 5px",
                      padding: "15px",
                      maxWidth: "100%",
                      overflowX: "auto",
                    }}
                  >
                    {activeTab === 1 && (
                      // <table className="table">
                      //   <tbody>
                      //     <tr>
                      //       <td>
                      //         <b>Quote #1 </b>
                      //         <br />
                      //         <span
                      //           style={{
                      //             backgroundColor: "silver",
                      //             padding: "3px",
                      //           }}
                      //         >
                      //           Awaiting response
                      //         </span>
                      //       </td>
                      //       <td>
                      //         Created on
                      //         <br />
                      //         Dec 25, 2023
                      //       </td>
                      //       <td>
                      //         jivandeep
                      //         <br />
                      //         sundarnagar
                      //         <br />
                      //         Gandhinagar, Gujarat 896523
                      //       </td>
                      //       <td>
                      //         <b>€30.00</b> <br />
                      //         <img
                      //           src={star}
                      //           alt="Icon"
                      //           style={{
                      //             width: "30px",
                      //             height: "30px",
                      //             margin: "5px",
                      //           }}
                      //         />
                      //       </td>
                      //     </tr>
                      //   </tbody>
                      // </table>

                      <div className="d-flex image-with-content justify-content-justifiy">
                        <div
                          className="image-summary"
                          style={{
                            backgroundColor: "#4A5073", // Circle background color
                            borderRadius: "50%",
                            padding: "5px",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={delivery}
                            alt="Icon"
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "5px",
                            }}
                          />
                        </div>

                        <div>
                          {" "}
                          <b>No active work</b>
                          <br />
                          No active jobs, invoices or quotes for this client yet
                        </div>
                      </div>
                    )}

                    {activeTab === 2 ? (
                      propertyRequestData &&
                      Object.keys(propertyRequestData).length > 0 ? (
                        <table className="table">
                          <tbody>
                            {propertyRequestData?.map((property, index) => (
                              <tr
                                onClick={() =>
                                  history.push(
                                    `/superadmin/requestdetails/${property.request_id}`
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <td>
                                  <b>Request</b>
                                </td>
                                <td>
                                  Requested on
                                  <br />
                                  {property?.request_date}
                                </td>
                                <td>
                                  {property?.property?.street_1},{" "}
                                  {property?.property?.street_2}
                                  <br />
                                  {property?.property?.city}
                                  <br />
                                  {property?.property?.state},{" "}
                                  {property?.property?.country}{" "}
                                  {property?.property?.zipcode}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex image-with-content justify-content-justifiy">
                          <div
                            className="image-summary"
                            style={{
                              backgroundColor: "#4A5073",
                              borderRadius: "50%",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={download}
                              alt="Icon"
                              style={{
                                width: "30px",
                                height: "30px",
                                margin: "5px",
                              }}
                            />
                          </div>

                          <div>
                            {" "}
                            <b>Client hasn't requested any work yet</b>
                            <br />
                            Clients can submit new requests for work online. You
                            and your team can also create requests to keep track
                            of new work that comes up.
                          </div>
                        </div>
                      )
                    ) : null}

                    {activeTab === 3 ? (
                      QuoteData && Object.keys(QuoteData).length > 0 ? (
                        <table className="table">
                          <tbody>
                            {QuoteData?.map((quote, index) => (
                              <tr
                                onClick={() =>
                                  history.push(
                                    `/superadmin/quotes_details/${quote.quotes_id}`
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <td>
                                  <b>{quote?.job_title}</b>
                                </td>
                                <td>{quote?.createdAt}</td>
                                <td>
                                  {quote?.property?.street_1},{" "}
                                  {quote?.property?.street_2}
                                  <br />
                                  {quote?.property?.city}
                                  <br />
                                  {quote?.property?.state},{" "}
                                  {quote?.property?.country}{" "}
                                  {quote?.property?.zipcode}
                                </td>
                                <td>
                                  <b>€{quote?.product_servise_total}</b> <br />
                                  {/* <img
                                    src={star}
                                    alt="Icon"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      margin: "5px",
                                    }}
                                  /> */}
                                  <StarRating rating={quote?.rating} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex image-with-content justify-content-justifiy">
                          <div
                            className="image-summary"
                            style={{
                              backgroundColor: "#4A5073", // Circle background color
                              borderRadius: "50%",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={left_quotes}
                              alt="Icon"
                              style={{
                                width: "30px",
                                height: "30px",
                                margin: "5px",
                              }}
                            />
                          </div>

                          <div>
                            {" "}
                            <b>No Quotes</b>
                            <br />
                            Measure twice, cut once. It's time for some quotes!
                          </div>
                        </div>
                      )
                    ) : null}

                    {activeTab === 4 ? (
                      jobData && Object.keys(jobData).length > 0 ? (
                        <table className="table">
                          <tbody>
                            {jobData?.map((job, index) => (
                              <tr
                                onClick={() =>
                                  history.push(
                                    `/superadmin/jobdetails/${job.job_id}`
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <td>{job?.job_title}</td>
                                <td>
                                  Schedule for
                                  <br />
                                  {job?.schedule_start_date}
                                </td>
                                <td>
                                  {job?.property?.street_1},{" "}
                                  {job?.property?.street_2}
                                  <br />
                                  {job?.property?.city}
                                  <br />
                                  {job?.property?.state},{" "}
                                  {job?.property?.country}{" "}
                                  {job?.property?.zipcode}
                                </td>
                                <td>€{job?.line_items_total}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex image-with-content  justify-content-justifiy">
                          <div
                            className="image-summary"
                            style={{
                              backgroundColor: "#4A5073", // Circle background color
                              borderRadius: "50%",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={hammer}
                              alt="Icon"
                              style={{
                                width: "30px",
                                height: "30px",
                                margin: "5px",
                              }}
                            />
                          </div>

                          <div>
                            {" "}
                            <b>No jobs </b>
                            <br />
                            Let’s get out there and work
                          </div>
                        </div>
                      )
                    ) : null}

                    {/* {activeTab === 5 && (
                      // <table className="table">
                      //   <tbody>
                      //     <tr>
                      //       <td>
                      //         <b>Job #1 -</b> Quotes title
                      //         <br />
                      //         <span
                      //           style={{
                      //             backgroundColor: "silver",
                      //             padding: "3px",
                      //           }}
                      //         >
                      //           Requires invoicing
                      //         </span>
                      //       </td>
                      //       <td>
                      //         COMPLETED
                      //         <br />
                      //         Dec 25, 2023
                      //       </td>
                      //       <td>
                      //         <b>€10.00</b>
                      //       </td>
                      //     </tr>
                      //   </tbody>
                      // </table>

                      <div className="d-flex image-with-content justify-content-justifiy">
                        <div
                          className="image-summary"
                          style={{
                            backgroundColor: "#4A5073", // Circle background color
                            borderRadius: "50%",
                            padding: "5px",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={invoice}
                            alt="Icon"
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "5px",
                            }}
                          />
                        </div>

                        <div>
                          {" "}
                          <b>No invoices </b>
                          <br />
                          There are no current invoices for this client yet
                        </div>
                      </div>
                    )} */}

                    {activeTab === 5 ? (
                      invoiceData && Object.keys(invoiceData).length > 0 ? (
                        <table className="table">
                          <tbody>
                            {invoiceData?.map((job, index) => (
                              <tr
                                onClick={() =>
                                  history.push(
                                    `/superadmin/invoicedetails/${job.invoice_id}`
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <td>Invoice</td>
                                <td>{job?.createdAt}</td>
                                <td>{job?.invoice_subject}</td>
                                <td>€{job?.product_servise_total}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="d-flex image-with-content justify-content-justifiy">
                          <div
                            className="image-summary"
                            style={{
                              backgroundColor: "#4A5073", // Circle background color
                              borderRadius: "50%",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={invoice}
                              alt="Icon"
                              style={{
                                width: "30px",
                                height: "30px",
                                margin: "5px",
                              }}
                            />
                          </div>

                          <div>
                            {" "}
                            <b>No invoices </b>
                            <br />
                            There are no current invoices for this client yet
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          {/* <Row className="mt-3">
          <Col>
            <Card>
              <Card.Body>
                <h5
                  className="card-title"
                  style={{
                    backgroundColor: "#4A5073",
                    padding: "10px",
                    color: "white",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #ced4da",
                  }}
                >
                  Schedule
                </h5>
                <hr />
                <div className="d-flex align-items-center mt-3">
                  <div
                    className="p-3 rounded-circle d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#eef0f2",
                      width: "60px",
                      height: "60px",
                      marginRight: "20px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                      No scheduled items
                    </h5>
                    <p>Nothing is scheduled for this client yet</p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
          {/* <Row className="mt-3">
          <Col xs={12}>
            <Card className="p-3">
              <h5
                className="card-title"
                style={{
                  backgroundColor: "#4A5073",
                  padding: "10px",
                  color: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ced4da",
                }}
              >
                Tag
              </h5>
              <hr />
              <div className="d-flex align-items-center mt-3">
                <div
                  className="p-3 rounded-circle d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#eef0f2",
                    width: "60px",
                    height: "60px",
                    marginRight: "20px",
                  }}
                >
                  <FontAwesomeIcon icon={faTag} style={{ color: "black" }} />
                </div>
                <div>
                  <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                    No Tags
                  </h5>
                  <p>This client has no tags</p>
                </div>
              </div>
            </Card>
          </Col>
        </Row> */}
          {/* <Row className="mt-3">
          <Col xs={12}>
            <Card className="p-3">
              <h5
                className="card-title"
                style={{
                  backgroundColor: "#4A5073",
                  padding: "10px",
                  color: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ced4da",
                }}
              >
                Last Client Communication
              </h5>
              <hr />
              <div className="d-flex align-items-center mt-3">
                <div
                  className="p-3 rounded-circle d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#eef0f2",
                    width: "60px",
                    height: "60px",
                    marginRight: "20px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faComment}
                    style={{ color: "black" }}
                  />
                </div>
                <div>
                  <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                    You haven't sent any client communications yet
                  </h5>
                </div>
              </div>
            </Card>
          </Col>
        </Row> */}
          {/* <Row className="mt-3">
          <Col xs={12}>
            <Card className="p-3">
              <h5
                className="card-title"
                style={{
                  backgroundColor: "#4A5073",
                  padding: "10px",
                  color: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ced4da",
                }}
              >
                Billing History
              </h5>
              <hr />
              <div className="d-flex align-items-center mt-3">
                <div
                  className="p-3 rounded-circle d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#eef0f2",
                    width: "60px",
                    height: "60px",
                    marginRight: "20px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    style={{ color: "black" }}
                  />
                </div>
                <div>
                  <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                    No billing history
                  </h5>
                  <p>This client hasn't been billed yet</p>
                </div>
              </div>
              <hr className="my-3" />
              <div className="d-flex justify-content-between">
                <p className="fw-bold mb-0">Current balance</p>
                <p className="fw-bold mb-0">€0.00</p>
              </div>
            </Card>
          </Col>
        </Row> */}
        </>
      )}
      <Toaster />
    </div>
  );
}

export default ClientSummary;
