import { Button } from "@themesberg/react-bootstrap";
import React , {useState, useEffect}from "react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';
import { Col, Form, Card, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import JobTable from "./JobTable";

function JobDashboard() {
  const history = useHistory();
 
  
  return (
    <div className="p-4">
      <div className="d-flex w-100 mb-4 justify-content-between">
        <p className="heading-form text-dark">Jobs</p>
        <Button
          variant="success"
          size="sm"
          style={{ maxHeight: 40 ,backgroundColor:"#e91818",border:"none"}}
          className="me-2"
          onClick={() => {
            history.push("/job/new")
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          New Job
        </Button>
      </div>
      {/* <div  className="w-100 align-items-end justify-content-end d-flex">
        <InputGroup style={{maxWidth:200,alignSelf:'flex-end'}} className='mx-2 mb-1'>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control type="text" name="search" placeholder="Search" />
        </InputGroup>
      </div> */}

      <div className="m-2 p-2  border rounded">
        <JobTable />
      </div>
    </div>
  );
}

export default JobDashboard;
