import { Nav, Dropdown, Container, ListGroup, ToggleButtonGroup, ToggleButton, FormCheck } from '@themesberg/react-bootstrap';
import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBriefcase, faPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {colors} from "../config/colors";
import {
  faBuilding,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import Jobsidebar from "./Jobsidebar"
// import "./style.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.6.1/css/bootstrap4-toggle.min.css"; // Import the Bootstrap Toggle CSS
import {
  Col,
  Row,
  Form,
  Button,
  InputGroup,
  Modal,
  Spinner,
  Collapse,
  Tab, Tabs,
  Table, Card, ButtonGroup,
} from "@themesberg/react-bootstrap";

import './job.css'
import Cookies from "universal-cookie";
import toast, { Toaster } from 'react-hot-toast';
import { Routes } from "../routes";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../config/AxiosInstance";
import { faAngleDown, faAngleUp, faTrash, faEdit, faTextWidth, faCamera, faFileAlt, faTimesCircle, } from "@fortawesome/free-solid-svg-icons";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const CustomToolbar = ({ label, onNavigate }) => {
  const goToToday = () => {
    const now = new Date();
    onNavigate('date', now);
  };

  return (
    <div className="rbc-toolbar">
      <Button type="button" variant="primary" className="btn btn-primary" onClick={() => onNavigate('PREV')} size={"sm"} >
        <i class="fa-solid fa-chevron-left"></i>
      </Button>
      <Button onClick={goToToday} >
        Today
      </Button>
      <Button onClick={() => onNavigate('NEXT')} >
        <i class="fa-solid fa-chevron-right"></i>
      </Button>
      <span className="rbc-toolbar-label">{label}</span>
    </div>
  );
};


const iosStyle = {
  toggle: {
    borderRadius: "20rem",
  },
  toggleHandle: {
    borderRadius: "20rem",
  },
  toggleOn: {
    borderRadius: "20rem",
  },
  toggleOff: {
    borderRadius: "20rem",
  },
};
function NewJobForm({
  handleInputCheckbox, handleDropdownChange, data,
}) {

  const cookies = new Cookies()
  const handleToggleChange = () => {
    setIsAssessmentRequired(!isAssessmentRequired);
  };

  const [isAssessmentRequired, setIsAssessmentRequired] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setShowPropertyModal(false);
  };
  const [selectedArrivalTimes, setSelectedArrivalTimes] = useState([]);
  const [internalLinks, setInternalLinks] = useState([]);
  useEffect(() => {
    handleInputCheckbox({ name: 'preferred_arrival_times', value: selectedArrivalTimes })
    handleInputCheckbox({ name: 'internal_link_note_to_related', value: internalLinks })
  }, [selectedArrivalTimes, internalLinks])
  const [open, setOpen] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleSubmitClient = () => {
    handleClose();

  }
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };


  const [startTime, setStartTime] = useState('12:00');
  const [endTime, setEndTime] = useState('18:00');
  const [scheduleLater, setScheduleLater] = useState(false);


  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };
  const handleScheduleLaterChange = () => {
    setScheduleLater(!scheduleLater);
  };

  const [entries, setEntries] = useState([
    { name: '', description: '', part_qty: '', part_price: '', total_amount: '' },
  ]);
  const updateEntry = (e, index) => {
    const { name, value } = e.target;
    const updatedEntries = [...entries];
    updatedEntries[index][name] = value;
    const totalAmount = updatedEntries[index].part_qty * updatedEntries[index].part_price || 0;
    updatedEntries[index].total_amount = totalAmount;
    setEntries(updatedEntries);
  };
  const deleteEntry = (index) => {
    const updatedEntries = [...entries];
    updatedEntries.splice(index, 1);
    setEntries(updatedEntries);
  };
  const addNewRow = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    setEntries([
      ...entries,
      { name: '', description: '', part_qty: '', part_price: '', total_amount: '' },
    ]);
  };


  const calculateTotalPartPrice = () => {
    return entries.reduce((total, entry) => total + parseFloat(entry.part_qty * entry.part_price) || 0, 0);
  };

  const calculateTotalAmount = () => {
    return entries.reduce((total, entry) => total + parseFloat(entry.part_price) || 0, 0);
  };
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([
    {
      title: 'Event 1',
      start: new Date(2024, 0, 1),
      end: new Date(2024, 0, 2),
    },
    {
      title: 'Event 2',
      start: new Date(2024, 1, 24),
      end: new Date(2024, 1, 25),
    },
    {
      title: 'Event 3',
      start: new Date(2024, 2, 15),
      end: new Date(2024, 2, 16),
    },
  ]);

  const handleEventClick = (event) => {
    alert(`Event clicked: ${event.title}`);
  };



  const [fieldName, setFieldName] = useState('');
  const [fieldValue, setFieldValue] = useState('');

  const [fields, setFields] = useState([]);

  const handleSaveChanges = () => {
    const newField = {
      name: fieldName,
      value: fieldValue,
    };

    setFields((prevFields) => [...prevFields, newField]);

    setFieldName('');
    setFieldValue('');
    setShow(false);
  };
  useEffect(() => {

  }, [fieldName, fieldValue]);
  const removeRow = (index) => {
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };

  const [editIndex, setEditIndex] = useState(null);
  const [editName, setEditName] = useState('');
  const [editValue, setEditValue] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditRow = (index) => {
    const rowToEdit = fields[index];
    setEditIndex(index);
    setEditName(rowToEdit.name);
    setEditValue(rowToEdit.value);
    setShowEditModal(true);
  };
  const handleSaveEdit = () => {
    if (editIndex !== null) {
      setFields((prevFields) =>
        prevFields.map((field, i) =>
          i === editIndex ? { ...field, name: editName, value: editValue } : field
        )
      );
    }

    // Reset edit state and close modal
    setEditIndex(null);
    setEditName('');
    setEditValue('');
    setShowEditModal(false);
  };
  const [userData, setUserData] = useState([])

  const [products, setProducts] = useState([]);

  const fetchProductsServices = async () => {
    try {
      const userData = cookies.get("userDetails");

      const response = await axiosInstance.get(
        `/product/product_service/${userData.user_id}`
      );
      if (response.data.data) {
        setProducts(response.data.data);
      }
    } catch (error) {
      console.log("error in getting fetch products in new quotes form:", error);
    } finally {
      setLoadingProperties(false);
    }
  };

  useEffect(() => {
    // Fetch data from the API
    const userData = cookies.get('userDetails')
    fetch(`https://jobmanager.cloudpress.host/api/product/product_service/${userData?.user_id}`)
      .then((response) => response.json())
      .then((data) => {
        // Update the state with the fetched products
        setProducts(data.data);
      })
      .catch((error) => console.error("Error fetching data:", error));

    fetchProductsServices();
  }, []);

  // useEffect(() => {
  //   fetchClients();
  // }, []);

  function handleTeamName() {
    handleInputCheckbox({ name: "team_name", value: userData.username })
  }

  const [formData, setFormData] = useState({
    user_id: '',
    client_id: '',
    property_id: '',
    job_title: '',
    job_instructions: '',
    job_details_field: [],
    is_one_off_job: false,
    is_recuring_job: false,
    schedule_start_date: '',
    schedule_end_date: '',
    schedule_start_time: '',
    schedule_end_time: '',
    is_schedule_later: false,
    schedule_repeats: '',
    schedule_duration: '',
    team_name: '',
    line_items: [],
    internal_notes_detais: '',
    internal_notes_attachments: '',
    internal_link_note_to_related: '',
    line_items_total: '',
  });
  const [activeTab, setActiveTab] = useState("tab1"); // Default to the first tab

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleCreateJob = async () => {
    try {

      const { user_id, property_id, client_id } = selectedProperty;
      const totalPartPrice = calculateTotalPartPrice();
      const isOneOffJob = activeTab === 'tab1';
      const isRecurringJob = activeTab === 'tab2';


      const response = await axiosInstance.post('/job/job', {
        ...formData,
        user_id: user_id,
        property_id: property_id,
        client_id: client_id,
        job_details_field: fields,
        line_items: entries,
        team_name: userData?.username,
        line_items_total: totalPartPrice,
        is_one_off_job: isOneOffJob,
        is_recuring_job: isRecurringJob,
      });
      toast.success('Job created successfully!', {
        position: 'top-center',
      });

      setTimeout(() => {
        history.push(Routes.JobDashboard.path)
      }, 1000);

      console.log('Server response:', response.data);
      setFormData({
        user_id: '',
        client_id: '',
        property_id: '',
        job_title: '',
        job_instructions: '',
        job_details_field: [],
        is_one_off_job: false,
        is_recuring_job: false,
        schedule_start_date: '',
        schedule_end_date: '',
        schedule_start_time: '',
        schedule_end_time: '',
        is_schedule_later: false,
        schedule_repeats: '',
        schedule_duration: '',
        team_name: '',
        line_items: [],
        internal_notes_detais: '',
        internal_notes_attachments: '',
        internal_link_note_to_related: '',
        line_items_total: '',
      });
    } catch (error) {
      toast.error('Error creating job. Please try again.', {
        position: 'top-center',
      });
      console.error('Error creating job:', error);
    }
  };


  const [selectedOption, setSelectedOption] = useState('');
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [loadingClients, setLoadingClients] = useState(true);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);  // Initialize with an empty array
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedProperty, setSelectedProperty] = useState([])
  const [properties, setProperties] = useState([]);
  const [loadingProperties, setLoadingProperties] = useState(true)
  const history = useHistory()


  const fetchProperty = async (properyId) => {
    try {
      const response = await axiosInstance.get(`/request/property/${properyId}`);
      const data = response.data.data[0]

      setSelectedProperty(data)
      handleInputCheckbox({ "name": "client_id", value: data?.client_id })
      handleInputCheckbox({ "name": "property_id", value: data?.property_id })
      // handleInputCheckbox({"name": "first_name", value: data[0].first_name})
      // handleInputCheckbox({"name": "last_name", value: data[0].last_name})
      // handleInputCheckbox({"name": "email", value: data[0].email})
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error);
      toast.error("We are Troubleshooting... try again later")
    } finally {
      setLoadingProperties(false);
      handleCloseModal();
    }
  }


  const filteredProperties = properties?.filter(
    (property) =>
      property.street_1.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (property.street_2 &&
        property.street2.toLowerCase().includes(searchTerm.toLowerCase())) ||
      property.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.zipcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.country.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const fetchClients = async () => {
    try {
      const userData = cookies.get('userDetails');
      setUserData(userData);
      const response = await axiosInstance.get(`/request/request_client/${userData.user_id}`);
      if (response.data.data) {
        const fetchedClients = response.data.data;
        setClients(fetchedClients);
        setFilteredClients(fetchedClients);

      }
    } catch (error) {
      console.log("error in getting fetchClients in new request form:", error);
    } finally {
      setLoadingClients(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchProperties = async (clientId) => {
    try {
      setSelectedClientId(clientId)
      const response = await axiosInstance.get(`/request/request_property/${clientId}`);
      if (response.data.data) {
        setProperties(response.data.data)

      }
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error)
    } finally {
      setLoadingProperties(false);
    }
  }
  const handleOpenPropertyModal = (id) => {
    // Open the modal
    setShowModal(false);
    setShowPropertyModal(true);

    fetchProperties(id);
  };

  const renderTable = () => {
    if (loadingClients) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            Loading...
          </td>
        </tr>
      );
    }

    if (!filteredClients || filteredClients.length === 0) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            No clients found
          </td>
        </tr>
      );
    }

    return filteredClients.map((client) => (
      <tr key={client.client_id} style={{ cursor: 'pointer' }} onClick={() => handleOpenPropertyModal(client.client_id)}>
        <td style={{ paddingLeft: 40 }}>
          {client.company_name ? (
            <FontAwesomeIcon icon={faBuilding} className="me-2" />
          ) : (
            <FontAwesomeIcon icon={faUser} className="me-2" />
          )}
        </td>
        <td>
          {client.company_name ? (
            <>
              <div>{client.company_name} ({client.first_name})</div>
              <div>
                {client.properties} Properties | {client.propertyCount}
              </div>
            </>
          ) : (
            <>
              <div>{client.first_name}</div>
              <div>
                {client.properties} Properties | {client.propertyCount}
              </div>
            </>
          )}
        </td>
        <td>{client.updatedAt}</td>
      </tr>
    ));
  };
  const renderProperty = () => {

    if (loadingProperties) {
      return (
        <div className="w-100 align-items-center d-flex justify-content-center py-2">
          <Spinner animation="grow" />
        </div>
      )
    }

    if (properties?.length === 0 || properties == undefined) {
      return <div className="w-100 align-items-center d-flex justify-content-center py-2">Properties Not found - Please Create Property</div>;
    }

    if (filteredProperties?.length === 0) {
      return <div className="w-100 align-items-center d-flex justify-content-center py-2">No results for "{searchTerm}"</div>;
    }

    return filteredProperties?.map((property, index) => (
      <div key={property.id} style={{ marginBottom: "10px", marginTop: "5px", cursor: 'pointer' }} onClick={() => { fetchProperty(property.property_id) }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 5,
            }}
          >
            <span>{`${property.street_1}${property.street_2 ? `, ${property.street_2}` : ""
              }, ${property.city} - ${property.zipcode}, ${property.state}, ${property.country
              }`}</span>
          </div>
          <div style={{ marginRight: "10px", paddingRight: 20 }}>
            <span>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </div>
        </div>
        {index < filteredProperties?.length - 1 && (
          <hr
            style={{
              width: "100%",
              margin: "5px 0",
              border: "1px solid #ccc",
            }}
          />
        )}
      </div>
    ));
  };
  const repeatsOptions = [
    { value: 'weeklyMondays', label: 'Weekly on Mondays' },
    { value: 'every2WeeksMondays', label: 'Every 2 Weeks on Mondays' },
    { value: 'monthly11th', label: 'Monthly on the 11th of the Month' },
    // Add more options as needed
  ];

  const durationOptions = [
    { value: 'days', label: 'day(s)' },
    { value: 'weeks', label: 'week(s)' },
    { value: 'months', label: 'Month(s)' },
    { value: 'years', label: 'Year(s)' },
  ];
  const [editMode, setEditMode] = useState(false);
  const handleEditClick = () => {
    setEditMode(true);
  };

  const [quotesId, setQuotesId] = useState(null);
  const [job_id, setjob_id] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const quotesIdFromUrl = queryParams.get('quotes_id');
    const jobIdFromUrl = queryParams.get('job_id');

    setQuotesId(quotesIdFromUrl);
    setjob_id(jobIdFromUrl);

    // if (quotesIdFromUrl) {
    //   // alert(`Quotes ID: ${quotesIdFromUrl}`);
    // } else if (jobIdFromUrl) {
    //   // alert(`Job ID: ${jobIdFromUrl}`);
    // } else {
    //   alert('Quotes ID or Job ID not found in the URL.');
    // }
  }, [location.search]);


  const [quoteData, setQuoteData] = useState([]);
  const [requestData, setRequestData] = useState([]);

  // console.log(requestData, "requestData -----------")

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (quotesId) {

          const response = await axiosInstance.get(`/quotes/quotes_get/${quotesId}`);

          if (response.data.data) {
            // console.log(response?.data.data, "requestData -----------")

            const responseRequest = await axiosInstance.get(
              `/request/request_clientdata/${response?.data?.data[0]?.request_id}`
            );
            if (responseRequest.data.data) {
              setRequestData(responseRequest?.data?.data);
            }
          }
          setQuoteData(response.data.data);
          setEntries(response?.data?.data[0]?.product_servise)
          const property = {
            ...response?.data.data[0].propertyData,
            client: response?.data.data[0].clientData
          };
          setSelectedProperty(property);
          // console.log(response.data.data[0], 'response.data.data[0]');

          if (response.data.data) {
            console.log(response?.data, "requestData -----------")

            const responseRequest = await axiosInstance.get(
              `/request/request_clientdata/${response?.data?.data[0]?.request_id}`
            );
            if (responseRequest.data.data) {
              setRequestData(responseRequest?.data?.data);
            } else {
              console.warn("request id not found")
            }
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [quotesId]);



  const handleSubmit = async () => {
    try {
      //  console.log('Submitting form with data:', formData);

      const url = `https://jobmanager.cloudpress.host/api/job/job/${job_id}`;
      const response = await axios.put(url, formData);

      // console.log('Update Response:', response.data);
      toast.success('Job Updated successfully!', {
        position: 'top-center',
      });
      setTimeout(() => {
        history.push(Routes.JobDashboard.path);
      }, 1000);
      setEditMode(false);

      fetchData();
    } catch (error) {
      console.error('Update Error:', error.message);
    }
  };



  const fetchData = async () => {
    try {
      const response = await fetch(`https://jobmanager.cloudpress.host/api/job/job_data/${job_id}`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      // setResponseData(result.data);
      // setFormData(result.data[0]);
      if (result.data) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          client_id: result?.data[0].client_id,
          user_id: result?.data[0].user_id,
          property_id: result?.data[0].property_id,
          job_id: result?.data[0].job_id,
          job_title: result?.data[0].job_title,
          job_instructions: result?.data[0].job_instructions,
          job_details_field: result?.data[0].job_details_field,
          is_one_off_job: result?.data[0].is_one_off_job,
          is_recuring_job: result?.data[0].is_recuring_job,
          schedule_start_date: result?.data[0].schedule_start_date,
          schedule_end_date: result?.data[0].schedule_end_date,
          schedule_start_time: result?.data[0].schedule_start_time,
          schedule_end_time: result?.data[0].schedule_end_time,
          schedule_repeats: result?.data[0].schedule_repeats,
          schedule_duration: result?.data[0].schedule_duration,
          team_name: result?.data[0].team_name,
          line_items: result?.data[0].line_items,
          internal_notes_detais: result?.data[0].internal_notes_detais,
          // internal_notes_attachments: result?.data[0].internal_notes_attachments
        }));
        setFields(result?.data[0].job_details_field)
        setEntries(result?.data[0].line_items)


        const property = {
          ...result?.data[0].propertyData,
          client: result?.data[0].clientData
        }
        setSelectedProperty(property)
      } else {
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [job_id]);

  

  useEffect(() => {
    fetchData();
  }, [job_id]);

  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisibility(!isSidebarVisible);
  };
  const [isButtonDisable, setIsButtonDisable] = useState(false);


  return (
    <div >

      <h3 style={{ cursor: 'pointer' }} className='d-flex align-items-center text-dark' onClick={handleOpenModal}>
        {/* <FontAwesomeIcon icon={faUser} /> &nbsp; */}
        Job for {"client Name"} &nbsp;
        <Button type="button" className="btn btn-primary" size={"sm"} style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
          +
        </Button>
      </h3>
      {requestData && requestData.length !== 0 && (
        <Jobsidebar
          isVisible={isSidebarVisible}
          quoteData={quoteData}
          requestData={requestData}
          onClose={toggleSidebar}
        />
      )}

      {
        selectedProperty.length != 0
          ?
          <Row>
            <Col xs={12} className="mt-2" >
              <hr />
            </Col>
            <Col md={6} lg={6} sm={12} >

              <Form.Label style={{ fontSize: 'large' }} className="text-dark">Property Address</Form.Label>
              <Form.Group >
                <Form.Label>{selectedProperty?.street_1}{selectedProperty.street_2 ? ", " + selectedProperty?.street_2 : null},<br /> {selectedProperty?.city}, {selectedProperty?.state}, {selectedProperty?.zipcode}</Form.Label>
                <br />
                <br />
                <Form.Label onClick={() => { handleOpenPropertyModal(); }} className="ml-2 text-dark" style={{ cursor: 'pointer' }}>Change</Form.Label>
              </Form.Group>
            </Col>
            <Col md={6} lg={6} sm={12}>
              <Form.Label style={{ fontSize: 'large' }} className="text-dark">Client Details</Form.Label>
              <Form.Group>
                <Form.Label className="text-dark">{selectedProperty?.client?.first_name + " " + selectedProperty?.client?.last_name} </Form.Label><br />
                <Form.Label>{selectedProperty?.client?.phone} </Form.Label><br />
                <Form.Label>{selectedProperty?.client?.email} </Form.Label><br />
              </Form.Group>
            </Col>
          </Row>
          :
          null
      }

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select or Create a Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-4" >
            <p className="mb-2 ml-2">
              Which client would you like to create this for?
            </p>
            <div className="border border-dark" >
              <div
                style={{
                  height: 70,
                  justifyContent: "center",
                  backgroundColor: "lightgray",
                  alignItems: "center",
                }}
                className="d-flex align-items-center mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Search client..."
                  style={{
                    border: "none",
                    width: "130px",
                    borderRadius: "0",
                  }}
                // onChange={handleSearchChange}  
                />
                <p className="mx-2 my-2">OR</p>
                <Button
                  variant="primary"
                  className=""
                  onClick={() => history.push(Routes.Client.path)}
                  style={{backgroundColor:colors.red,color:colors.white,border:"none"}}
                >
                  Create New Client
                </Button>
              </div>
              <tr className="my-3">
                <label style={{ marginLeft: '10px' }}> Leads</label>{" "}
              </tr>
              <Col xs={12} className="mb-2">
                <hr />
              </Col>

              <table className="table table-striped">
                <tbody>{renderTable()}</tbody>
              </table>
              <tr>
                {" "}
                <label style={{ marginLeft: '10px' }}> Archived </label>{" "}
              </tr>
              <Col xs={12} className="mb-2">
                <hr />
              </Col>
              <table className="table table-striped">
                <tbody>{renderTable()}</tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button> */}

        </Modal.Footer>
      </Modal>
      <Modal show={showPropertyModal} centered onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select or create a property...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add your content for client selection here */}

          <div className="p-4">
            <p className="mb-2 ml-2">
              Which property would you like to use for this?
            </p>
            <div className="border border-dark" >
              <div
                style={{
                  height: 70,
                  justifyContent: "center",
                  backgroundColor: "lightgray",
                  alignItems: "center",
                }}
                className="d-flex align-items-center"
              >
                <Form.Control
                  type="text"
                  placeholder="Search properties..."
                  style={{
                    border: "none",
                    width: "150px",
                    fontSize: "13px",
                    borderRadius: "0",
                  }}
                // onChange={handleSearchpropertyChange}
                />
                <p className="mx-2 my-2">OR</p>
                <Button
                  variant="primary"
                  className=""
                  style={{
                    fontSize: "14px",
                    backgroundColor:colors.red,color:colors.white,border:"none"
                  }}
                  onClick={() => history.push(`/properties/${selectedClientId}`)}
                >
                  Create New Property
                </Button>
              </div>

              <div>{renderProperty()}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Row>
        <Col xs={12} className="mt-2 " >
          <hr />
        </Col>
        <Col md={12} lg={12} sm={12} className='mb-5'>
          <Form.Label style={{ fontSize: 'large' }} className="text-dark">Job Title</Form.Label>
          <Form.Group >
            <Form.Control
              autoFocus
              required
              className='mb-3'
              type="text"
              name="job_title" value={formData.job_title} onChange={handleInputChange}
              placeholder="job title"
            />
          </Form.Group>
          <Form.Label style={{ fontSize: 'large' }} className="text-dark">Description</Form.Label>
          <Form.Group >
            <Form.Control as="textarea" className='mb-3' name="job_instructions" value={formData.job_instructions} onChange={handleInputChange} rows={3} placeholder="Description....." />
          </Form.Group>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col md={12} lg={12} sm={12}>
          <div className='d-flex justify-content-between mb-3'
            aria-controls="example-collapse-text"
            aria-expanded={open}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(!open)}>
            <h5 className='text-dark'>Job  Details</h5>
            <h4 className='text-dark'><FontAwesomeIcon icon={!open ? faAngleDown : faAngleUp} /></h4>
          </div>
          <Collapse in={open} className='px-1 mb-0'>
            <div id="example-collapse-text">
              {fields.map((field, index) => (
                <div key={index}>

                  <Row>
                    <Col className="mb-4" md={5} lg={3} >
                      {/* Input for something else, adjust as needed */}
                      <Form.Group >
                        <Form.Control
                          type="text"
                          placeholder="Field Name"
                          autoFocus
                          value={field.name}
                          onChange={(e) => setFieldName(e.target.value)}
                        />

                      </Form.Group>
                    </Col>
                    <Col className="mb-4" md={5} lg={3} >
                      {/* Input for something else, adjust as needed */}
                      <Form.Group >
                        <Form.Control readOnly={true} type="text" placeholder="Job Number" value={field.value} />
                      </Form.Group>
                    </Col>
                    <Col md={1} lg={1}>
                      <div className='d-flex '>
                        <Button variant="outline-primary" onClick={() => removeRow(index)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button variant="outline-primary" className='mx-2' onClick={() => handleEditRow(index)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </div>
                    </Col>


                  </Row>
                </div>
              ))}

            </div>
          </Collapse>
        </Col>
      </Row>
      <Button onClick={() => { setShow(true) }} variant="outline-primary" className="w-100 " style={{ maxWidth: 150,backgroundColor:colors.red,color:colors.white,border:"none" }}>
        <p style={{ fontSize: '0.9rem' }}>Add Custom Field</p>
      </Button>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Row</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Edit Name"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="editValue">
              <Form.Label>Value</Form.Label>
              <Form.Control
                type="text"
                placeholder="Edit Value"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => handleSaveEdit()} style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Client Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Field Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Field Name"
                autoFocus
                value={fieldName}
                onChange={(e) => setFieldName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Field Value</Form.Label>
              <Form.Control
                type="text"
                placeholder="Value"
                autoFocus
                value={fieldValue}
                onChange={(e) => setFieldValue(e.target.value)}

              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" className='dash-btn' onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges} style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Form className=" p-0">
        <div>
          <Row>
            <Col xs={12} className="mb-2">
              <hr />
            </Col>
          </Row>
        </div>
        <>
          <div
            style={{
              border: "1px solid #ccc",
              padding: "15px",
              borderRadius: "10px",
              marginBottom: "15px",
            }}
          >
            <Row className="mb-2">
              <Col>
                {/* <Form.Label>Assessment</Form.Label> */}
              </Col>
            </Row>
            <Row className="justify-content-between tabs-nav lg-12 w-100 mb-2">
              <Col >
                <Tabs defaultActiveKey="tab1" id="tabs">
                  <Tab eventKey="tab1" st title="One-Off Job" >
                    <Row>
                      <Col md={12} lg={5} sm={12}>
                        <Card className="border mb-3 my-3">
                          <Card.Header style={{ backgroundColor: '#eeeeee', fontSize: '15px' }}>Schedule</Card.Header>
                          <Card.Body className="w-100 px-2">
                            <Form>
                              <Row className="mb-3">
                                <Form.Group as={Col} controlId="startDate">
                                  <Form.Label>Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    className='mb-3'
                                    name="schedule_start_date"
                                    value={formData.schedule_start_date} onChange={handleInputChange}
                                    disabled={scheduleLater} // Disable if scheduleLater is true
                                  />
                                </Form.Group>
                                <Form.Group as={Col} controlId="endDate">
                                  <Form.Label>End Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="schedule_end_date"
                                    value={formData.schedule_end_date} onChange={handleInputChange}
                                    disabled={scheduleLater} // Disable if scheduleLater is true
                                  />
                                </Form.Group>
                              </Row>

                              <Row className="mb-3">
                                <Form.Group as={Col} controlId="startTime">
                                  <Form.Label>Start Time</Form.Label>
                                  <Form.Control
                                    type="time"
                                    name="schedule_start_time"
                                    value={formData.schedule_start_time} onChange={handleInputChange}
                                    disabled={scheduleLater} // Disable if scheduleLater is true
                                  />
                                </Form.Group>
                                <Form.Group as={Col} controlId="endTime">
                                  <Form.Label>End Time</Form.Label>
                                  <Form.Control
                                    type="time"
                                    name="schedule_end_time"
                                    value={formData.schedule_end_time} onChange={handleInputChange}
                                    disabled={scheduleLater} // Disable if scheduleLater is true
                                  />
                                </Form.Group>
                              </Row>
                              <Form.Group as={Row} className="mb-3">
                                <Form.Check
                                  type="checkbox"
                                  label="Schedule later"
                                  className="mb-0 px-5 my-2"
                                  style={{ marginRight: '10px' }}
                                  checked={scheduleLater}
                                  onChange={handleScheduleLaterChange}
                                />
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                        {/* <div className="card border mb-3 my-3">
                          <div className="card-header" style={{ backgroundColor: '#eeeeee', fontSize: '15px' }}>Team</div>
                          <div className="card-body w-100 px-2">
                            <h6>no user are currently assigned</h6>
                          </div>
                        </div> */}
                        <Card style={{ padding: '15px', marginTop: 20 }}>
                          <Card.Body>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Form.Label size="large">Teams &nbsp;</Form.Label>

                              <Dropdown>
                                <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications">
                                  <Button variant="success" size="sm" style={{ fontSize: '12px' }} >
                                    &nbsp;+ Assign&nbsp;
                                  </Button>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="multi-select-dropdown">
                                  <ListGroup className="list-group-flush">
                                    <Nav.Link href="#" className="text-primary fw-bold border-bottom border-light py-1 mb-1 bg-soft">
                                      Select Team
                                    </Nav.Link>
                                    <Dropdown.Item onClick={handleTeamName}>
                                      {userData?.username}
                                    </Dropdown.Item>
                                  </ListGroup>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div>
                              <hr />
                            </div>
                            {
                              data.team_name ? (
                                <>
                                  <ToggleButtonGroup type="radio" name="options">
                                    <ToggleButton size="sm" style={{ fontSize: 12 }} id="tbg-radio-1">
                                      {data.team_name}
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{ fontSize: 12 }}
                                      onClick={() => handleInputCheckbox({ name: 'team_name', value: '' })}
                                      size="sm"
                                      id="tbg-radio-2"
                                    >
                                      X
                                    </ToggleButton>
                                  </ToggleButtonGroup>

                                  <Form.Group id="dropdown-revenue" className="mb-3 mt-3" style={{ maxWidth: 200 }}>
                                    <Form.Label>Team Reminder</Form.Label>
                                    <Form.Select
                                      name="selectedOption"
                                      value={data.team_reminder}
                                      onChange={(e) => handleDropdownChange('team_reminder', e)}
                                    >
                                      <option value="">Choose Here</option>
                                      <option value="At start of Task">At start of Task</option>
                                      <option value="30 min Before">30 min Before</option>
                                      <option value="1 hour Before">1 hour Before</option>
                                      <option value="2 hours Before">2 hours Before</option>
                                      {/* Add more options as needed */}
                                    </Form.Select>
                                  </Form.Group>

                                  <Form.Check type="checkbox" className="mt-2" style={{ marginLeft: 2 }}>
                                    <Form.Check.Input
                                      onClick={() => handleInputCheckbox({ name: 'is_email_team_about_assignment', value: !data.is_email_team_about_assignment })}
                                      id="defaultCheck5"
                                      className="me-2"
                                      checked={data.is_email_team_about_assignment}
                                    />
                                    <Form.Check.Label htmlFor="defaultCheck5" className="mb-0">
                                      Email team about assignment
                                    </Form.Check.Label>
                                  </Form.Check>
                                </>
                              ) : (
                                <Form.Label>No users are currently assigned</Form.Label>
                              )}
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={12} lg={7} sm={12} className='my-5 calendar1'>
                        <div class="calendar1">
                          <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            className='calendar1'
                            style={{ height: 500 }}
                            onSelectEvent={handleEventClick}
                            components={{
                              toolbar: (props) => (
                                <CustomToolbar {...props} onNavigate={props.onNavigate} />
                              ),
                            }}
                          />
                        </div>

                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="tab2" title="Recurring Job">
                    <Row>
                      <Col md={12} lg={5} sm={12} >
                        <Card className="border mb-3 my-3">
                          <Card.Header style={{ backgroundColor: '#eeeeee', fontSize: '15px' }}>Schedule</Card.Header>
                          <Card.Body className="w-100 px-2">
                            <Form>
                              <Row className="mb-3">
                                <Form.Group as={Col} controlId="startDate">
                                  <Form.Label>Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    className='mb-3'
                                    name="schedule_start_date"
                                    value={formData.schedule_start_date} onChange={handleInputChange}

                                  />
                                </Form.Group>
                                {/* <Form.Group as={Col} controlId="endDate">
                                  <Form.Label>End Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                  // value={startTime}
                                  // onChange={handleStartTimeChange}
                                  />
                                </Form.Group> */}
                              </Row>
                              <Row className="mb-3">
                                <Form.Group as={Col} controlId="startTime">
                                  <Form.Label>Start Time</Form.Label>
                                  <Form.Control
                                    type="time"
                                    name="schedule_start_time"
                                    value={formData.schedule_start_time} onChange={handleInputChange}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} controlId="endTime">
                                  <Form.Label>End Time</Form.Label>
                                  <Form.Control
                                    type="time"
                                    name="schedule_end_time"
                                    value={formData.schedule_end_time} onChange={handleInputChange}
                                  />
                                </Form.Group>
                              </Row>

                              <Row className="mb-3">
                                <Form.Group as={Col} controlId="selectFieldRepeats">
                                  <Form.Label>Repeats</Form.Label>
                                  <Form.Select
                                    name="schedule_repeats"
                                    value={formData.schedule_repeats}
                                    onChange={handleInputChange}
                                  >
                                    {repeatsOptions.map((option) => (
                                      <option key={option.value} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group as={Col} controlId="selectFieldDuration">
                                  <Form.Label>Duration</Form.Label>
                                  <Form.Select
                                    name="schedule_duration"
                                    value={formData.schedule_duration}
                                    onChange={handleInputChange}
                                  >
                                    {durationOptions.map((option) => (
                                      <option key={option.value} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Row>

                            </Form>
                          </Card.Body>
                        </Card>
                        <Card style={{ padding: '15px', marginTop: 20 }}>
                          <Card.Body>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Form.Label size="large">Teams &nbsp;</Form.Label>

                              <Dropdown>
                                <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications">
                                  <Button variant="success" size="sm" style={{ fontSize: '12px' }}>
                                    &nbsp;+ Assign&nbsp;
                                  </Button>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="multi-select-dropdown">
                                  <ListGroup className="list-group-flush">
                                    <Nav.Link href="#" className="text-primary fw-bold border-bottom border-light py-1 mb-1 bg-soft">
                                      Select Team
                                    </Nav.Link>
                                    <Dropdown.Item onClick={handleTeamName}>
                                      {userData?.username}
                                    </Dropdown.Item>
                                  </ListGroup>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div>
                              <hr />
                            </div>
                            {
                              data.team_name ? (
                                <>
                                  <ToggleButtonGroup type="radio" name="options">
                                    <ToggleButton size="sm" style={{ fontSize: 12 }} id="tbg-radio-1">
                                      {data.team_name}
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{ fontSize: 12 }}
                                      onClick={() => handleInputCheckbox({ name: 'team_name', value: '' })}
                                      size="sm"
                                      id="tbg-radio-2"
                                    >
                                      X
                                    </ToggleButton>
                                  </ToggleButtonGroup>

                                  <Form.Group id="dropdown-revenue" className="mb-3 mt-3" style={{ maxWidth: 200 }}>
                                    <Form.Label>Team Reminder</Form.Label>
                                    <Form.Select
                                      name="selectedOption"
                                      value={data.team_reminder}
                                      onChange={(e) => handleDropdownChange('team_reminder', e)}
                                    >
                                      <option value="">Choose Here</option>
                                      <option value="At start of Task">At start of Task</option>
                                      <option value="30 min Before">30 min Before</option>
                                      <option value="1 hour Before">1 hour Before</option>
                                      <option value="2 hours Before">2 hours Before</option>
                                      {/* Add more options as needed */}
                                    </Form.Select>
                                  </Form.Group>

                                  <Form.Check type="checkbox" className="mt-2" style={{ marginLeft: 2 }}>
                                    <Form.Check.Input
                                      onClick={() => handleInputCheckbox({ name: 'is_email_team_about_assignment', value: !data.is_email_team_about_assignment })}
                                      id="defaultCheck5"
                                      className="me-2"
                                      checked={data.is_email_team_about_assignment}
                                    />
                                    <Form.Check.Label htmlFor="defaultCheck5" className="mb-0">
                                      Email team about assignment
                                    </Form.Check.Label>
                                  </Form.Check>
                                </>
                              ) : (
                                <Form.Label>No users are currently assigned</Form.Label>
                              )}
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={12} lg={7} sm={12} className='my-5 calendar1' >
                        <div class="calendar-wrapper">
                          <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            className='calendar1'
                            style={{ height: 500 }}
                            onSelectEvent={handleEventClick}
                            components={{
                              toolbar: (props) => (
                                <CustomToolbar {...props} onNavigate={props.onNavigate} />
                              ),
                            }}
                          />
                        </div>

                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </div>
        </>
        {/* form 2 */}
        <Form
          style={{
            border: "1px solid #ccc",
            padding: "15px",
            borderRadius: "10px",
            marginBottom: "15px",
          }}
        >
          <Row className="mb-2">
            <Col>
              <Form.Label>Invoicing</Form.Label>
              <Form className='px-4 my-2'>
                <Form.Group as={Row} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Remind me  to invoice when i clode the job "
                    className="mb-0"
                    style={{ marginRight: '10px' }}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
          </Row>
        </Form>
        <Form
          style={{
            border: "1px solid #ccc",
            padding: "15px",
            borderRadius: "10px",
            marginBottom: "15px",
          }}
        >
          <Row className="mb-2 justify-content-between">
            <Col>
              <div className="py-4">
                <div className="table-responsive">
                  <Table
                    className="table table-bordered"
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid #ddd",
                    }}
                  >
                        <thead className="thead-light">
                    <tr>
                      <th>PRODUCT / SERVICE</th>
                      <th>QTY.</th>
                      <th>UNIT PRICE</th>
                      <th>TOTAL</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      {entries?.map((entry, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              value={entry.name}
                              onChange={(e) => updateEntry(e, index)}
                              name="name"
                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '200px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            >
                              <option value="" disabled>Select Name</option>
                              {products.map((product) => (
                                <option key={product._id} name={"name"} value={product.product_name}>
                                  {product.product_name}
                                </option>
                              ))}

                            </select>
                            <textarea class="form-control" id="floatingTextarea" style={{  width: "200px",}}     value={entry.description}
                              onChange={(e) => updateEntry(e, index)}
                              name="description"
                              placeholder="Description"></textarea>
                          </td>
                          {/* <td>
                            <input
                              type="text"
                              value={entry.description}
                              onChange={(e) => updateEntry(e, index)}
                              name="description"
                              placeholder="Description"
                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '150px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            />
                          </td> */}
                          <td>
                            <input
                              type="text"
                              value={entry.part_qty}
                              onChange={(e) => updateEntry(e, index)}
                              name="part_qty"
                              placeholder="Quantity"
                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '150px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={entry.part_price}
                              onChange={(e) => updateEntry(e, index)}
                              name="part_price"
                              placeholder="Unit Price"
                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '150px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={entry.part_qty * entry.part_price || ''}
                              readOnly
                              placeholder="Total"
                              onChange={(e) => updateEntry(e, index)}
                              name="total_amount"

                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '150px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => deleteEntry(index)}
                              style={{ fontSize: "20px" }}
                              className="mt-2"
                            />
                          </td>
                        </tr>
                      ))}
                      {/* Button to add a new row */}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex flex-wrap align-items-center mt-3">
                  <Form onSubmit={(event) => addNewRow(event)}>
                    <Button type="submit" className="btn btn-dark btn-sm mb-2 me-2" style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
                      Add Line Item
                    </Button>
                  </Form>

                </div>
              </div>
            </Col>
            <Col xs={12} className="mt-2 " >
              <hr />
            </Col>
            <Col>
              <Row>
                <Col xs={8}>
                  <button type="button" className="btn btn two-btn" >
                    New line Item
                  </button>
                </Col>
                <Col xs={2}>
                  <p>Grand Total</p>
                  {/* <p>Total Price</p> */}
                </Col>
                <Col xs={2} className='text-right'>
                  <p>{calculateTotalPartPrice()}</p>
                  {/* <p>{calculateTotalAmount()}</p> */}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
          </Row>
        </Form>
        <Form
          style={{
            border: "1px solid #ccc",
            padding: "15px",
            borderRadius: "10px",
            marginBottom: "15px",
          }}
        >
          <Row className="mb-2">
            <Col >
              <Form.Label className="text-end d-flex justify-conten-between mb-3">Internal Notes & attachments</Form.Label>
              <Form.Control as="textarea" className='mb-3' rows={3} name="internal_notes_detais" value={formData.internal_notes_detais} onChange={handleInputChange} placeholder="Enter your notes here..." />
              <Form.Control
                autoFocus
                required
                type="file"
                name="internal_notes_attachments" value={formData.internal_notes_attachments} onChange={handleInputChange}
                style={{ border: '1px dashed black' }}
                placeholder="Company Name"
              />
            </Col>
            <Col xs={12} className="mt-2 " >
              <hr />
            </Col>

          </Row>
        </Form>
        <div className="buttond d-flex justify-content-between">
          <button type="button" className="btn btn two-btn"  onClick={() => {
            history.push(Routes.JobDashboard.path);
          }}>
            Cancel
          </button>
          {/* {
            data?.client_id ?
              <Button variant="primary" type="submit" className=" mt-4">
                Submit Client
              </Button>
              :
              <Button onClick={handleOpenModal} variant="primary" className=" mt-4">
                Select Client
              </Button>
          }
          {job_id ? ( // If job_id exists, display Update button
            <Button variant="primary" onClick={handleButtonClick} className="mt-4">
              Update
            </Button>
          ) : ( // If job_id doesn't exist, display Submit button
            <Button variant="primary" onClick={handleButtonClick} className="mt-4" style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
              Submit
            </Button>
          )} */}


<Button
  onClick={(e) => {
    e.preventDefault();
    if (job_id) {
      handleSubmit();
    } else if (data.client_id) {
      handleCreateJob();
    } else {
      handleOpenModal();
      // handleCreateJob();
    }
  }}
  variant="primary"
  type="button"  // Change the type to "button" to prevent form submission
  className="mt-4"
  disabled={isButtonDisable}
  style={{backgroundColor:colors.red,color:colors.white,border:"none"}}
>
  {job_id ? "Update Job" : (data.client_id ? (isButtonDisable ? "Submitting..." : "Submit Client") : "Select Client")}
</Button>

        </div>
      </Form>

      <Toaster />
    </div>
  );
}

export default NewJobForm;
