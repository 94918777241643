import React, { useState } from "react";
import { Table, Pagination, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import {
  faTrash,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

const RequestTable = ({ userClients = [], deleteRow, loading }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory();
  const [currentDate, setcurrentdate] = useState(moment().format("YYYY-MM-DD"));

  const getStatus = (rowData) => {

    if (rowData?.status !== "") {
      return {
        color: "#dc3545",
        border: "1px solid #dc3545",
        borderRadius: "5px",
        padding: "4px 8px",
      };
    } else if (currentDate === moment(rowData?.request_date).format("YYYY-MM-DD")) {
      return {
        color: "#ffc107",
        border: "1px solid #ffc107",
        borderRadius: "5px",
        padding: "4px 8px",
      };
    } else {
      return {
        color: "#28a745",
        border: "1px solid #28a745",
        borderRadius: "5px",
        padding: "4px 8px",
      };
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const Loader = () => <div className="loader-container">Loading...</div>;
  const totalPages = Math.ceil(userClients.length / perPage);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const handleSelect = (e) => {
      deleteRow(rowData?._id);
    };
    return (
      <td {...props} className="link-group" style={{ minHeight: 50 }}>
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          onClick={handleSelect}
          icon={faTrash}
        />
      </td>
    );
  };

  const handleRowClick = (rowData) => {
    history.push(`/request/overview/${rowData?.request_id}`);
  };

  const renderPagination = () => (
    <Pagination>
      <Pagination.Prev
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
      />
      {Array.from({ length: totalPages }, (_, index) => (
        <Pagination.Item
          key={index}
          active={index + 1 === page}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        onClick={() => handlePageChange(page + 1)}
        disabled={page === totalPages}
      />
    </Pagination>
  );
  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* {loading ? (
            <CircularProgress />
      ) : (
        <> */}
      <Table
        striped
        bordered
        hover
        responsive
        className="mx-2 px-2"
        id="table"
        loading={loading}
      >
        <thead
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <tr>
            <th onClick={() => handleSort("name")}>
              Name
              <span>
                {sortOrder === "asc" ? (
                  <FontAwesomeIcon icon={faArrowUp} />
                ) : (
                  <FontAwesomeIcon icon={faArrowDown} />
                )}
              </span>
            </th>
            <th onClick={() => handleSort("company_name")}>
              Company Name
              <span>
                {sortOrder === "asc" ? (
                  <FontAwesomeIcon icon={faArrowUp} />
                ) : (
                  <FontAwesomeIcon icon={faArrowDown} />
                )}
              </span>
            </th>
            <th onClick={() => handleSort("request_title")}>
              Title
              <span>
                {sortOrder === "asc" ? (
                  <FontAwesomeIcon icon={faArrowUp} />
                ) : (
                  <FontAwesomeIcon icon={faArrowDown} />
                )}
              </span>
            </th>
            <th>Phone</th>
            <th>Created Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {userClients
            .slice(page - 1, perPage, page, perPage)
            .sort((a, b) => {
              const columnA = a[sortColumn];
              const columnB = b[sortColumn];

              const order = sortOrder === "asc" ? 1 : -1;

              if (columnA < columnB) {
                return -1 * order;
              }
              if (columnA > columnB) {
                return 1 * order;
              }
              return 0;
            })
            .map((rowData) => (
              <tr key={rowData?._id} onClick={() => handleRowClick(rowData)}>
                <td>
                  {rowData?.client?.first_name} {rowData?.client?.last_name}
                </td>
                <td>{rowData?.client?.company_name}</td>
                <td>{rowData?.request_title}</td>
                <td>{rowData?.client?.phone}</td>
                {/* <td>{getStatus(rowData)}</td> */}
                <td>{moment(rowData?.request_date).format("YYYY-MM-DD")}</td>
                <td>
                  {rowData?.status !== "" ? (
                    <a style={getStatus(rowData)}>{rowData?.status}</a>
                  ) : (
                    <a style={getStatus(rowData)}>
                      {currentDate === moment(rowData?.request_date).format("YYYY-MM-DD") ? "Today" : "Overdue"}
                    </a>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between mt-3">
        {renderPagination()}
        <div className="drop-count col-2">
          <Form.Group
            controlId="itemsPerPageSelect"
            className="mb-3 d-flex align-items-center"
          >
            <Form.Label>Show</Form.Label>
            <Form.Control
              as="select"
              className="mx-2"
              value={perPage}
              onChange={(e) => {
                setPerPage(parseInt(e.target.value, 10));
                setPage(1);
              }}
              style={{ width: "30%" }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Form.Control>
          </Form.Group>
        </div>
      </div>
      {/* </>
      )} */}
    </div>
  );
};

export default RequestTable;