import axios from "axios";
import { getItem } from "./cookieStorage";

const axiosInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
});

console.log( axiosInstance, process.env.REACT_APP_API_URL, "baseURL")

axiosInstance.interceptors.request.use((config) => { 
	const token = getItem("userDetails");
	config.params = config.params || {};
	if (token) {
		config.headers["authorization"] = `Bearer ${token}`;
	}
	return config;
});

export default axiosInstance;
