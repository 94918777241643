import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faCloudUploadAlt, faCircle, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";
import { useHistory } from "react-router-dom"
import { Routes } from "../../routes";
import {colors} from "../../config/colors";
import Cookies from "universal-cookie";

export default () => {
  const history = useHistory();
  const handleNavigate = (page) => {
    history.push(Routes[page].path);
  }
  const [apiData, setApiData] = useState({});
  const getIcon = (category) => {
    switch (category) {
      case "Job":
        return faUserShield;
      case "Quotes":
        return faChartLine;
      case "Request":
        return faRocket;
      // Add more cases for additional categories
      default:
        return faTasks;
    }
  };
  
  const getIconColor = (category) => {
    // Define icon colors based on your design
    switch (category) {
      case "Job":
        return "shape-primary";
      case "Quotes":
        return "shape-tertiary";
      case "Request":
        return "shape-quaternary";
      // Add more cases for additional categories
      default:
        return "shape-secondary";
    }
  };

  const cookies = new Cookies();
  useEffect(() => {
    const fetchData = async () => {

      try {
    const userData = cookies.get('userDetails');

        const response = await axios.get(`https://jobmanager.cloudpress.host/api/user_dashboard/${userData.user_id}`)
        setApiData(response.data.data);
        console.log(response,"janak")
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // const filteredCategories = Object.keys(apiData).filter((category) => category !== "Client");
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
          <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2" style={{ backgroundColor:colors.red,color:colors.white,border:"none"}}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />Create
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2" >
            <Dropdown.Item className="fw-bold" onClick={()=>{handleNavigate('NewRequest')}}>
              <FontAwesomeIcon icon={faTasks} className="me-2" /> Create Request
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" onClick={()=>{handleNavigate('NewQuotes')}}>
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" /> Create Quote
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" onClick={()=>{handleNavigate('NewJob')}}>
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Create Job
            </Dropdown.Item>
            {/* <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Create Invoice
            </Dropdown.Item> */}

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faRocket} className="text-danger me-2" /> Upgrade to Pro
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <ButtonGroup>
          <Button variant="outline-primary" className="dash-btn" size="sm">Share</Button>
          <Button variant="outline-primary" className="dash-btn" size="sm">Export</Button>
        </ButtonGroup>
      </div>



      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            {/* <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <PageVisitsTable />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col>
              </Row>
            </Col> */}

            {/* <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} md={12} lg={12} sm={12} className="mb-4">
                  <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders} />
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  <RankingWidget />
                </Col>

                <Col xs={12} className="px-0">
                  <AcquisitionWidget />
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-md-center">


        {/* <Col xs={12} className="mb-4 d-sm-none">
          <SalesValueWidgetPhone
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col> */}
        {/* {filteredCategories.map((category) => (
          <Col key={category} xs={12} sm={6} xl={4} className="mb-4">
            <CounterWidget
              category={category}
              title={apiData[category].total_request || apiData[category].total_Quotes || apiData[category].total_job}
              period="Total"
              icon={getIcon(category)}
              iconColor={getIconColor(category)}
            />
          </Col>
        ))} */}
        
        {/* Request CounterWidget */}
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Request"
            title={`${apiData.Request?.total_request || 0}`}
            period={`Today: ${apiData.Request?.Today || 0} | Overdue: ${apiData.Request?.Overdue || 0} | Converted: ${apiData.Request?.Converted || 0}`}
            icon={getIcon("Request")}
            iconColor={getIconColor("Request")}
          />
        </Col>
        
        {/* Quotes CounterWidget */}
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Quotes"
            title={`${apiData.Quotes?.total_Quotes || 0}`}
            period={`Awaiting: ${apiData.Quotes?.AwaitingResponse || 0} | Approved: ${apiData.Quotes?.Approved || 0}`}
            icon={getIcon("Quotes")}
            iconColor={getIconColor("Quotes")}
          />
        </Col>


        {/* Job CounterWidget */}
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Job"
            title={`${apiData.Job?.total_job || 0}`}
            // period={`Draft: ${apiData.Job?.Draft || 0}`}
             period={`-`}

            icon={getIcon("Job")}
            iconColor={getIconColor("Job")}
          />
        </Col>
        
        {/* <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col> */}

      </Row>
    </>
  );
};
