
import Profile1 from "../assets/img/team/profile-picture-1.jpg"
import Profile2 from "../assets/img/team/profile-picture-2.jpg"
import Profile3 from "../assets/img/team/profile-picture-3.jpg"
import Profile4 from "../assets/img/team/profile-picture-4.jpg"
import Profile5 from "../assets/img/team/profile-picture-5.jpg"

export default [
    // {
    //     "id": 1,
    //     "read": false,
    //     "image": Profile1,
    //     "sender": "Jose Leos",
    //     "time": "a few moments ago",
    //     "link": "#",
    //     "message": `Added you to an event "Project stand-up" tomorrow at 12:30 AM.`
    // },
]