import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "@themesberg/react-bootstrap";
import axios from "axios"; // Import axios for making HTTP requests
import "../premium/Premium.css";

function Premium1() {
  // State to store the fetched plans
  const [plans, setPlans] = useState([]);

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        // Make a GET request to the API endpoint
        const response = await axios.get("http://localhost:8444/api/plan/plan");

        // Set the fetched plans in the state
        console.log(response.data, "yash");
        setPlans(response.data.data.reverse());
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };

    // Call the fetchPlans function
    fetchPlans();
  }, []); // The empty dependency array ensures that this effect runs only once

  return (
    <Container fluid className="homee-section" id="home">
      <Row className="header-premium d-flex justify-content-center text-light mb-4">
        <Col className="text-center" lg={6}>
          <h4>Affordable plans for any situation</h4>
          <p>
            Choose a Premium plan and listen to ad-free music without limits on
            your phone, speaker, and other devices. Pay in various ways. Cancel
            anytime.
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        {plans.map((plan) => (
          <Col
            key={plan._id}
            xs={12}
            md={4}
            lg={3}
            className="my-5 d-flex flex-column main-container"
          >
            <div className="card h-100 premium-cards">
              <div className="card-header">
                <b style={{ color: "rgb(10, 37, 59)" }}>
                  <i
                    className="fa-solid fa-calendar"
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  ></i>{" "}
                  {plan.free_trial
                    ? "Free for 1 month"
                    : `${plan.plan_name} - ${plan.billing_interval}`}
                </b>
              </div>
              <div className="card-body">
                <b className="mb-5">Premium</b>
                <h5 className="card-title" style={{ color: "red" }}>
                  {plan.plan_name}
                </h5>
                <p className="">${plan.price} {plan.billing_interval}</p>
                <hr />
                <ul className="">
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <button
                  type="button"
                  className="btn btn-secondary first-button"
                >
                  Get Started
                </button>
              </div>
              <div className="card-footer text-muted text-center">
                <a href="#" style={{ fontSize: "12px" }}>
                  Terms apply.
                </a>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Premium1;
