import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Routes } from "../routes";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";
import LandingPage from "./LandingPage";

// Premium
import PremiumPlans from "./premium/PremiumPlans"

// components
import Sidebar from "../components/Sidebar";
import ClientProjectSidebar from "../clientProject/components/Sidebar";
import SidebarSuperAdmin from "../superadminsrc/components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import Profile from "../components/Profile";
// client
import Client from "../client/Client";
import ClientDashboard from "../client/ClientDashboard";

// properties
import NewProperties from "../properties/NewProperties";
import Properties from "../properties/Properties";

// request
import RequestDashboard from "../request/RequestDashboard";
import Request from "../request/Request";
import WorkRequestData from "../request/WorkRequestData";

// quotes
import NewQuotes from "./quotes/NewQuotes";
import QuotesDashboard from "./quotes/QuotesDashboard";
import QuotesDetails from "./quotes/QuotesDetails";
import Quotes from "./quotes/Quotes";

// settings
import CompanySetting from "../settings/CompanySetting";
import productSetting from "../settings/productSetting";
import Teamsetting from "../settings/Teamsetting";
import Clienthubsetting from "../settings/Clienthubsetting";

// job
import NewJobPage from "../job/NewJobPage";
import JobDashboard from "../jobs/JobDashboard";
import JobDetail from "../jobs/JobDetail";

// Invoice
import InvoiceUser from "../invoices/Invoice";
import Newinvoice from "../invoices/Newinvoice";
import InvoiceDetails from "../invoices/InvoiceDetails";
import ScheduleUser from "../Schedule/Schedule";
// -------------------------------------------------------- superadmin --------------------------------------------------------
import SuperAdminSignin from "../superadminsrc/pages/examples/Signin";
import SuperAdminDashboardOverview from "../superadminsrc/pages/dashboard/DashboardOverview";
import { SuperAdminRoutes } from "../superAdminRoutes";
import { ClientProjectRoutes } from "../ClientProjectRoutes";
// pages
import SuperAdminPriority from "../superadminsrc/Component/pages/Priority";
import SuperAdminRevenue from "../superadminsrc/Component/pages/Revenue";
import SuperAdminTeamSize from "../superadminsrc/Component/pages/TeamSize";
import SuperAdminIndustrial from "../superadminsrc/Component/pages/Industrial";
import SuperAdminUser from "../superadminsrc/Component/pages/User";
import SuperAdminClient from "../superadminsrc/Component/pages/Client";
import SuperAdminProperty from "../superadminsrc/Component/pages/Property";
import SuperAdminRequest from "../superadminsrc/Component/pages/Request";
import SuperAdminDraggableTable from "../superadminsrc/Component/pages/DraggableTable";
import SuperAdminRequestDetails from "../superadminsrc/Component/pages/RequestDetails";
import SuperAdminProductAndService from "../superadminsrc/Component/pages/ProductAndService";
import SuperAdminQuotes from "../superadminsrc/Component/pages/Quotes";
import SuperAdminQuotesDetails from "../superadminsrc/Component/pages/QuotesDetails";
import SuperAdminClientPageSidebar from "../superadminsrc/Component/pages/ClientPage";
import SuperAdminClientSummary from "../superadminsrc/Component/pages/ClientSummary";
import SuperAdminPropertyDetails from "../superadminsrc/Component/pages/PropertyDetails";
import SuperAdminJobDetails from "../superadminsrc/Component/pages/JobDetails";
import SuperAdminIndustrialCategory from "../superadminsrc/Component/pages/IndustrialCategory";
import SuperAdminInvoiceDetails from "../superadminsrc/Component/pages/InvoiceDetails";
import SuperAdminSuperAdminDashboard from "../superadminsrc/Component/pages/SuperAdminDashboard";
import SuperAdminJob from "../superadminsrc/Component/pages/Job";
import SuperAdminSchedule from "../superadminsrc/Component/pages/Schedule";
import SuperAdminInvoicese from "../superadminsrc/Component/pages/Invoices";
import SuperAdminNavbar from "../superadminsrc/components/Navbar";

import SuperAdminProducstDetails from "../superadminsrc/Component/pages/Products/ProducstDetails";
import SuperAdminPlans from "../superadminsrc/Component/pages/Plans/Plans";


// -------------------------------------------------------- superadmin --------------------------------------------------------

// signup
import ClientProjectSignin from "../clientProject/pages/examples/Signin";

// request
import ClientProjectRequestDashboard from "../clientProject/request/RequestDashboard";
import ClientProjectRequestDetail from "../clientProject/request/RequestDetail";
import ClientNewRequest from "../clientProject/request/Request";
import ClientProjectLandingPage from "../clientProject/pages/LandingPage";

//quote
import ClientProjectQuotesDashboard from "../clientProject/quotes/QuotesDashboard";
import ClientProjectQuotesDetail from "../clientProject/quotes/QuotesDetails";
import ClientProjectQuoteRequestChange from "../clientProject/quotes/QuoteRequestchange";

//appointment
import ClientProjectAppointmentDashboard from "../clientProject/appointment/AppointmentDashboard";
import ClientProjectAppointmentDetails from "../clientProject/appointment/AppointmentDetails";

//invoice
import ClientProjectClientInvoiceDash from "../clientProject/invoice/ClientInvoiceDash";
import ClientProjectClientInvoiceDetail from "../clientProject/invoice/ClientInvoiceDetail";
import ClientNavbar from "../clientProject/components/Navbar";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithLoaderSuperAdmin = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className="content">
            <Navbar />
            <Component {...props} />
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

const RouteWithSidebarSuperAdmin = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <SidebarSuperAdmin />

          <main className="content">
            <SuperAdminNavbar />
            <Component {...props} />
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

const RouteWithSidebarClientProject = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <ClientProjectSidebar />

          <main className="content">
            <ClientNavbar />
            <Component {...props} />
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.PremiumPlans.path} component={PremiumPlans} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ResetPassword.path}
      component={ResetPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      exact
      path={Routes.ServerError.path}
      component={ServerError}
    />
    <RouteWithLoader
      exact
      path={Routes.LandPage.path}
      component={LandingPage}
    />

    {/* pages */}
    <RouteWithSidebar
      exact
      path={Routes.DashboardOverview.path}
      component={DashboardOverview}
    />

    <RouteWithSidebar exact path={Routes.Profile.path} component={Profile} />

    {/* client */}
    <RouteWithSidebar exact path={Routes.Client.path} component={Client} />
    <RouteWithSidebar exact path={Routes.ClientEdit.path} component={Client} />
    <RouteWithSidebar
      exact
      path={Routes.ClientDashBoard.path}
      component={ClientDashboard}
    />

    {/* properties */}
    <RouteWithSidebar
      exact
      path={Routes.Properties.path}
      component={Properties}
    />
    <RouteWithSidebar
      exact
      path={Routes.NewProperties.path}
      component={NewProperties}
    />

    {/* request */}
    <RouteWithSidebar
      exact
      path={Routes.RequestDashboard.path}
      component={RequestDashboard}
    />
    <RouteWithSidebar exact path={Routes.NewRequest.path} component={Request} />
    <RouteWithSidebar
      exact
      path={Routes.EditRequest.path}
      component={Request}
    />
    <RouteWithSidebar
      exact
      path={Routes.WorkRequestData.path}
      component={WorkRequestData}
    />

    {/* Quotes */}
    <RouteWithSidebar
      exact
      path={Routes.QuotesDashboard.path}
      component={QuotesDashboard}
    />
    <RouteWithSidebar exact path={Routes.NewQuotes.path} component={Quotes} />
    <RouteWithSidebar exact path={Routes.EditQuotes.path} component={Quotes} />
    <RouteWithSidebar
      exact
      path={Routes.QuotesDetails.path}
      component={QuotesDetails}
    />
    <RouteWithSidebar exact path={Routes.ConvertQuotes.path} component={Quotes} />
    {/* jobs */}
    {/* <RouteWithSidebar
			exact
			path={Routes.JobDashboard.path}
			component={JobDashboard}
		/> */}

    {/* Settins */}
    <RouteWithSidebar
      exact
      path={Routes.CompanySetting.path}
      component={CompanySetting}
    />
    {/* <RouteWithSidebar
			exact
			path={Routes.BrandingSetting.path}
			component={Brandingsetting}
		/> */}
    <RouteWithSidebar
      exact
      path={Routes.productSetting.path}
      component={productSetting}
    />
    {/* <RouteWithSidebar
			exact
			path={Routes.Customersetting.path}
			component={Customersetting}
		/> */}
    {/* <RouteWithSidebar
			exact
			path={Routes.Expensesetting.path}
			component={Expensesetting}
		/> */}
    <RouteWithSidebar
      exact
      path={Routes.Teamsetting.path}
      component={Teamsetting}
    />
    {/* <RouteWithSidebar
			exact
			path={Routes.Worksetting.path}
			component={Worksetting}
		/> */}
    {/* <RouteWithSidebar
			exact
			path={Routes.Schedulesetting.path}
			component={Schedulesetting}
		/> */}
    {/* <RouteWithSidebar
			exact
			path={Routes.Locationsetting.path}
			component={Locationsetting}
		/> */}
    {/* <RouteWithSidebar
			exact
			path={Routes.Routessetting.path}
			component={Routessetting}
		/>
		<RouteWithSidebar
			exact
			path={Routes.Jobsetting.path}
			component={Jobsetting}
		/> */}
    <RouteWithSidebar
      exact
      path={Routes.Clienthubsetting.path}
      component={Clienthubsetting}
    />
    {/* <RouteWithSidebar
			exact
			path={Routes.Emailsetting.path}
			component={Emailsetting}
		/> */}
    {/* <RouteWithSidebar
			exact
			path={Routes.Requestsetting.path}
			component={Requestsetting}
		/> */}
    {/* <RouteWithSidebar
			exact
			path={Routes.Bookingsetting.path}
			component={Bookingsetting}
		/> */}

    {/* job */}
    <RouteWithSidebar exact path={Routes.NewJob.path} component={NewJobPage} />
    <RouteWithSidebar exact path={Routes.EditJob.path} component={NewJobPage} />
    <RouteWithSidebar exact path={Routes.ConvertJob.path} component={NewJobPage} />
    <RouteWithSidebar
      exact
      path={Routes.JobDashboard.path}
      component={JobDashboard}
    />
    <RouteWithSidebar
      exact
      path={Routes.JobDetail.path}
      component={JobDetail}
    />
    <RouteWithSidebar
      exact
      path={Routes.InvoiceDashboard.path}
      component={InvoiceUser}
    />
   <RouteWithSidebar
      exact
      path={Routes.InvoiceDetails.path}
      component={InvoiceDetails}
    /> 
    
    <RouteWithSidebar
      exact
      path={Routes.NewInvoice.path}
      component={Newinvoice}
    />
     <RouteWithSidebar
      exact
      path={Routes.ConvertInvoice.path}
      component={Newinvoice}
    />
     <RouteWithSidebar
      exact
      path={Routes.Schedule.path}
      component={ScheduleUser}
    />

    {/* -------------------------------------------------------- superadmin -------------------------------------------------------- */}
    <RouteWithLoaderSuperAdmin
      exact
      path={SuperAdminRoutes.Signin.path}
      component={SuperAdminSignin}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.DashboardOverview.path}
      component={SuperAdminDashboardOverview}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.DraggableTable.path}
      component={SuperAdminDraggableTable}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.Request.path}
      component={SuperAdminRequest}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.Property.path}
      component={SuperAdminProperty}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.Client.path}
      component={SuperAdminClient}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.User.path}
      component={SuperAdminUser}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.Industrial.path}
      component={SuperAdminIndustrial}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.TeamSize.path}
      component={SuperAdminTeamSize}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.Revenue.path}
      component={SuperAdminRevenue}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.Priority.path}
      component={SuperAdminPriority}
    />

    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.RequestDetails.path}
      component={SuperAdminRequestDetails}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.ProductAndService.path}
      component={SuperAdminProductAndService}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.Quotes.path}
      component={SuperAdminQuotes}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.QuotesDetailsSuperAdmin.path}
      component={SuperAdminQuotesDetails}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.ClientPage.path}
      component={SuperAdminClientPageSidebar}
    />

    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.ClientSummary.path}
      component={SuperAdminClientSummary}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.PropertyDetails.path}
      component={SuperAdminPropertyDetails}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.JobDetails.path}
      component={SuperAdminJobDetails}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.IndustrialCategory.path}
      component={SuperAdminIndustrialCategory}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.InvoiceDetails.path}
      component={SuperAdminInvoiceDetails}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.SuperAdminDashboard.path}
      component={SuperAdminSuperAdminDashboard}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.JobSuperAdmin.path}
      component={SuperAdminJob}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.ScheduleSuperAdmin.path}
      component={SuperAdminSchedule}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.InvoicesSuperAdmin.path}
      component={SuperAdminInvoicese}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.productsdetailsSuperAdmin.path}
      component={SuperAdminProducstDetails}
    />
    <RouteWithSidebarSuperAdmin
      exact
      path={SuperAdminRoutes.plansSuperAdmin.path}
      component={SuperAdminPlans}
    />

    {/* -------------------------------------------------------- client -------------------------------------------------------- */}
    <RouteWithLoader
      exact
      path={ClientProjectRoutes.Signin.path}
      component={ClientProjectSignin}
    />
    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.RequestDetail.path}
      component={ClientProjectRequestDetail}
    />
    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.RequestDashboard.path}
      component={ClientProjectRequestDashboard}
    />
    <RouteWithSidebarClientProject
      exact
      path={"/client/newrequest"}
      component={ClientNewRequest}
    />
    <RouteWithLoader
      exact
      path={ClientProjectRoutes.LandPage.path}
      component={ClientProjectLandingPage}
    />
    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.RequestDetail.path}
      component={ClientProjectRequestDetail}
    />

    {/* quotes */}

    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.QuotesDetails.path}
      component={ClientProjectQuotesDetail}
    />
    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.QuotesDashboard.path}
      component={ClientProjectQuotesDashboard}
    />
    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.QuoteRequestchange.path}
      component={ClientProjectQuoteRequestChange}
    />

    {/* Appointment */}
    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.AppointmentDashboard.path}
      component={ClientProjectAppointmentDashboard}
    />
    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.AppointmentDetails.path}
      component={ClientProjectAppointmentDetails}
    />

    {/* Invoice */}
    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.ClientInvoiceDash.path}
      component={ClientProjectClientInvoiceDash}
    />
    <RouteWithSidebarClientProject
      exact
      path={ClientProjectRoutes.ClientInvoiceDetail.path}
      component={ClientProjectClientInvoiceDetail}
    />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
