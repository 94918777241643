import React, { useEffect, useState } from "react";
import NewRequestForm from "./NewRequestForm";
import "../css/client.css";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../routes";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Form,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "@themesberg/react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../config/AxiosInstance";
import Cookies from "universal-cookie";

function Request() {
  const [isButtonDisable, setIsButtonDisable] = useState(false);

  const cookies = new Cookies();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const { request_id } = useParams();

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputCheckbox = (e) => {
    const value = e.value;
    const name = e.name;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    setIsButtonDisable(true);

    try {
      const userData = cookies.get("userDetails");
      const apiData = {
        ...data,
        user_id: userData.user_id,
      };

      // const response = await axiosInstance.post('/request/request', apiData, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // });

      const api = request_id
        ? `/request/request/${request_id}`
        : "/request/request";

      const response = await axiosInstance({
        method: request_id ? "put" : "post",
        url: api,
        data: apiData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const responseData = response.data;
        console.log("Response Data:", responseData);
        toast.success("Request submitted successfully");
        // Redirect to another page after successful submission if needed
        setTimeout(() => {
          history.push(Routes.RequestDashboard.path);
        }, 1000);
      } else {
        toast.error("Error submitting request");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An unexpected error occurred");
    }
    setIsButtonDisable(false);
  };

  const handleDropdownChange = (name, e) => {
    const selectedOption = e.target.value;

    setData((prevData) => ({
      ...prevData,
      [name]: selectedOption,
    }));
  };

  useEffect(() => {
    if (request_id == undefined) {
      setLoading(false);
    }
  }, []);

  // if (loading) {
  //   return(
  //     <div className="d-flex justify-content-center mt-5">
  //     <div className="spinner-border text-primary" role="status">
  //       <span className="visually-hidden">Loading...</span>
  //     </div>
  //   </div>
  //   )
  // }

  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <div className="container-form mt-4">
        <p className="heading-form text-dark mx-2">Requested Client Details</p>
        <div className="container-form-inputs">
          <NewRequestForm
            handleSubmit={handleSubmit}
            handleInputCheckbox={handleInputCheckbox}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            setData={setData}
            handleInput={handleInput}
            data={data}
            handleDropdownChange={handleDropdownChange}
            isButtonDisable={isButtonDisable}
            setIsButtonDisable={setIsButtonDisable}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default Request;
