import { Nav, Dropdown, Container, ListGroup, ToggleButtonGroup, ToggleButton, FormCheck } from '@themesberg/react-bootstrap';
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBriefcase, faPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  faBuilding,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { Modal} from 'react-bootstrap';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.6.1/css/bootstrap4-toggle.min.css"; // Import the Bootstrap Toggle CSS
import {
  Col,
  Row,
  Form,
  Button,
  InputGroup,
  Spinner
} from "@themesberg/react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import toast, { Toaster } from 'react-hot-toast';
import { Routes } from "../routes";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../config/AxiosInstance";
import {colors} from "../config/colors";
const iosStyle = {
  toggle: {
    borderRadius: "20rem",
  },
  toggleHandle: {
    borderRadius: "20rem",
  },
  toggleOn: {
    borderRadius: "20rem",
  },
  toggleOff: {
    borderRadius: "20rem",
  },
};

function NewRequestForm({
  handlechange,
  handleInput,
  handleSubmit,
  data,
  handleDropdownChange,
  handleInputCheckbox,
  isButtonDisable, setIsButtonDisable
}) {

  

  const cookies = new Cookies()
  const handleToggleChange = () => {
    setIsAssessmentRequired(!isAssessmentRequired);
  };

  const handleSecondCheckboxChange = (e) => {
    setIsSecondCheckboxChecked(!isSecondCheckboxChecked);
    handleInputCheckbox({ name: e.target.name, value: !isSecondCheckboxChecked })
  };
  const [isAssessmentRequired, setIsAssessmentRequired] = useState(false);
  const [isSecondCheckboxChecked, setIsSecondCheckboxChecked] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const [showModal, setShowModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleOpenPropertyModal = (id) => {
    // Open the modal
    setShowModal(false);
    setShowPropertyModal(true);

    fetchProperties(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowPropertyModal(false);
  };
  const handleBtnBack = () => {
    history.goBack();
  };

  const [clients, setClients] = useState([]);
  const [properties, setProperties] = useState([]);
  const [loadingClients, setLoadingClients] = useState(true)
  const [loadingProperties, setLoadingProperties] = useState(true)

  const [selectedArrivalTimes, setSelectedArrivalTimes] = useState([]);
  const [internalLinks, setInternalLinks] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const teams = ['Team 1', 'Team 2', 'Team 3', 'Team 4'];
  const [userData, setUserData] = useState([])
  const currentDate = new Date();
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString(undefined, options);

  const history = useHistory()

  const handleInputArrivalTime = (e) => {
    const { value, checked } = e.target;

    console.log({ value, checked })

    if (checked) {
      // If the checkbox is checked, add the value to the array
      setSelectedArrivalTimes((prevSelected) => [...prevSelected, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setSelectedArrivalTimes((prevSelected) =>
        prevSelected?.filter((time) => time !== value)
      );
    }
  };

  const uploadImages = async () => {
    const imageData = new FormData();

    for (let index = 0; index < selectedFiles.length; index++) {
      const element = selectedFiles[index];
      imageData.append(`files`, element);
    }

    const url = `https://jobmanager.cloudpress.host/api/images/upload`;
    var image;

    try {
      const result = await axios.post(url, imageData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      image = result.data.files.map((data, index) => {
        return data.url;
      });
      setSelectedFiles(image);
      console.log(image, "image");
    } catch (error) {
      console.log(error);
      toast.error("Please try again later");
    }
  };

  const handleInputInternalLinks = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // If the checkbox is checked, add the value to the array
      setInternalLinks((prevSelected) => [...prevSelected, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setInternalLinks((prevSelected) =>
        prevSelected?.filter((time) => time !== value)
      );
    }
  };

  useEffect(() => {
    handleInputCheckbox({ name: 'preferred_arrival_times', value: selectedArrivalTimes })
    handleInputCheckbox({ name: 'internal_link_note_to_related', value: internalLinks })
  }, [selectedArrivalTimes, internalLinks])

  const fetchClients = async () => {
    try {
      const userData = cookies.get('userDetails');
      setUserData(userData)
      const response = await axiosInstance.get(`/request/request_client/${userData.user_id}`);
      if (response.data.data) {
        setClients(response.data.data)
      }
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error)
    } finally {
      setLoadingClients(false)
    }
  }

  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedProperty, setSelectedProperty] = useState([])

  const fetchProperties = async (clientId) => {
    try {
      setSelectedClientId(clientId)
      const response = await axiosInstance.get(`/request/request_property/${clientId}`);
      if (response.data.data) {
        setProperties(response.data.data)
      }
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error)
    } finally {
      setLoadingProperties(false);
    }
  }

  const fetchProperty = async (properyId) => {
    try {
      const response = await axiosInstance.get(`/request/property/${properyId}`);
      const data = response.data.data[0]

      console.log("response:", data)
      setSelectedProperty(data)
      handleInputCheckbox({ "name": "client_id", value: data?.client_id })
      handleInputCheckbox({ "name": "property_id", value: data?.property_id })
      // handleInputCheckbox({"name": "first_name", value: data[0].first_name})
      // handleInputCheckbox({"name": "last_name", value: data[0].last_name})
      // handleInputCheckbox({"name": "email", value: data[0].email})
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error);
      toast.error("We are Troubleshooting... try again later")
    } finally {
      setLoadingProperties(false);
      handleCloseModal();
    }
  }

  // const fetchDataFromApi = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://jobmanager.cloudpress.host/api/request/requestdata/${request_id}`
  //     );
  //     const apiData = response.data.data;

  //     console.log("api data:", apiData)

  //     // setData({
  //     //   request_title: apiData[0]?.request_title || "",
  //     //   service_detail: apiData[0]?.service_detail || "",
  //     //   availability_assessment_work_1: apiData[0]?.availability_assessment_work_1 || "",
  //     //   availability_assessment_work_2: apiData[0]?.availability_assessment_work_2 || "main",
  //     //   preferred_arrival_times: apiData[0]?.preferred_arrival_times || [],
  //     //   internal_note_details: apiData[0]?.internal_note_details || "",
  //     //   first_name: apiData[0]?.clientData.first_name || "",
  //     //   last_name: apiData[0]?.clientData.last_name || "",
  //     //   email: apiData[0]?.clientData.email || "",
  //     //   phone: apiData[0]?.phone || "",
  //     //   is_on_site_assessment_required: apiData[0]?.is_on_site_assessment_required || "",
  //     //   assessment_end_time: apiData[0]?.assessment_end_time || "",
  //     //   assessment_start_time: apiData[0]?.assessment_start_time || "",
  //     //   assessment_end_date: apiData[0]?.assessment_end_date || "",
  //     //   assessment_instruction: apiData[0]?.assessment_instruction || "",
  //     // });

  //   setIsSecondCheckboxChecked(apiData[0]?.is_on_site_assessment_required);

  //     const property = {
  //       ...apiData[0]?.propertyData,
  //       client: apiData[0]?.clientData
  //     }

  //     setSelectedProperty(property)
  //     handleInputCheckbox({ "name": "client_id", value: apiData[0]?.clientData?.client_id })
  //     handleInputCheckbox({ "name": "property_id", value: apiData[0]?.propertyData?.property_id })
  //     // setLoading(false)

  //     // You can update state or perform any other actions with the data
  //   } catch (error) {
  //     console.error('Error fetching data from API:', error);
  //     // Handle error, e.g., show an error message to the user
  //   }
  // };

  useEffect(() => {
    fetchClients()
  }, [])

  const filteredClients = clients?.filter(
    (client) =>
      client.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (client.last_name &&
        client.last_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      client.company_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredProperties = properties?.filter(
    (property) =>
      property.street_1.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (property.street_2 &&
        property.street2.toLowerCase().includes(searchTerm.toLowerCase())) ||
      property.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.zipcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearchpropertyChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFormSubmit = (event) => {

    event.preventDefault();

    if (data.preferred_arrival_times == undefined || data.preferred_arrival_times?.length == 0) {
      toast.error("Please select arrival time!")
      return;
    }

    handleSubmit()
  }

  function handleTeamName() {
    handleInputCheckbox({ name: "team_name", value: userData.username })
  }

  const renderTable = () => {
    if (loadingClients) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            <Spinner animation="grow" />;
          </td>
        </tr>
      )
    }
    if (clients?.length == 0) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            Please Create Client to Create Request
          </td>
        </tr>
      );
    }
    if (filteredClients?.length === 0 && searchTerm == "") {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            Clients not Found
          </td>
        </tr>
      );
    }
    if (filteredClients?.length === 0) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            No results for "{searchTerm}"
          </td>
        </tr>
      );
    }

    return filteredClients?.map((client) => (
      <tr key={client.client_id} style={{ cursor: 'pointer' }} onClick={() => handleOpenPropertyModal(client.client_id)}>
        <td style={{ paddingLeft: 40 }}>
          {client.company_name ? (
            <FontAwesomeIcon icon={faBuilding} className="me-2" />
          ) : (
            <FontAwesomeIcon icon={faUser} className="me-2" />
          )}
        </td>
        <td>
          {client.company_name ? (
            <>
              <div>{client.company_name} ({client.first_name})</div>
              <div>
                {client.properties} Properties | {client.propertyCount}
              </div>
            </>
          ) : (
            <>
              <div>{client.first_name}</div>
              <div>
                {client.properties} Properties | {client.propertyCount}
              </div>
            </>
          )}
        </td>
        <td>{client.updatedAt}</td>
      </tr>
    ));

  };

  const renderProperty = () => {

    if (loadingProperties) {
      return (
        <div className="w-100 align-items-center d-flex justify-content-center py-2">
          <Spinner animation="grow" />
        </div>
      )
    }

    if (properties?.length === 0 || properties == undefined) {
      return <div className="w-100 align-items-center d-flex justify-content-center py-2">Properties Not found - Please Create Property</div>;
    }

    if (filteredProperties?.length === 0) {
      return <div className="w-100 align-items-center d-flex justify-content-center py-2">No results for "{searchTerm}"</div>;
    }

    return filteredProperties?.map((property, index) => (
      <div key={property.id} style={{ marginBottom: "10px", marginTop: "5px", cursor: 'pointer' }} onClick={() => { fetchProperty(property.property_id) }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 5,
            }}
          >
            <span>{`${property.street_1}${property.street_2 ? `, ${property.street_2}` : ""
              }, ${property.city} - ${property.zipcode}, ${property.state}, ${property.country
              }`}</span>
          </div>
          <div style={{ marginRight: "10px", paddingRight: 20 }}>
            <span>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </div>
        </div>
        {index < filteredProperties?.length - 1 && (
          <hr
            style={{
              width: "100%",
              margin: "5px 0",
              border: "1px solid #ccc",
            }}
          />
        )}
      </div>
    ));
  };
  // const [isDirty, setIsDirty] = useState(false);
  // const handleBeforeUnload = (event) => {
  //   console.log("event", event);
  //   if (isDirty) {
  //     const message =
  //       "You have unsaved changes. Are you sure you want to leave?";
  //     event.returnValue = message;
  //     return message;
  //   }
  // };

  // const handlePopState = () => {
  //   if (!leaveConfirmationCanceled) {
  //     const confirmLeave = window.confirm(
  //       "You have unsaved changes. Are you sure you want to leave?"
  //     );

  //     if (confirmLeave) {
  //       window.removeEventListener("beforeunload", handleBeforeUnload);
  //       history.push("/request");
  //     } else {
  //       leaveConfirmationCanceled = true;
  //       history.push("/request/new");
  //     }
  //   } else {
  //     leaveConfirmationCanceled = false;
  //   }
  // };

  // let leaveConfirmationCanceled = false;

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [isDirty, history]);
  return (
    <div >
      <h3 style={{ cursor: 'pointer' }} className='d-flex align-items-center text-dark' onClick={handleOpenModal}>
        {/* <FontAwesomeIcon icon={faUser} /> &nbsp; */}
        Request for {"client Name"} &nbsp;
        <Button type="button" className="btn btn-primary" size={"sm"} >
          +
        </Button>
      </h3>

      {
        selectedProperty.length != 0
          ?
          <Row>
            <Col xs={12} className="mt-2" >
              <hr />
            </Col>
            <Col md={6} lg={6} sm={12} >
              <Form.Label style={{ fontSize: 'large' }} className="text-dark">Property Address</Form.Label>
              <Form.Group >
                <Form.Label>{selectedProperty?.street_1}{selectedProperty.street_2 ? ", " + selectedProperty?.street_2 : null},<br /> {selectedProperty?.city}, {selectedProperty?.state}, {selectedProperty?.zipcode}</Form.Label>
                <br />
                <br />
                <Form.Label onClick={() => { handleOpenPropertyModal(); }} className="ml-2 text-dark" style={{ cursor: 'pointer' }}>Change</Form.Label>
              </Form.Group>
            </Col>
            <Col md={6} lg={6} sm={12}>
              <Form.Label style={{ fontSize: 'large' }} className="text-dark">Client Details</Form.Label>
              <Form.Group>
                <Form.Label className="text-dark">{selectedProperty?.client?.first_name + " " + selectedProperty?.client?.last_name} </Form.Label><br />
                <Form.Label>{selectedProperty?.client?.phone} </Form.Label><br />
                <Form.Label>{selectedProperty?.client?.email} </Form.Label><br />
              </Form.Group>
            </Col>
          </Row>
          :
          null
      }


      <Modal className=""
        show={showModal}
        onHide={handleCloseModal}
        style={{ height: "auto" }}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select or create a client</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflowY:'scroll', maxHeight:'80vh'}}>
          {/* Add your content for client selection here */}

          <div className="p-4" >
            <p className="mb-2 ml-2">
              Which client would you like to create this for?
            </p>
            <div className="border border-dark" >
              <div
                style={{
                  height: 70,
                  justifyContent: "center",
                  backgroundColor: "lightgray",
                  alignItems: "center",
                }}
                className="d-flex align-items-center mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Search client..."
                  style={{
                    border: "none",
                    width: "130px",
                    borderRadius: "0",
                  }}
                  onChange={handleSearchChange}
                />
                <p className="mx-2 my-2">OR</p>
                <Button
                  variant="primary"
                  className=""
                  onClick={() => history.push(Routes.Client.path)}
                >
                  Create New Client
                </Button>
              </div>
              <tr className="my-3">
                <label style={{ marginLeft: '10px' }}> Leads</label>{" "}
              </tr>
              <Col xs={12} className="mb-2">
                <hr />
              </Col>

              <table className="table table-striped">
                <tbody>{renderTable()}</tbody>
              </table>
              {/* <tr>
                {" "}
                <label style={{ marginLeft: '10px' }}> Archived </label>{" "}
              </tr>
              <Col xs={12} className="mb-2">
                <hr />
              </Col>
              <table className="table table-striped">
                <tbody>{renderTable()}</tbody>
              </table> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showPropertyModal} centered onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select or create a property...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add your content for client selection here */}

          <div className="p-4">
            <p className="mb-2 ml-2">
              Which property would you like to use for this?
            </p>
            <div className="border border-dark" >
              <div
                style={{
                  height: 70,
                  justifyContent: "center",
                  backgroundColor: "lightgray",
                  alignItems: "center",
                }}
                className="d-flex align-items-center"
              >
                <Form.Control
                  type="text"
                  placeholder="Search properties..."
                  style={{
                    border: "none",
                    width: "150px",
                    fontSize: "13px",
                    borderRadius: "0",
                  }}
                  onChange={handleSearchpropertyChange}
                />
                <p className="mx-2 my-2">OR</p>
                <Button
                  variant="primary"
                  className=""
                  style={{
                    fontSize: "14px",
                  }}
                  onClick={() => history.push(`/properties/${selectedClientId}`)}
                >
                  Create New Property
                </Button>
              </div>

              <div>{renderProperty()}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Form onSubmit={handleFormSubmit} className="mt-2 p-0">
        <Row>
          <Col xs={12} >
            <hr />
          </Col>
          <Col md={6} lg={6} sm={12} className="mb-4">
            <Form.Label style={{ fontSize: 'large' }} className="text-dark">Request title</Form.Label>

            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUser} />
              </InputGroup.Text>

              <Form.Control
                autoFocus
                required
                type="text"
                name="request_title"
                value={data.request_title}
                onChange={handleInput}
                placeholder="Request title"
              />
            </InputGroup>
          </Col>

          <Col md={6} lg={6} sm={12} className="mb-4">
            <Form.Label style={{ fontSize: 'large' }} className="text-dark">Request details</Form.Label>
            <Form.Group>
              Requested on 
              <Form.Label>&nbsp;{formattedDate}</Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-1">
          <Form.Label style={{ fontSize: 'large' }} className="text-dark">Service Details</Form.Label>
        </Row>
        <Row>
          <Form.Label>Please provide as much information as you can</Form.Label>
        </Row>
        <Row className="mb-2">
          <InputGroup>
            <Form.Control
              autoFocus
              required
              placeholder="Service Detail"
              type="text"
              name="service_detail"
              value={data.service_detail}
              onChange={handleInput}
            />
          </InputGroup>
        </Row>
        <Row className="mb-1">
          <Form.Label style={{ fontSize: 'large' }} className="text-dark mt-2">Your Availability</Form.Label>
        </Row>
        <Row>
          {" "}
          <Form.Label>
            Which day would be best for an assessment of the work?
          </Form.Label>
        </Row>
        <Row className="mb-2">
          <InputGroup>
            <Form.Control
              required
              placeholder=""
              type="date"
              name="availability_assessment_work_1"
              value={data.availability_assessment_work_1}
              onChange={handleInput}
            />
          </InputGroup>
        </Row>
        <Row>
          {" "}
          <Form.Label>What is another day that works for you?</Form.Label>
        </Row>
        <Row className="mb-2">
          <InputGroup>
            <Form.Control
              autoFocus
              required
              type="date"
              name="availability_assessment_work_2"
              value={data.availability_assessment_work_2}
              onChange={handleInput}
            />
          </InputGroup>
        </Row>
        <Row>
          <Form.Label>What are your preffered arrival times?</Form.Label>
        </Row>
        <Row className="mb-2">
          <InputGroup>
            <Form.Check
              type="checkbox"
              label="Any time"
              name="preferred_arrival_times"
              value="any time"
              onChange={handleInputArrivalTime}
            />
          </InputGroup>
          <InputGroup>
            <Form.Check
              type="checkbox"
              label="Morning"
              name="preferred_arrival_times"
              value="morning"
              onChange={handleInputArrivalTime}
            />
          </InputGroup>
          <InputGroup>
            <Form.Check
              type="checkbox"
              label="Afternoon"
              name="preferred_arrival_times"
              value="afternoon"
              onChange={handleInputArrivalTime}
            />
          </InputGroup>
          <InputGroup>
            <Form.Check
              type="checkbox"
              label="Evening"
              name="preferred_arrival_times"
              value="evening"
              onChange={handleInputArrivalTime}
            />
          </InputGroup>
          {/* 
          <div className="mt-2">
            <p className="text-dark">{selectedArrivalTimes.join(', ')}</p>
          </div> */}
        </Row>
        <div>
          <Row>
            <Col xs={12} className="mb-2">
              <hr />
            </Col>
            <Col xs={12} className="mb-2 text-left">
              <div className="form-check form-switch ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="secondCheckbox"
                  name="is_on_site_assessment_required"
                  checked={data.is_on_site_assessment_required}
                  onChange={handleSecondCheckboxChange}
                />

                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                  On-site assessment required <br />
                  Schedule an assessment to collect more information before the
                  job
                </label>
              </div>
            </Col>
          </Row>
        </div>
        {/* assessment form1 */}
        {isSecondCheckboxChecked && (
          <>
            <div
              style={{
                border: "1px solid #ccc",
                padding: "15px",
                borderRadius: "10px",
                marginBottom: "15px",
              }}
            >
              <Row className="mb-2">
                <Col>
                  <Form.Label>Assessment</Form.Label>
                  <InputGroup>
                    <Form.Control
                      autoFocus
                      required
                      as="textarea"
                      name="assessment_instruction"
                      value={data.assessment_instruction}
                      onChange={handleInput}
                      placeholder="Instructions"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="justify-content-between mb-2">
                <Col lg="6" md="6" sm="12">
                  <Form.Label>Start date</Form.Label>
                  <InputGroup className="mb-2" style={{ display: "flex" }}>
                    <Form.Control placeholder="Start Date" onChange={handleInput} value={data?.assessment_start_date} autoFocus type="date" name="assessment_start_date" />
                  </InputGroup>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <Form.Label>End date</Form.Label>
                  <InputGroup className="mb-2" style={{ display: "flex" }}>
                    <Form.Control placeholder="End Date" onChange={handleInput} autoFocus type="date" data={data?.assessment_end_date} name="assessment_end_date" />
                  </InputGroup>
                </Col>

                <Col lg="6" md="6" sm="12">
                  <Form.Label>Start Time</Form.Label>
                  <InputGroup className="mb-2" style={{ display: "flex" }}>
                    <Form.Control placeholder="Start Time" onChange={handleInput} autoFocus type="time" value={data?.assessment_start_time} name="assessment_start_time" />
                  </InputGroup>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <Form.Label>End Time</Form.Label>
                  <InputGroup className="mb-2" style={{ display: "flex" }}>
                    <Form.Control placeholder="End Time" onChange={handleInput} autoFocus type="time" value={data?.assessment_end_time} name="assessment_end_time" />
                  </InputGroup>
                </Col>

                {/* <Col className="vertical-line" style={{ height: "45px" }}></Col> */}
                <Col style={{ paddingLeft: "15px", marginTop: 20, }} lg="6" md="6" sm="12">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Form.Label size={"large"}>Teams &nbsp;</Form.Label>

                    <Dropdown >
                      <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications ">
                        <Button
                          variant="success"
                          size="sm"
                          style={{ fontSize: "12px" }}
                        >
                          &nbsp;+ Assign{" "}&nbsp;
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="multi-select-dropdown">
                        <ListGroup className="list-group-flush">
                          <Nav.Link href="#" className="text-primary fw-bold border-bottom border-light py-1 mb-1 bg-soft">
                            Select Team
                          </Nav.Link>

                          <Dropdown.Item onClick={handleTeamName}>
                            {userData?.username}
                          </Dropdown.Item>
                        </ListGroup>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {
                    data.team_name ?

                      <>
                        <ToggleButtonGroup type="radio" name="options">
                          <ToggleButton size='sm' style={{ fontSize: 12 }} id="tbg-radio-1">
                            {data.team_name}
                          </ToggleButton>
                          <ToggleButton style={{ fontSize: 12 }} onClick={() => { handleInputCheckbox({ name: 'team_name', value: '' }) }} size='sm' id="tbg-radio-2">
                            X
                          </ToggleButton>
                        </ToggleButtonGroup>

                        <Form.Group id="dropdown-revenue" className="mb-3 mt-3" style={{ maxWidth: 200 }}>
                          <Form.Label>Team Reminder</Form.Label>
                          <Form.Select
                            name="selectedOption"
                            value={data.team_reminder}
                            onChange={(e) => { handleDropdownChange("team_reminder", e) }}
                          >
                            <option value="">Choose Here</option>
                            <option value="At start of Task">At start of Task</option>
                            <option value="30 min Berfore">30 min Berfore</option>
                            <option value="1 hour Berfore">1 hour Berfore</option>
                            <option value="2 hour Berfore">2 hour Berfore</option>
                            {/* Add more options as needed */}
                          </Form.Select>
                        </Form.Group>

                        <Form.Check type="checkbox" className="mt-2" style={{ marginLeft: 2 }}>
                          <Form.Check.Input
                            onClick={() => handleInputCheckbox({ name: 'is_email_team_about_assignment', value: !data.is_email_team_about_assignment })}
                            id="defaultCheck5"
                            className="me-2"
                            checked={data.is_email_team_about_assignment}
                          />
                          <Form.Check.Label htmlFor="defaultCheck5" className="mb-0">
                            Email team about assignment
                          </Form.Check.Label>
                        </Form.Check>
                      </>

                      : <Form.Label>No users are currently assigned</Form.Label>
                  }
                </Col>
              </Row>
            </div>
          </>
        )}
        {/* form 2 */}

        <Form
          style={{
            border: "1px solid #ccc",
            padding: "15px",
            borderRadius: "10px",
            marginBottom: "15px",
          }}
        >
          <Row className="mb-2">
            <Col>
              <Form.Label>Internal notes & attachments</Form.Label>
              <InputGroup>
                <Form.Control
                  autoFocus
                  required
                  as="textarea"
                  name="internal_note_details"
                  value={data?.internal_note_details}
                  onChange={handleInput}
                  placeholder="Notes Details"
                />
              </InputGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col>
              <div style={{ border: '1px dashed #000' }} className="mb-3 my-3">
                <input
                  type="file"
                  className="form-control"
                  aria-label="file example"
                  required
                  multiple  // Enable multiple file selection
                  onChange={handleFileChange}
                />
                <div className="invalid-feedback">Example invalid form file feedback</div>

                {/* Display selected files */}
                {selectedFiles.length > 0 && (
                  <div className="p-3">
                    <ul>
                      {selectedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          {/* <Col xs={12} className="mb-2">
            <hr />
          </Col> */}
          {/* <Row>
            <Form.Label>Link note to related</Form.Label>
          </Row>
          <Row className="mb-2">
            <Col lg="2" sm="4">
              <InputGroup style={{ margin: "auto" }}>
                <Form.Check
                  type="checkbox" // Use type="checkbox" for a checkbox input
                  label="Quotes"
                  name="internal_link_note_to_related"
                  value="Quotes"
                  onChange={handleInputInternalLinks}
                />
              </InputGroup>
            </Col>
            <Col lg="2" sm="4">
              <InputGroup style={{ margin: "auto" }}>
                <Form.Check
                  type="checkbox" // Use type="checkbox" for a checkbox input
                  label="Jobs"
                  name="internal_link_note_to_related"
                  value="Jobs"
                  onChange={handleInputInternalLinks}
                />
              </InputGroup>
            </Col>
            <Col lg="2" sm="4" >
              <InputGroup style={{ margin: "auto" }}>
                <Form.Check
                  type="checkbox"
                  label="Invoices"
                  name="internal_link_note_to_related"
                  value="Invoices"
                  onChange={handleInputInternalLinks}
                />
              </InputGroup>
            </Col>
          </Row> */}
        </Form>
        <div className="buttond d-flex justify-content-between">
           <button type="button" className="btn btn two-btn" onClick={handleBtnBack}>
            Cancel
          </button>
        
          {data.client_id ? (
            <Button
              onSubmit={handleFormSubmit}
              variant="primary"
              type="submit"
              className=" mt-4"
              disabled={isButtonDisable}
            >
              {isButtonDisable ? "submitting..." : "Submit Client"}
            </Button>
          ) : (
            <Button
              onClick={handleOpenModal}
              variant="primary"
              className=" mt-4"
              style={{
                backgroundColor: colors.red,
                color: colors.white,
                border: "none",
              }}
            >
              Select Client
            </Button>
          )}
        </div>
      </Form>
      <Toaster />
    </div>
  );
}

export default NewRequestForm;
