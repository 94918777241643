import React, { useState } from "react";
import hammer from "../../assets/img/hammer.png";
import location from "../../assets/img/location.png";
import lilnk from "../../assets/img/link.png";
import AxiosInstance from "../../config/AxiosInstance";
import { useParams } from "react-router-dom";


function JobDetails() {
  let [loader, setLoader] = React.useState(false);
  const [jobDetailsData, setJobDetailsData] = useState();
  const { job_id } = useParams();
  // const job_id = "1704174820255eaulzjq9248811635";
  const getData = async () => {
    try {
      setLoader(true);
      const res = await AxiosInstance.get(`/job/job_details/${job_id}`);
      setJobDetailsData(res.data.data[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div className="signup-container shadow p-2 rounded">
      <div className="main-sec">
        <div className="three-items d-flex justify-content-between">
          <div
            className="street-sign"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                backgroundColor: "#4A5073", // Circle background color
                borderRadius: "50%", // Make it circular
                padding: "5px", // Adjusted padding for better visibility
                marginRight: "10px", // Margin to separate from other elements
              }}
            >
              <img
                src={hammer}
                alt="Icon"
                style={{
                  width: "30px",
                  height: "30px",
                  margin: "5px",
                }}
              />
            </div>
            <div
              className="first-two-items d-flex"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <b className="h5">Requires invoicing</b>
            </div>
            <div className="first-two-items d-flex"></div>
          </div>

          <div
            className="first-two-items d-flex"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <h5>Job #1 </h5>
          </div>
        </div>

        <hr />

        <div className="copy-section">
          <div className="req  d-flex justify-content-between ">
            <div className="firs-sec">
              <div className="main-heading d-flex">
                {/* <h1>{requestDetaisData?.request_title}sparrow soft</h1> */}
                <h1>
                  {jobDetailsData?.clientData?.first_name}{" "}
                  {jobDetailsData?.clientData?.last_name}
                </h1>

                <img
                  src={lilnk}
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: "10px",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
          </div>
          <h6>Quote Title </h6>
          <div className="row d-flex flex-wrap justify-content-between">
            <div className="col-sm-4 col-md-4 mt-3">
              <div className="card h-100">
                <div className="card-body">
                  <p className="mb-3">
                    <b>Property address</b>
                  </p>
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src={location}
                      style={{
                        width: "35px",
                        height: "40px",
                        margin: "10px",
                        alignItems: "center",
                        marginTop: "-56px",
                      }}
                    />
                    <div>
                      <p>
                        {jobDetailsData?.propertyData?.street_1}
                        <br />
                        {jobDetailsData?.propertyData?.street_2}
                        <br />
                        {jobDetailsData?.propertyData?.city},{" "}
                        {jobDetailsData?.propertyData?.state}{" "}
                        {jobDetailsData?.propertyData?.zipcode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-md-4 mt-3">
              <div className="card h-100">
                <div className="card-body">
                  <div>
                    <p>
                      <b>Contact details</b>
                    </p>
                    <div className="d-flex">
                      <p>
                        {jobDetailsData?.clientData?.phone}
                        <br />
                        {jobDetailsData?.clientData?.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-md-4 mt-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Quote Details</h5>

                  <div className="info-section">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">Job type</p>
                      <p>
                        {jobDetailsData?.is_one_off_job ? "One-off job" : null}
                        {jobDetailsData?.is_recuring_job
                          ? "Recurring Job"
                          : null}
                      </p>
                      {/* <p>One-off job </p> */}
                    </div>
                    <hr className="my-2" />

                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">Start date</p>
                      <p>{jobDetailsData?.schedule_start_date}</p>
                    </div>
                    <hr className="my-2" />

                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">End date</p>
                      <p>{jobDetailsData?.schedule_end_date}</p>
                    </div>
                    <hr className="my-2" />

                    {/* <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">Marked closed</p>
                      <p>Dec 25, 2023</p>
                    </div>
                    <hr className="my-2" /> */}

                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">Billing frequency</p>
                      <p>Upon job completion </p>
                    </div>
                    <hr className="my-2" />

                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">From quote</p>
                      <p>Quote #2</p>
                    </div>
                    <hr className="my-2" />

                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">From request</p>
                      <p>Dec 25, 2023</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 p-3">
        <div className="card">
          <div className="card-body">
            <h5
              className="card-title"
              style={{
                backgroundColor: "#4A5073",
                padding: "10px",
                color: "white",
              }}
            >
              Line Items
            </h5>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">
                      <b>Product / Service</b>
                    </th>
                    <th scope="col">
                      <b>Quantity</b>
                    </th>
                    {/* <th scope="col">
                      <b>Cost</b>
                    </th> */}
                    <th scope="col">
                      <b>Price</b>
                    </th>
                    <th scope="col">
                      <b>Total</b>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {jobDetailsData?.line_items?.map((item, index) => (
                    <tr>
                      <td>
                        <b>{item?.name}</b>
                        <br />
                        {item?.description}
                      </td>
                      <td> {item?.part_qty}</td>
                      {/* <td>€0.00*</td> */}
                      <td>€{item?.part_price} </td>
                      <td>€ {item?.total_amount}</td>
                    </tr>
                  ))}

                  <tr>
                    <td></td>
                    <td></td>
                    <td>{/* <b>€50.00*</b> */}</td>
                    {/* <td></td> */}
                    <td>
                      <b>€{jobDetailsData?.line_items_total}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* labour */}
      {/* <div className="col-lg-12 p-3">
        <div className="card">
          <div className="card-body">
            <h5
              className="card-title"
              style={{
                backgroundColor: "#4A5073",
                padding: "10px",
                color: "white",
              }}
            >
              labour
            </h5>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">
                      <b>Team</b>
                    </th>
                    <th scope="col">
                      <b>Notes</b>
                    </th>
                    <th scope="col">
                      <b>Date</b>
                    </th>
                    <th scope="col">
                      <b>Hours</b>
                    </th>
                    <th scope="col">
                      <b>Cost</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>shivam shukla</td>
                    <td>react developer</td>
                    <td>
                      12/27/2023
                      <br /> 02:52 PM - 03:52 PM
                    </td>
                    <td>01:00</td>
                    <td>€10.00*</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>01:00</b>
                    </td>
                    <td>
                      <b>€10.00*</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}

      {/* Expenses */}
      {/* <div className="col-lg-12 p-3">
        <div className="card">
          <div className="card-body">
            <h5
              className="card-title"
              style={{
                backgroundColor: "#4A5073",
                padding: "10px",
                color: "white",
              }}
            >
              Expenses
            </h5>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">
                      <b>Item</b>
                    </th>
                    <th scope="col">
                      <b>Description</b>
                    </th>
                    <th scope="col">
                      <b>Date</b>
                    </th>
                    <th scope="col">
                      <b>Amount</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Laptop</td>
                    <td>dell,hp,lenovo</td>
                    <td>12/27/2023</td>
                    <td>€0.00</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>€0.00</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}

      {/* Internal notes and attachments */}
      {/* <div className="col-lg-12 ">
        <div className="card">
          <div className="card-body">
            <h5
              className="card-title"
              style={{
                backgroundColor: "#4A5073",
                padding: "10px",
                color: "white",
              }}
            >
              Created: Dec 25, 2023 15:25
            </h5>
            <p className="card-text p-3">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries,
            </p>
            <hr />
            <div className="card-text p-4">
              <img
                src={lilnk}
                style={{
                  width: "20px",
                  height: "20px",
                  margin: "10px",
                  alignItems: "center",
                }}
              />
              <img
                src={location}
                style={{
                  width: "35px",
                  height: "40px",
                  margin: "10px",
                  alignItems: "center",
                }}
              />
            </div>
            <hr />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default JobDetails;
