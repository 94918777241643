import axios from "axios";

const axiosClientInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

console.log(axiosClientInstance, process.env.REACT_APP_API_URL, "baseUnitURL");



export default axiosClientInstance;
