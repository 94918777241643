// Invoice.js

import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../config/AxiosInstance";
import InvoiceTable from "./InvoiceTable";
import Cookies from "universal-cookie";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Routes } from "../routes";
import {colors} from "../config/colors";
import {
  Col,
  Row,
  Form,
  // Spinner
} from "@themesberg/react-bootstrap";
import {
  faAngleDown,
  faAngleUp,
  faPlus,
  faTrash,
  faEdit,
  faTextWidth,
  faCamera,
  faFileAlt,
  faTimesCircle,
  faBuilding,
  faUser,faSearch,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import {  InputGroup } from '@themesberg/react-bootstrap';


function Invoice() {
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
    // Close the second modal when the first modal is opened
    setShowSecondModal(false);
  };

  const handleCloseModall = () => {
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowPropertyModal(false);
  };

  const handleShowSecondModal = () => {
    setShowSecondModal(true);
    // Close the first modal when the second modal is opened
    setShowModal(false);
  };

  const handleCloseSecondModal = () => setShowSecondModal(false);

  const [loadingClients, setLoadingClients] = useState(true);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]); // Initialize with an empty array
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [properties, setProperties] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loadingProperties, setLoadingProperties] = useState(true);
  const cookies = new Cookies();
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const history = useHistory();
  const fetchProperty = async (properyId) => {
    try {
      setLoadingProperties(true);

      history.push(`/invoice/new/${properyId}`);
      // handleInputCheckbox({ "name": "client_id", value: data?.client_id })
      // handleInputCheckbox({ "name": "property_id", value: data?.property_id })
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error);
      toast.error("We are Troubleshooting... try again later");
    } finally {
      // selectedProperty
      // history.push(Routes.NewInvoice.path)

      setLoadingProperties(false);
      handleCloseModal();
    }
  };
  const filteredProperties = properties?.filter(
    (property) =>
      property.street_1.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (property.street_2 &&
        property.street2.toLowerCase().includes(searchTerm.toLowerCase())) ||
      property.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.zipcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.country.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const fetchClients = async () => {
    try {
      const userData = cookies.get("userDetails");
      setUserData(userData);
      const response = await axiosInstance.get(
        `/request/request_client/${userData.user_id}`
      );
      if (response.data.data) {
        const fetchedClients = response.data.data;
        setClients(fetchedClients);
        setFilteredClients(fetchedClients); // Set both clients and filteredClients with the fetched data
        console.log("client data", fetchedClients);
      }
    } catch (error) {
      console.log("error in getting fetchClients in new request form:", error);
    } finally {
      setLoadingClients(false);
    }
  };
  useEffect(() => {
    fetchClients();
  }, []);
  const fetchProperties = async (clientId) => {
    try {
      setSelectedClientId(clientId);
      const response = await axiosInstance.get(
        `/request/request_property/${clientId}`
      );
      if (response.data.data) {
        setProperties(response.data.data);
      }
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error);
    } finally {
      setLoadingProperties(false);
    }
  };
  const handleOpenPropertyModal = (id) => {
    // Open the modal
    setShowModal(false);
    setShowPropertyModal(true);
    fetchProperties(id);
  };
  const renderTable = () => {
    if (loadingClients) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            Loading...
          </td>
        </tr>
      );
    }
    if (!filteredClients || filteredClients.length === 0) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            No clients found
          </td>
        </tr>
      );
    }
    return filteredClients.map((client) => (
      <tr
        key={client.client_id}
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenPropertyModal(client.client_id)}
      >
        <td style={{ paddingLeft: 40 }}>
          {client.company_name ? (
            <FontAwesomeIcon icon={faBuilding} className="me-2" />
          ) : (
            <FontAwesomeIcon icon={faUser} className="me-2" />
          )}
        </td>
        <td>
          {client.company_name ? (
            <>
              <div>
                {client.company_name} ({client.first_name})
              </div>
              <div>
                {client.properties} Properties | {client.propertyCount}
              </div>
            </>
          ) : (
            <>
              <div>{client.first_name}</div>
              <div>
                {client.properties} Properties | {client.propertyCount}
              </div>
            </>
          )}
        </td>
        <td>{client.updatedAt}</td>
      </tr>
    ));
  };
  const renderProperty = () => {
    if (loadingProperties) {
      return (
        <div className="w-100 align-items-center d-flex justify-content-center py-2">
          <Spinner animation="grow" />
        </div>
      );
    }
    if (properties?.length === 0 || properties == undefined) {
      return (
        <div className="w-100 align-items-center d-flex justify-content-center py-2">
          Properties Not found - Please Create Property
        </div>
      );
    }
    if (filteredProperties?.length === 0) {
      return (
        <div className="w-100 align-items-center d-flex justify-content-center py-2">
          No results for "{searchTerm}"
        </div>
      );
    }
    return filteredProperties?.map((property, index) => (
      <div
        key={property.id}
        style={{ marginBottom: "10px", marginTop: "5px", cursor: "pointer" }}
        onClick={() => {
          fetchProperty(property.property_id);
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 5,
            }}
          >
            <span>{`${property.street_1}${
              property.street_2 ? `, ${property.street_2}` : ""
            }, ${property.city} - ${property.zipcode}, ${property.state}, ${
              property.country
            }`}</span>
          </div>
          <div style={{ marginRight: "10px", paddingRight: 20 }}>
            <span>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </div>
        </div>
        {index < filteredProperties?.length - 1 && (
          <hr
            style={{
              width: "100%",
              margin: "5px 0",
              border: "1px solid #ccc",
            }}
          />
        )}
      </div>
    ));
  };
  

  return (
    <>
      <div className="text-end">
        <div className="d-flex w-100 mb-4 my-3 justify-content-between">
          <p className="heading-form text-dark">Invoice</p>
          <Button 
            variant="success"
            size="sm"
            onClick={handleShowModal}
            style={{ maxHeight: 40 ,backgroundColor:"#e91818",border:"none"}}
            className="me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            New Invoice
          </Button>
        </div>
        <Modal
          className=""
          show={showModal}
          onHide={handleCloseModall}
          style={{ height: "auto" }}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Select or create a client</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: "scroll", maxHeight: "80vh" }}>
            <div className="p-4">
              <p className="mb-2 ml-2">
                Which client would you like to create this for?
              </p>
              <div className="border border-dark">
                <div
                  style={{
                    height: 70,
                    justifyContent: "center",
                    backgroundColor: "lightgray",
                    alignItems: "center",
                  }}
                  className="d-flex align-items-center mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search client..."
                    style={{
                      border: "none",
                      width: "130px",
                      borderRadius: "0",
                    }}
                    // onChange={handleSearchChange}
                  />
                  <p className="mx-2 my-2">OR</p>
                  <Button
                    variant="primary"
                    className=""
                    onClick={() => history.push(Routes.Client.path)}
                    style={{backgroundColor:colors.red,color:colors.white,border:"none"}}
                  >
                    Create New Client
                  </Button>
                </div>
                <div>
                  <tr className="my-3">
                    <label style={{ marginLeft: "10px" }}> Leads</label>{" "}
                  </tr>
                  <Col xs={12} className="mb-2">
                    <hr />
                  </Col>
                  <table className="table table-striped">
                    <tbody>{renderTable()}</tbody>
                  </table>
                  <tr>
                    {" "}
                    <label style={{ marginLeft: "10px" }}>
                      {" "}
                      Archived{" "}
                    </label>{" "}
                  </tr>
                  <Col xs={12} className="mb-2">
                    <hr />
                  </Col>
                  <table className="table table-striped">
                    <tbody>{renderTable()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showPropertyModal} centered onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Select or create a property...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-4">
              <p className="mb-2 ml-2">
                Which property would you like to use for this?
              </p>
              <div className="border border-dark">
                <div
                  style={{
                    height: 70,
                    justifyContent: "center",
                    backgroundColor: "lightgray",
                    alignItems: "center",
                  }}
                  className="d-flex align-items-center"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search properties..."
                    style={{
                      border: "none",
                      width: "150px",
                      fontSize: "13px",
                      borderRadius: "0",
                    }}
                    // onChange={handleSearchpropertyChange}
                  />
                  <p className="mx-2 my-2">OR</p>
                  <Button
                    variant="primary"
                    className=""
                    style={{
                      fontSize: "14px",
                      backgroundColor:colors.red,color:colors.white
                      ,border:"none"
                    }}
                    onClick={() => {
                      // Additional logic if needed before closing the modal
                      handleCloseModal();
                      // Additional logic after closing the modal
                      // history.push(`/properties/${selectedClientId}`);
                    }}
                  >
                    Create New Property
                  </Button>
                </div>
                <div>{renderProperty()}</div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>         
      <div className="m-2 pt-4  border rounded">
        <InvoiceTable />
      </div>
    </>
  );
}

export default Invoice;
