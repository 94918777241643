import React from 'react'

// import LandingPageNavbar from '../components/LandingPageNavbar'
import Home from '../LandingContent1';
import LandingPageNavbar from '../../components/LandingPageNavbar';
import LandingContent3 from '../LandingContent3';
import Premium1 from './Premium1';



function PremiumPlans() {
  return (
    <>
        <LandingPageNavbar />
        {/* <Home /> */}
        <Premium1 />
    </>
  )
}

export default PremiumPlans
