  import React, { useState, useEffect } from "react";
  import { Button, Dropdown, ButtonGroup } from "@themesberg/react-bootstrap";
  import Col from "react-bootstrap/Col";
  import Form from "react-bootstrap/Form";
  import InputGroup from "react-bootstrap/InputGroup";
  import Row from "react-bootstrap/Row";
  import * as formik from "formik";
  import * as yup from "yup";
  import Cookies from "universal-cookie";
  import { useHistory } from "react-router-dom";
  import axios from "axios";  
  import {
    faArrowLeft,
    faAddressBook,
    faPen,
    faEllipsisH,
  } from "@fortawesome/free-solid-svg-icons";
  const cookies = new Cookies();

  const Profile = () => {
    const { Formik } = formik;
    const [userData, setUserData] = useState([]);
    const history = useHistory();
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      companyName: "",
      companyRevenue: "",
      teamSize: "",
      priority: "",
    });
    console.log("user", userData);
    useEffect(() => {
      const userDetails = cookies.get("userDetails");

      if (userDetails) {
        setUserData(userDetails);
        setFormData({
          user_id: userDetails.user_id || "",
          username: userDetails.username || "",
          email: userDetails.email || "",
          phone: userDetails.phone || "",
          companyName: userDetails.companyName || "",
          companyRevenue: userDetails.companyRevenue || "",
          teamSize: userDetails.teamSize || "",
          priority: userDetails.priority || "",
          createAt: userDetails.createAt || "",
          lastThing: userDetails.lastThing || "",
          password: userDetails.password || "",
          updateAt: userDetails.updateAt || "",
        });
      }
    }, []);
    console.log("userDetails", formData);

    const handleEditClick = () => {
      setIsEditing(!isEditing); // Toggle edit mode
    };
    const handleTextBoxClick = () => {
      setIsEditing(true);
    };


    const schema = yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      username: yup.string().required(),
      city: yup.string().required(),
      state: yup.string().required(),
      zip: yup.string().required(),
      terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
    });

    const handleSaveClick = async () => {
      try {
        // Replace the URL with your actual API endpoint
        const apiUrl = `https://jobmanager.cloudpress.host/api/user/user_profile/${userData.user_id}`;

        // Make a PUT request to update the user profile
        await axios.put(apiUrl, {
          // Update the properties based on your API requirements
          username: formData.username,
          email: formData.email,
          phone: formData.phone,
          companyName: formData.companyName,
          companyRevenue: formData.companyRevenue,
          teamSize: formData.teamSize,
          priority: formData.priority,
        });

        // If the request is successful, update the local user details
        setUserData(formData);

        // Save the updated user details to cookies
        cookies.set("userDetails", formData);

        // Exit edit mode
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    };

    return (
  
  <div className="container-fluid my-5">
    <div className="row" style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",padding:"20px",borderBottomLeftRadius:"30px",borderTopRightRadius:"30px"}}>
      <div className="col-md-5 text-center d-flex justify-content-center align-items-center" >
        <div className="col-md-10 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-column align-items-center text-center">
                <div
                  style={{
                    backgroundColor: "#ccc",
                    borderRadius: "50%",
                    padding: "20px",
                    width: "90px",
                    fontSize: "30px",
                  }}
                >
                  {userData?.username?.slice(0, 1).toUpperCase()}
                </div>
                <div className="mt-3">
                  <h4>{userData?.username}</h4>
                  <p>{userData?.email}</p>
                  <p>{userData?.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-7">
        <div className="ms-md-5">
          <Formik
            validationSchema={schema}
            onSubmit={handleSaveClick}
            initialValues={{
              firstName: "Mark",
              lastName: "Otto",
              username: "",
              email: "",
              phone: "",
              companyName: "",
              companyRevenue: "",
              teamSize: "",
              priority: "",
              terms: false,
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <i className="fa-solid fa-user" style={{ marginRight: "10px" }}></i>
                    <label htmlFor="username" className="form-label">User Name</label>
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      // value={formData.username}
                      value={isEditing ? formData.username : userData.username}
                      onChange={(e) =>
                        setFormData({ ...formData, username: e.target.value })
                      }
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="col-md-6">
                    <i className="fa fa-envelope" style={{ marginRight: "10px" }}></i>
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      // value={formData.email}
                      value={isEditing ? formData.email : userData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      disabled={!isEditing}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <i className="fa-solid fa-phone" style={{ marginRight: "10px" }}></i>
                    <label htmlFor="phone" className="form-label">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      // value={formData.phone}
                      value={isEditing ? formData.phone : userData.phone}
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="col-md-6">
                    <i className="fa fa-building" style={{ marginRight: "10px" }}></i>
                    <label htmlFor="companyName" className="form-label">Company Name</label>
                    <input
                      type="text"
                      name="companyName"
                      className="form-control"
                      // value={formData.companyName}
                      value={isEditing ? formData.companyName : userData.companyName}
                      onChange={(e) =>
                        setFormData({ ...formData, companyName: e.target.value })
                      }
                      disabled={!isEditing}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <i className="fa-solid fa-dollar-sign" style={{ marginRight: "10px" }}></i>
                    <label htmlFor="companyRevenue" className="form-label">Company Revenue</label>
                    <input
                      type="text"
                      name="companyRevenue"
                      className="form-control"
                      // value={formData.companyRevenue}
                      value={isEditing ? formData.companyRevenue : userData.companyRevenue}
                      onChange={(e) =>
                        setFormData({ ...formData, companyRevenue: e.target.value })
                      }
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="col-md-6">
                    <i className="fa-solid fa-users" style={{ marginRight: "10px" }}></i>
                    <label htmlFor="teamSize" className="form-label">Team Size</label>
                    <input
                      type="text"
                      name="teamSize"
                      className="form-control"
                      // value={formData.teamSize}
                      value={isEditing ? formData.teamSize : userData.teamSize}
                      onChange={(e) =>
                        setFormData({ ...formData, teamSize: e.target.value })
                      }
                      disabled={!isEditing}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <i className="fa-solid fa-arrows-to-dot" style={{ marginRight: "10px" }}></i>
                    <label htmlFor="priority" className="form-label">Priority</label>
                    <input
                      type="text"
                      name="priority"
                      className="form-control"
                      // value={formData.priority}
                      value={isEditing ? formData.priority : userData.priority}
                      onChange={(e) =>
                        setFormData({ ...formData, priority: e.target.value })
                      }
                      disabled={!isEditing}
                    />
                  </div>
                </div>

                <div className="d-grid gap-2 d-md-flex">
                  {/* <button className="btn btn-primary me-md-2" type="submit" ><i class="fa-solid fa-pen" style={{marginRight:"3px"}}></i> Edit</button>
                  <button className="btn btn-primary" type="button"><i class="fa-solid fa-check" style={{marginRight:"3px"}}></i> Save</button> */}
              
            <ButtonGroup>
              <Button
                variant="btn btn-primary"
                size="sm"
                onClick={isEditing ? handleSaveClick : handleEditClick}
                style={{padding:"10px"}}
              >
                <i
      className={`fa ${isEditing ? "fa-check" : "fa-pencil"}`}
      aria-hidden="true"
      style={{ marginRight: "10px"  }}
    ></i>
                {isEditing ? "Save" : "Edit"}
              </Button>
            </ButtonGroup>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  </div>

    );
  };

  export default Profile;
