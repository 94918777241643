import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faYoutube, faGoogle, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Container } from "@themesberg/react-bootstrap";
import homeLogo from "../assets/img/home-main2.png";

function LandingContent4() {
  
  return (
    <div>
      <div>

      </div>
      <section>
        <Container fluid className="home-section" style={{ paddingBottom: 0, margin: 0 }} id="home">
          <Container className="home-content">

            <h3 style={{ textAlign: 'center', paddingTop: '100px' }}>Job  Cloud</h3>
            <p style={{ maxWidth: '80%', textAlign: 'center', margin: '0 auto', color: 'gray' }}>
              Foolish developer is a blog of a website where you will find great tutorials on web design and development.
              Here, each tutorial is beautifully described step by step with the required source code.
            </p>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
              <FontAwesomeIcon
                icon={faFacebook}
                style={{ fontSize: '25px', margin: '0 10px', cursor: 'pointer', ':hover': { color: 'blue' } }}
              />
              <FontAwesomeIcon
                icon={faTwitter}
                style={{ fontSize: '25px', margin: '0 10px', cursor: 'pointer', ':hover': { color: 'blue' } , color:'pink'}}
              />
              <FontAwesomeIcon
                icon={faYoutube}
                className="social-icon"
                style={{ fontSize: '25px', margin: '0 10px', cursor: 'pointer', ':hover': { color: 'blue' } }}
              />
              <FontAwesomeIcon
                icon={faGoogle}
                style={{ fontSize: '25px', margin: '0 10px', cursor: 'pointer', ':hover': { color: 'blue' } }}
              />
              <FontAwesomeIcon
                icon={faLinkedin}
                style={{ fontSize: '25px', margin: '0 10px', cursor: 'pointer', ':hover': { color: 'blue' } }}
              />
            </div>
          </Container>
        </Container>
      </section>


    </div>
  );
}

export default LandingContent4;
