// config/colors.js

export const colors = {
    primary: '#007BFF',
    secondary: '#6C757D',
    success: '#28A745',
    warning: '#FFC107',
    danger: '#DC3545',
    red: '#e91818',
    white:'#ffffff',
  };