import React, { useState } from "react";
import location from "../../assets/img/location.png";
import hammer from "../../assets/img/hammer.png";
import left_quotes from "../../assets/img/left-quotes-sign.png";
import delivery from "../../assets/img/delivery.png";
import download from "../../assets/img/download.png";
import star from "../../assets/img/star.png";
import AxiosInstance from "./../../config/AxiosInstance";
import { useHistory, useParams } from "react-router-dom";

function PropertyDetails() {
  const history = useHistory();
  const { property_id } = useParams();

  const [activeTab, setActiveTab] = React.useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const TabControl = () => {};

  const [propertyDetails, setPropertyDetails] = useState();
  const [clientId, setClientId] = useState();
  console.log(clientId, "clientId");
  // const request_id = "1703049698098iimg5bca3048754553";
  const getData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/properties/property_get/${property_id}`
      );
      setPropertyDetails(res.data.data[0]);
      setClientId(res.data.data[0].client.client_id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getData();
  }, []);

  const [propertyRequestData, setPropertyRequestData] = useState();
  // const { request_id } = useParams();
  // const propertyid = "1703306087846zr8pl9pi6697307010";
  const getpropertyDetails = async () => {
    try {
      const res = await AxiosInstance.get(`/request/request_data/${clientId}`);
      setPropertyRequestData(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getpropertyDetails();
  }, []);

  const [QuoteData, setQuoteData] = useState();
  console.log(QuoteData, "QuoteData");
  // const { request_id } = useParams();
  // const quoteid = "1703138304798fd57hf9j5406954087";
  const getquostDetails = async () => {
    try {
      const res = await AxiosInstance.get(`/quotes/quotes_client/${clientId}`);
      setQuoteData(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getquostDetails();
  }, []);

  return (
    <div className="p-5 ">
      <div className="signup-container shadow p-3 rounded">
        <h2 style={{ color: "black" }}>Property Details</h2>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-3 shadow">
          <div className="card">
            <div className="card-body">
              <h5
                className="card-title"
                style={{
                  backgroundColor: "#4A5073",
                  padding: "10px",
                  color: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ced4da",
                }}
              >
                Location
              </h5>
              <div className="d-flex flex-column flex-sm-row gap-5">
                <div>
                  <p>
                    <h5>Client</h5> {propertyDetails?.client?.first_name}{" "}
                    {propertyDetails?.client?.last_name}
                  </p>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <img
                    src={location}
                    style={{
                      width: "35px",
                      height: "40px",
                      margin: "10px",
                      alignItems: "center",
                    }}
                  />
                  <div>
                    <p>
                      {propertyDetails?.street_1} {propertyDetails?.street_2}
                      <br />
                      {propertyDetails?.city} {propertyDetails?.state}
                      <br />
                      {propertyDetails?.country} {propertyDetails?.zipcode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-12 mt-3 shadow">
          <div className="card">
            <div className="card-body">
              <h5
                className="card-title"
                style={{
                  backgroundColor: "#4A5073",
                  padding: "10px",
                  color: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ced4da",
                }}
              >
                Recent pricing for this property
              </h5>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Line Item</th>
                      <th scope="col">Quoted</th>
                      <th scope="col">Job</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Backup Data </td>
                      <td>€20.00*</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Software Installation </td>
                      <td>€20.00*</td>
                      <td></td>
                    </tr>
                    <tr>* Hover for notes regarding this cost</tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-lg-12 mt-3 shadow">
          <div className="card">
            <div className="card-body">
              <h5
                className="card-title"
                style={{
                  backgroundColor: "#4A5073",
                  padding: "10px",
                  color: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ced4da",
                }}
              >
                Overview
              </h5>

              <div style={{ fontFamily: "Arial, sans-serif" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    onClick={() => handleTabClick(1)}
                    style={{
                      flex: "1",
                      padding: "10px 15px",
                      border: "none",
                      borderRadius: "5px 5px 0 0",
                      backgroundColor: activeTab === 1 ? "lightblue" : "white",
                      color: activeTab === 1 ? "black" : "gray",
                      cursor: "pointer",
                      marginBottom: "10px", // Add some space between buttons
                    }}
                  >
                    Active Work
                  </button>
                  <button
                    onClick={() => handleTabClick(2)}
                    style={{
                      flex: "1",
                      padding: "10px 15px",
                      border: "none",
                      borderRadius: "5px 5px 0 0",
                      backgroundColor: activeTab === 2 ? "lightblue" : "white",
                      color: activeTab === 2 ? "black" : "gray",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    Requests
                  </button>
                  <button
                    onClick={() => handleTabClick(3)}
                    style={{
                      flex: "1",
                      padding: "10px 15px",
                      border: "none",
                      borderRadius: "5px 5px 0 0",
                      backgroundColor: activeTab === 3 ? "lightblue" : "white",
                      color: activeTab === 3 ? "black" : "gray",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    Quotes
                  </button>
                  <button
                    onClick={() => handleTabClick(4)}
                    style={{
                      flex: "1",
                      padding: "10px 15px",
                      border: "none",
                      borderRadius: "5px 5px 0 0",
                      backgroundColor: activeTab === 4 ? "lightblue" : "white",
                      color: activeTab === 4 ? "black" : "gray",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    Jobs
                  </button>
                </div>

                <div
                  style={{
                    border: "1px solid lightblue",
                    borderRadius: "0 0 5px 5px",
                    padding: "15px",
                    maxWidth: "100%",
                    overflowX: "auto",
                  }}
                >
                  {activeTab === 1 && (
                    // <table className="table">
                    //   <tbody>
                    //     <tr>
                    //       <td>
                    //         <b>Quote #1 </b>
                    //         <br />
                    //         <span
                    //           style={{
                    //             backgroundColor: "silver",
                    //             padding: "3px",
                    //           }}
                    //         >
                    //           Awaiting response
                    //         </span>
                    //       </td>
                    //       <td>
                    //         Created on
                    //         <br />
                    //         Dec 25, 2023
                    //       </td>
                    //       <td>
                    //         jivandeep
                    //         <br />
                    //         sundarnagar
                    //         <br />
                    //         Gandhinagar, Gujarat 896523
                    //       </td>
                    //       <td>
                    //         <b>€30.00</b> <br />
                    //         <img
                    //           src={star}
                    //           alt="Icon"
                    //           style={{
                    //             width: "30px",
                    //             height: "30px",
                    //             margin: "5px",
                    //           }}
                    //         />
                    //       </td>
                    //     </tr>
                    //   </tbody>
                    // </table>

                    <div className="d-flex justify-content-justifiy">
                      <div
                        style={{
                          backgroundColor: "#4A5073", // Circle background color
                          borderRadius: "50%",
                          padding: "5px",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          src={delivery}
                          alt="Icon"
                          style={{
                            width: "30px",
                            height: "30px",
                            margin: "5px",
                          }}
                        />
                      </div>

                      <div>
                        {" "}
                        <b>No active work</b>
                        <br />
                        No active jobs, invoices or quotes for this client yet
                      </div>
                    </div>
                  )}

                  {activeTab === 2 ? (
                    propertyRequestData &&
                    Object.keys(propertyRequestData).length > 0 ? (
                      <table className="table">
                        <tbody>
                          {propertyRequestData?.map((property, index) => (
                            <tr
                              onClick={() =>
                                history.push(
                                  `/superadmin/requestdetails/${property.request_id}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <td>
                                <b>Request</b>
                              </td>
                              <td>
                                Requested on
                                <br />
                                {property?.request_date}
                              </td>
                              <td>
                                {property?.property?.street_1},{" "}
                                {property?.property?.street_2}
                                <br />
                                {property?.property?.city}
                                <br />
                                {property?.property?.state},{" "}
                                {property?.property?.country}{" "}
                                {property?.property?.zipcode}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="d-flex justify-content-justifiy">
                        <div
                          style={{
                            backgroundColor: "#4A5073",
                            borderRadius: "50%",
                            padding: "5px",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={download}
                            alt="Icon"
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "5px",
                            }}
                          />
                        </div>

                        <div>
                          {" "}
                          <b>Client hasn't requested any work yet</b>
                          <br />
                          Clients can submit new requests for work online. You
                          and your team can also create requests to keep track
                          of new work that comes up.
                        </div>
                      </div>
                    )
                  ) : null}

                  {activeTab === 3 ? (
                    QuoteData && Object.keys(QuoteData).length > 0 ? (
                      <table className="table">
                        <tbody>
                          {QuoteData?.map((quote, index) => (
                            <tr>
                              <td>
                                <b>{quote?.job_title}</b>
                              </td>
                              <td>{quote?.createdAt}</td>
                              <td>
                                {quote?.property?.street_1},{" "}
                                {quote?.property?.street_2}
                                <br />
                                {quote?.property?.city}
                                <br />
                                {quote?.property?.state},{" "}
                                {quote?.property?.country}{" "}
                                {quote?.property?.zipcode}
                              </td>
                              <td>
                                <b>{quote?.property?.zipcode}</b> <br />
                                <img
                                  src={star}
                                  alt="Icon"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    margin: "5px",
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="d-flex justify-content-justifiy">
                        <div
                          style={{
                            backgroundColor: "#4A5073", // Circle background color
                            borderRadius: "50%",
                            padding: "5px",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={left_quotes}
                            alt="Icon"
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "5px",
                            }}
                          />
                        </div>

                        <div>
                          {" "}
                          <b>No Quotes</b>
                          <br />
                          Measure twice, cut once. It's time for some quotes!
                        </div>
                      </div>
                    )
                  ) : null}

                  {activeTab === 4 && (
                    // <table className="table">
                    //   <tbody>
                    //     <tr>
                    //       <td>
                    //         <b>Job #1 -</b> Quotes title
                    //         <br />
                    //         <span
                    //           style={{
                    //             backgroundColor: "silver",
                    //             padding: "3px",
                    //           }}
                    //         >
                    //           Requires invoicing
                    //         </span>
                    //       </td>
                    //       <td>
                    //         COMPLETED
                    //         <br />
                    //         Dec 25, 2023
                    //       </td>
                    //       <td>
                    //         <b>€10.00</b>
                    //       </td>
                    //     </tr>
                    //   </tbody>
                    // </table>

                    <div className="d-flex justify-content-justifiy">
                      <div
                        style={{
                          backgroundColor: "#4A5073", // Circle background color
                          borderRadius: "50%",
                          padding: "5px",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          src={hammer}
                          alt="Icon"
                          style={{
                            width: "30px",
                            height: "30px",
                            margin: "5px",
                          }}
                        />
                      </div>

                      <div>
                        {" "}
                        <b>No jobs </b>
                        <br />
                        Let’s get out there and work
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-12 mt-3 shadow">
          <div className="card">
            <div className="card-body">
              <h5
                className="card-title"
                style={{
                  backgroundColor: "#4A5073",
                  padding: "10px",
                  color: "white",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #ced4da",
                }}
              >
                Schedule
              </h5>

              <div class="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Overdue</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        First Room Cleaning
                        <br />
                        re: Mr. meet gohel
                      </td>
                      <td>
                        Remove unnecessary items, dust surfaces from top to
                        bottom, <br />
                        and wipe down sur...
                      </td>
                      <td>
                        Dec 22, 2023 16:00 <br />
                        Not assigned yet
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Reminder about quote
                        <br />
                        re: Mr. meet gohel
                      </td>
                      <td>
                        Quote was sent on Dec 22, 2023 but no job has been
                        generated
                        <br />
                        yet{" "}
                      </td>
                      <td>
                        Dec 25, 2023 <br />
                        Assigned to shivam shukla
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PropertyDetails;
