import React, { useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
} from "@themesberg/react-bootstrap";
import "../../Component/pages/test.css";
import lilnk from "../../assets/img/link.png";
// import MoveToInboxOutlinedIcon from "@mui/icons-material/MoveToInboxOutlined";
import "./reqDetail.css";
import AxiosInstance from "./../../config/AxiosInstance";
// import DateRangeIcon from "@mui/icons-material/DateRange";
import { height } from "@mui/system";
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useHistory, useParams } from "react-router-dom";
import download from "../../assets/img/download.png";
import location from "../../assets/img/location.png";
import { Circles } from "react-loader-spinner";
import moment from "moment";

function RequestDetails() {
  let [loader, setLoader] = React.useState(false);

  // const [propertiesData, setProperties] = useState([])
  const [requestDetaisData, setRequestDetailsData] = useState();
  // console.log("1a1a1a1a",requestDetaisData[0])
  const { request_id } = useParams();
  const getData = async () => {
    try {
      setLoader(true);
      const res = await AxiosInstance.get(`/request/request_get/${request_id}`);
      setRequestDetailsData(res.data.data[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getData();
  }, []);

  const formattedDate = moment(requestDetaisData?.request_date).format(
    "DD-MM-YYYY"
  );
  const currentDate = moment().format("DD-MM-YYYY");

  const dateCellContent = formattedDate === currentDate ? "Today" : "Overdue";

  return (
    <div className="signup-container shadow p-2 rounded">
      {loader ? (
        <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
          <Circles
            height="50"
            width="50"
            color="#4A5073"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="main-sec">
            <div className="three-items d-flex justify-content-between">
              <div
                className="street-sign"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#4A5073", // Circle background color
                    borderRadius: "50%", // Make it circular
                    padding: "5px", // Adjusted padding for better visibility
                    marginRight: "10px", // Margin to separate from other elements
                  }}
                >
                  <img
                    src={download}
                    alt="Icon"
                    style={{
                      width: "30px",
                      height: "30px",
                      margin: "5px",
                    }}
                  />
                </div>
                <div
                  className="first-two-items d-flex"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <b className="h5">{requestDetaisData?.request_title}</b>
                </div>
                <div className="first-two-items d-flex"></div>
              </div>

              <div
                className="first-two-items d-flex"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                {/* <h5>Request</h5> */}
                <h4 className="text-success">
                  {requestDetaisData?.status == ""
                    ? dateCellContent
                    : requestDetaisData?.status}
                </h4>
              </div>
            </div>
            <hr />
            <div className="copy-section">
              <div className="req  d-flex justify-content-between ">
                <div className="firs-sec">
                  <div className="main-heading d-flex">
                    {/* <h1>{requestDetaisData?.request_title}sparrow soft</h1> */}
                    <h1>
                      {requestDetaisData?.clientData?.first_name}{" "}
                      {requestDetaisData?.clientData?.last_name}
                    </h1>
                    {/* <img src=""></> */}
                    <img
                      src={lilnk}
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px",
                        alignItems: "center",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row d-flex flex-wrap justify-content-between">
                <div className="col-sm-4 col-md-4 mt-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <p className="mb-3">
                        <b>Property address</b>
                      </p>
                      <div className="d-flex align-items-center mb-3">
                        <img
                          src={location}
                          style={{
                            width: "35px",
                            height: "40px",
                            margin: "10px",
                            alignItems: "center",
                            marginTop: "-56px",
                          }}
                        />
                        <div>
                          <p>
                            {/* <LocationOnIcon /> */}
                            {requestDetaisData?.propertyData?.street_1},<br />
                            {requestDetaisData?.propertyData?.street_2},<br />
                            {requestDetaisData?.propertyData?.city},
                            {requestDetaisData?.propertyData?.state},<br />
                            {requestDetaisData?.propertyData?.country},
                            {requestDetaisData?.propertyData?.zipcode}.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-md-4 mt-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <div>
                        <p>
                          <b>Contact details</b>
                        </p>
                        <div className="d-flex">
                          <p>
                            {requestDetaisData?.clientData?.phone}
                            <br />
                            {requestDetaisData?.clientData?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-md-4 mt-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <h5>Request details</h5>
                      <div className="d-flex justify-content-between mb-3">
                        <p>Requested on</p>

                        <p>
                          {moment(requestDetaisData?.request_date).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <p>Assessment</p>
                        <p>None</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="copy-section my-5">
            <div className="reqq  d-flex justify-content-between">
              <div
                className="firs-sec com-lg-6 col-sm-5 col-xl-6 col-lg-11"
                style={{ marginLeft: "20px" }}
              >
                <h5>Service Details</h5>
                <p>
                  <b>Please provide as much information as you can </b>
                </p>
                <p> {requestDetaisData?.service_detail} </p>
                <hr />
                <h5>Your Availability</h5>
                <p>
                  <b>Which day would be best for an assessment of the work?</b>
                  <br />
                  {requestDetaisData?.assessment_start_date}
                </p>
                <p>
                  <b>What is another day that works for you? </b>
                  <br />
                  {requestDetaisData?.assessment_end_date}
                </p>
                <p>
                  <b>What are your preferred arrival times? </b>
                  <br />
                  {requestDetaisData?.preferred_arrival_times.map(
                    (arrivalTime, index) => (
                      <div key={index}>
                        <i className="fa-solid fa-check"></i> {arrivalTime}
                      </div>
                    )
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{
                    backgroundColor: "#4A5073",
                    padding: "10px",
                    color: "white",
                  }}
                >
                  Assessment
                </h5>
                <h6>Instructions :</h6>
                <p className="card-text p-2">
                  {requestDetaisData?.assessment_instruction}
                </p>
                <hr />
                <div>
                  <h6>
                    Schedule : &nbsp; Start :&nbsp;
                    {requestDetaisData?.assessment_start_date} &nbsp;
                    {requestDetaisData?.assessment_start_time} &nbsp;&nbsp;
                    <b>-</b>&nbsp;&nbsp; End :&nbsp;
                    {requestDetaisData?.assessment_end_date} &nbsp;
                    {requestDetaisData?.assessment_end_time}
                  </h6>
                </div>
                <hr />
                <div>
                  <h6>
                    Team : &nbsp;
                    {requestDetaisData?.team_name} &nbsp;
                  </h6>
                </div>
                <hr />
                <div>
                  <h6>
                    Visit reminderss : &nbsp;
                    {requestDetaisData?.team_reminder} &nbsp;
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-3">
            <div className="card">
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{
                    backgroundColor: "#4A5073",
                    padding: "10px",
                    color: "white",
                  }}
                >
                  Internal notes and attachments
                </h5>
                <h6>Created :</h6>{" "}
                {moment(requestDetaisData?.request_date).format("DD-MM-YYYY")}
                <h6 className="mt-2">Notes Details :</h6>
                <p className="card-text">
                  {requestDetaisData?.internal_note_details}
                </p>
                <hr />
                <div>
                  <h6>Attachment:</h6>
                  <div style={{ display: "flex" }}>
                    {/* {requestDetaisData?.internal_attachments.map(
                      (attachment, index) => (
                        <div key={index} style={{ marginRight: "10px" }}>
                          <img
                            src={attachment}
                            alt={`Attachment ${index + 1}`}
                            style={{ height: "70px", width: "70px",  cursor:"pointer" }}

                          />
                        </div>
                      )
                    )} */}
                    {requestDetaisData?.internal_attachments.map(
                      (attachment, index) => (
                        <div key={index} style={{ marginRight: "10px" }}>
                          <a
                            href={attachment}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={attachment}
                              alt={`Attachment ${index + 1}`}
                              style={{
                                height: "70px",
                                width: "70px",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div
        style={{
          padding: "10px",
          border: "2px solid #3CA2E0",
          backgroundColor: "#E2F1FA",
        }}
        className="third-box d-flex col-6 "
      >
        <div className="icon">
          <i
            style={{
              fontSize: "40px",
              borderRight: "2px solid #3CA2E0",
              height: "100%",
              padding: "20px",
              color: "#3CA2E0",
            }}
            className="fa-regular fa-lightbulb"
          ></i>
        </div>
        <div style={{ padding: "20px" }} className="contains">
          <h5 style={{ color: "#012939" }}>Customize your request form</h5>
          <p>
            Get the information you need upfront from your clients and new leads
          </p>
          <Button
            variant=""
            style={{
              minWidth: "50px",
              height: "44px",
              marginRight: "10px",
              backgroundColor: "#3CA2E0",
              color: "#fff",
              // marginLeft: "50px",
            }}
          >
            Customize Form
          </Button>
        </div>
      </div> */}
    </div>
    // </div>
  );
}

export default RequestDetails;
