import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMapMarkerAlt,
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";
import axiosClientInstance from "../config/axiosClientInstance.js";
import {colors} from "../config/colors.js";
const QuotesDashboard = () => {
  const history = useHistory();
  const [quoteData, setQuoteData] = useState(null);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      setUserData(decodedToken);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log(userData.id.  client_id, "yash")
        const response = await axiosClientInstance.get(
          `/quotes/all_quotes_client/${userData.id.client_id}`
        );
        setQuoteData(response.data.data); // Keep the entire data object
        console.log(response, "janak")
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false); // Set loading to false when data is fetched or if an error occurs
      }
    };

    fetchData();
  }, [userData]);

  const filterQuotesByStatus = (status) => {
    return quoteData?.[status] || [];
  };

  const hasApprovedQuotes = Array.isArray(filterQuotesByStatus("approved")) && filterQuotesByStatus("approved").length > 0;
  const hasAwaitingResponseQuotes = Array.isArray(filterQuotesByStatus("awaitingResponse")) && filterQuotesByStatus("awaitingResponse").length > 0;
  const hasQuotes = hasApprovedQuotes || hasAwaitingResponseQuotes;

  return (
    <div className="row justify-content-center">
      <div className="col-5 mt-5">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {hasQuotes ? (
              <p style={{ textAlign: "left", paddingLeft: "5px", color: "#262b40" }}>
                <label>Your Quotes</label>
              </p>
            ) : (
              <p style={{ textAlign: "center", color: "#262b40" }}>No quotes found.</p>
            )}
            <div className="card-deck">
              {/* Approved Quotes */}
              {hasApprovedQuotes &&
                filterQuotesByStatus("approved").map((quote, index) => (
                  <>
                    {index === 0 && (
                      <p>
                        <label
                          style={{
                            textAlign: "left",
                            paddingLeft: "5px",
                            color: "green",
                            fontWeight: "bold",
                            marginTop: "5px",
                            marginBottom: "10px",
                          }}
                        >
                          Approved Quotes
                        </label>
                      </p>
                    )}
                    <div
                      className="card shadow mb-3"
                      key={quote._id}
                      onClick={() =>
                        history.push(
                          `/client/quoteoverview/${quote.quotes_id}/${quote.status}`
                        )
                      }
                    >
                      {/* Render Quote Card Content */}
                      <div className="card-body">
                        <h5 className="card-title">Quote</h5>
                        <div className="card-text">
                          <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faCalendar} className="ml-3" style={{ color:colors.red}}/>
                            <span style={{ padding: "6px" }}>
                              Sent {new Date(quote.createdAt).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className="ml-3"
                              style={{ color:colors.red}}
                            />
                            <span style={{ padding: "6px" }}>
                              {quote.property &&
                                `${quote.property.city}, ${quote.property.state}, ${quote.property.country}, ${quote.property.street_1}, ${quote.property.street_2}, ${quote.property.zipcode}`}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex" style={{ marginLeft: "auto" }}>
                          <div>
                            <span style={{ padding: "6px" }}>Total</span>
                          </div>
                          <div style={{ marginLeft: "auto" }}>
                            <FontAwesomeIcon icon={faEuroSign} />
                            <span>
                              {quote.product_servise
                                .reduce(
                                  (total, product) =>
                                    total + parseFloat(product.total_amount),
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}

              {/* Awaiting Response Quotes */}
              {hasAwaitingResponseQuotes &&
                filterQuotesByStatus("awaitingResponse").map((quote, index) => (
                  <>
                    {index === 0 && (
                      <p>
                        <label
                          style={{
                            textAlign: "left",
                            paddingLeft: "5px",
                            color: "red",
                            fontWeight: "bold",
                            marginTop: "5px",
                            marginBottom: "10px",
                          }}
                        >
                          Awaiting response
                        </label>
                      </p>
                    )}
                    <div
                      className="card shadow mb-3"
                      key={quote._id}
                      onClick={() =>
                        history.push(
                          `/client/quoteoverview/${quote.quotes_id}/${quote.status}`
                        )
                      }
                    >
                      {/* Render Quote Card Content */}
                      <div className="card-body">
                        <h5 className="card-title">Quote</h5>
                        <div className="card-text">
                          <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faCalendar} className="ml-3" style={{ color:colors.red}}/>
                            <span style={{ padding: "6px" }}>
                              Sent {new Date(quote.createdAt).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className="ml-3"
                              style={{ color:colors.red}}
                            />
                            <span style={{ padding: "6px" }}>
                              {quote.property &&
                                `${quote.property.city}, ${quote.property.state}, ${quote.property.country}, ${quote.property.street_1}, ${quote.property.street_2}, ${quote.property.zipcode}`}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex" style={{ marginLeft: "auto" }}>
                          <div>
                            <span style={{ padding: "6px" }}>Total</span>
                          </div>
                          <div style={{ marginLeft: "auto" }}>
                            <FontAwesomeIcon icon={faEuroSign} />
                            <span>
                              {quote.product_servise
                                .reduce(
                                  (total, product) =>
                                    total + parseFloat(product.total_amount),
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QuotesDashboard;
