import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

import {
  Button,
  Form,
  Col,
  Row,
  InputGroup,
  DropdownButton,
  Table,
  Dropdown,
  OverlayTrigger,
} from "@themesberg/react-bootstrap";
import {
  faEnvelope,
  faArrowLeft,
  faAddressBook,
  faPen,
  faEllipsisH,
  faStar,
  faCheck,
  faFilePdf,
  faChevronDown,
  faCreditCard,
  faFileInvoiceDollar,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../routes";
import { ButtonGroup } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLine } from "@fortawesome/free-brands-svg-icons";

function InvoiceDetails() {
  const { invoice_id } = useParams();

  const [invoiceDetails, setInvoiceDetails] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const fetchInvoiceDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://jobmanager.cloudpress.host/api/invoice/invoice_detail/${invoice_id}`
      );
      console.log(invoice_id, "invoice_id");
      setInvoiceDetails(response.data.data[0]);
      console.log(response.data, "krushil");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching invoice details:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInvoiceDetails();
  }, [invoice_id]); 
  
  useEffect(() => {
    fetchInvoiceDetails();
  }, [invoice_id]);
  // Empty dependency array ensures the effect runs once when the component mounts

  return (
    <div className="signup-container shadow p-2 rounded">
      {loading ? (
        <div>Loading...</div>
      ) : invoiceDetails ? (
        <>
          <div className="">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <Dropdown className="btn-toolbar">
                <Dropdown.Toggle
                  as={Button}
                  variant="primary"
                  size="sm"
                  className="me-2"

                  style={{backgroundColor:"#e91818",border:"none"}}
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                  Back To
                </Dropdown.Toggle>
                <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
                  <Dropdown.Item
                    onClick={() => {
                      history.push(Routes.InvoiceDashboard.path);
                    }}
                    className="fw-bold"
                  >
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      className="me-2"
                    />{" "}
                    Invoice
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <ButtonGroup>
                <Button variant="outline-primary" size="sm">
                  {" "}
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ marginRight: "3px" }}
                  />{" "}
                  Email
                </Button>
                <Button variant="outline-primary" size="sm">
                  {" "}
                  <FontAwesomeIcon
                    icon={faPen}
                    style={{ marginRight: "3px" }}
                  />
                  Edit
                </Button>
                <Button variant="outline-primary" size="sm">
                  {" "}
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    style={{ marginRight: "3px" }}
                  />
                  More Action
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="main-sec">
            <div className="three-items d-flex justify-content-between">
              <div
                className="street-sign"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#4A5073", 
                    borderRadius: "50%", 
                    padding: "10px", 
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    style={{
                      marginRight: "5px",
                      color: "white",
                      fontSize: "24px",
                    }}
                  />
                </div>
                <div
                  className="first-two-items d-flex"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <b>Awaiting Payment</b>
                </div>
                <div className="first-two-items d-flex"></div>
              </div>

              <div
                className="first-two-items d-flex"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <h5>Invoice</h5>
              </div>
            </div>
            <hr />
            <div className="copy-section">
              <div className="req  d-flex justify-content-between ">
                <div className="firs-sec">
                  <div className="main-heading d-flex">
                    {/* <h1>{requestDetaisData?.request_title}sparrow soft</h1> */}
                    <h1>
                      {/* {quotesDetaisData?.client?.first_name}{" "} */}
                      {/* {quotesDetaisData?.client?.last_name} */}
                      {invoiceDetails?.client?.first_name}{" "}
                      {invoiceDetails?.client?.last_name}
                    </h1>
                    {/* <FontAwesomeIcon icon={faLink} style={{ marginRight: "5px", color: "#4A5073", fontSize: '24px'}} /> */}
                  </div>
                </div>
              </div>
              {/* <p>{quotesDetaisData?.job_title}</p> */}

              <div className="row d-flex flex-wrap justify-content-between">
                <div className="col-sm-4 col-md-4 mt-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <h5 className="card-title">Property address</h5>
                      <div className="d-flex align-items-center mb-3">
                        <div>
                          <p>
                            {invoiceDetails?.property?.street_1},
                            {invoiceDetails?.property?.city},<br />
                            {invoiceDetails?.property?.state},
                            {invoiceDetails?.property?.country}
                            <br />
                            {invoiceDetails?.property?.zipcode}.
                          </p>
                          {/* <p>
                      {{invoiceDetails?.client?.first_name} ,
                      {quotesDetaisData?.property?.city},
                      {quotesDetaisData?.property?.state},<br />
                      {quotesDetaisData?.property?.country},
                      {quotesDetaisData?.property?.zipcode}.
                    </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-md-4 mt-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <div>
                        <h5 className="card-title">Contact details</h5>
                        <div className="d-flex">
                          <p>
                            {/* 7359425150
                      krushilvaghani1227@gmail.com */}
                            {invoiceDetails?.client?.phone} <br />
                            {invoiceDetails?.client?.email}
                            {/* {quotesDetaisData?.client?.phone} */}
                            <br />
                            {/* {quotesDetaisData?.client?.email} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-md-4 mt-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <h5 className="card-title">Invoice details</h5>

                      <div className="info-section">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <p className="mb-0">Issued</p>
                          <p> {invoiceDetails?.invoice_issued_date} </p>
                        </div>
                        <hr className="my-2" />

                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <p className="mb-0">Due</p>
                          <p> {invoiceDetails?.invoice_payment_due}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive my-5 p-3">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Product / Service</th>
                        <th scope="col">Qty.</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    {/* <tbody>
                {quotesDetaisData?.product_servise.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <b>{item.name}</b>
                      <br />
                      {item.description}
                    </td>
                    <td>{item.part_qty}</td>
                    <td>€{item.part_price}</td>
                    <td>€{item.part_price}</td>
                  </tr>
                ))}
              </tbody> */}
                    <tbody>
                      {/* {quotesDetaisData?.product_servise.map((item, index) => ( */}
                      <tr>
                        <td>
                          <b>
                            Name: {invoiceDetails?.product_servise[0]?.name}
                          </b>
                          <br />
                          Deacription:{" "}
                          {invoiceDetails?.product_servise[0]?.description}
                        </td>
                        <td> {invoiceDetails?.product_servise[0]?.part_qty}</td>
                        <td>
                          € {invoiceDetails?.product_servise[0]?.part_price}
                        </td>
                        <td>
                          € {invoiceDetails?.product_servise[0]?.total_amount}
                        </td>
                      </tr>
                      {/* ))} */}
                    </tbody>
                  </table>

                  <hr style={{ height: "5px" }} />
                  {/* <div className="container"> */}

                  <div className="row">
                    <div className="col-8">
                      {/* <p>{quotesDetaisData?.client_message}</p> */}
                      <p>
                        This quote is valid for the next 30 days, after which
                        values may be subject to change.
                      </p>
                    </div>

                    <div className="col-4">
                      <table className="table">
                        <tbody>
                          {/* <tr>
                            <td>Subtotal</td>
                            <td>€{quotesDetaisData?.product_servise_total}</td>
                          </tr> */}
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                               € {invoiceDetails?.product_servise[0]?.total_amount}
                            </td>
                            <td>
                              {/* <b>€{quotesDetaisData?.product_servise_total}</b> */}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <hr style={{ height: "5px" }} />
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h5
                        className="card-title"
                        style={{
                          backgroundColor: "#4A5073",
                          padding: "10px",
                          color: "white",
                        }}
                      >
                        Created: {quotesDetaisData?.createdAt}
                      </h5>
                      <p className="card-text">
                        {quotesDetaisData?.internal_notes_detais}
                      </p>
                      <hr />
                      <div>
                        <img
                    src={lilnk}
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "10px",
                      alignItems: "center",
                    }}
                  />
                  <img
                    src={location}
                    style={{
                      width: "35px",
                      height: "40px",
                      margin: "10px",
                      alignItems: "center",
                    }}
                  />
                      </div>
                      <hr />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>Data Not Found... </div>
      )}
    </div>
  );
}

export default InvoiceDetails;
