import * as React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import ArrowUpLineIcon from '@rsuite/icons/ArrowUpLine';
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "react-bootstrap";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Collapse from "@mui/material/Collapse";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "universal-cookie";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { useHistory, useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
import AxiosInstance from "../../config/AxiosInstance";
const label = { inputProps: { "aria-label": "Switch demo" } };

const headCells = [
  {
    label: "Street 1",
  },
  {
    label: "Street 2",
  },
  {
    label: "City",
  },
  {
    label: "State",
  },
  {
    label: "Zipcode",
  },
  {
    label: "Country",
  },

  {
    label: "Date",
  },
];

function Row(props) {
  const { row, handleClick, isItemSelected, labelId, seletedEditData } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, row._id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.machineTypeId}
        selected={isItemSelected}
      >
        {/* <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowUpLineIcon /> : <ArrowDownLineIcon />}
          </IconButton>
        </TableCell> */}
  

        {/* <TableCell align="center">{ row + 1}</TableCell> */}
        <TableCell align="center">
          {row.street_1}
        </TableCell>
        <TableCell align="center">{row.street_2}</TableCell>
        <TableCell align="center">
          {row.city}
        </TableCell>
        <TableCell align="center">
          {row.state}
        </TableCell>
        <TableCell align="center">
          {row.zipcode}
        </TableCell>
        <TableCell align="center">
          {row.country}
        </TableCell>
        <TableCell align="center">
          {new Date(row.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })}
        </TableCell>
      </TableRow>

      {/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ paddingLeft: 15, margin: 2 }}>
            <Typography variant="h6" gutterBottom component="div">
              Other Data :
            </Typography>
            <hr />
         
            <table
              style={{
                fontFamily: "arial, sans-serif",
                border: "collapse",
                width: "50%",
              }}
            >
              <tr>
                <th
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  Field
                </th>
                <th
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  Value
                </th>
              </tr>

              {row.client_details.map((index, id) => (
                <tr key={id}>
                  <th
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {index.key}
                  </th>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {index.value}
                  </td>
                </tr>
              ))}
            </table>
          </Box>
        </Collapse>
      </TableCell> */}
    </React.Fragment>
  );
}

export default function Property() {
  let cookies = new Cookies();
  const history = useHistory();
  const { client_id } = useParams();
  React.useEffect(() => {
    const userData = cookies.get("token");

    if (!userData) {
      history.push("/superadmin/sign-in");
    }
  }, [cookies]);

  const [propertiesData, setProperties] = useState([])
  console.log(propertiesData, "propertiesData")
  let [loader, setLoader] = React.useState(true);
  let [countData, setCountData] = useState(0);

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const getData = async () => {
    try {
      const res = await AxiosInstance.get(
        `/properties/properties/${client_id}`,
        {
          params: {
            pageSize: rowsPerPage,
            pageNumber: page,
          },
        }
      );
      setLoader(false);
      setProperties(res.data.data);
      setCountData(res.data.count); // Make sure to adjust the key here
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = propertiesData?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Delete selected
  var handleDelete = () => {
    swal("Are You Sure You Want TO Delete ?", {
      buttons: ["No", "Yes"],
    }).then(async (buttons) => {
      if (buttons === true) {
        const token = cookies.get("token");
        AxiosInstance.delete("/revenue/revenue", {
          data: selected,
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //   },
        }).then((response) => {
          if (response.data.statusCode === 200) {
            getData();
            setSelected([]);
            toast.success(response.data?.message);
          }
        });
      }
    });
  };

  // Searchbar
  //   const [searchLoader, setSearchLoader] = useState(false);
  //   let handleSearchData = async (values) => {
  //     setSearchLoader(true);
  //     // const token = cookies.get("token");
  //     let res = await axios.post(
  //       "https://jobmanager.cloudpress.host/apirevenue/search/revenue",
  //       {
  //         search: values,
  //       }
  //     );
  //     if (res.data.statusCode === 200) {
  //       if (values !== "") {
  //         setSearchLoader(false);
  //         setProperties(res.data.data);
  //         setCountData(res.data.count);
  //       } else {
  //         setSearchLoader(false);
  //         getData();
  //       }
  //     }
  //   };

  //   edit machine-type here
  let [modalShowForPopupForm, setModalShowForPopupForm] = React.useState(false);
  let [id, setId] = React.useState();

  var handleSubmit;

  if (!id) {
    handleSubmit = async (values) => {
      try {
        const res = await AxiosInstance.post("/revenue/revenue", values);

        if (res.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          toast.success(res.data?.message);
        } else {
          toast.error(res.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error);
      }
    };
  } else {
    handleSubmit = async (values) => {
      try {
        const response = await AxiosInstance.put(
          `/revenue/revenue/${id}`, // Use template literals to include the id
          values
        );

        if (response.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          toast.success(response.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error);
      }
    };
  }

  //    // "add fom logic"
  let [editData, setEditData] = React.useState({});

  //   auto form fill up in edit
  let seletedEditData = async (datas) => {
    setModalShowForPopupForm(true);
    setId(datas._id);
    setEditData(datas);
  };

  // Formik
  //   let [ProductDetailsFormik, setProductDetailsFormik] = useState({});
  //   const FormikValues = () => {
  //     const formik = useFormikContext();
  //     React.useEffect(() => {
  //       setProductDetailsFormik(formik.values);
  //     }, [formik.values]);
  //     return null;
  //   };


  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }
  const textStyle = {
    color: 'black',
  };

  return (
    <>
      <Box sx={{ width: "100%", pb: "2%", pl: "2%", pr: "2%" }}>
        <Paper sx={{ width: "100%" }} className="table_bg_img">
          <Toolbar
            className="border-top border-bottom"
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Properties
              </Typography>
            )}

            {/* <form className="form-inline">
              <input
                id="serchbar-size"
                className="form-control mr-sm-2"
                type="search"
                onChange={(e) => handleSearchData(e.target.value)}
                placeholder="Search"
                aria-label="Search"
              />
            </form> */}

            <>
              {selected.length > 0 ? (
                <Tooltip title="Delete">
                  {/* <IconButton onClick={() => handleDelete()}>
                    <DeleteIcon />
                  </IconButton> */}
                </Tooltip>
              ) : null}
            </>
          </Toolbar>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <Circles
                  height="50"
                  width="50"
                  color="#4A5073"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
            </div>
          ) : (
            <TableContainer style={{overflow: "clip"}}>
             <div className="row" >
              {propertiesData.length > 0 ? (
                propertiesData.map((property) => (
                <div className="col-lg-6 mb-3" key={property._id}>
                 <div className="table_bg_img">
                  <div className="card-body" style={textStyle}>
                    <h5 className="card-title">{property.title}</h5>
                      <p className="card-text">
                      <div className="d-flex">
                        <div>
                          <strong>Street 1</strong><br />
                          <strong>Street 2</strong><br />
                          <strong>City</strong><br />
                          <strong>State</strong><br />
                          <strong>Zip Code</strong><br />
                          <strong>Country</strong> <br />
                          <strong>Created At</strong> <br />
                          <strong>Updated At</strong> <br />
                        </div>
                        <div>
                          <strong>:  </strong>{property.street_1}<br />
                          <strong>:  </strong>{property.street_2}<br />
                          <strong>:  </strong>{property.city}<br />
                          <strong>:  </strong>{property.state}<br />
                          <strong>:  </strong>{property.zipcode}<br />
                          <strong>:  </strong>{property.country}<br />
                          <strong>:  </strong>{formatDate(property.createdAt)}<br />
                          <strong>:  </strong>{formatDate(property.updatedAt)}
                        </div>
                      </div>
                      </p>
                  </div>
                </div>
              </div>
              ))
              ) : (
              <div className="table_bg_img">
                <p className="text-center mt-3">Data Not Found...</p>
                </div>
                )}
              </div>
            </TableContainer>
          )}
        </Paper>
      </Box>
      <Toaster />
    </>
  );
}
