import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from '../config/AxiosInstance';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faBriefcase,
  faTrash, faEdit,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  InputGroup,
  Collapse,
  Dropdown,
  Modal,
} from "@themesberg/react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { colors } from "../config/colors";

function NewClientForm({
  handlechange,
  handleInput,
  handleSubmit,
  data,
  setData,
  handleDropdownChange,
  handleInputClientdetails,
  handleDelete,
  handleEdit,
  isEditing,
  editIndex,
  setIsEditing,
  setEditIndex
}) {
  const [open, setOpen] = useState(false);
  const { clientId } = useParams();
  const [isDataChanged, setIsDataChanged] = useState(false);
  console.log("clientId", clientId);
  const [editModalShow, setEditModalShow] = useState(false);


  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axiosInstance.get(
          `/client/client_record/${clientId}`
        );
        const apiData = response.data.data;
        console.log(response.data.data, "response.data.data");
        // Set the initial state with the fetched data
        setData({
          first_name: apiData[0]?.first_name || "",
          last_name: apiData[0]?.last_name || "",
          company_name: apiData[0]?.company_name || "",
          contact_info: apiData[0]?.contact_info || "main",
          email: apiData[0]?.email || "",
          phone: apiData[0]?.phone || "",
          // Add other fields as needed
        });

        console.log("Fetched Client Data:", apiData);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchClientData();
  }, []);

  const { client_details } = data;

  const [key, setKey] = useState("");
  const [value, setValue] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);




  // const handleSubmitClient = () => {
  //   handleClose();
  //   if (isEditing) {
  //     handleInputClientdetails({ key, value }, editIndex);
  //   } else {
  //     handleInputClientdetails({ key, value });
  //   }
  //   setKey('');
  //   setValue('');
  //   setOpen(!open);
  // };
  const handleSubmitClient = () => {
  handleClose();

  const newClientDetail = { key, value };

  if (isEditing) {
    handleInputClientdetails(newClientDetail, editIndex);
  } else {
    handleInputClientdetails(newClientDetail);
  }

  setKey('');
  setValue('');
  setOpen(!open);
  // Remove the line setValueCheckbox(''); // Reset checkbox value

  // Set the value state based on the checked status of the checkbox
  const updatedClientDetails = [...client_details];

  if (isEditing) {
    updatedClientDetails.splice(editIndex, 1, newClientDetail);
  } else {
    updatedClientDetails.push(newClientDetail);
  }

  // Adjust the value state based on the checked status of the checkbox
  const checkboxValue = updatedClientDetails.some(item => item.value === 'Yes') ? 'Yes' : 'No';

  setData({
    ...data,
    client_details: updatedClientDetails,
  });

  // Set the value state based on the checked status of the checkbox
  setValue(checkboxValue);
};

  const [valueCheckbox, setValueCheckbox] = useState("");


  const handleEditModalShow = () => setEditModalShow(true);
  const handleEditModalClose = () => setEditModalShow(false);

  const handleEditClient = () => {
    const updatedClientDetails = [...client_details];
    updatedClientDetails.splice(editIndex, 1);
    updatedClientDetails.splice(editIndex, 0, { key, value });
    handleEdit(editIndex, { key, value });
    setData({
      ...data,
      client_details: updatedClientDetails,
    });
    setKey("");
    setValue("");
    handleEditModalClose();
  };


  const handleEditField = (index, item) => {
    setKey(item.key);
    setValue(item.value);
    setEditIndex(index);
    setIsEditing(true);
    handleEditModalShow();
  };

  const [clientData, setClientData] = useState({
    title: "",
    first_name: "",
    last_name: "",
  });


  const handleInputs = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };
  return (
    <div>
      <h3 className="text-dark">
        <FontAwesomeIcon icon={faUser} /> &nbsp;Client Details
      </h3>

      <Form onSubmit={handleSubmit} className="mt-4 p-2">

        <Row>
          <Col md={1} lg={1} sm={12} className="mb-4">
            {/* Title Dropdown */}
            <Dropdown
              onSelect={(selectedTitle) => handleInputs({ target: { name: "title", value: selectedTitle } })}
            >
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-basic"
                style={{
                  backgroundColor: "white",
                  color: "#999999",
                  border: "1px solid #ccc",
                  outline: "none",
                  boxShadow: "none",
                }}
              >
                {data.title || "Mr"}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  backgroundColor: "white",
                  color: "#999999",
                  border: "1px solid #ccc",
                  marginTop: "0",
                }}
              >
                <Dropdown.Item eventKey="Mr">Mr</Dropdown.Item>
                <Dropdown.Item eventKey="Mrs">Mrs</Dropdown.Item>
                {/* Add more titles as needed */}
              </Dropdown.Menu>
            </Dropdown>
          </Col>


          <Col md={5} lg={5} sm={12} className="mb-4">
            <InputGroup >
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUser} />
              </InputGroup.Text>
              <Form.Control
                autoFocus
                required
                type="text"
                name="first_name"
                value={data.first_name}
                onChange={handleInput}
                placeholder="First Name"
              />
            </InputGroup>
          </Col>

          <Col md={6} lg={6} sm={12} className="mb-4">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUser} />
              </InputGroup.Text>
              <Form.Control
                autoFocus
                required
                type="text"
                name="last_name"
                value={data.last_name}
                onChange={handleInput}
                placeholder="Last Name"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} sm={12} className="mb-4">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faBriefcase} />
              </InputGroup.Text>
              <Form.Control
                autoFocus
                required
                type="text"
                name="company_name"
                value={data.company_name}
                onChange={handleInput}
                placeholder="Company Name"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Form.Label>Enter Phone Number</Form.Label>

          <Col className="mb-4" md={5} lg={3}>
            {/* Dropdown for Team Size */}
            <Form.Group>
              <Form.Select
                name="contact_info"
                value={data.contact_info}
                onChange={(e) => {
                  handleDropdownChange("contact_info", e);
                }}
                required
              >
                <option value="main">Main</option>
                <option value="work">Work</option>
                <option value="mobile">Mobile</option>
                <option value="home">Home</option>
                <option value="fex">Fex</option>
                <option value="other">Other</option>
                {/* Add more options as needed */}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={7} lg={9} className="mb-4">
            {/* Input for something else, adjust as needed */}
            <Form.Group>
              <Form.Control
                type="text"
                name="phone"
                value={data.phone}
                onChange={handleInput}
                placeholder="Phone Number"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Form.Label>Enter Email Address</Form.Label>

          <Col className="mb-4" md={5} lg={3}>
            {/* Dropdown for Team Size */}
            <Form.Group>
              <Form.Select
                name="email_info"
                value={data.email_info}
                onChange={(e) => {
                  handleDropdownChange("email_info", e);
                }}
                required
              >
                <option value="main">Main</option>
                <option value="work">Work</option>
                <option value="personal">Personal</option>
                <option value="other">Other</option>
                {/* Add more options as needed */}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={7} lg={9} className="mb-4">
            {/* Input for something else, adjust as needed */}
            <Form.Group>
              <Form.Control
                type="email"
                name="email"
                value={data.email}
                onChange={handleInput}
                placeholder="Email Address"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={12} lg={12} sm={12}>
            <div
              className="d-flex justify-content-between mb-3"
              aria-controls="example-collapse-text"
              aria-expanded={open}
              style={{ cursor: "pointer" }}
              onClick={() => setOpen(!open)}
            >
              <h5 className="text-dark">Additional Client Details</h5>
              <h4 className="text-dark">
                <FontAwesomeIcon icon={!open ? faAngleDown : faAngleUp} />
              </h4>
            </div>

            <Collapse in={open} className="px-1 mb-0">
              <div id="example-collapse-text">
                {client_details?.length == 0 || client_details == undefined ? (
                  <Row>
                    <Col className="mb-4" md={12} lg={12}>
                      {/* Input for something else, adjust as needed */}
                      <Form.Group>
                        <Form.Control
                          readOnly={true}
                          type="text"
                          value={"No data Found"}
                          placeholder="Phone Number"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  <div></div>
                )}
                {client_details?.map((item, index) => (
                  <Row key={index}>
                    <Col className="mb-4" md={5} lg={3}>
                      {/* Input for something else, adjust as needed */}
                      <Form.Group>
                        <Form.Control
                          readOnly={true}
                          type="text"
                          value={item.key}
                          placeholder="Phone Number"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={5} lg={3} className="mb-4">
                      {/* Input for something else, adjust as needed */}
                      <Form.Group>
                        <Form.Control
                          readOnly={true}
                          type="text"
                          name="phone"
                          value={item.value}
                          onChange={handleInput}
                          placeholder="Phone Number"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={1} lg={1}>
                      <div className="d-flex">
                        <Button
                          variant="outline-primary"
                          onClick={() => handleDelete(index)}
                          className="m-1"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button
                          variant="outline-primary"
                          onClick={() => handleEditField(index, item)}
                          className="m-1"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            </Collapse>
          </Col>
        </Row>
        <Button
          onClick={() => {
            setShow(true);
          }}
          variant="outline-primary"
          className="w-100  mb-4"
          // style={{ maxWidth: 150 }}
          style={{ backgroundColor: colors.red, color: colors.white, maxWidth: 150, border: "none" }}
        >
          <p style={{ fontSize: "0.9rem" }}>Add Custom Field</p>
        </Button>


        <Modal show={editModalShow} onHide={handleEditModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Client Field</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3'>
                <Form.Label>Field Name</Form.Label>
                <Form.Control
                  type='text'
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Field Value</Form.Label>
                <Form.Control
                  type='text'
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className="dash-btn" onClick={handleEditModalClose}>
              Close
            </Button>
            <Button variant='primary' onClick={handleEditClient}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Row style={{ borderTop: "1px solid lightgray" }}>
          {!clientId ? (
            <Button
              onSubmit={handleSubmit}
              variant="primary"
              type="submit"
              className="w-100  mt-4"
              style={{ maxWidth: 200, backgroundColor: colors.red, color: colors.white, border: "none" }}
            >
              Create Client
            </Button>
          ) : (
            <Button
              onSubmit={handleSubmit}
              variant="primary"
              type="submit"
              className="w-100  mt-4 mx-3"
              style={{ maxWidth: 200, backgroundColor: colors.red }}
            >
              update Client
            </Button>

          )}
        </Row>
      </Form>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Client Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitClient}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Field Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Field Name"
                autoFocus
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Field Type</Form.Label>
              <select
                className="form-select"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                <option value="Text">Text</option>
                <option value="True/False">True/False</option>
              </select>
            </Form.Group>
            {value === "True/False" && (
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Field Value</Form.Label>
                <div>
                  <Form.Check
                    type="checkbox"
                    id="checkbox-yes"
                    label="Yes"
                    checked={valueCheckbox === "Yes"}
                    onChange={() => setValueCheckbox("Yes")}
                  />
                  <Form.Check
                    type="checkbox"
                    id="checkbox-no"
                    label="No"
                    checked={valueCheckbox === "No"}
                    onChange={() => setValueCheckbox("No")}
                  />
                </div>
              </Form.Group>
            )}
            {value !== "True/False" && (
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Field Value</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Value"
                  autoFocus
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                />
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" className="dash-btn" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmitClient}
            style={{ backgroundColor: colors.red, color: colors.white, border: "none" }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>



    </div>
  );
}

export default NewClientForm;
