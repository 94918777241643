import React from 'react'

function LandingContent3() {
  return (
    <div className='w-100'>
        
    </div>
  )
}

export default LandingContent3