import React from 'react'
import SidebarSetting from './SidebarSetting'

function Teamsetting() {
  return (
    <>
    <div className='main d-flex'>
      <SidebarSetting />
      <div className='headccc text-center'>
        <h5>Teamsetting</h5>
      </div>
    </div>
  </>
  )
}

export default Teamsetting
