// import { Button } from "@themesberg/react-bootstrap";
// import React, { useState, useEffect } from "react";
// import { faPlus, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Routes } from "../../routes";
// import { useHistory } from "react-router-dom";
// import Cookies from "universal-cookie";
// import axios from "axios";
// import QuotesTable from "./QuotesTable";

// const QuotesDashboard = () => {
//   const history = useHistory();
//   const [quotes, setQuotes] = useState();

//   return (
//     <div className="p-4">
//       <div className="d-flex w-100 mb-4 justify-content-between">
//         <h1>{}</h1>
//         <div>
//           <p className="heading-form text-dark">Quotes</p>
//         </div>
//         <div>
//           <Button
//             onClick={() => {
//               history.push(Routes.NewQuotes.path);
//             }}
//             size="sm"
//             style={{ maxHeight: 40 }}
//             className="me-2"
//           >
//             <FontAwesomeIcon icon={faPlus} className="me-2" />
//             New Quotes
//           </Button>
//           <Button size="sm" style={{ maxHeight: 40 }} className="me-2">
//             <FontAwesomeIcon icon={faEllipsisH} className="me-2" />
//             More Action
//           </Button>
//         </div>
//       </div>
//       <div className="three-content d-flex">
//         <div className="col-2 inputs">
//           <form className="">
//             <label htmlFor="searchInput" className="form-label">
//               Search
//             </label>
//             <input
//               id="searchInput"
//               className="form-control me-2"
//               type="search"
//               placeholder="Search"
//               aria-label="Search"
//             />
//           </form>
//         </div>
//         <div className="col-3 inputs">
//           <label htmlFor="dateInput" className="form-label">
//             Created
//           </label>
//           <select id="dateInput" className="form-select">
//             <option defaultValue>All</option>
//             <option>This month</option>
//             <option>Last 30 Days</option>
//             <option>Last month</option>
//             <option>This year</option>
//             <option>Custom</option>
//           </select>
//         </div>
//         <div className="col-3 inputs">
//           <label htmlFor="statusInput" className="form-label">
//             Sort
//           </label>
//           <select id="statusInput" className="form-select">
//             <option defaultValue>Status</option>
//             <option>Quote Number </option>
//             <option>First Name</option>
//             <option>Last Name</option>
//             <option>Star Rating</option>
//           </select>
//         </div>
//         <div className="col-3 inputs">
//           <label htmlFor="statusInput" className="form-label">
//             Type
//           </label>
//           <select id="statusInput" className="form-select">
//             <option defaultValue>All</option>
//             <option>New</option>
//             <option>Assessment completed</option>
//             <option>Overdue</option>
//             <option>Today</option>
//             <option>Upcoming</option>
//             <option>Unscheduled</option>
//             <option>Converted</option>
//             <option>Archived</option>
//           </select>
//         </div>
//       </div>
//       <div className=" mt-3 pt-4">
//         <QuotesTable quotes={quotes} />
//       </div>
//     </div>
//   );
// };

// export default QuotesDashboard;

import { Button } from "@themesberg/react-bootstrap";
import React, { useState, useEffect } from "react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import axiosInstance from "../../config/AxiosInstance";
import QuotesTable from "./QuotesTable";
import {
  Col,
  Form,
  Card,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";

function QuotesDashboard() {
  const history = useHistory();
  const cookies = new Cookies();
  const [userClients, setUserClients] = useState();
  const [loading, setLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredData, setFilteredData] = useState("");

  const fetchData = async () => {
    try {
      const userData = cookies.get("userDetails");
      const response = await axiosInstance.get(
        `/quotes/quotes_all/${userData.user_id}`
      );
      setUserClients(response.data.data);
    } catch (error) {
      console.log("error in fetchdata in company details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteRow = async (id) => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(
        `https://jobmanager.cloudpress.host/api/client/client/delete/${id}`
      );
      fetchData();
      setLoading(false);
    } catch (error) {
      console.log("error in fetchdata in company details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (keyword) => {
    console.log(userClients, "yash");
    const filteredResults = userClients?.filter((item) => {
      // Customize this logic based on your search requirements
      const phoneNumberString = String(item.phone);

      console.log(keyword, "keyword");
      console.log(item, "keyword2");

      return (
        (item.client &&
          item.client.first_name &&
          item.client.first_name
            .toLowerCase()
            .includes(keyword.toLowerCase())) ||
        (item.client &&
          item.client.last_name &&
          item.client.last_name
            .toLowerCase()
            .includes(keyword.toLowerCase())) ||
        (item.client &&
          item.client.company_name &&
          item.client.company_name
            .toLowerCase()
            .includes(keyword.toLowerCase())) ||
        phoneNumberString.includes(keyword)
      );
    });
    setSearchKeyword(keyword);
    setFilteredData(filteredResults);
  };
  return (
    <div className="p-4">
      <div className="d-flex quotes-firstt-button w-100 mb-4 justify-content-between">
        <p className="heading-form text-dark">Quotes</p>
        <Button
          onClick={() => {
            history.push({ pathname: Routes.NewQuotes.path, state: [] });
          }}
          variant="success"
          size="sm"
          style={{ maxHeight: 40, backgroundColor: "#e91818", border: "none" }}
          className="me-2"
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          New Quotes
        </Button>
      </div>
      <div className="w-100 align-items-end justify-content-end d-flex">
        <InputGroup
          style={{ maxWidth: 200, alignSelf: "flex-end" }}
          className="mx-2 mb-1"
        >
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control
            onChange={(value) => handleSearch(value.target.value)}
            type="text"
            name="search"
            placeholder="Search"
          />
        </InputGroup>
      </div>

      <div className="m-2 p-4  border rounded">
        <QuotesTable
          loading={loading}
          userClients={searchKeyword === "" ? userClients : filteredData}
          deleteRow={deleteRow}
        />
      </div>
    </div>
  );
}

export default QuotesDashboard;
