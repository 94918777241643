import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Container,
  Row,
} from "@themesberg/react-bootstrap";

function RequestRightSidebar({ isVisible, onClose, requestData }) {

  const data = requestData[0];

  // {
  //   "_id": "6585a2f0d34fa791e186c01c",
  //   "request_id": "1703256816110lh8xxe5l5827609137",
  //   "user_id": "1703138180564hq7hqw9l5151665860",
  //   "client_id": "1703138304798fd57hf9j5406954087",
  //   "property_id": "1703152584180ap7u6ubq5825634837",
  //   "request_date": "2023-12-22 20:23:36",
  //   "request_title": "meet",
  //   "service_detail": "this is details",
  //   "availability_assessment_work_1": "1211-12-12",
  //   "availability_assessment_work_2": "11211-02-11",
  //   "preferred_arrival_times": [],
  //   "internal_note_details": "pppoi",
  //   "internal_attachments": [],
  //   "internal_link_note_to_related": [
  //     "Invoices"
  //   ],
  //   "__v": 0,
  //   "clientData": {
  //     "_id": "6583d4001a22765f6c311b87",
  //     "client_id": "1703138304798fd57hf9j5406954087",
  //     "user_id": "1703138180564hq7hqw9l5151665860",
  //     "first_name": "gohel",
  //     "last_name": "meet",
  //     "company_name": "MJ Gohel",
  //     "contact_info": "home",
  //     "phone": 8494848484,
  //     "email_info": "work",
  //     "email": "mj1212@gmail.com",
  //     "client_details": [],
  //     "createdAt": "2023-12-21T05:58:24.802Z",
  //     "updatedAt": "2023-12-21T05:58:24.802Z"
  //   },
  //   "propertyData": {
  //     "_id": "65840bc89a80fadbe7da68c9",
  //     "property_id": "1703152584180ap7u6ubq5825634837",
  //     "client_id": "1703138304798fd57hf9j5406954087",
  //     "user_id": "1703138180564hq7hqw9l5151665860",
  //     "street_1": "etrwer",
  //     "street_2": "eqwjegqwg`",
  //     "city": "dgdfg",
  //     "state": "fasdf",
  //     "zipcode": "364002",
  //     "country": "india",
  //     "createdAt": "2023-12-21T09:56:24.182Z",
  //     "updatedAt": "2023-12-21T09:56:24.182Z"
  //   }
  // }

  return (
    <div className={`right-sidebar ${isVisible ? 'visible' : ''} d-flex align-items-center justify-content-center`} style={{ zIndex:10, backgroundColor: 'whitesmoke', width: '300px' }}>
      <div style={{ cursor: 'pointer', backgroundColor: 'whitesmoke', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', marginLeft: -40 }} onClick={onClose} className='px-3 py-2'>
        <FontAwesomeIcon
          style={{ fontSize: '1.5rem' }}
          icon={isVisible ? faAngleRight : faAngleLeft}
        />
      </div>

      <div style={{ width: 300, alignSelf: 'flex-start', padding: 20 }}>
        <Row>
          <Col>
            <h4 className='text-dark'>Request</h4>
            <p style={{ fontSize: 12 }}>{data.request_date.split(' ')[0]}</p>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        {
          data?.clientData &&
          <Row className="mt-2 ">
            <Col className='d-flex justify-content-between' style={{ cursor: 'pointer' }} onClick={() => {
              const newUrl = `https://jobmanager.cloudpress.host/api#/properties/${data.client_id}`; // Example URL
              window.open(newUrl, '_blank');
            }}>
              <h6 className='mb-3'>{data.clientData.first_name + " " + data.clientData.last_name}</h6>
              <FontAwesomeIcon
                style={{ fontSize: '1.5rem' }}
                icon={isVisible ? faAngleRight : faAngleLeft}
              />
              {/* <p>{`${data?.clientData.first_name} ${data?.clientData.last_name}`}</p> */}
            </Col>
          </Row>
        }
        <div><b>service details</b></div>
        <hr />
        <div> <h6 className='mb-3'>Your Availability</h6><br />
          <p className='mb-2'><b >which day would be best for an assessment of the Work ?</b> <br /></p>
          <p> {data?.availability_assessment_work_1}</p><br />
          <p className='mb-2'><b >What is another day that works for you?</b> <br /></p>
          <p className='mb-3'>{data?.availability_assessment_work_2} </p><br />


          {data && data.preferred_arrival_times && data.preferred_arrival_times.length > 0 && (
            <div>
              <b>What are Your preferred arrival times?</b><br />
              {data.preferred_arrival_times.map((time, index) => (
                <div key={index}>
                  <span>&#10003;</span> {time}
                  
                </div>
              ))}
            </div>
          )}







        </div>

      </div>

    </div>
  )
}

export default RequestRightSidebar