import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faMapMarkerAlt, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import axiosClientInstance from '../config/axiosClientInstance';
import {colors} from "../config/colors";
const ClientInvoiceDash = () => {
  const history = useHistory();
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState();
  const handleCardClick = () => {
    // Navigate to QuotesDetails page
    history.push(`/client/invoicedetail/${invoiceData.invoice_id}`);
  };
  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      setUserData(decodedToken);
    }
  }, []);
  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const response = await axiosClientInstance.get(`invoice/invoicebyclient/${userData.id.client_id} `);
        setInvoiceData(response.data.data[0]);
        console.log("response", response.data.data[0])
      } catch (error) {
        console.error('Error fetching invoice data:', error);
      }
    };

    fetchInvoiceData();
  }, [userData]); // Empty dependency array means this effect will run once after the initial render

  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="col-5 mt-5">
          <p style={{ textAlign: 'left', paddingLeft: '5px', color: '#262b40' }}>
            <label>Your Invoices</label>
          </p>
          <p className="mb-3" style={{ textAlign: 'left', paddingLeft: '5px', color: 'red' }}>
            <label>Awaiting payment</label>
          </p>

          <div className="card shadow" style={{ width: '30rem', color: '#262b40' }} onClick={handleCardClick}>
            <div className="card-body">
              <h5 className="card-title">For services Rendered</h5>
              <div className="card-text">
                {invoiceData ? (
                  <>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faCalendar} className="ml-3" style={{ color:colors.red}} />
                      <span style={{ padding: '6px' }}>{`Sent ${invoiceData?.invoice_issued_date} | Due ${invoiceData?.invoice_due_date} `}</span>
                    </div>
                    {invoiceData?.clientData && ( // Check if clientData exists
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="ml-3" style={{ color:colors.red}} />
                        <span style={{ padding: '6px' }}>{invoiceData?.propertyData?.street_1}</span>
                      </div>
                    )}
                  </>
                ) : (
                  <p>Loading invoice data...</p>
                )}
              </div>
              <hr />
              <div className="d-flex" style={{ marginLeft: 'auto' }}>
                <div>
                  <span style={{ padding: '6px' }}>Total</span>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <FontAwesomeIcon icon={faEuroSign} />
                  <span>
                    {invoiceData?.product_servise
                      ? invoiceData.product_servise.reduce((total, item) => total + (item.total_amount || 0), 0)
                      : 'Loading...'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientInvoiceDash;
