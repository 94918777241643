import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Cookies from "universal-cookie";
import './stylesetting.css'
import SidebarSetting from './SidebarSetting'
import { Button, Form, Modal } from "@themesberg/react-bootstrap";
import axiosInstance from "../config/AxiosInstance";
import {
  Table,
  Popover,
} from "rsuite";
import toast, { Toaster } from 'react-hot-toast';

const { Column, Row, HeaderCell, Cell } = Table;

const CompanySetting = () => {
  const cookies = new Cookies();
    const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [product_cost, setproduct_cost] = useState('');
  const [product_description, setproduct_description] = useState('');
  const [product_image, setproduct_image] = useState('');
  const [product_item_type, setproduct_item_type] = useState('');
  const [product_unit_price, setproduct_unit_price] = useState('');
  const [product_name, setproduct_name] = useState('');
  const [useId, setUseId] = useState(null);

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      // const cookies = new Cookies(); // Create an instance of the Cookies class
  
      const userData = cookies.get("userDetails");
  
      // console.log("userData",userData);
      setUseId(userData.user_id)

      const response = await axiosInstance.get(
        `/product/product_service/${userData?.user_id}`
      );
      if (response.status === 200) {
        const result = response.data;
        setData(result);
      } else {
        const errorData = response.data;
        setError(`Error: ${response.status} - ${errorData.message}`);
      }
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  const NameCell = ({ rowData, dataKey, dataKey2, ...props }) => {
    const speaker = (
      <Popover title="Description">
        <p>
          <b>Product:</b>
        </p>
        <p>
          <b>Description:</b>
        </p>
        <p>
          <b>Type:</b>
        </p>
      </Popover>
    );
  }
  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const handleSelect = (e) => {

    };
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [postedData, setPostedData] = useState([]);

  useEffect(() => {
    const userData = cookies.get("userDetails");
    setUseId(userData.user_id)

    if (userData.user_id) {

      if (userData.user_id) {
        setUseId(userData.user_id);
        // console.log("user  for product is", userData.user_id)
      } else {

      }
    } else {

    }
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();

    handleClose()

    // Log the data before sending the POST request
    const postData = {
      user_id: useId,
      product_cost: product_cost,
      product_description: product_description,
      product_image: product_image,
      product_item_type: product_item_type,
      product_unit_price: product_unit_price,
      product_name: product_name,
    };

    // console.log('Data to be posted:', postData);

    try {
      setLoading(true);
      const response = await axiosInstance.post('/product/product', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // Handle successful response
        // console.log('Data posted successfully');

        toast.success('Item Added');

        fetchData();

        // Update the array of posted data
        setPostedData((prevPostedData) => [...prevPostedData, postData]);
      } else {
        // Handle error response
        console.error(`Error: ${response.status} - ${response.data.message}`);
      }
    } catch (error) {
      // Handle network or other errors
      console.error(`Error: ${error.message}`);
    }
    finally {
      setLoading(false);
    }
  };




  return (
    <div className='d-flex mainmn '>

      <SidebarSetting />
      <div className="form-sec p-4 col-md-10 col-sm-12">
        <h2 className="mb-3 text-dark">Product & Services</h2>


        <div className="update text-end ">
          <Button variant="success" size="sm" onClick={handleShow} style={{ maxHeight: 40 }} className="me-2 mb-5">
            <FontAwesomeIcon icon={faPlus} className="me-2" />Add Item
          </Button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit} >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={product_name}
                    name="product_name"
                    onChange={(e) => setproduct_name(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>Product Cost</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    required
                    value={product_cost}
                    name="product_cost"
                    onChange={(e) => setproduct_cost(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Product Description</Form.Label>
                  <Form.Control as="textarea" rows={2} value={product_description}
                    name="product_description"
                    onChange={(e) => setproduct_description(e.target.value)} />
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Product Image</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={product_image}
                    name="product_image"
                    onChange={(e) => setproduct_image(e.target.value)}
                  />
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Product Item Type</Form.Label>
                  <Form.Select
                    value={product_item_type}
                    name="product_item_type"
                    onChange={(e) => setproduct_item_type(e.target.value)}
                  >
                    <option value="" disabled>Select  Item Type</option>
                    <option value="Services">Services</option>
                    <option value="Product">Product</option>
                    {/* Add more options as needed */}
                  </Form.Select>
                </Form.Group>

                {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Product Unit Price</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={product_unit_price}
                    name="product_unit_price"
                    onChange={(e) => setproduct_unit_price(e.target.value)}
                  />
                </Form.Group> */}
               

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="ouline-primary" onClick={handleClose}>
                Close
              </Button>
              {/* <Button onClick={handleSubmit} type="submit" variant="primary">
                Save Changes
              </Button> */}
              {loading ?
                <Button disabled variant="primary" type="submit" >
                  Saving Changes
                </Button>
                :
                <Button onClick={handleSubmit} variant="primary" type="submit" >
                  Save Changes
                </Button>}
            </Modal.Footer>
          </Modal>
        </div>
        <div>


          {data ? (
            <Table
              height={300}
              className="mx-2 px-2"
              data={data.data} // Assuming data is an array
              loading={loading}
            >
              <Column flexGrow={3}>
                <HeaderCell>Product</HeaderCell>
                <Cell dataKey="product_name" />
              </Column>

              <Column flexGrow={6}>
                <HeaderCell>Description</HeaderCell>
                <Cell dataKey="product_description" />
              </Column>
              <Column flexGrow={3}>
                <HeaderCell>Type</HeaderCell>
                <Cell dataKey="product_item_type" />
              </Column>
            </Table>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <p></p>
          )}
        </div>

      </div>
      <Toaster />
    </div>
  )
}

export default CompanySetting