import { jwtDecode } from 'jwt-decode';
import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import toast, { Toaster } from 'react-hot-toast';
import Cookies from "universal-cookie";

import axiosInstance from '../../config/AxiosInstance';

export default () => {

	const [buttonLoader, setButtonLoader] = useState(false);
	const cookies = new Cookies();
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	React.useEffect(() => {
		const userData = cookies.get('userDetails');

		if (userData) {
			history.push(Routes.DashboardOverview.path)
		}
	}, []);

	// form values
	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	async function handleSubmit() {

		try {
			setLoading(true);

			const response = await axiosInstance.post('/user/login', formData);
			if (response.data.success == true) {
				toast.success('Login Successful');
				const dataAfterDecode = jwtDecode(response.data.token)

				cookies.set('userDetails', dataAfterDecode.userId, { path: '/' });
				setTimeout(() => {
					history.push(Routes.DashboardOverview.path);
				}, 2000);
			} else {
				toast.error("Login Failed...")
			}
		} catch (error) {
			console.log("error in submit data in handle submit:", error);
			toast.error("login failed..!")
		} finally {
			setLoading(false);
		}
	}


	const handleLogin = (event) => {
		setButtonLoader(true);

		event.preventDefault();

		handleSubmit();
		setButtonLoader(false);
	}

	return (
		<main>
			<section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
				<Container>
					{/* <p className="text-center">
						<Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
							<FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
						</Card.Link>
					</p> */}
					<Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
						<Col xs={12} className="d-flex align-items-center justify-content-center">
							<div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
								<div className="text-center text-md-center mb-4 mt-md-0">
									<h3 className="mb-0">Sign in to Your Job Management System</h3>
								</div>

								<Form className="mt-4">
									<Form.Group id="email" className="mb-4">
										<Form.Label>Your Email</Form.Label>
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon={faEnvelope} />
											</InputGroup.Text>
											<Form.Control onChange={handleChange} autoFocus required type="email" name="email" value={formData?.email} placeholder="example@company.com" />
										</InputGroup>
									</Form.Group>
									<Form.Group>
										<Form.Group id="password" className="mb-4">
											<Form.Label>Your Password</Form.Label>
											<InputGroup>
												<InputGroup.Text>
													<FontAwesomeIcon icon={faUnlockAlt} />
												</InputGroup.Text>
												<Form.Control onChange={handleChange} required type="password" name="password" value={formData.password} placeholder="Password" />
											</InputGroup>
										</Form.Group>
										<div className="d-flex justify-content-between align-items-center mb-4">
											<Form.Check type="checkbox">
												<FormCheck.Input id="defaultCheck5" className="me-2" />
												<FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
											</Form.Check>
											<Card.Link as={Link} to={Routes.ForgotPassword.path} className="small text-end">Lost password?</Card.Link>
										</div>
									</Form.Group>

										{loading ? 
									<Button disabled variant="primary" type="submit" className="w-100">
										Signing in...
									</Button>
										: 
											<Button onClick={handleLogin} variant="primary" type="submit" className="w-100">
										Sign In
									</Button>}
								</Form>
								<div className="d-flex justify-content-center align-items-center mt-4">
									<span className="fw-normal">
										Not registered?
										<Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
											{` Create account `}
										</Card.Link>
									</span>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<Toaster />
		</main>
	);
};
