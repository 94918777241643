import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Form,
  Col,
  Row,
  InputGroup,
  DropdownButton,
  Table,
  Dropdown,
  Modal,
  OverlayTrigger,
  Collapse,Overlay, Popover
} from "@themesberg/react-bootstrap";
import {colors} from "../config/colors";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { ButtonGroup } from "@themesberg/react-bootstrap";
import { Routes } from "../routes";
// import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faArrowLeft,
  faAddressBook,
  faPen,
  faEllipsisH,
  faStar,
  faCheck,
  faFilePdf,
  faTimesCircle,
  faCamera,
  faTrash,
  faFileAlt,
  faPlus,
  faTextWidth,
  faAngleDown,
  faAngleUp,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../config/AxiosInstance";
import Cookies from 'universal-cookie';

function Newinvoice() {
  // getting dat using location passed data from invoice
  const location = useLocation();
  const { invoice_id } = useParams();
  // const [invoice_idFromUrl, setInvoice_idFromUrl] = useState(null);
  // const [job_id, setJobId] = useState(null);
  const [invoice_idFromUrl, setInvoice_idFromUrl] = useState(null);
  const [job_id, setJobId] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/request/property/${invoice_id}`
        );
        const data = response.data.data[0];
        setSelectedProperty(data);
        // console.log(data, "---------- selectedProperty");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); // Call the async function immediately
  }, [invoice_id]);
  // console.log("got data from invoice:", selectedProperty);

  const [entries, setEntries] = useState([
    {
      name: "",
      description: "",
      part_qty: "",
      part_price: "",
      total_amount: "",
    },
  ]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const invoice_idFromUrl = queryParams.get("invoice_id");
    const jobIdFromUrl = queryParams.get("job_id");
    setInvoice_idFromUrl(invoice_idFromUrl);

    // setInvoice_id(invoice_idFromUrl);
    setJobId(jobIdFromUrl);
    try {
      const response = axiosInstance.get(`/request/property/${invoice_id}`);
      console.log(response.data, "---------- API Response");

      const data = response.data.data[0];
      setSelectedProperty(data);
      console.log(data, "---------- selectedProperty");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [location.search]);

  //   useEffect(async () => {
  //     try {
  //       const response = await axiosInstance.get(`/request/property/${invoice_id}`);
  //       console.log(response.data, "---------- API Response");

  //       const data = response.data.data[0];
  //       setSelectedProperty(data);
  //       console.log(data, "---------- selectedProperty");
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }

  //   }, [invoice_id]);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://jobmanager.cloudpress.host/api/job/job_data/${job_id}`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.data) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          line_items: result?.data[0].line_items,
        }));
        setEntries(result?.data[0].line_items || []);

        const property = {
          ...result?.data[0].propertyData,
          client: result?.data[0].clientData,
        };
        setSelectedProperty(property);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [job_id]);
  const updateEntry = (e, index) => {
    const { name, value } = e.target;
    const updatedEntries = [...entries];
    updatedEntries[index][name] = value;
    const totalAmount =
      updatedEntries[index].part_qty * updatedEntries[index].part_price || 0;
    updatedEntries[index].total_amount = totalAmount;
    setEntries(updatedEntries);
  };
  const deleteEntry = (index) => {
    const updatedEntries = [...entries];
    updatedEntries.splice(index, 1);
    setEntries(updatedEntries);
  };
  // const addNewRow = () => {
  //   event.preventDefault();
  //   setEntries([
  //     ...entries,
  //     {
  //       name: "",
  //       description: "",
  //       part_qty: "",
  //       part_price: "",
  //       total_amount: "",
  //     },
  //   ]);
  // };

  const addNewRow = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setEntries([
      ...entries,
      { name: '', description: '', part_qty: '', part_price: '', total_amount: '' },
    ]);
  };
  const calculateTotalPartPrice = () => {
    return entries.reduce(
      (total, entry) =>
        total + parseFloat(entry.part_qty * entry.part_price) || 0,
      0
    );
  };
  const calculateTotalAmount = () => {
    return entries.reduce(
      (total, entry) => total + parseFloat(entry.part_price) || 0,
      0
    );
  };
  const cookies = new Cookies()
  const [products, setProducts] = useState([]);
  const fetchProductsServices = async () => {
    try {
      const userData = cookies.get("userDetails");

      const response = await axiosInstance.get(
        `/product/product_service/${userData.user_id}`
      );
      if (response.data.data) {
        setProducts(response.data.data);
      }
    } catch (error) {
      console.log("error in getting fetch products in new quotes form:", error);
    } finally {
      // setLoadingProperties(false);
    }
  };

  useEffect(() => {
    // Fetch data from the API
    fetch("https://jobmanager.cloudpress.host/api/product/product_service/user_id")
      .then((response) => response.json())
      .then((data) => {
        // Update the state with the fetched products
        setProducts(data.data);
      })
      .catch((error) => console.error("Error fetching data:", error));

    fetchProductsServices();
  }, []);

  const [open, setOpen] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [fields, setFields] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleSaveChanges = () => {
    const newField = {
      name: fieldName,
      value: fieldValue,
    };
    setFields((prevFields) => [...prevFields, newField]);
    setFieldName("");
    setFieldValue("");
    setShow(false);
  };
  useEffect(() => { }, [fieldName, fieldValue]);
  const removeRow = (index) => {
    setFields((prevFields) => prevFields.filter((_, i) => i !== index));
  };
  const [editIndex, setEditIndex] = useState(null);
  const [editName, setEditName] = useState("");
  const [editValue, setEditValue] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditRow = (index) => {
    const rowToEdit = fields[index];
    setEditIndex(index);
    setEditName(rowToEdit.name);
    setEditValue(rowToEdit.value);
    setShowEditModal(true);
  };
  const handleSaveEdit = () => {
    if (editIndex !== null) {
      setFields((prevFields) =>
        prevFields.map((field, i) =>
          i === editIndex
            ? { ...field, name: editName, value: editValue }
            : field
        )
      );
    }
    // Reset edit state and close modal
    setEditIndex(null);
    setEditName("");
    setEditValue("");
    setShowEditModal(false);
  };
  const history = useHistory();

  const [quoteData, setQuoteData] = useState(null);
  useEffect(() => {
    // Fetch data from the API
    fetch("https://jobmanager.cloudpress.host/api/product/product_service/user_id")
      .then((response) => response.json())
      .then((data) => {
        // Update the state with the fetched products
        setProducts(data.data);
        // console.log("kk.data", data.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const [propertyData, setPropertyData] = useState(null);

  useEffect(() => {
    const fetchPropertyData = async (propertyId) => {
      try {
        const response = await axios.get(
          `https://jobmanager.cloudpress.host/api/request/property/${propertyId}`
        );

        setPropertyData(response.data);

      } catch (error) {
        console.error("Error fetching property data:", error);
      }
    };

    fetchPropertyData();
  }, []); 
  const [formData, setFormData] = useState({
    user_id: "",
    client_id: "",
    property_id: "",
    job_title: "",
    job_instructions: "",
    invoice_details_fields: [],
    is_one_off_job: false,
    is_recuring_job: false,
    schedule_start_date: "",
    schedule_end_date: "",
    schedule_start_time: "",
    schedule_end_time: "",
    is_schedule_later: false,
    schedule_repeats: "",
    schedule_duration: "",
    team_name: "",
    product_servise: [],
    internal_notes_detais: "",
    internal_notes_attachments: "",
    internal_link_note_to_related: "",
    line_items_total: "",
    invoice_subject: "",
    invoice_payment_due :"",
    product_servise_total: " ",
    invoice_due_date: " ",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

   const handlePaymentDueClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    setShowCalendar(option === 'Custom');
  };
  // console.log(selectedProperty?.client_id, "yashujr");
  const handleSubmit = async () => {
    try {
      // const { user_id, property_id, client_id } = selectedProperty;
 
      const totalPartPrice = calculateTotalPartPrice();
      const object = {
        user_id: selectedProperty.user_id,
          property_id: selectedProperty.property_id,
          client_id: selectedProperty.client_id,
          invoice_details_fields: fields,
          product_servise: entries,
          product_servise_total: totalPartPrice,
          invoice_subject: formData.invoice_subject,
          invoice_issued_date: formData.invoice_issued_date,
          internal_notes_detais: formData.internal_notes_detais,
          internal_notes_attachments: [formData.internal_notes_attachments],
          client_message: formData.client_message,
          invoice_payment_due: selectedOption,
          schedule_start_date: formData.schedule_start_date,
          client_view: "",
          invoice_due_date: formData.invoice_due_date,
      };
      // console.log(object, "object issssss");
      const response = await axiosInstance.post(
        "https://jobmanager.cloudpress.host/api/invoice/invoice",
        object
      );
      toast.success("invoice created successfully!", {
        position: "top-center",
      });
      setTimeout(() => {
        history.push(Routes.InvoiceDashboard.path);
      }, 1000);
      console.log("Server response:=========================", response);
      // setFormData({
      //   user_id: "",
      //   client_id: "",
      //   property_id: "",
      //   invoice_details_fields: [],
      //   team_name: "",
      //   product_servise: [],
      //   internal_notes_detais: "",
      //   internal_notes_attachments: "",
      //   product_servise_total: "",
      //   invoice_subject: "",
      //   // internal_link_note_to_related: '',
      //   line_items_total: "",
      // });
      // history.push("/invoice");
    } catch (error) {
      toast.error("Error creating invoice. Please try again.", {
        position: "top-center",
      });
      console.error("Error creating job:", error);
    }
  };
  const [isDiscountInputVisible, setDiscountInputVisibility] = useState(false);

  const showDiscountInput = () => {
    setDiscountInputVisibility(!isDiscountInputVisible);
  };

  const [discountType, setDiscountType] = useState("€");
  const [discountValue, setDiscountValue] = useState("");
  const [total, setTotal] = useState(0);

  const handleDiscountTypeChange = (event) => {
    setDiscountType(event.target.value);
    recalculateTotal(event.target.value, discountValue);
  };

  const handleDiscountValueChange = (event) => {
    const value = event.target.value;
    setDiscountValue(value);
    recalculateTotal(discountType, value);
  };

  const recalculateTotal = (type, value) => {
    const subtotal = entries.reduce(
      (acc, entry) => acc + entry.part_qty * entry.part_price,
      0
    );
    let discount = 0;

    if (type === "€") {
      discount = parseFloat(value) || 0;
    } else if (type === "%") {
      discount = ((parseFloat(value) || 0) / 100) * subtotal;
    }

    const newTotal = subtotal - discount;
    setTotal(newTotal.toFixed(2));
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Net-30');
  const [target, setTarget] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  

  return (
    <div
      className="m-5 main-quates"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <div className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <Dropdown className="btn-toolbar">
            <Dropdown.Toggle
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
              style={{backgroundColor:"#e91818",border:"none"}}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              Back To
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
              <Dropdown.Item
                onClick={() => {
                  history.push(Routes.Invoice.path);
                }}
                className="fw-bold"
              >
                <FontAwesomeIcon icon={faAddressBook} className="me-2" />{" "}
                Invoice
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <ButtonGroup>
            <Button variant="outline-primary" size="sm" >
              {" "}
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ marginRight: "3px" }}
              />{" "}
              Email
            </Button>
            <Button variant="outline-primary" size="sm">
              {" "}
              <FontAwesomeIcon icon={faPen} style={{ marginRight: "3px" }} />
              Edit
            </Button>
            <Button variant="outline-primary" size="sm">
              {" "}
              <FontAwesomeIcon
                icon={faEllipsisH}
                style={{ marginRight: "3px" }}
              />
              More Action
            </Button>
          </ButtonGroup> */}
        </div>
      </div>

      <div className="main-sec">
        <div className="first-table d-flex">
          <div className="first-head col-6 d-flex  align-items-center">
            <h2 className="d-flex">
              {selectedProperty?.client?.first_name}&nbsp;
              {selectedProperty?.client?.last_name}
            </h2>
          </div>
          <div className="tab col-6 star-table d-flex justify-content-center align-items-center text-center">
            <table className="table table-bordered">
              <tbody style={{ border: "2px solid grey" }}>
                <tr>
                  <th scope="row"> Issued date&nbsp; </th>
                  <td>

                    <Form.Control
                      type="date"
                      className="mb-3"

                      name="invoice_issued_date"
                      value={formData.invoice_issued_date}
                      onChange={(e) => handleInputChange(e)}

                    />

                  </td>
                </tr>


                <tr>
        <th scope="row"> Payment due&nbsp;</th>
       <td style={{ position: 'relative' }}>
        <div onClick={handlePaymentDueClick} style={{ cursor: 'pointer' }}>
          {isDropdownOpen ? (
            <Form
              value={selectedOption}
              onChange={() => {}}
              onFocus={handlePaymentDueClick}
              style={{ width: '100%' }}
              
            >
            
             
            </Form>
          ) : (
            selectedOption
          )}
        </div>
        {isDropdownOpen && (
          <div className="dropdown-content">
            {/* Render your dropdown options here */}
            <Form.Select
              value={selectedOption}
              onChange={(e) => handleOptionSelect(e.target.value)}
              style={{ width: '100%' }}
              name="invoice_payment_due "
            >
              <option value="Net-15">Net-15</option>
              <option value="Net-30">Net-30</option>
              <option value="Net-45">Net-45</option>
              <option value="Custom">Custom</option>
            </Form.Select>
          </div>
        )}
      </td>
      </tr>
      {showCalendar && (
        <tr>
          <th scope="row"> Due date&nbsp;</th>
          <td>
            {/* Render your calendar component here */}
           <Form.Control
        type="date"
        name="invoice_due_date"
        value={formData.invoice_due_date}
        onChange={handleInputChange}
        // placeholder="Invoice Issued Date"
      />
          </td>
        </tr>
      )}
                <tr>
                  <th scope="row" colSpan="2">
                    {" "}
                    <button type="button" className="btn btn-outline-dark w-50 dash-btn">
                      Dark
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="main-sec" style={{ borderTop: "none" }}>
        <Form.Label>Invoice subject</Form.Label>
        <Form.Control
          className="mb-3"
          type="text"
          placeholder="Subject"
          name="invoice_subject"
          value={formData.invoice_subject}
          onChange={(e) => handleInputChange(e)}
        />
        <div
          className="address "
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="col-3 ">
            <div className="sub-head propertyad">
              <p>
                <h5>Billing address</h5>
                <p>
                  {" "}
                  <div>
                    { }
                    {selectedProperty?.street_1}
                    {/* {quoteData.propertyData?.street_1} */}
                  </div>
                  <div>
                    {selectedProperty?.street_2}
                    {/* gujarat */}
                    {/* {quoteData.propertyData?.street_2} */}
                  </div>
                  <div>
                    {selectedProperty?.city}&nbsp; <br />
                    {selectedProperty?.state}&nbsp;
                    {selectedProperty?.zipcode}
                    {/* india */}
                    {/* {quoteData.propertyData?.city}
                  {quoteData.propertyData?.state}
                  {quoteData.propertyData?.zipcode} */}
                  </div>
                  <div></div>
                </p>
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="sub-head">
              <p>
                {" "}
                <h5>Service addresses</h5>
              </p>
              <p>rto road</p>
              <p>
                bhavnagar
                {/* {quoteData.clientData?.email} */}
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="sub-head">
              <p>
                {" "}
                <h5>Contact Details</h5>
              </p>
              <p>
                {selectedProperty?.client?.phone}
                {/* 7359425150 */}
                {/* {quoteData.clientData?.phone} */}
              </p>
              <p>
                {selectedProperty?.client?.email}
                {/* krushilvaghani3383@gmail.com */}
                {/* {quoteData.clientData?.email} */}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Row className="mb-2 justify-content-between">
        <Col>
          <div className="py-4">
            <div className="table-responsive">
              <Table
                className="table table-bordered"
                style={{
                  borderCollapse: "collapse",
                  border: "1px solid #ddd",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    <th >PRODUCT / SERVICE</th>
                    <th>QTY.</th>
                    <th>UNIT PRICE</th>
                    <th>TOTAL</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {entries?.map((entry, index) => (
                    <tr key={index}>
                      <td >
                        <select
                          value={entry.name}
                          onChange={(e) => updateEntry(e, index)}
                          name="name"
                          className="form-control mb-2 mr-sm-2"
                          style={{
                            borderRadius: "5px",
                            padding: "10px",
                            border: "1px solid #CED4DA",
                            width: "200px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          }}
                        >
                          <option value="" disabled>
                            Select Name
                          </option>
                          {products.map((product) => (
                            <option
                              key={product._id}
                              name={"name"}
                              value={product.product_name}
                            >
                              {product.product_name}
                            </option>
                          ))}
                        </select>
  <textarea class="form-control" id="floatingTextarea" style={{  width: "200px",}}   value={entry.description}
                          onChange={(e) => updateEntry(e, index)}
                          name="description"
                          placeholder="Description"></textarea>
                      </td>
                      {/* <td>
                        <input
                          type="text"
                          value={entry.description}
                          onChange={(e) => updateEntry(e, index)}
                          name="description"
                          placeholder="Description"
                          className="form-control mb-2 mr-sm-2"
                          style={{
                            borderRadius: "5px",
                            padding: "10px",
                            border: "1px solid #CED4DA",
                            width: "150px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          }}
                        />
                      </td> */}
                      <td style={{display:"flex",alignItems:"start"}}>
                        <input
                          type="text"
                          value={entry.part_qty}
                          onChange={(e) => updateEntry(e, index)}
                          name="part_qty"
                          placeholder="Quantity"
                          className="form-control mb-2 mr-sm-2"
                          style={{
                            borderRadius: "5px",
                            padding: "10px",
                            border: "1px solid #CED4DA",
                            width: "150px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={entry.part_price}
                          onChange={(e) => updateEntry(e, index)}
                          name="part_price"
                          placeholder="Unit Price"
                          className="form-control mb-2 mr-sm-2"
                          style={{
                            borderRadius: "5px",
                            padding: "10px",
                            border: "1px solid #CED4DA",
                            width: "150px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={entry.part_qty * entry.part_price}
                          readOnly
                          placeholder="Total"
                          onChange={(e) => updateEntry(e, index)}
                          name="total_amount"
                          className="form-control mb-2 mr-sm-2"
                          style={{
                            borderRadius: "5px",
                            padding: "10px",
                            border: "1px solid #CED4DA",
                            width: "150px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => deleteEntry(index)}
                          style={{ fontSize: "20px" }}
                          className="mt-2"
                        />
                      </td>
                    </tr>
                  ))}
                  {/* Button to add a new row */}
                  
                </tbody>
              </Table>
            </div>
            <div className="d-flex flex-wrap align-items-center mt-3">
              <Form onSubmit={addNewRow}>
                <Button type="submit" className="btn btn-dark btn-sm mb-2 me-2" style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
                  Add Line Item
                </Button>
              </Form>
            </div>
          </div>
        </Col>
        <Col xs={12} className="mt-2 ">
          <hr />
        </Col>
        <Col className="mb-4">
          <Row>
            <Col xs={8}>
              <button type="button" className="btn btn two-btn" >
                New line Item
              </button>
            </Col>
            <Col xs={2}>
              <p>Grand Total</p>
              <p
                name="product_servise_total"
                value={formData.product_servise_total}
                onChange={(e) => handleInputChange(e)}
              >
                Total Price
              </p>
            </Col>
            <Col xs={2} className="text-right">
              <p>{calculateTotalPartPrice()}</p>
              <p>{calculateTotalAmount()}</p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Form
        style={{
          border: "1px solid #ccc",
          padding: "15px",
          borderRadius: "10px",
          marginBottom: "15px",
        }}
      >
        <Row className="mb-2">
          <Col>
            <Form.Label>Internal notes & attachments</Form.Label>
            <InputGroup>
              <Form.Control
                autoFocus
                required
                as="textarea"
                name="internal_notes_detais"
                value={formData.internal_notes_detais}
                onChange={(e) => handleInputChange(e)}
                placeholder="Notes Details"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <div style={{ border: "1px dashed #000" }} className="mb-3 my-3">
              <input
                type="file"
                className="form-control"
                aria-label="file example"
                required
                multiple // Enable multiple file selection
                // onChange={handleFileChange}
                name="internal_notes_attachments"
                value={formData.internal_notes_attachments}
                onChange={(e) => handleInputChange(e)}
              />
              <div className="invalid-feedback">
                Example invalid form file feedback
              </div>

              {/* Display selected files */}
              {/* {selectedFiles.length > 0 && ( */}
              {/* <div className="p-3">
                    <ul>
                      {selectedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div> */}
              {/* )} */}
            </div>
          </Col>
        </Row>
        {/* <Col xs={12} className="mb-2">
            <hr />
          </Col> */}
        {/* <Row>
            <Form.Label>Link note to related</Form.Label>
          </Row>
          <Row className="mb-2">
            <Col lg="2" sm="4">
              <InputGroup style={{ margin: "auto" }}>
                <Form.Check
                  type="checkbox" // Use type="checkbox" for a checkbox input
                  label="Quotes"
                  name="internal_link_note_to_related"
                  value="Quotes"
                  onChange={handleInputInternalLinks}
                />
              </InputGroup>
            </Col>
            <Col lg="2" sm="4">
              <InputGroup style={{ margin: "auto" }}>
                <Form.Check
                  type="checkbox" // Use type="checkbox" for a checkbox input
                  label="Jobs"
                  name="internal_link_note_to_related"
                  value="Jobs"
                  onChange={handleInputInternalLinks}
                />
              </InputGroup>
            </Col>
            <Col lg="2" sm="4" >
              <InputGroup style={{ margin: "auto" }}>
                <Form.Check
                  type="checkbox"
                  label="Invoices"
                  name="internal_link_note_to_related"
                  value="Invoices"
                  onChange={handleInputInternalLinks}
                />
              </InputGroup>
            </Col>
          </Row> */}
      </Form>
      <div
        className="job_dtl"
        style={{
          border: "2px solid #D5D5D5",
          padding: "15px",
          borderBottomRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      >
        <Row className="mb-2">
          <Col md={12} lg={12} sm={12} className="p-4">
            <div
              className="d-flex justify-content-between mb-3"
              aria-controls="example-collapse-text"
              aria-expanded={open}
              style={{ cursor: "pointer" }}
              onClick={() => setOpen(!open)}
            >
              <h5 className="text-dark">Invoice Details</h5>
              <h4 className="text-dark">
                <FontAwesomeIcon icon={!open ? faAngleDown : faAngleUp} />
              </h4>
            </div>
            <Collapse in={open} className="px-1 mb-0">
              <div id="example-collapse-text">
                {fields.map((field, index) => (
                  <div key={index}>
                    <Row>
                      <Col className="mb-4" md={5} lg={3}>
                        {/* Input for something else, adjust as needed */}
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Field Name"
                            autoFocus
                            value={field.name}
                            onChange={(e) => setFieldName(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="mb-3" md={5} lg={3}>
                        {/* Input for something else, adjust as needed */}
                        <Form.Group>
                          <Form.Control
                            readOnly={true}
                            type="text"
                            placeholder="Job Number"
                            value={field.value}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={1} lg={1}>
                        <div className="d-flex ">
                          <Button
                            variant="outline-primary"
                            onClick={() => removeRow(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                          <Button
                            variant="outline-primary"
                            className="mx-2"
                            onClick={() => handleEditRow(index)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            </Collapse>
          </Col>
        </Row>
        <Button
          onClick={() => {
            setShow(true);
          }}
          variant="outline-primary"
          className="w-100 "
          style={{ maxWidth: 150,backgroundColor:colors.red,color:colors.white,border:"none" }}

        >
          <p style={{ fontSize: "0.9rem" }}>Add Custom Field</p>
        </Button>
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Row</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Edit Name"
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editValue">
                <Form.Label>Value</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Edit Value"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => handleSaveEdit()} >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Client Fields</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Field Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Field Name"
                  autoFocus
                  value={fieldName}
                  onChange={(e) => setFieldName(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Field Value</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Value"
                  autoFocus
                  value={fieldValue}
                  onChange={(e) => setFieldValue(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" className="dash-btn" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges} style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col xs={12} className="mb-2">
            <hr />
          </Col>
        </Row>
      </div>
      <div className="row clientmsg-main  d-flex my-5">
        <Col md={6} lg={6} sm={12} className="clientmsg">
          {/* <div>
            <label className="form-control " style={{ height: "100px" }}>
              <p>
                <span style={{ fontWeight: "bold" }}>Client Message :</span>{" "}
                {quoteData?.client_message}
              </p>
              <p style={{ color: "gray" }}>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ marginRight: "8px" }}
                />
                {time}
              </p>
              ))}
            </label>
          </div> */}

          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              Client Message
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              name="client_message"
              value={formData.client_message}
              onChange={(e) => handleInputChange(e)}
            ></textarea>
          </div>
        </Col>
        <Col md={6} lg={6} sm={12}>
          <table
            className="table table-bordered client-table"
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
            }}
          >
            <tbody>
              <tr>
                <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Subtotal :{" "}
                </th>

                <td className="text-end">{calculateTotalPartPrice()}</td>
              </tr>

              <tr>
                <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Discount :{" "}
                </th>
                <td className="text-end d-flex">
                  <div class="mb-3 col-8">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleDropdownFormEmail1"
                      placeholder=""
                      value={discountValue}
                      onChange={handleDiscountValueChange}
                    />
                  </div>
                  <div class="col-md-3">
                    {/* <label for="validationCustom04" class="form-label">State</label> */}
                    {/* <select
                      class="form-select"
                      id="validationCustom04"
                      required
                    >
                      <option>€</option>
                      <option>%</option>
                    </select> */}

                    <select
                      className="form-select"
                      id="validationCustom04"
                      onChange={handleDiscountTypeChange}
                      required
                    >
                      <option>€</option>
                      <option>%</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Tax :{" "}
                </th>
                <td colspan="2" className="text-end">
                  <a href="">Add Tax</a>
                </td>
              </tr>
              {/* <tr> */}
              {/* <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Total :{" "}
                </th>
                <td colspan="2" className="text-end">
                  €0.00
                </td>
              </tr> */}
              <tr>
                <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Total :{" "}
                </th>
                <td colSpan="2" className="text-end">
                  €{total}
                </td>
              </tr>
              {/* <tr>
                <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Deposits :{" "}
                </th>
                <td colspan="2" className="text-end">
                  9999
                </td>
              </tr> */}
            </tbody>
          </table>
        </Col>
      </div>

      <div className="buttond d-flex justify-content-between">
        <button
          type="button"
          className="btn btn two-btn"
          onClick={() => {
            history.push(Routes.InvoiceDashboard.path);
          }}
        >
          Cancel
        </button>
        {/* {
            data.client_id ? */}
        <Button
          //  onSubmit={handleFormSubmit}
          variant="primary"
          type="submit"
          className=""
          onClick={handleSubmit}
          style={{backgroundColor:colors.red,color:colors.white,border:"none"}}
        >
          Submit Client
        </Button>
        {/* } */}
      </div>
      <Toaster />
    </div>
  );
}

export default Newinvoice;
