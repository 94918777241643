import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../routes";
import {
  faAngleDown,
  faAngleUp,
  faBriefcase,
  faTrash,
  faUser,
  faBuilding
} from "@fortawesome/free-solid-svg-icons";
import {colors} from "../config/colors";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  InputGroup,
  Collapse,
  Modal,
} from "@themesberg/react-bootstrap";

function NewPropertiesForm({
  handleInput,
  handleSubmit,
  data,
}) {
  const history = useHistory();
  const [userData, setUserData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    // Fetch user data
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          "https://jobmanager.cloudpress.host/api/user/get_user/1702623757956155ic0pn44997471380"
        );

        if (response.ok) {
          const data = await response.json();
          setUserData(data.data[0]);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // Fetch countries data
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all?fields=name");

        if (response.ok) {
          const data = await response.json();
          setCountries(data);
        } else {
          console.error("Failed to fetch countries data");
        }
      } catch (error) {
        console.error("Error fetching countries data:", error);
      }
    };

    fetchUserData();
    fetchCountries();
  }, []);

  return (
    
    <div>
      <h3 className="text-dark">
        <FontAwesomeIcon icon={faBuilding} /> &nbsp;Properties Details
      </h3>

      <Form onSubmit={handleSubmit} className="mt-4 p-2">
        <Row style={{ display: "block" }}>
          <Col
            //   md={6}
            //   lg={6}
            //   sm={12}
            className="mb-4"
            style={{ display: "block" }}
          >
            <InputGroup>
              <Form.Control
                autoFocus
                required
                type="text"
                name="street_1"
                value={data?.street_1}
                onChange={handleInput}
                placeholder="Street 1"
              />
            </InputGroup>
          </Col>
        </Row>
        <Row style={{ display: "block" }}>
          <Col
            //   md={6}
            //   lg={6}
            //   sm={12}
            className="mb-4"
            style={{ display: "block" }}
          >
            <InputGroup>
              <Form.Control
                autoFocus
                type="text"
                name="street_2"
                value={data?.street_2}
                onChange={handleInput}
                placeholder="Street 2"
              />
            </InputGroup>
          </Col>
        </Row>

        {/* Phone Number input */}
        <Row>
          <Col md={6} lg={6} sm={12} className="mb-4">
            <InputGroup>
              <Form.Control
                autoFocus
                required
                type="text"
                name="city"
                value={data?.city}
                onChange={handleInput}
                placeholder="City"
              />
            </InputGroup>
          </Col>
          <Col md={6} lg={6} sm={12} className="mb-4">
            <InputGroup>
              <Form.Control
                autoFocus
                required
                type="text"
                name="state"
                value={data?.state}
                onChange={handleInput}
                placeholder="State"
              />
            </InputGroup>
          </Col>
        </Row>
        {/* Email Address input */}
        <Row>
          <Col md={6} lg={6} sm={12} className="mb-4">
            <InputGroup>
              <Form.Control
                autoFocus
                required
                type="text"
                name="zipcode"
                value={data?.zipcode}
                onChange={handleInput}
                placeholder="Zipcode"
              />
            </InputGroup>
          </Col>
          <Col md={6} lg={6} sm={12} className="mb-4">
          <select
            id="country"
            className="form-select"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="" disabled>
              Choose...
            </option>
            {countries.map((country) => (
              <option key={country.name.common} value={country.name.common}>
                {country.name.common}
              </option>
            ))}
          </select>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md={4} lg={6} sm={12} className="mb-4">
            <Button
              variant="primary"
              // type="submit"
              className="w-100 mt-3"
              style={{ maxWidth: 200, marginRight: "10px" ,backgroundColor:colors.red,color:colors.white,border:"none"}}
              size="sm"
              onClick={()=>{history.push(Routes.ClientDashBoard.path);}}
            >
              Cancel
            </Button>
          </Col>
          <Col md={4} lg={6} sm={12} className="mb-4 d-flex justify-content-end">
            <Button
             variant="primary"
              type="submit"
              className="w-100 mt-3 "
              style={{ maxWidth: 200, marginRight: "10px",backgroundColor:colors.red,color:colors.white,border:"none"}}
              size="sm"
            >
              Create Properties
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default NewPropertiesForm;
