import React, { useEffect, useState } from "react";
import NewRequestForm from "./NewRequestForm";
import "../css/client.css";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../routes";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Form,
  InputGroup,
  Col,
  Row,
  DropdownButton,
  Dropdown,
} from "@themesberg/react-bootstrap";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import RequestDashboard from "./RequestDashboard";
import axiosClientInstance from "../config/axiosClientInstance.js";
import {colors} from "../config/colors.js";
function Request() {
  const history = useHistory();
  const [clientData, setClientData] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [userData, setUserData] = useState({
    id: {
      user_id: "",
      client_id: "",
    },
  });
  console.log(userData, "----------------------------")

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      setUserData(decodedToken);
    }
  }, []);
  const [data, setData] = useState({});
  useEffect(() => {
    setData({
      user_id: userData.id.user_id || "",
      client_id: userData.id.client_id || "",
      address: "",
      service_detail: "",
      property_id: "",
      availability_assessment_work_1: "",
      availability_assessment_work_2: "",
      preferred_arrival_times: [],
      is_lead: true
    });
  }, [userData]);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSubmit = () => {
  //   console.log("handle submit clicked");
  //   history.push(Routes.ClientDashBoard.path);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosClientInstance.post(`/request/request`, data);
      const status = response.data.statusCode;

      if (status == 200) {
        toast.success("Request created.. successfully");
        history.push(Routes.RequestDashboard.path);
      } else if (status == 201) {
        toast.error("Data Already Exist..");
      }
    } catch (error) {
      console.log(error);
      toast.error("please try again later");
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    let updatedArrivalTimes = [...data.preferred_arrival_times];

    if (checked) {
      updatedArrivalTimes.push(value);
    } else {
      updatedArrivalTimes = updatedArrivalTimes.filter(
        (time) => time !== value
      );
    }

    handleInput({
      target: { name: "preferred_arrival_times", value: updatedArrivalTimes },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClientInstance.get(
          `/request/request_property/${userData.id?.client_id}`
        );
        setClientData(response.data);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    if (userData.id?.client_id) {
      fetchData();
    }
  }, [userData.id?.client_id]);

  const handlePropertySelection = (property) => {
    setSelectedProperty(property);

    const selectedIndex = clientData.data.findIndex(
      (item) => item._id === property._id
    );

    if (selectedIndex !== -1) {
      const selectedPropertyId = clientData.data[selectedIndex].property_id;

      setData((prevData) => ({
        ...prevData,
        property_id: selectedPropertyId,
      }));
    }
  };


  const location = useLocation();

  return (
    <div className="p-4 d-flex justify-content-center align-items-center">
      <div className=" container-form mt-4" style={{maxWidth:"100%"}}>
        <p className="heading-form text-dark mx-2">New request</p>
        <div className="container-form-inputs">
          <div>
            <Form onSubmit={handleSubmit} className="mt-4 p-2">
              <Row>
                <Col className="mb-4">
                  <Form.Label style={{ fontSize: "30px" }}>Address</Form.Label>

                  {clientData &&
                    clientData.data &&
                    clientData.data.length > 0 ? (
                    clientData.data.map((property) => (
                      <div key={property._id}>
                        <InputGroup>
                          <Form.Check
                            type="checkbox"
                            label={`${property.street_1}, ${property.street_2}, ${property.city}, ${property.state}, ${property.country}, ${property.zipcode}`}
                            checked={selectedProperty === property}
                            onChange={() => handlePropertySelection(property)}
                          />
                        </InputGroup>
                      </div>
                    ))
                  ) : (
                    <div>No address found</div>
                  )}
                </Col>
              </Row>

              <Row className="mb-1">
                <Form.Label style={{ fontSize: "20px" }}>Service Title</Form.Label>
              </Row>
              <Row className="mb-2">
                <InputGroup>
                  <Form.Control
                    autoFocus
                    required
                    type="text"
                    name="request_title"
                    value={data?.request_title}
                    onChange={handleInput}
                    placeholder="Service Title"
                  />
                </InputGroup>
              </Row>


              <Row className="mb-1">
                <Form.Label style={{ fontSize: "20px" }}>
                  Service Details
                </Form.Label>
              </Row>
              <Row>
                <Form.Label>
                  Please provide as much information as you can
                </Form.Label>
              </Row>
              <Row className="mb-2">
                <InputGroup>
                  <Form.Control
                    autoFocus
                    required
                    type="text"
                    name="service_detail"
                    value={data?.service_detail}
                    onChange={handleInput}
                    placeholder="Service Detail"
                  />
                </InputGroup>
              </Row>
              <Row className="mb-1">
                <Form.Label style={{ fontSize: "20px" }}>
                  Your Availability
                </Form.Label>
              </Row>
              <Row>
                {" "}
                <Form.Label>
                  Which day would be best for an assessment of the work?
                </Form.Label>
              </Row>
              <Row className="mb-2">
                <InputGroup>
                  <Form.Control
                    autoFocus
                    required
                    placeholder=""
                    type="date"
                    name="availability_assessment_work_1"
                    onChange={handleInput}
                  />
                </InputGroup>
              </Row>
              <Row>
                {" "}
                <Form.Label>
                  What is another day that works for you? (optional)
                </Form.Label>
              </Row>
              <Row className="mb-2">
                <InputGroup>
                  <Form.Control
                    autoFocus
                    required
                    type="date"
                    name="availability_assessment_work_2"
                    onChange={handleInput}
                  />
                </InputGroup>
              </Row>
              <Row>
                <Form.Label>
                  What are your preffered arrival times? (optional)
                </Form.Label>
              </Row>
              <Row className="mb-2">
                <InputGroup>
                  <Form.Check
                    type="checkbox" // Use type="checkbox" for a checkbox input
                    label="Any time"
                    name="preferred_arrival_times"
                    value="any time"
                    onChange={handleCheckboxChange}
                  />
                </InputGroup>
                <InputGroup>
                  <Form.Check
                    type="checkbox" // Use type="checkbox" for a checkbox input
                    label="Morning"
                    name="preferred_arrival_times"
                    value="morning"
                    onChange={handleCheckboxChange}
                  />
                </InputGroup>
                <InputGroup>
                  <Form.Check
                    type="checkbox"
                    label="Afternoon"
                    name="preferred_arrival_times"
                    value="afternoon"
                    onChange={handleCheckboxChange}
                  />
                </InputGroup>
                <InputGroup>
                  <Form.Check
                    type="checkbox"
                    label="Evening"
                    name="preferred_arrival_times"
                    value="evening"
                    onChange={handleCheckboxChange}
                  />
                </InputGroup>
              </Row>
              <div>
                <Row>
                  <Col xs={12} className="mb-2">
                    <hr />
                  </Col>
                </Row>
              </div>

              <div
                className="buttond d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <Button
                  variant=""
                  // type="submit"
                  className="w-100 mt-4  bg-gradient"
                  style={{ maxWidth: 200, backgroundColor:colors.red,color:colors.white}}
                  size="sm"
                  onClick={() => {
                    history.push(`/client/request/${userData.id.client_id}`);
                    // history.push(`/client/request/1703916168717uwp4dkxg0353756502`);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onSubmit={handleSubmit}
                  variant=""
                  type="submit"
                  className="w-100 mt-4  bg-gradient"
                  style={{ maxWidth: 200, backgroundColor:colors.red,color:colors.white }}
                  size="sm"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Request;
