import React , {useState, useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Container,
  Row,
} from "@themesberg/react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../config/AxiosInstance";

function RequestRightSidebar({ isVisible, onClose, requestData }) {

  const data = requestData[0];
 return (
    <div className={`right-sidebar ${isVisible ? 'visible' : ''} d-flex align-items-center justify-content-center`} style={{ zIndex:10, backgroundColor: 'whitesmoke', width: '300px' }}>
      <div style={{ cursor: 'pointer', backgroundColor: 'whitesmoke', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', marginLeft: -40 }} onClick={onClose} className='px-3 py-2'>
        <FontAwesomeIcon
          style={{ fontSize: '1.5rem' }}
          icon={isVisible ? faAngleRight : faAngleLeft}
        />
      </div>

      <div style={{ width: 300, alignSelf: 'flex-start', padding: 20 }}>
        <Row>
          <Col>
            <h4 className='text-dark'>Request</h4>
            <p style={{ fontSize: 12 }}>{data.request_date.split(' ')[0]}</p>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        {
          data?.clientData &&
          <Row className="mt-2 ">
            <Col className='d-flex justify-content-between' style={{ cursor: 'pointer' }} onClick={() => {
              const newUrl = `https://https://jobmanager.cloudpress.host/api/api#/properties/${data.client_id}`; // Example URL
              window.open(newUrl, '_blank');
            }}>
              <h6 className='mb-3'>{data.clientData.first_name + " " + data.clientData.last_name}</h6>
              <FontAwesomeIcon
                style={{ fontSize: '1.5rem' }}
                icon={isVisible ? faAngleRight : faAngleLeft}
              />
              {/* <p>{`${data?.clientData.first_name} ${data?.clientData.last_name}`}</p> */}
            </Col>
          </Row>
        }
        <div><b>service details</b></div>
        <hr />
        <div> <h6 className='mb-3'>Your Availability</h6><br />
          <p className='mb-2'><b >which day would be best for an assessment of the Work ?</b> <br /></p>
          <p> {data?.availability_assessment_work_1}</p><br />
          <p className='mb-2'><b >What is another day that works for you?</b> <br /></p>
          <p className='mb-3'>{data?.availability_assessment_work_2} </p><br />


          {data && data?.preferred_arrival_times && data?.preferred_arrival_times.length > 0 && (
            <div>
              <b>What are Your preferred arrival times?</b><br />
              {data?.preferred_arrival_times.map((time, index) => (
                <div key={index}>
                  <span>&#10003;</span> {time}

                </div>
              ))}
            </div>
          )}

        </div>
     </div>
   </div>
  )
}

export default RequestRightSidebar