import React from "react";
import lilnk from "../../assets/img/link.png";
import download from "../../assets/img/download.png";
import location from "../../assets/img/location.png";
import AxiosInstance from "../../config/AxiosInstance";
import star from "../../assets/img/star.png";
import { useParams } from "react-router-dom";
import { useState } from "react";
import moment from "moment";

function QoutesDetails() {
  const [quotesDetaisData, setQuotesDetailsData] = useState();
  const { quotes_id } = useParams();
  // const request_id = "170374011303356i46m7i7380257926";
  const getData = async () => {
    try {
      const res = await AxiosInstance.get(`/quotes/quotes_detail/${quotes_id}`);
      setQuotesDetailsData(res.data.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getData();
  }, []);

  const StarRating = ({ rating }) => {
    const renderStars = () => {
      const stars = [];
      for (let i = 0; i < rating; i++) {
        stars.push(
          <img
            key={i}
            src={star}
            alt="Star"
            style={{
              width: "20px",
              height: "20px",
              // margin: "5px",
            }}
          />
        );
      }
      return stars;
    };

    return <div>{renderStars()}</div>;
  };

  return (
    <div className="signup-container shadow p-2 rounded">
      <div className="main-sec">
        <div className="three-items d-flex justify-content-between">
          <div
            className="street-sign"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                backgroundColor: "#4A5073", // Circle background color
                borderRadius: "50%", // Make it circular
                padding: "5px", // Adjusted padding for better visibility
                marginRight: "10px", // Margin to separate from other elements
              }}
            >
              <img
                src={download}
                alt="Icon"
                style={{
                  width: "30px",
                  height: "30px",
                  margin: "5px",
                }}
              />
            </div>
            <div
              className="first-two-items d-flex"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <b>Awaiting response</b>
            </div>
            <div className="first-two-items d-flex"></div>
          </div>

          <div
            className="first-two-items d-flex"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <h4 className="text-success">{quotesDetaisData?.status}</h4>
          </div>
        </div>
        <hr />
        <div className="copy-section">
          <div className="req  d-flex justify-content-between ">
            <div className="firs-sec">
              <div className="main-heading d-flex">
                {/* <h1>{requestDetaisData?.request_title}sparrow soft</h1> */}
                <h1>
                  {quotesDetaisData?.client?.first_name}{" "}
                  {quotesDetaisData?.client?.last_name}
                </h1>
                <img
                  src={lilnk}
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: "10px",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
          </div>
          <p>{quotesDetaisData?.job_title}</p>

          <div className="row d-flex flex-wrap justify-content-between">
            <div className="col-sm-4 col-md-4 mt-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Property address</h5>
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src={location}
                      style={{
                        width: "35px",
                        height: "40px",
                        margin: "10px",
                        alignItems: "center",
                      }}
                    />
                    <div>
                      <p>
                        {quotesDetaisData?.property?.street_1},<br />
                        {quotesDetaisData?.property?.city},
                        {quotesDetaisData?.property?.state},<br />
                        {quotesDetaisData?.property?.country},
                        {quotesDetaisData?.property?.zipcode}.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-md-4 mt-3">
              <div className="card h-100">
                <div className="card-body">
                  <div>
                    <h5 className="card-title">Contact details</h5>
                    <div className="d-flex">
                      <p>
                        {quotesDetaisData?.client?.phone}
                        <br />
                        {quotesDetaisData?.client?.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-md-4 mt-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">Quote Details</h5>

                  <div className="info-section">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">Rating</p>
                      {/* <p>
                        {" "}
                        <img
                          src={star}
                          alt="Icon"
                          style={{
                            width: "30px",
                            height: "30px",
                            margin: "5px",
                          }}
                        />
                      </p> */}
                      <StarRating rating={quotesDetaisData?.rating} />
                    </div>
                    {/* <hr className="my-2" />

                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">Reminder</p>
                      <p>Dec 28, 2023</p>
                    </div> */}

                    <hr className="my-2" />

                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">Created</p>
                      {/* <p> {quotesDetaisData?.createdAt}</p> */}
                      <p>
                        {" "}
                        {moment(quotesDetaisData?.createdAt).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </div>
                    <hr className="my-2" />

                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">Sent</p>
                      <p>
                        {" "}
                        {moment(quotesDetaisData?.createdAt).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </div>

                    <hr className="my-2" />

                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <p className="mb-0">From request</p>
                      <p>
                        {" "}
                        {moment(quotesDetaisData?.createdAt).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive my-5 p-3">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Product / Service</th>
                    <th scope="col">Attachments</th>
                    <th scope="col">Qty.</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {quotesDetaisData?.product_servise.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <b>{item.name}</b>
                        <br />
                        {item.description}
                      </td>
                      <td>
                        <img
                          src={location}
                          style={{
                            width: "35px",
                            height: "40px",
                            margin: "10px",
                            alignItems: "center",
                          }}
                          alt="Location"
                        />
                      </td>
                      <td>{item.part_qty}</td>
                      <td>€{item.part_price}</td>
                      <td>€{item.total_amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <hr style={{ height: "5px" }} />
              {/* <div className="container"> */}

              <div className="row">
                <div className="col-8">
                  <p>{quotesDetaisData?.client_message}</p>
                  <p>
                    This quote is valid for the next 30 days, after which values
                    may be subject to change.
                  </p>
                </div>

                <div className="col-4">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td>€{quotesDetaisData?.product_servise_total}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Total</b>
                        </td>
                        <td>
                          <b>€{quotesDetaisData?.product_servise_total}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr style={{ height: "5px" }} />
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      backgroundColor: "#4A5073",
                      padding: "10px",
                      color: "white",
                    }}
                  >
                    {/* Created: {quotesDetaisData?.createdAt} */}
                    Internal notes and attachments
                  </h5>
                  <h6>Created :</h6>{" "}
                  {moment(quotesDetaisData?.createdAt).format("DD-MM-YYYY")}
                  <h6 className="mt-2">Notes Details :</h6>
                  <p className="card-text">
                    {quotesDetaisData?.internal_notes_detais}
                  </p>
                  <hr />
                <div>
                  <h6>Attachment:</h6>
                  <div style={{ display: "flex" }}>
                    {/* {requestDetaisData?.internal_attachments.map(
                      (attachment, index) => (
                        <div key={index} style={{ marginRight: "10px" }}>
                          <img
                            src={attachment}
                            alt={`Attachment ${index + 1}`}
                            style={{ height: "70px", width: "70px",  cursor:"pointer" }}

                          />
                        </div>
                      )
                    )} */}
                    {quotesDetaisData?.internal_attachments?.map(
                      (attachment, index) => (
                        <div key={index} style={{ marginRight: "10px" }}>
                          <a
                            href={attachment}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={attachment}
                              alt={`Attachment ${index + 1}`}
                              style={{
                                height: "70px",
                                width: "70px",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        </div>
                      )
                    )}
                  </div>
                </div>
                  {/* <hr />
                  <div>
                    <img
                      src={lilnk}
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "10px",
                        alignItems: "center",
                      }}
                    />
                    <img
                      src={location}
                      style={{
                        width: "35px",
                        height: "40px",
                        margin: "10px",
                        alignItems: "center",
                      }}
                    />
                  </div>
                  <hr /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QoutesDetails;
