import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Correct import
import {

	faMapMarkerAlt,

} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {colors} from "../config/colors";
const AppointmentDashboard = () => {
	const history = useHistory();
	const [selectedOption, setSelectedOption] = useState("Today");

	const handleCardClick = () => {
		// Navigate to AppointmentDetail
		history.push("/client/appointmentdetails");
	};
	return (
		<>
			<div className="row d-flex justify-content-center">
				<div className="col-5 mt-5">
					<p
						style={{ textAlign: "left", paddingLeft: "5px", color: "#262B40" }}
					>
						<label style={{fontWeight:"bold",fontSize:"20px"}}>Your appointments</label>
					</p>
					<p
						className="mb-3"
						style={{ textAlign: "left", paddingLeft: "5px", color: "#262B40" }}
					>
						{/* <label>Select option:</label> */}
						<label style={{fontWeight:"bold",fontSize:"20px"}}>Appointment history:</label>

						<select
							style={{ marginLeft: "10px" , cursor:"pointer"}}
							value={selectedOption}
							onChange={(e) => setSelectedOption(e.target.value)}
						>
							<option value="Today">Today</option>
							<option value="Past">Past</option>
						</select>
					</p>
					{selectedOption === "Today" && (
						<div
							className="card shadow"
							style={{ width: "30rem", color: " #262B40" ,cursor:"pointer"}}
							onClick={handleCardClick}
						>
							<div className="card-body">
								<h5 className="card-title">Saturday Dec 30, 2023</h5>
								<div className="card-text">
									<div className="d-flex align-items-center">
										<FontAwesomeIcon icon={faClock} className="ml-3" style={{ color:colors.red}} />
										<span style={{ padding: "6px" }}>14:00</span>
									</div>
									<div className="d-flex align-items-center">
										<FontAwesomeIcon icon={faMapMarkerAlt} className="ml-3" style={{ color:colors.red}} />
										<span style={{ padding: "6px" }}>
											San Francisco, California 94128
										</span>
									</div>
								</div>
							</div>
						</div>
					)}

					{selectedOption === "Past" && (
						<div
							className="card shadow"
							style={{ width: "30rem", color: " #262B40",cursor:"pointer" }}
							onClick={handleCardClick}
						>
							<div className="card-body">
								<h5 className="card-title">Saturday Dec 30, 2023</h5>
								<div className="card-text">
									<div className="d-flex align-items-center">
										<FontAwesomeIcon icon={faClock} className="ml-3" />
										<span style={{ padding: "6px" }}>13:00</span>
									</div>
									<div className="d-flex align-items-center">
										<FontAwesomeIcon icon={faMapMarkerAlt} className="ml-3" />
										<span style={{ padding: "6px" }}>
											N 21st St East Orange, New Jersey, 07017
										</span>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default AppointmentDashboard;
