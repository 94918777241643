export const Routes = {
  // pages
  Presentation: { path: "/project/presentation" },
  DashboardOverview: { path: "/user" },
  LandPage: { path: "/" },
  Transactions: { path: "/transactions" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/billing" },
  Invoice: { path: "/invoice" },
  Signin: { path: "/sign-in" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },
  Lock: { path: "/lock" },
  NotFound: { path: "/404" },
  ServerError: { path: "/500" },
  Profile: { path: "/profile" },

  // premium
  PremiumPlans: { path: "/premium-plan" },


  // client
  Client: { path: "/client/new" },
  ClientEdit: { path: "/client/new/:clientId" },

  ClientDashBoard: { path: "/client/dashboard" },

  // properties
  // Properties: {path: "/properties"},
  // NewProperties: {path: "/properties/new"},

  Properties: { path: "/properties/:clientId" },
  NewProperties: { path: "/properties/newproperties/form/:clientId" },

  //quotes
  QuotesDashboard: { path: "/quotes" },
  NewQuotes: { path: "/quotes/new" },
  QuotesDetails: { path: "/quotes/overview/:quotes_id" },
  EditQuotes: { path: "/quotes/new/:quotes_id" },
  ConvertQuotes: { path: "/quotes/new/:request_id" },

  // shedule
  Schedule: { path: "/schedule" },

  // request
  RequestDashboard: { path: "/request" },
  NewRequest: { path: "/request/new" },
  EditRequest: { path: "/request/new/:request_id" },
  WorkRequestData: { path: "/request/overview/:request_id" },
  

  //jobs
  JobDashboard: { path: "/jobdashboard" },
  JobDetail: { path: "/job/jobdetail/:job_id" },
  NewJob: { path: "/job/new" },
  EditJob: { path: "/job/new/:job_id" },
  ConvertJob: { path: "/job/new/:quotes_id" },

  // Schedule
  Schedule: { path: "/schedule" },

  //invoices
  InvoiceDashboard: { path: "/invoice" },
  NewInvoice: {path: "/invoice/new/:invoice_id"},
  InvoiceDetails: {path: "/invoice/overview/:invoice_id"},
  ConvertInvoice: { path: "/invoice/new" },
  Schedule: { path: "/schedule" },

  // settings
  CompanySetting: { path: "/setting/company/edit" },
  productSetting: { path: "/setting/product/edit" },
  Teamsetting: { path: "/setting/Team/edit" },
  Clienthubsetting: { path: "/setting/Clienthub/edit" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
