import * as React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import ArrowUpLineIcon from "@rsuite/icons/ArrowUpLine";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "react-bootstrap";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Collapse from "@mui/material/Collapse";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "universal-cookie";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import AxiosInstance from "../../../config/AxiosInstance";
import toast, { Toaster } from "react-hot-toast";
import "../tableBg.css";
import deleterecord from "../../../assets/img/delete.png";

const label = { inputProps: { "aria-label": "Switch demo" } };

const headCells = [
  {
    label: "Plan Name",
  },
  {
    label: "Price",
  },
  {
    label: "Billing Interval",
  },

  {
    label: "CreatAt",
  },
];

function Row(props) {
  const { row, handleClick, isItemSelected, labelId, seletedEditData } = props;
  const [open, setOpen] = React.useState(false);

  const [accessType, setAccessType] = React.useState(null);

  return (
    <React.Fragment>
      <TableRow
        hover
        // onClick={(event) => handleClick(event, row._id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.machineTypeId}
        selected={isItemSelected}
      >
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowUpLineIcon /> : <ArrowDownLineIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell align="center" padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell> */}

        {/* <TableCell align="center">{ row + 1}</TableCell> */}
        <TableCell align="center">{row.plan_name}</TableCell>
        <TableCell align="center">{row.price}</TableCell>
        <TableCell align="center">{row.billing_interval}</TableCell>
        <TableCell align="center">
          {new Date(row.createdAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })}
        </TableCell>

        {/* <TableCell align="center">
          <button class="btn btn-default" onClick={() => seletedEditData(row)}>
            <EditIcon />
          </button>
        </TableCell> */}
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ paddingLeft: 15, margin: 2 }}>
            <Typography variant="h6" gutterBottom component="div">
              Other Data :
            </Typography>
            <hr />

            <table
              style={{
                fontFamily: "arial, sans-serif",
                border: "collapse",
                width: "50%",
              }}
            >
              <tr>
                <th
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  Index
                </th>
                <th
                  style={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  Feature
                </th>
              </tr>

              {row.features.map((index, id) => (
                <tr key={id}>
                  <th
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {id + 1}
                  </th>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    {index}
                  </td>
                </tr>
              ))}
            </table>
          </Box>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
}

export default function Plans() {
  let cookies = new Cookies();
  const history = useHistory();
  React.useEffect(() => {
    const userData = cookies.get("token");
    if (!userData) {
      history.push("/superadmin/sign-in");
    }
  }, [cookies]);

  const [industrialData, setIndustrialData] = useState([]);
  let [loader, setLoader] = React.useState(true);
  let [countData, setCountData] = useState(0);

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const getData = async () => {
    const token = cookies.get("token");
    try {
      const res = await AxiosInstance.get("/plan/plan/get", {
        params: {
          pageSize: rowsPerPage,
          pageNumber: page,
        },
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
      });
      setLoader(false);
      setIndustrialData(res.data.data);
      setCountData(res.data.count); // Make sure to adjust the key here
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = industrialData?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Delete selected
  var handleDelete = () => {
    swal("Are You Sure You Want TO Delete ?", {
      buttons: ["No", "Yes"],
    }).then(async (buttons) => {
      if (buttons === true) {
        AxiosInstance.delete("/industry/industry", {
          data: selected,
        }).then((response) => {
          if (response.data.statusCode === 200) {
            getData();
            setSelected([]);
            toast.success(response.data?.message);
          }
        });
      }
    });
  };

  const [searchLoader, setSearchLoader] = useState(false);
  let handleSearchData = async (values) => {
    setSearchLoader(true);
    let res = await AxiosInstance.post("/industry/search/industry", {
      search: values,
    });
    if (res.data.statusCode === 200) {
      if (values !== "") {
        setSearchLoader(false);
        setIndustrialData(res.data.data);
        setCountData(res.data.count);
      } else {
        setSearchLoader(false);
        getData();
      }
    }
  };

  //   edit machine-type here
  let [modalShowForPopupForm, setModalShowForPopupForm] = React.useState(false);
  let [id, setId] = React.useState();

  var handleSubmit;

  if (!id) {
    handleSubmit = async (values) => {
      try {
        values["features"] = inputFields;
        const res = await AxiosInstance.post("/plan/plan", values);

        if (res.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          toast.success(res.data?.message);
        } else {
          toast.error(res.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error);
      }
    };
  } else {
    handleSubmit = async (values) => {
      try {
        const response = await AxiosInstance.put(
          `/industry/industry/${id}`, // Use template literals to include the id
          values
          //   {
          //     headers: {
          //       Authorization: `Bearer ${token}`,
          //     },
          //   }
        );

        if (response.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          toast.success(response.data?.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error);
      }
    };
  }

  //    // "add fom logic"
  let [editData, setEditData] = React.useState({});

  //   auto form fill up in edit
  let seletedEditData = async (datas) => {
    setModalShowForPopupForm(true);
    setId(datas._id);
    setEditData(datas);
  };

  // Formik
  //   let [ProductDetailsFormik, setProductDetailsFormik] = useState({});
  //   const FormikValues = () => {
  //     const formik = useFormikContext();
  //     React.useEffect(() => {
  //       setProductDetailsFormik(formik.values);
  //     }, [formik.values]);
  //     return null;
  //   };

  const [IndustrialCategory, setIndustrialCategory] = useState();
  console.log(IndustrialCategory, "IndustrialCategory");
  const getpropertyDetails = async () => {
    try {
      const res = await AxiosInstance.get(`/industrial_category/category/get`);
      setIndustrialCategory(res.data.data);
      console.log(res.data.data, "------------------------------------------");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    getpropertyDetails();
  }, []);

  const [inputFields, setInputFields] = useState([
    {
      features: "",
    },
  ]);
  console.log(inputFields, "inputFields====================");

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        features: "",
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleFeaturesChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  return (
    <>
      <div className="signup-container shadow p-3 rounded">
        <div id="main-btn-add-machinetype">
          <div className="d-flex flex-row justify-content-end mb-2">
            <Button
              className="text-capitalize"
              size="small"
              onClick={() => {
                setModalShowForPopupForm(true);
                setId(null);
                setEditData({});
              }}
              variant="contained"
              style={{ backgroundColor: "#4A5073", color: "#ffffff" }} // Set background color and text color
            >
              Add Plans
            </Button>
          </div>
        </div>

        <Paper
          sx={{
            width: "100%",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Toolbar
            className="border-top border-bottom"
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              bgcolor: "#4A5073", // Set the background color here
              color: "white", // Set the font color to white
            }}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Plans
              </Typography>
            )}

            {/* <form className="form-inline">
              <input
                id="serchbar-size"
                className="form-control mr-sm-2"
                type="search"
                onChange={(e) => handleSearchData(e.target.value)}
                placeholder="Search"
                aria-label="Search"
              />
            </form> */}

            <>
              {selected.length > 0 ? (
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete()}>
                    <img
                      src={deleterecord}
                      style={{
                        width: "30px",
                        height: "30px",
                        margin: "10px",
                        alignItems: "center",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
          </Toolbar>
          {loader || searchLoader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <Circles
                height="50"
                width="50"
                color="#4A5073"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="center" padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < industrialData?.length
                        }
                        checked={
                          industrialData?.length > 0 &&
                          selected.length === industrialData?.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell> */}

                    <TableCell align="center"></TableCell>

                    {headCells.map((headCell, id) => {
                      return (
                        <TableCell key={id} className="fw-bold" align="center">
                          {headCell.label}
                        </TableCell>
                      );
                    })}

                    {/* <TableCell className="fw-bold" align="center">
                      Action
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {industrialData?.map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <Row
                        key={row.machineTypeId}
                        row={row}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        handleClick={handleClick}
                        selected={selected}
                        index={index}
                        seletedEditData={seletedEditData}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={countData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Paper>
      </div>

      <Dialog
        fullWidth
        open={modalShowForPopupForm}
        onClose={() => setModalShowForPopupForm(false)}
      >
        <DialogTitle>{"Industry Form"}</DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              plan_name:
                editData && editData.plan_name ? editData.plan_name : "",
              price: editData && editData.price ? editData.price : "",
              billing_interval:
                editData && editData.billing_interval
                  ? editData.billing_interval
                  : "",
            }}
            validationSchema={Yup.object().shape({
              plan_name: Yup.string().required("Required"),
              price: Yup.string().required("Required"),
              billing_interval: Yup.string().required("Required"),
            })}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm(values);
            }}
          >
            {({ values, errors, touched, handleBlur, handleChange }) => (
              <Form>
                {/* <FormikValues /> */}
                <div>
                  <div className="mt-3">
                    <TextField
                      type="text"
                      size="small"
                      fullWidth
                      placeholder="Plan Name *"
                      label="Plan Name *"
                      name="plan_name"
                      value={values.plan_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.plan_name && errors.plan_name ? (
                      <div className="text-danger">{errors.plan_name}</div>
                    ) : null}
                  </div>

                  <div className="col-sm-8">
                    {inputFields.map((data, index) => {
                      const { features } = data;
                      return (
                        <div className="row my-3" key={index}>
                          <div className="col">
                            <div className="form-group">
                              <TextField
                                type="text"
                                onChange={(evnt) =>
                                  handleFeaturesChange(index, evnt)
                                }
                                value={features}
                                name="features"
                                className="form-control"
                                placeholder="Features"
                              />
                            </div>
                          </div>

                          <div className="col">
                            {inputFields.length !== 1 ? (
                              <button
                                className="btn btn-outline-danger"
                                onClick={removeInputFields}
                              >
                                x
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}

                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          className="btn btn-outline-success "
                          onClick={addInputField}
                        >
                          Add New
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <TextField
                      type="number"
                      size="small"
                      fullWidth
                      placeholder="Price *"
                      label="Price *"
                      name="price"
                      value={values.price}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.price && errors.price ? (
                      <div className="text-danger">{errors.price}</div>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <FormControl fullWidth>
                      <InputLabel size="small">
                        Select Billing-Interval
                      </InputLabel>
                      <Select
                        size="small"
                        label="Select Billing-Interval"
                        name="billing_interval"
                        value={values.billing_interval}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        MenuProps={{
                          style: {
                            maxHeight: 210,
                          },
                        }}
                      >
                        <MenuItem value={"Monthly"}>Monthly</MenuItem>
                        <MenuItem value={"Annual"}>Annual</MenuItem>
                      </Select>
                      {touched.billing_interval && errors.billing_interval ? (
                        <div className="text-danger">
                          {errors.billing_interval}
                        </div>
                      ) : null}
                    </FormControl>
                  </div>

                  {!id ? (
                    <Button className="mt-3" type="submit" variant="primary">
                      Add
                    </Button>
                  ) : (
                    <Button className="mt-3" type="submit" variant="warning">
                      Update
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Toaster />
    </>
  );
}
