export const SuperAdminRoutes = {
  // pages
  plansSuperAdmin: { path: "/superadmin/plans" },
  productsdetailsSuperAdmin: { path: "/superadmin/productsdetails" },
  InvoicesSuperAdmin: { path: "/superadmin/invoices" },
  ScheduleSuperAdmin: { path: "/superadmin/schedule" },
  JobSuperAdmin: { path: "/superadmin/job" },
  SuperAdminDashboard: { path: "/superadmin/superadmin-dashboard" },
  InvoiceDetails: { path: "/superadmin/invoicedetails/:invoice_id" },
  JobDetails: { path: "/superadmin/jobdetails/:job_id" },
  ClientPage: { path: "/superadmin/client" },
  ClientSummary: { path: "/superadmin/clientsummary/:client_id" },
  Quotes: { path: "/superadmin/quotes" },
  QuotesDetailsSuperAdmin: { path: "/superadmin/quotes_details/:quotes_id" },
  PropertyDetails: { path: "/superadmin/propertydetails/:property_id" },
  ProductAndService: { path: "/superadmin/product_service" },
  Request: { path: "/superadmin/request" },
  RequestDetails: { path: "/superadmin/requestdetails/:request_id" },
  DraggableTable: { path: "/superadmin/table" },
  Property: { path: "/superadmin/property/:client_id" },
  Client: { path: "/superadmin/client/:user_id" },
  User: { path: "/superadmin/users" },
  IndustrialCategory: { path: "/superadmin/industrial-category" },
  Industrial: { path: "/superadmin/industrial" },
  TeamSize: { path: "/superadmin/teamsize" },
  Revenue: { path: "/superadmin/revenue" },
  Country: { path: "/superadmin/country" },
  Priority: { path: "/superadmin/priority" },
  Presentation: { path: "/superadmin/project/presentation" },
  DashboardOverview: { path: "/superadmin/" },
  LandPage: { path: "/superadmin/landpage" },
  Transactions: { path: "/superadmin/transactions" },
  Settings: { path: "/superadmin/settings" },
  Upgrade: { path: "/superadmin/upgrade" },
  BootstrapTables: { path: "/superadmin/tables/bootstrap-tables" },
  Billing: { path: "/superadmin/billing" },
  Invoice: { path: "/superadmin/invoice" },
  Signin: { path: "/superadmin/sign-in" },
  Signup: { path: "/superadmin/sign-up" },
  ForgotPassword: { path: "/superadmin/forgot-password" },
  ResetPassword: { path: "/superadmin/reset-password" },
  Lock: { path: "/superadmin/lock" },
  NotFound: { path: "/superadmin/404" },
  ServerError: { path: "/superadmin/500" },

  // docs
  DocsOverview: { path: "/superadmin/documentation/overview" },
  DocsDownload: { path: "/superadmin/documentation/download" },
  DocsQuickStart: { path: "/superadmin/documentation/quick-start" },
  DocsLicense: { path: "/superadmin/documentation/license" },
  DocsFolderStructure: { path: "/superadmin/documentation/folder-structure" },
  DocsBuild: { path: "/superadmin/documentation/build-tools" },
  DocsChangelog: { path: "/superadmin/documentation/changelog" },

  // components
  Accordions: { path: "/superadmin/components/accordions" },
  Alerts: { path: "/superadmin/components/alerts" },
  Badges: { path: "/superadmin/components/badges" },
  Widgets: { path: "/superadmin/widgets" },
  Breadcrumbs: { path: "/superadmin/components/breadcrumbs" },
  Buttons: { path: "/superadmin/components/buttons" },
  Forms: { path: "/superadmin/components/forms" },
  Modals: { path: "/superadmin/components/modals" },
  Navs: { path: "/superadmin/components/navs" },
  Navbars: { path: "/superadmin/components/navbars" },
  Pagination: { path: "/superadmin/components/pagination" },
  Popovers: { path: "/superadmin/components/popovers" },
  Progress: { path: "/superadmin/components/progress" },
  Tables: { path: "/superadmin/components/tables" },
  Tabs: { path: "/superadmin/components/tabs" },
  Tooltips: { path: "/superadmin/components/tooltips" },
  Toasts: { path: "/superadmin/components/toasts" },
  WidgetsComponent: { path: "/superadmin/components/widgets" },
};
