import React, { useState } from 'react';
import NewClientForm from './NewClientForm';
import '../css/client.css';
import Cookies from "universal-cookie";

import { useHistory } from 'react-router-dom';
import { Routes } from '../routes';
import toast, { Toaster } from 'react-hot-toast';
import axiosInstance from '../config/AxiosInstance';
import { useParams } from 'react-router-dom';

function Client() {
	const cookies = new Cookies();
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const history = useHistory();
  const [data, setData] = useState([{ email_info: 'main', contact_info: 'main' }]);
  const { clientId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [userData, setUserData] = useState([]);
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const [show, setShow] = useState(false);

  React.useEffect(() => {
    const userData = cookies.get('userDetails');
    setUserData(userData);
    setData((prevData) => ({
      ...prevData,
      user_id: userData.user_id,
    }));
    if (!userData) {
      history.push('/sign-in');
    }
  }, []);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputClientdetails = (data) => {
    setData((prevData) => ({
      ...prevData,
      client_details: prevData.client_details ? [...prevData.client_details, data] : [data],
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsButtonDisable(true);
    try {
      // Check if client ID exists
      if (clientId) {
        // If client ID exists, perform a PUT request to update the client
        const response = await axiosInstance.put(`/client/client/${clientId}`, data);
        const status = response.data.statusCode;

        if (status === 200) {
          toast.success('Client updated successfully');

          // Delay the navigation after displaying the toast for 3 seconds
          setTimeout(() => {
            history.push(Routes.ClientDashBoard.path);
          }, 3000);
        } else if (status === 201) {
          toast.error('Email already used');
        } else if (status === 202) {
          toast.error('Phone number already used');
        }
      } else {
        // If client ID doesn't exist, perform a POST request to create a new client
        const response = await axiosInstance.post('/client/client', data);
        const status = response.data.statusCode;

        if (status === 200) {
          toast.success('Client created successfully');

          // Delay the navigation after displaying the toast for 3 seconds
          setTimeout(() => {
            history.push(Routes.ClientDashBoard.path);
          }, 3000);
        } else if (status === 201) {
          toast.error('Email already used');
        } else if (status === 202) {
          toast.error('Phone number already used');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Please try again later');
    } finally {
      setIsButtonDisable(false);
    }
  };

  const handleDropdownChange = (name, e) => {
    const selectedOption = e.target.value;
    setData((prevData) => ({
      ...prevData,
      [name]: selectedOption,
    }));
  };

  const handleDelete = (index) => {
    setData((prevData) => ({
      ...prevData,
      client_details: prevData.client_details.filter((_, i) => i !== index),
    }));
  };

  const handleShow = () => setShow(true);

  const handleEdit = (index, item) => {
    setKey(item.key);
    setValue(item.value);
    setEditIndex(index);
    setIsEditing(true);
    handleShow();
  };

  return (
    <div className=' d-flex justify-content-center align-items-center'>
      <div className='container-form mt-4 mb-4'>
        <p className='heading-form text-dark mx-2'>New Client</p>
        <div className='container-form-inputs'>
          <NewClientForm
            handleSubmit={handleSubmit}
            handleInput={handleInput}
            data={data}
            setData={setData}
            handleDropdownChange={handleDropdownChange}
            handleInputClientdetails={handleInputClientdetails}
            handleDelete={handleDelete}
            isButtonDisable={isButtonDisable}
            setIsButtonDisable={setIsButtonDisable}
            handleEdit={handleEdit}
			setIsEditing={setIsEditing}
			setEditIndex={setEditIndex}
          />
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default Client;
