import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Table, Pagination, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";

function InvoiceTable() {
  const history = useHistory();
  const [invoiceData, setInvoiceData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("first_name");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const cookies = new Cookies();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);

        const userData = cookies.get("userDetails");
        setUserId(userData.user_id);

        const response = await fetch(
          `https://jobmanager.cloudpress.host/api/invoice/invoice_get/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              page: currentPage,
              itemsPerPage: itemsPerPage,
              sortColumn: sortColumn,
              sortOrder: sortOrder,
              search: searchTerm,
            },
          }
        );

        const data = await response.json();

        const filteredData = data?.data?.map((item) => {
          const { invoiceData, clientData } = item;
          return {
            _id: invoiceData?._id,
            invoice_id: invoiceData?.invoice_id,
            invoice_issued_date: invoiceData?.invoice_issued_date,
            product_servise_total: invoiceData?.product_servise_total,
            invoice_subject: invoiceData?.invoice_subject,
            first_name: clientData?.first_name,
            last_name: clientData?.last_name,
            createdAt: invoiceData?.createdAt,
          };
        });
        
        
        setInvoiceData(filteredData);
        setTimeout(()=> {
          setLoading(false);
        }, 3000);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };

    fetchData();
  }, [userId, cookies, currentPage, itemsPerPage, sortColumn, sortOrder, searchTerm]);
  console.log(loading,"hjhgfdsfdgdg")

  const handleSort = (column) => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setSortColumn(column);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleRowClick = (rowData) => {
    const invoice_id = rowData.invoice_id;
    localStorage.setItem("selectedJobId", invoice_id);
    history.push(`/invoice/overview/${invoice_id}`);
  };

  const sortedData = invoiceData?.slice().sort((a, b) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    if (valueA < valueB) {
      return sortOrder === "asc" ? -1 : 1;
    } else if (valueA > valueB) {
      return sortOrder === "asc" ? 1 : -1;
    } else {
      return 0;
    }
  });

  const renderTableRows = () => {
    const filteredData = sortedData.filter((rowData) => {
      const clientName = `${rowData?.first_name} ${rowData?.last_name}`.toLowerCase();
      const invoiceSubject = rowData?.invoice_subject.toLowerCase();

      return (
        clientName.includes(searchTerm.toLowerCase()) ||
        invoiceSubject.includes(searchTerm.toLowerCase())
      );
    });

    return filteredData
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map((rowData, index) => (
        <tr key={index} onClick={() => handleRowClick(rowData)}>
          <td>
            <a>
              {rowData?.first_name} {rowData?.last_name}
            </a>
          </td>
          <td>{rowData?.invoice_issued_date || "----/--/--"}</td>
          <td>{rowData?.invoice_subject}</td>
          <td>{rowData?.product_servise_total}</td>
        </tr>
      ));
  };

  const totalPages = Math.ceil(sortedData?.length / itemsPerPage);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div style={{ justifyContent: "center", alignItems: "center" }}>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <section className="ftco-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="w-100 align-items-end justify-content-end d-flex mb-4">
                  <Form.Group controlId="searchInput" className="mb-3 mx-2">
                    <Form.Control
                      onChange={handleSearchChange}
                      value={searchTerm}
                      type="text"
                      name="search"
                      placeholder="Search"
                    />
                  </Form.Group>
                </div>
                <div className="table-wrap">
                  <Table striped>
                    <thead style={{ borderTop: "1px solid black" }}>
                      <tr>
                        <th onClick={() => handleSort('first_name')}>
                          Client{" "}
                          <span style={{marginLeft:"10px"}}>
                            {sortOrder === 'asc' ? (
                              <FontAwesomeIcon icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon icon={faArrowDown} />
                            )}
                          </span>
                        </th>
                        <th onClick={() => handleSort("invoice_issued_date")}>
                          Date{" "}
                          {sortColumn === "invoice_issued_date" && (
                            <FontAwesomeIcon
                              icon={sortOrder === "asc" ? faArrowUp : faArrowDown}
                            />
                          )}
                        </th>
                        <th onClick={() => handleSort('invoice_subject')}>
                          Subject{" "}
                          <span style={{marginLeft:"10px"}}>
                            {sortOrder === 'asc' ? (
                              <FontAwesomeIcon icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon icon={faArrowDown} />
                            )}
                          </span>
                        </th>
                        <th onClick={() => handleSort("product_servise_total")}>
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(sortedData) && sortedData?.length > 0 ? (
                        renderTableRows()
                      ) : (
                        <tr>
                          <td colSpan="4">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="pagination-table d-flex justify-content-between">
                  <div>
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => handlePaginationClick(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                      {Array.from({ length: totalPages }, (_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => handlePaginationClick(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => handlePaginationClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  </div>
                  <div className="drop-count col-2">
                    <Form.Group
                      controlId="itemsPerPageSelect"
                      className="mb-3 d-flex align-items-center"
                    >
                      <Form.Label>Show</Form.Label>
                      <Form.Control
                        as="select"
                        className="mx-2"
                        value={itemsPerPage}
                        onChange={(e) => {
                          setItemsPerPage(parseInt(e.target.value, 10));
                          setCurrentPage(1);
                        }}
                        style={{ width: "30%" }}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default InvoiceTable;
