import React from 'react'
import { useHistory } from "react-router-dom";
import {colors} from "../config/colors";

const QuoteRequestchange = () => {
    const history = useHistory();

    const handleCardClick = () => {
        // Navigate to QuotesDetails page
        history.push("/client/quotedash");
    };

    return (
        // <div className="card" style={{width:"150px"}}>
        //     <div className="col-lg-6" style={{boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",width: "18rem"}}>

        //         <tr style={{fontWeight:"bold",fontSize:"20px"}}>
        //        Your changes have been requested for quote #2
        //        </tr>
        //        <tr className='mt-2 mb-'>
        //        We will be in touch soon. If you have any query contact us.
        //        </tr>
        //        <tr>
        //        <button type="button" className="btn btn-lg" style={{backgroundColor:"#262B40", color:"white"}} onClick={handleCardClick}>View All Quotes</button>
        //        </tr>

        //     </div>
        // </div>
        <div className='row d-flex justify-content-center'>
            <div className="card mt-4 " style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", width: "40rem" }}>
                <div className="card-body">

                    <div> 
                        <span style={{ fontWeight: "bold", fontSize: "20px" ,color:"black"}}>
                        Your changes have been requested for quote #2
                    </span>
                    </div>
                    <div className="mt-2 mb-4">
                        <span >
                        We will be in touch soon. If you have any query contact us.
                    </span>
                    </div>
                    <div className="mt-2">
                    <span>
                        <button type="button" className="btn btn-lg" style={{  backgroundColor:colors.red,color:colors.white,maxHeight:"50px" ,width:"auto"}} onClick={handleCardClick}>View All Quotes</button>
                    </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuoteRequestchange
