import React, { useState } from 'react'
import NewJobForm from './NewJob';
import '../css/client.css'
import { useHistory } from 'react-router-dom';
import { Routes } from '../routes';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Form,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "@themesberg/react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import axiosInstance from '../config/AxiosInstance';
import Cookies from 'universal-cookie';
import './job.css'

function NewJobPage() {

  const cookies = new Cookies()
  const history = useHistory();
  const [data, setData] = useState({
    product_servise: [],
});
  console.log("data is",data)


  const handleInput = (e) => {
    const value = e.target.value
    const name = e.target.name

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleInputCheckbox = (e) => {
    const value = e.value
    const name = e.name

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
  const handleSubmit = async (e) => {
    try {
      const userData = cookies.get('userDetails');

      const apiData = { ...data, user_id: userData.user_id }

      const response = await axiosInstance.post('/request/request', apiData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        const responseData = response.data;
        console.log('Response Data:', responseData);
        toast.success('Request submitted successfully');
        // Redirect to another page after successful submission if needed
        setTimeout(() => {
          history.push(Routes.RequestDashboard.path);
        }, 2000);
      } else {
        toast.error('Error submitting request');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred');
    }
  };

  const handleDropdownChange = (name, e) => {
    const selectedOption = e.target.value;

    setData((prevData) => ({
      ...prevData,
      [name]: selectedOption,
    }));
  };

  return (
    <div className='w-100 d-flex justify-content-center align-items-center'>

      <div className='container-form1 mt-4'>
        <p className='heading-form text-dark mx-2'>JOB </p>
        <div className='container-form-inputs'>
          <NewJobForm data={data} handleSubmit={handleSubmit} handleInputCheckbox={handleInputCheckbox} handleInput={handleInput} handleDropdownChange={handleDropdownChange} />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

export default NewJobPage