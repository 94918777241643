import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import AxiosInstance from "../../config/AxiosInstance";

export default function TeamSizeGrid() {
  const [teamSizeData, setTeamSizeData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // Fetch data from the API
    AxiosInstance.get('/teamsize/teamsize')
      .then(response => {
        const responseData = response.data.data;
        setTeamSizeData(responseData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching team size data:', error);
        setLoading(false);
      });
  }, []);

  const columns = [
    { field: '_id', headerName: 'ID', flex: 1 },
    { field: 'teamSize', headerName: 'Team Size', flex: 1 },
    { field: 'createdAt', headerName: 'Created At', flex: 1 },
    { field: 'updatedAt', headerName: 'Updated At', flex: 1 },
  ];

  // Specify the getRowId prop to use _id as the unique identifier
  const getRowId = (row) => row._id;

  const handleRowOrderChange = async (params) => {
    // Update the order of rows locally
    const updatedRows = [...teamSizeData];
    const movedRow = updatedRows.splice(params.oldIndex, 1)[0];
    updatedRows.splice(params.newIndex, 0, movedRow);
  
    // Update the order property based on the new index
    updatedRows.forEach((row, index) => {
      row.order = index + 1;
    });
  
    setTeamSizeData(updatedRows);
  
    // Update the order of rows in the database
    try {
        // Extract only the necessary fields to send to the server
        const rowsToUpdate = updatedRows.map(({ _id, order }) => ({ _id, order }));
        
        console.log("Rows to Update:", rowsToUpdate);
      
        await AxiosInstance.put('/teamsize/update_order', {
          rows: rowsToUpdate,
        });
      } catch (error) {
        console.error('Error updating team size order:', error);
        // Handle error
      }
  };
  

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro
        loading={loading}
        columns={columns}
        rows={teamSizeData}
        getRowId={getRowId}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
      />
    </div>
  );
}
