import {
  faArrowLeft,
  faCogs,
  faEllipsisH,
  faInbox,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {colors} from "../config/colors";
import {
  Button,
  Form,
  InputGroup,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Nav,
} from "@themesberg/react-bootstrap";
import down from "../../src/assets/img/icons/arrow-down.png";
import logo from "../assets/img/logo.png";
import link from "../../src/assets/img/icons/check-mark.png";
import click from "../../src/assets/img/icons/arrow-down.png";
import { useState, useEffect } from "react";
import axiosInstance from "../config/AxiosInstance";
import { Routes } from "../routes";
import moment from "moment";

function WorkRequestData() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { request_id } = useParams();

  const todayDate = moment().format("YYYY-MM-DD");
  const requestDate = data[0]?.request_date.split(" ")[0];

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `/request/request_clientdata/${request_id}`
      );
      setData(response?.data?.data);
      console.log(response?.data?.data,"response?.data?.data====================================================this is data from reduest")
    } catch (error) {
      console.log("error in fetchdata in company details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMoveToQuotes = () => {
    try {
      const dataToSend = data;
      if (data[0].status == undefined || data[0].status == "") {
        history.push(`/request/overview/${request_id}`);
      } else {
        console.log("you converted request to quote!");
        alert(data[0].status);
      }
    } catch (error) {
      console.log("error in work request:", error);
    }
   
  };

  const handleConverjob = () => {
    // navigate to edit screen of request
    // history.push(`/job/new/${quotes_id}`);
     history.push(`/quotes/new?request_id=${request_id}`)

  }

  // console.log(history,'history')

  const handleEditClick = () => {
    if (data[0].status == undefined || data[0].status == "") {
      history.push(`/request/new/${request_id}`);
    } else {
      console.log(data[0].status);
      alert("you converted request to quote!");
    }
  };

  return loading ? (
    <div className="d-flex justify-content-center mt-5">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div
      className="p-3"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        padding: "20px",
        borderRadius: "10px",
        padding: "20px",
        margin: "auto",
        marginBottom: "30px",
      }}
    >
      <div className="main d-flex justify-content-between">
        <div className="button-three">
          <Dropdown className="btn-toolbar" size="sm">
            <Dropdown.Toggle
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
              style={{backgroundColor:"#e91818",border:"none"}}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              Back To
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
              <Dropdown.Item
                onClick={() => {
                  history.push(Routes.RequestDashboard.path);
                }}
                className="fw-bold"
              >
                <FontAwesomeIcon icon={faInbox} className="me-2" /> Request
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="button-three">
          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link}>
              <Button variant="outline-primary" size="sm"style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
              <Dropdown.Item className="fw-bold" onClick={handleEditClick}  >
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  className="text-dark me-2"
                />{" "}
                Edit Request
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item className="fw-bold" onClick={handleConverjob}>
                <FontAwesomeIcon icon={faCogs} className="text-danger me-2" />{" "}
                Convert to Quote
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div
        className="main-sec"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
      >
        <div className="three-items ">
          <div className="first-two-items d-flex justify-content-between">
            <div className="address">
              <h5 className="m-0">
                {data[0]?.clientData?.first_name +
                  " " +
                  data[0]?.clientData?.last_name}
              </h5>
              <h6>{data[0]?.clientData?.email}</h6>
              <h6>{data[0]?.clientData?.phone}</h6>
              <div>
                {data[0].status != "" ? (
                  <h5 className="text-success">{data[0].status}</h5>
                ) : todayDate == requestDate ? (
                  <h5 className="text-success">Today</h5>
                ) : (
                  <h5 className="text-danger">Overdue</h5>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="copy-section">
          <div className="req  d-flex justify-content-between ">
            <div className="firs-sec">
              <div className="main-heading ">
                <h6>{data[0]?.request_title}</h6>
                <h6>{data[0]?.propertyData?.street_1}</h6>
                <h6>{data[0]?.propertyData?.street_2}</h6>
                <h6>
                  {data[0]?.propertyData?.city +
                    ", " +
                    data[0]?.propertyData?.state +
                    " " +
                    data[0]?.propertyData?.zipcode}
                </h6>

                <span className="mt-1 text-bold "></span>
              </div>
            </div>
            <div className="tab col-6 star-table d-flex justify-content-center align-items-center text-center">
              <table className="table table-responsive table-bordered">
                <tbody style={{ border: "2px solid grey" }}>
                  <tr>
                    <th scope="row" style={{ width: "40%" }}>
                      {" "}
                      Request Date :{" "}
                    </th>
                    <td>
                      {new Date(data[0]?.request_date).toLocaleDateString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        className="copy-section my-5"
        style={{
          backgroundColor: "#eef0f2",
          padding: "30px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderTopRightRadius: "20px",
          borderBottomLeftRadius: "20px",
        }}
      >
        <div className="reqq  d-flex justify-content-between">
          <div className="firs-sec com-lg-6 col-sm-5 col-xl-12 col-lg-12">
            <h5>Request</h5>
            <p>
              <b>Please provide as much information as you can</b>
            </p>
            <p>{data[0]?.service_detail}</p>
            {/* <hr /> */}
            <p>
              <b>Which day would be best for an assessment of the work?</b>
            </p>
            <p>{data[0]?.availability_assessment_work_1}</p>
            <p>
              <b>What is another day that works for you?</b>
            </p>
            <p>{data[0]?.availability_assessment_work_2}</p>
            <p>
              <b>What are your preferred arrival times? </b>
              <br />
              {data[0]?.preferred_arrival_times.map((arrivalTime, index) => (
                <div key={index}>✔ {arrivalTime}</div>
              ))}
            </p>
          </div>
        </div>
      </div>

      {data[0]?.assessment_start_date && (
        <div
          style={{
            border: "1px solid #b5b5b5",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            height: "100%",
            borderRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            marginBottom: "30px",
          }}
          className="assesment col-md-12"
        >
          <div className="sec-box">
            <div
              style={{
                backgroundColor: "#eef0f2",
                padding: "20px",
                borderRadius: "10px",
              }}
              className="head-tab d-flex justify-content-between"
            >
              <h5>Assessment</h5>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                />
                <label className="form-check-label" for="gridCheck">
                  Check me out
                </label>
              </div>
            </div>
            <div
              style={{ padding: "20px" }}
              className="description d-flex justify-content-between"
            >
              <div className="data">
                <p>
                  <strong>Instructions - </strong>
                  {data[0]?.assessment_instruction}
                </p>
                <p>
                  <strong>Schedule - </strong>
                  {data[0]?.assessment_start_date + " ... "}
                  {data[0]?.assessment_start_time + " - "}
                  {data[0]?.assessment_end_time + " "}
                  <b>
                    {" "}
                    <i className="fa-regular fa-calendar"></i>
                  </b>
                </p>
                <p>
                  <strong>Team reminders - </strong>
                  {data[0]?.team_reminder}
                </p>
                <p>
                  <strong>Team - </strong>
                  {data[0]?.team_name}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor: "#eef0f2",
          padding: "30px",
          borderRadius: "10px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          borderTopRightRadius: "10px",
          borderBottomLeftRadius: "10px",
        }}
        className="attachments"
      >
        <h4>Internal notes and attachments </h4>
        <div
          style={{
            border: "1px solid #b5b5b5",
            borderTopLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            padding: "20px",
          }}
          className="last-sec my-3"
        >
          <div className="first-card d-flex mb-4">
            <div className="logi d-flex justify-content-between text-center">
              <p>
                {data[0]?.clientData?.first_name?.charAt(0).toUpperCase()}
                {data[0]?.clientData?.last_name?.charAt(0).toUpperCase()}
              </p>
            </div>
            <div className="des">
              <h5>
                {data[0]?.clientData?.first_name}{" "}
                {data[0]?.clientData?.last_name}
              </h5>
              <p>Created: {data[0]?.clientData?.createdAt.substring(0, 10)}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#fff", padding: "20px" }}
            className="main-content"
          >
            <b>Note</b>
            <p>
              <i>{data[0]?.internal_note_details}</i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkRequestData;
