import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
} from "@themesberg/react-bootstrap";
import "./stylesetting.css"
import { Routes } from "../routes";

import { Dropdown, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";





function SidebarSetting() {

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className={`sidebar-container`} style={{ minHeight:'100vh'}}>
        <div
          className="main-sidebar "
        >
          <h5>Settings</h5>
          <div className="ANCHORS my-3">
            <p className="mb-0">
              <b>BUSINESS MANAGEMENT</b>
            </p>
            <a href={"/#" + Routes.CompanySetting.path}>Company settings</a>
            <a href={"/#" + Routes.productSetting.path}>Product & Services</a>
       
          </div>
          <div className="ANCHORS my-3">
            <p className="mb-0">
              <b>TEAM ORGANIZATION</b>
            </p>
            <a href={"/#" + Routes.Teamsetting.path}>Manage Team</a>
          
          </div>
          <div className="ANCHORS my-3">
            <p className="mb-0">
              <b>CLIENT COMMUNICATION</b>
            </p>
            <a href={"/#" + Routes.Clienthubsetting.path}>Client Hub</a>

          </div>
        </div>
      </div>
      <div className="col-md-4 mobile-dropdown">
      <Dropdown className="dropdown-submenu" as={Nav.Item}>
          <Dropdown.Toggle as={Nav.Link} className="fw-bold">
            <FontAwesomeIcon icon={faCog} className="me-2"   /> Settings
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu">
            {/* Business Management options */}
            <Dropdown.Item className="fw-bold d-head">BUSINESS MANAGEMENT</Dropdown.Item>
            <Dropdown.Item href={'/#' + Routes.CompanySetting.path}>Company Settings</Dropdown.Item>
            <Dropdown.Item href={'/#' + Routes.productSetting.path}>Product & Services</Dropdown.Item>
           
            {/* Team Organization options */}
            <Dropdown.Item className="fw-bold d-head">TEAM ORGANIZATION</Dropdown.Item>
            <Dropdown.Item href={'/#' + Routes.Teamsetting.path}>Manage Team</Dropdown.Item>
           
            <Dropdown.Item className="fw-bold d-head">CLIENT COMMUNICATION</Dropdown.Item>
            <Dropdown.Item href={'/#' + Routes.Clienthubsetting.path}>Client Hub</Dropdown.Item>
           

          </Dropdown.Menu> 
        </Dropdown>
      </div>
    </>
  );
}

export default SidebarSetting;
