import React from "react";
import { useHistory } from "react-router";
import request from "../../assets/img/dashboared/add-friend.png";
import job from "../../assets/img/dashboared/certificate.png";
import invoice from "../../assets/img/dashboared/money-document.png";
import quotes from "../../assets/img/dashboared/quote-frame.png";
import client from "../../assets/img/dashboared/user-feedback.png";

import priority from "../../assets/img/dashboared/priority.png";
import revenue from "../../assets/img/dashboared/revenue.png";
import teamsize from "../../assets/img/dashboared/teamsize.png";
import Industrycat from "../../assets/img/dashboared/Industrycat.png";
import Industry from "../../assets/img/dashboared/industry.png";

import { borderRadius } from "@mui/system";
import ReactApexChart from "react-apexcharts";

function SuperAdminDashboard() {
  const history = useHistory();
  const [chartState, setChartState] = React.useState({
    series: [76, 67, 61, 90, 50],
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      // ... (other options)
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              show: false,
            },
            chart: {
              height: 280, // Adjust the height for smaller screens
            },
          },
        },
      ],
    },
  });

  const [chartState1, setChartState1] = React.useState({
    series: [76, 67, 61, 90, 50],
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      // ... (other options)
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              show: false,
            },
            chart: {
              height: 280, // Adjust the height for smaller screens
            },
          },
        },
      ],
    },
  });

  return (
    <div className="signup-container shadow p-2 rounded">
      <div className="main-sec text-center">
        {/* <div className="row d-flex justify-content-evenly">
          <div className="col-sm-4 col-md-1 mt-3">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#432534",
              }}
            >
              <div className="card-body" style={{ marginBottom: "5px" }}>
                <img
                  src={client}
                  style={{
                    width: "200px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>Clients</h4>
              </div>
            </div>
          </div>

          <div className="col-sm-4 col-md-1 mt-3">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#ad2831",
              }}
            >
              <div className="card-body">
                <img
                  src={request}
                  style={{
                    width: "200px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>
                  Requests
                </h4>
              </div>
            </div>
          </div>

          <div className="col-sm-4 col-md-1 mt-3">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#4A5073",
              }}
            >
              <div className="card-body">
                <img
                  src={quotes}
                  style={{
                    width: "200px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>Quotes</h4>
              </div>
            </div>
          </div>

          <div className="col-sm-4 col-md-1 mt-3">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#183a37",
              }}
            >
              <div className="card-body">
                <img
                  src={job}
                  style={{
                    width: "200px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>Jobs</h4>
              </div>
            </div>
          </div>

          <div className="col-sm-4 col-md-1 mt-3">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#f6ae2d",
              }}
            >
              <div className="card-body">
                <img
                  src={invoice}
                  style={{
                    width: "200px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>
                  Invoices
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-evenly">
          <div className="col-sm-4 col-md-1 mt-3">

            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#2a9d8f",
              }}
            >
              <div className="card-body" style={{ marginBottom: "5px" }}>
                <img
                  src={priority}
                  style={{
                    width: "130px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>Priority</h4>
              </div>
            </div>
          </div>

          <div className="col-sm-4 col-md-1 mt-3">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#b5838d",
              }}
            >
              <div className="card-body">
                <img
                  src={revenue}
                  style={{
                    width: "200px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>
                Revenue
                </h4>
              </div>
            </div>
          </div>

          <div className="col-sm-4 col-md-1 mt-3">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#6b9080",
              }}
            >
              <div className="card-body">
                <img
                  src={teamsize}
                  style={{
                    width: "200px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>Team-Size</h4>
              </div>
            </div>
          </div>

          <div className="col-sm-4 col-md-1 mt-3">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#111d4a",
              }}
            >
              <div className="card-body">
                <img
                  src={Industrycat}
                  style={{
                    width: "200px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>Ind. Category</h4>
              </div>
            </div>
          </div>

          <div className="col-sm-4 col-md-1 mt-3">
            <div
              className="card"
              style={{
                borderRadius: "20px",
                width: "200px",
                height: "200px",
                backgroundColor: "#8cb369",
              }}
            >
              <div className="card-body">
                <img
                  src={Industry}
                  style={{
                    width: "200px",
                    height: "130px",
                    alignItems: "center",
                  }}
                />
                <h4 style={{ textAlign: "center", color: "white" }}>
                Industry
                </h4>
              </div>
            </div>
          </div>
        </div> */}
        <div class="row text-center">
          {/* Clients */}
          <div
            className="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3 mb-3"
            onClick={() => history.push("/superadmin/client")}
            style={{ cursor: "pointer" }}
          >
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={client}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Clients</h5>
              </div>
            </div>
          </div>

          {/* Request */}
          <div
            class="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3"
            onClick={() => history.push("/superadmin/request")}
            style={{ cursor: "pointer" }}
          >
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={request}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Request</h5>
              </div>
            </div>
          </div>

          {/* Quotes */}
          <div
            class="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3"
            onClick={() => history.push("/superadmin/quotes")}
            style={{ cursor: "pointer" }}
          >
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={quotes}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Quotes</h5>
              </div>
            </div>
          </div>

          {/* Jobs */}
          <div
            class="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3"
            onClick={() => history.push("/superadmin/job")}
            style={{ cursor: "pointer" }}
          >
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={job}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Jobs</h5>
              </div>
            </div>
          </div>

          {/* Invoice */}
          {/* <div class="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3 mb-3">
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={invoice}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Invoices</h5>
              </div>
            </div>
          </div> */}

          {/* Priority */}
          <div
            class="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3"
            onClick={() => history.push("/superadmin/priority")}
            style={{ cursor: "pointer" }}
          >
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={priority}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Priority</h5>
              </div>
            </div>
          </div>

          {/* Revenue */}
          <div
            class="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3"
            onClick={() => history.push("/superadmin/revenue")}
            style={{ cursor: "pointer" }}
          >
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={revenue}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Revenue</h5>
              </div>
            </div>
          </div>

          {/* Teamsize */}
          <div
            class="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3"
            onClick={() => history.push("/superadmin/teamsize")}
            style={{ cursor: "pointer" }}
          >
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={teamsize}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Teamsize</h5>
              </div>
            </div>
          </div>

          {/* Industry Category */}
          <div
            class="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3"
            onClick={() => history.push("/superadmin/industrial-category")}
            style={{ cursor: "pointer" }}
          >
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={Industrycat}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Industrial Category</h5>
              </div>
            </div>
          </div>

          {/* Industry */}
          <div
            class="col-sm-6 col-lg-6 col-md-6 col-xl-3 mb-3"
            onClick={() => history.push("/superadmin/industrial")}
            style={{ cursor: "pointer" }}
          >
            <div class="card dash-card">
              <div class="card-body text-center">
                <img
                  src={Industry}
                  alt=""
                  style={{ width: "130px", height: "130px" }}
                />
                <h5 class="card-title">Industry</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Circle */}
      {/* <div className="row d-flex justify-content-between">
      <div className="col-lg-6 col-md-12">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="radialBar"
          height={390}
        />
      </div>

      <div className="col-lg-6 col-md-12">
        <ReactApexChart
          options={chartState1.options}
          series={chartState1.series}
          type="radialBar"
          height={390}
        />
      </div>
    </div> */}
    </div>
  );
}

export default SuperAdminDashboard;
