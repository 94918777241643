import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Button } from "@themesberg/react-bootstrap";
import {colors} from "../config/colors";
const AppointmentDetails = () => {
  const history = useHistory();
  const handleBtnBack = () => {
    history.goBack();
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
         {/* Back Button Row */}
         <div className="p-2">
        <Button
          variant=""
          style={{
            width: "auto",
            height: "40px",
            color: "white",
            marginRight:"550px",
            backgroundColor:colors.red,
            color:colors.white,

          }}
          onClick={handleBtnBack}
        >
          <i className="fa-solid fa-arrow-left"></i> Back
        </Button>
      </div>
      {/* Main Content Row */}
      <div
        className="row d-flex justify-content-center"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <div
          className="card mt-1"
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            width: "40rem",
            cursor: "pointer",
          }}
        >
          <div className="card-body">
            <span
              className="card-title"
              style={{ fontWeight: "bold", fontSize: "20px", color: "#262B40" }}
            >
              {" "}
              Appointment on Saturday Dec 30, 2023
            </span>
            <div className="card-text">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faClock} className="ml-3" style={{ color:colors.red}} />
                <span style={{ padding: "6px" }}>13:00</span>
              </div>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="ml-3" style={{ color:colors.red}} />
                <span style={{ padding: "6px" }}>
                  N 21st St East Orange, New Jersey, 07017
                </span>
              </div>
            </div>
            <div className="mt-2">
              <span>
                <button
                  type="button"
                  className="btn btn-lg"
                  style={{
                    backgroundColor:colors.red,
                    color:colors.white,
                    color: "white",
                    maxHeight: "50px",
                    width: "auto",
                  }}
                >
                  View Quote
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Confirm Button Row */}
      <div className="p-2">
        <Button
          className="confirmbutton"
          variant=""
          style={{
            width: "auto",
            height: "40px",
            color: "white",
            marginLeft:"430px",
            backgroundColor:colors.red,
            color:colors.white,
          }}
        >
          Confirm Appointment
        </Button>
      </div>
    </div>
  );
};
export default AppointmentDetails;