import React, { useEffect, useState } from "react";
import { Button, Form } from "@themesberg/react-bootstrap";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { jwtDecode } from "jwt-decode";
import { ClientProjectRoutes } from "../../ClientProjectRoutes";
import { useHistory, useParams } from "react-router-dom";
import axiosInstance from "../../config/AxiosInstance.js";
import {colors} from "../config/colors.js";
function RequestDashboard() {
  const { client_id } = useParams();
  console.log(client_id, "abc");
  localStorage.setItem("client_id", client_id);
  const history = useHistory();

  // const [userData, setUserData] = useState(null);
  // const [requestData, setRequestData] = useState();
  // const [loading, setLoading] = useState(true);

  // // ==========================================================

  // React.useEffect(() => {
  // const getpropertyDetails = async () => {
  //   try {
  //     const res = await axiosInstance.get(
  //       `/client/client_token/?client_id=${client_id}`
  //     );
  //     const myToken = res.data.token;
  //     const myClientIc = res.data.client_id;

  //     localStorage.setItem("authToken", myToken);
  //     localStorage.setItem("client_id", myClientIc);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  //   getpropertyDetails();
  // }, []);

  // // ==========================================================

  // useEffect(() => {
  //   const storedToken = localStorage.getItem("authToken");
  //   if (storedToken) {
  //     const decodedToken = jwtDecode(storedToken);
  //     setUserData(decodedToken);
  //   }
  // }, []);

  // const clientId = userData?.id?.client_id;

  // const fetchData = async () => {
  //   try {
  //     const response = await axiosInstance.get(
  //       `/request/request_data/${clientId}`
  //       );
  //       console.log(response.data, "response.data");
  //       setRequestData(response.data);
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error("Error fetching client data:", error);
  //     }
  //   };

  //   useEffect(() => {
  //   if (clientId) {
  //     fetchData();
  //   }
  // }, [clientId]);

  // const handleRowClick = (clientId, requestId) => {
  //   history.push(`/client/request/${clientId}/${requestId}`);
  // };

  const [userData, setUserData] = useState(null);
  const [requestData, setRequestData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedToken = localStorage.getItem('authToken');

        if (storedToken) {
          const decodedToken = jwtDecode(storedToken);
          setUserData(decodedToken);

          const response = await axiosInstance.get(`/request/request_data/${decodedToken?.id?.client_id}`);
          setRequestData(response.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching client data:', error);
      }
    };

    const getpropertyDetails = async () => {
      try {
        const res = await axiosInstance.get(`/client/client_token/?client_id=${client_id}`);
        const myToken = res.data.token;
        const myClientIc = res.data.client_id;

        localStorage.setItem('authToken', myToken);
        localStorage.setItem('client_id', myClientIc);

        fetchData(); // Call fetchData after storing token in localStorage
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    getpropertyDetails();
  }, [client_id]);

  const handleRowClick = (clientId, requestId) => {
    history.push(`/client/request/${clientId}/${requestId}`);
  };

  return (
    <>
      <Form className="mt-5">
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : requestData && requestData.data && requestData.data.length > 0 ? (
          <div>
            <div className="row justify-content-center mb-4">
              <div className="col-6 d-flex justify-content-center">
                <h2
                  style={{
                    color: "black",
                    marginTop: "10px",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  Your Requests
                </h2>
              </div>
              <div className="col-3">
                <Button
                  onClick={() => {
                    const data = { name: "meetgojhel" };

                    history.push({
                      pathname: ClientProjectRoutes.NewRequest.path,
                      state: data,
                    });
                  }}
                  variant="primary"
                  style={{ marginTop: "10px" ,backgroundColor:colors.red }}
                >
                  New
                </Button>
              </div>
            </div>

            {requestData.data.map((request) => (
             <div className="container p-2">
             <div className="row justify-content-center">
               <div
                 className="col-12 col-md-8 col-lg-6 p-2"
                 onClick={() => handleRowClick(request?.client_id, request?.request_id)}
                 style={{
                   cursor: "pointer",
                   boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
                   backgroundColor: "white",
                   padding: "20px",
                   width: "50%",
                   marginBottom: "20px",
                 }}
               >
                 <div className="d-flex request-box-client align-items-center">
                   <div
                     className="p-3 bg-gradient rounded-circle"
                     style={{
                       width: "55px",
                       height: "55px",
                       marginRight: "15px",
                       marginLeft: "15px",
                       backgroundColor:colors.red
                     }}
                   >
                     <FontAwesomeIcon
                       icon={faInbox}
                       style={{ color: "white", fontSize: "20px" }}
                     />
                   </div>
                   <div>
                     <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                       Requested On {request.request_date.split(" ")[0]}
                     </p>
                     {request.property && (
                       <p style={{ fontSize: "15px", color: "gray" }}>
                         {`${request.property.city}, ${request.property.state}, ${request.property.country}, ${request.property.street_1}, ${request.property.street_2}, ${request.property.zipcode}`}
                       </p>
                     )}
                   </div>
                 </div>
               </div>
             </div>
           </div>
           
            ))}
          </div>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ paddingTop: "50px" }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "15px",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              <div
                className="p-3 bg-dark bg-gradient rounded-circle"
                style={{ width: "60px", height: "60px", margin: "0 auto" }}
              >
                <FontAwesomeIcon
                  icon={faInbox}
                  style={{ color: "white", fontSize: "25px" }}
                />
              </div>
              <Form.Label
                style={{
                  color: "black",
                  marginTop: "10px",
                  fontWeight: "bold",
                  fontSize: "25px",
                }}
              >
                Need some work done?
              </Form.Label>
              <br />
              <Form.Label style={{ marginTop: "10px", fontSize: "20px" }}>
                Send us a request to fill us in on the details
              </Form.Label>
              <br />
              <Button
                onClick={() => {
                  history.push(ClientProjectRoutes.NewRequest.path);
                }}
                variant="success"
                style={{ marginTop: "10px" }}
              >
                Create a Request
              </Button>
            </div>
          </div>
        )}
      </Form>
    </>
  );
}

export default RequestDashboard;
