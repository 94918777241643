import React, { useState, useEffect } from "react";

import axiosInstance from "../config/AxiosInstance";

import Cookies from "js-cookie";
import './stylesetting.css'
import SidebarSetting from './SidebarSetting'
import { Button, Form } from "@themesberg/react-bootstrap";


const initialFormData = {
    company_name: "",
    company_phone_number: "",
    company_website_url: "",
    company_email: "",
    company_street_1: "",
    company_street_2: "",
    company_city: "",
    company_state: "",
    company_zipcode: "",
    company_country: "",

    tax_id_name: "",
    tax_id_number: "",

    regional_country: "",
    regional_timezone: "",
    regional_first_day_of_week: "",
};

function CompanySetting() {
    const [userId, setUserId] = useState(null);
	const [loading, setLoading] = useState(false);
    useEffect(() => {

        const userDetailsFromCookie = Cookies.get("userDetails");

        if (userDetailsFromCookie) {

            const userDetails = JSON.parse(userDetailsFromCookie);


            const { _id } = userDetails;
            console.log("user id",_id)

            if (_id) {
                setUserId(_id);
            } else {

            }
        } else {

        }
    }, []);
    const [formData, setFormData] = useState(initialFormData);
    const [isInputSelected, setIsInputSelected] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        const inputValue = value;
        setIsInputSelected(!!inputValue);
    };


    const handleUpdate = async () => {
        
        try {
            setLoading(true);
            const apiEndpoint = `/user/user_setting/${userId}`;

            const updatedFormData = {
                ...formData,
                company_name: formData.company_name,
                company_phone_number: formData.company_phone_number,
                company_website_url: formData.company_website_url,
                company_email: formData.company_email,
                company_street_1: formData.company_street_1,
                company_street_2: formData.company_street_2,
                company_city: formData.company_city,
                company_state: formData.company_state,
                company_zipcode: formData.company_zipcode,
                company_country: formData.company_country,

                tax_id_name: formData.tax_id_name,
                tax_id_number: formData.tax_id_number,

                regional_country: formData.regional_country,
                regional_timezone: formData.regional_timezone,
                regional_first_day_of_week: formData.regional_first_day_of_week,


            };

            const response = await axiosInstance.put(apiEndpoint, updatedFormData);



            alert('done')
        } catch (error) {

        }
        finally {
			setLoading(false);
		}
    };



    const handleUpdateAllFields = () => {

        const updatedData = {
            company_name: "Updated Company Name",
            company_phone_number: "Updated Phone Number",
            company_website_ur: "updated website url",
        };

        setFormData(updatedData);
    };


    const [useId, setUseId] = useState(null);

    useEffect(() => {
        const userDetailsFromCookie = Cookies.get("userDetails");

        if (userDetailsFromCookie) {
            const userDetails = JSON.parse(userDetailsFromCookie);
            const { user_id } = userDetails;


            if (user_id) {
                setUseId(user_id);
            } else {

            }
        } else {

        }
    }, []);


    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(
    //                 `https://jobmanager.cloudpress.host/api/user/get_user/${useId}`
    //             );

    //             if (response.ok) {
    //                 const data = await response.json();
    //                 setFormData(data.data[0]);

    //             } else {

    //             }
    //         } catch (error) {

    //         }
    //     };

    //     fetchData();
    // }, [useId]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axiosInstance.get(`/user/get_user/${useId}`);
    
            if (response.status === 200) {
              const data = response.data;
              setFormData(data.data[0]);
            } else {
              // Handle non-OK response if needed
            }
          } catch (error) {
            // Handle error
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [useId]);

    const [countries, setCountries] = useState([])

    const apiUrl = 'https://restcountries.com/v3.1/all?fields=name';

    fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
            // Extract common names from the response
            const commonNames = data.map(country => country.name.common);
            setCountries(commonNames)
        })
        .catch(error => console.error('Error fetching data:', error));

    return (
        <div className='d-flex mainmn '>
            <SidebarSetting />
            <div className="form-sec p-4 col-md-10 col-sm-12">
                <h2 className="mb-3 text-dark">Company settings</h2>
                <div className="imput-sec  mb-3">
                    <div className="headingg p-0">
                        <h3 className="p-4" style={{ color: "black" }}>Company details</h3>
                    </div>
                    <div className="inputs p-3 ">
                        <input
                            type="text"
                            class="form-control mb-3"
                            placeholder="Company Name"
                            aria-label="First name"
                            name="company_name"
                            value={formData?.company_name || ""}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            class="form-control mb-3"
                            placeholder="Phone Number"
                            aria-label="First name"
                            name="company_phone_number"
                            value={formData.company_phone_number}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            class="form-control mb-3"
                            placeholder="Website URL"
                            aria-label="First name"
                            name="company_website_url"
                            value={formData.company_website_url}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Email Address"
                            aria-label="First name"
                            name="company_email"
                            value={formData.company_email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="inputs p-3 mt-0 mb-0">
                        <input
                            type="text"
                            class="form-control mb-3"
                            placeholder="Street 1"
                            aria-label="First name"
                            name="company_street_1"
                            value={formData.company_street_1}
                            onChange={handleInputChange}
                        />
                        <input
                            type="text"
                            class="form-control mb-3"
                            placeholder="Street 2"
                            aria-label="First name"
                            name="company_street_2"
                            value={formData.company_street_2}
                            onChange={handleInputChange}

                        />
                        <div className="city col-9 d-flex">
                            {/* <div class="row"> */}
                            <div class="col-8 mb-3">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="City"
                                    aria-label="First name"
                                    name="company_city"
                                    value={formData.company_city}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div class="col-8">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="State"
                                    aria-label="Last name"
                                    name="company_state"
                                    value={formData.company_state}
                                    onChange={handleInputChange}
                                />
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="city col-9 d-flex">
                            {/* <div class="row"> */}
                            <div class="col-8">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Zip Code"
                                    aria-label="First name"
                                    name="company_zipcode"
                                    value={formData.company_zipcode}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div class="col-md-8">
                                {/* <label for="inputState" class="form-label">State</label> */}
                                <select id="inputState" class="form-select" name="company_country"
                                    value={formData.company_country}
                                    onChange={handleInputChange}>
                                    <option selected>Choose...</option>
                                    {countries.map((country)=>(
                                        <option>{country}</option>
                                    ))}
                                </select>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>

                    {/* <div className="business p-3">
                        <h6>Business hours</h6>
                        <p>
                            Business hours set your default availability for online booking,
                            team members, and request forms.
                        </p>
                        <div className="table-time d-flex justify-content-between">
                            <div className="col-6">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr>
                                            <td>Sunday</td>
                                            <td>Closed</td>
                                        </tr>
                                        <tr>
                                            <td>Monday</td>
                                            <td>09:00 - 17:00</td>
                                        </tr>
                                        <tr>
                                            <td>Tuesday</td>
                                            <td>09:00 - 17:00</td>
                                        </tr>
                                        <tr>
                                            <td>Wednesday</td>
                                            <td>09:00 - 17:00</td>
                                        </tr>
                                        <tr>
                                            <td>Thursday</td>
                                            <td>09:00 - 17:00</td>
                                        </tr>
                                        <tr>
                                            <td>Friday</td>
                                            <td>09:00 - 17:00</td>
                                        </tr>
                                        <tr>
                                            <td>Saturday</td>
                                            <td>Closed</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-6 text-end edit-button">
                                <a href="">Edit</a>
                            </div>
                        </div>
                        <div className="table-time d-flex justify-content-between">
                            <div className="col-6">
                                <h6>Show business hours</h6>
                                <p>Display your business hours on client hub.</p>
                            </div>
                            <div className="col-6">
                                <div
                                    style={{ marginRight: "20px" }}
                                    class="form-check form-switch d-flex justify-content-end mr-5"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="imput-sec  mb-3">
                    <div className="headinggg p-0 d-flex justify-content-between" style={{ backgroundColor: 'white' }}>
                        <div className="text">
                            <h3 className="p-4 " style={{ color: 'black', }}>Tax settings</h3>
                        </div>
                        {/* <div className="butns ">
                            <button className="butnss">
                                <i class="fa-solid fa-plus"></i> Create Tax Group
                            </button>
                            <button className="butnss">
                                <i class="fa-solid fa-plus"></i> Create Tax Rate
                            </button>
                        </div> */}
                    </div>
                    <div className="contnt p-3">
                        <div className="city col-9 d-flex">
                            {/* <div class="row"> */}
                            <div class="col-8">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Tax ID Name ex(GST)"
                                    aria-label="First name"
                                    name="tax_id_name"
                                    value={formData.tax_id_name}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div class="col-8">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Tax ID number"
                                    aria-label="First name"
                                    name="tax_id_number"
                                    value={formData.tax_id_number}
                                    onChange={handleInputChange}
                                />
                            </div>

                        </div>
                        <p>
                            <i>Tax ID name and number will appear on invoices</i>
                        </p>
                    </div>
                    {/* <div className="tax-rate p-3 d-flex">
                        <div className=" per">
                            <p>%</p>
                        </div>
                        <div className="col-8 mx-3">
                            <h5>No tax rates</h5>
                            <p>
                                Create one or more tax rates to apply them to quotes and
                                invoices
                            </p>
                            <Button
                                variant=""
                                style={{
                                    minWidth: "50px",
                                    border: "2px solid black",
                                    height: "44px",
                                    marginRight: "20px",
                                    marginTop: "10px",
                                }}
                            >
                                New request
                            </Button>
                        </div>
                    </div> */}
                </div>
                <div className="imput-sec mb-5 " >
                    <div className="headinggg p-0 d-flex justify-content-between" style={{ backgroundColor: 'white' }}>
                        <div className="text">
                            <h3 className="p-4" style={{ color: 'black', textAlign: 'center' }}>Regional settings</h3>
                        </div>
                    </div>
                    <div className="contnt p-3 ">
                    <div class="col-md-12">
                                <label for="inputState" class="form-label">Country</label>
                                <select id="inputState" class="form-select" name="company_country"
                                    value={formData.company_country}
                                    onChange={handleInputChange}>
                                    <option selected>Choose...</option>
                                    {countries.map((country)=>(
                                        <option>{country}</option>
                                    ))}
                                </select>
                            </div>
                        <div class="col-md-12  mb-3">
                            <label for="inputState" class="form-label">
                                Timezone
                            </label>
                            <select id="inputState" class="form-select" name="regional_timezone"
                                value={formData.regional_timezone}
                                onChange={handleInputChange}>
                                <option selected>Choose...</option>
                                <option>(GMT + 05:30) Asia/Calcutta</option>
                                <option>(GMT - 10:00) Hawali</option>
                            </select>
                        </div>
                        {/* <div class="col-md-12">
                            <label for="inputState" class="form-label">
                                Date format
                            </label>
                            <select id="inputState" class="form-select">
                                <option selected>Choose...</option>
                                <option>01/31/2023</option>
                                <option>31/01/2023</option>
                                <option>2023/01/31</option>
                                <option>Jan 31,2023</option>
                            </select>
                        </div>
                        <div class="col-md-12">
                            <label for="inputState" class="form-label">
                                Time format

                            </label>
                            <select id="inputState" class="form-select">
                                <option selected>Choose...</option>
                                <option>12 Hour(1:30)</option>
                                <option>24 Hour(13:00)</option>
                            </select>
                        </div> */}
                        <div class="col-md-12">
                            <label for="inputState" class="form-label">
                                First day of the week
                            </label>
                            <select id="inputState" class="form-select" name="regional_first_day_of_week"
                                value={formData.regional_first_day_of_week}
                                onChange={handleInputChange}>
                                <option selected>Choose...</option>
                                <option>Sunday</option>
                                <option>Monday</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="update text-end ">
                    <button onClick={handleUpdate} disabled={!isInputSelected || loading}style={{
                        border: "none",
                        color: "white",
                        backgroundColor: isInputSelected ? "#4A5073" : "gray",
                    }} className="update-button p-2 my-4  fixed-button">{loading? 'Updating...' : "Update Setting"}</button>
                </div>
            </div>
        </div>
    )
}

export default CompanySetting