import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import axios from "axios";
import Cookies from "universal-cookie";
import "./schedule.css";
import AxiosInstance from "../config/AxiosInstance";

const localizer = momentLocalizer(moment);
const cookies = new Cookies();

function Schedule() {
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = cookies.get("userDetails");
       
        if (!userDetails.user_id) {
          // Handle the case where user_id is not found in cookies
          console.error("User ID not found in cookies");
          return;
        }
        const response = await AxiosInstance.get(
          `/request/abc/${userDetails.user_id}`
        );
        
        setApiResponse(response.data);
        console.log("kiya", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!apiResponse) {
    return <div>Loading...</div>;
  }
  
  const events = apiResponse.data.map((item) => ({
    start: moment(item.request_date).toDate(),
    end: moment(item.request_date).add(1, "hours").toDate(),
    title: item.request_title,
    id: item._id,
  }));

  // Custom style to add a margin-bottom between events
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: "#4A5073",
      color: "#fff",
      border: "none",
      margin: "0",
    };

    if (isSelected) {
      style.border = "2px solid #265999";
    }

    // Add margin-bottom to create a gap between events
    style.marginBottom = "10px";

    return {
      style: style,
    };
  };

  return (
    <div className="full-screen-calendar">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100vh" }}
        eventPropGetter={eventStyleGetter} // Set the custom style for events
      />
    </div>
  );
}

export default Schedule;
