import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Col,
  Row,
  InputGroup,
  DropdownButton,
  Table,
  Dropdown,
  OverlayTrigger,
  Nav,
} from "@themesberg/react-bootstrap";
import { Routes } from "../routes";
import { ButtonGroup } from "@themesberg/react-bootstrap";
// import down from "../../assets/img/icons/arrow-down.png";
// import link from "../../assets/img/icons/link (1).png";
// import homeimage from "../../assets/img/home-bg.jpeg";
// import "./style.css";
// import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faArrowLeft,
  faAddressBook,
  faPen,
  faEllipsisH,
  faStar,
  faCheck,
  faFilePdf,
  faChevronDown,
  faCreditCard,
  faPencilAlt,
  faTrash, 
  faCogs
} from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle, faHome } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import axiosInstance from "../config/AxiosInstance";
import Cookies from "universal-cookie";
import {colors} from "../config/colors";

// import down from "../assets/img/icons/";
function JobDetail() {
  const cookies = new Cookies()
  const history = useHistory();
  const [showText, setShowText] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const { job_id } = useParams();
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState({
  line_items: [],
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/job/job_details/${job_id}`
        );
        setJobDetails(response.data);
        console.log(response.data, 'response.data.data[0]');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


 

  const [entries, setEntries] = useState([
    { name: '', description: '', part_qty: '', part_price: '', total_amount: '' },
  ]);
  const updateEntry = (e, index) => {
    const { name, value } = e.target;
    const updatedEntries = [...entries];
    updatedEntries[index][name] = value;
    const totalAmount = updatedEntries[index].part_qty * updatedEntries[index].part_price || 0;
    updatedEntries[index].total_amount = totalAmount;
    setEntries(updatedEntries);
  };
  const deleteEntry = (index) => {
    const updatedEntries = [...entries];
    updatedEntries.splice(index, 1);
    setEntries(updatedEntries);
  };
  const addNewRow = (event) => {
    event.preventDefault(); 
  
    setEntries([
      ...entries,
      { name: '', description: '', part_qty: '', part_price: '', total_amount: '' },
    ]);
  };
  const calculateTotalPartPrice = () => {
    return entries.reduce((total, entry) => total + parseFloat(entry.part_qty * entry.part_price) || 0, 0);
  };

  const [products, setProducts] = useState([]);

  const fetchProductsServices = async () => {
    try { 
      const userData = cookies.get("userDetails");

      const response = await axiosInstance.get(
        `/product/product_service/${userData.user_id}`
      );
      if (response.data.data) {
        setProducts(response.data.data);
      }
    } catch (error) {
      console.log("error in getting fetch products in new quotes form:", error);
    } finally {
      
    }
  };
  useEffect(() => {
    // Fetch data from the API
    const userData = cookies.get('userDetails')
    fetch(`https://jobmanager.cloudpress.host/api/product/product_service/${userData?.user_id}`)
      .then((response) => response.json())
      .then((data) => {
        // Update the state with the fetched products
        setProducts(data.data);
      })
      .catch((error) => console.error("Error fetching data:", error));

    fetchProductsServices();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://jobmanager.cloudpress.host/api/job/job_data/${job_id}`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      // setResponseData(result.data);
      // setFormData(result.data[0]);
      if (result.data) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          
          line_items: result?.data[0].line_items,
  
        }));
        setFields(result?.data[0].job_details_field)
        setEntries(result?.data[0].line_items)


        
      } else {
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [job_id]);

  const handleConverinvoice = () => {
    history.push(`/invoice/new?job_id=${job_id}`)
 }

 const handleEditClick = () => {
  // history.push(`/job/new/${job_id}`)                             
  history.push(`/job/new?job_id=${job_id}`);
}
  return (
    <div
      className="m-5 main-quates"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <div className="">
        <div className="d-flex justify-content-between  flex-md-nowrap align-items-center py-4">
          <Dropdown className="btn-toolbar">
            <Dropdown.Toggle
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
              style={{backgroundColor:"#e91818",border:"none"}}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              Back To
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
              <Dropdown.Item
                onClick={() => {
                  history.push(Routes.JobDashboard.path);
                }}
                className="fw-bold"
              >
                <FontAwesomeIcon icon={faAddressBook} className="me-2" /> Job
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link}>
              <Button variant="outline-primary" size="sm" style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
              {/* <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faEllipsisH} className="me-2" /> Settings
              </Dropdown.Item>*/}
              <Dropdown.Item className="fw-bold" onClick={handleEditClick}>
                <FontAwesomeIcon icon={faPencilAlt} className="text-dark me-2" />{" "}
                Edit Job
              </Dropdown.Item>
              <Dropdown.Divider />
  
              <Dropdown.Item className="fw-bold" onClick={handleConverinvoice}>
                <FontAwesomeIcon icon={faCogs} className="text-danger me-2" />{" "}
                Convert to Invoice
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="main-sec">
        <div className="three-items d-flex justify-content-end">
          {/* <div className="first-two-items d-flex">
            <img alt="" />
            <p>Draft</p>
          </div> */}
          <div className="first-two-items d-flex">
            <h5>Job #1</h5>
          </div>
        </div>

        <hr />
        {jobDetails && jobDetails.data && jobDetails.data.length > 0 && (
          <div className="copy-section">
            <div className="req  d-flex justify-content-between ">
              <div className="firs-sec">
                <div className="main-heading d-flex namelead">
                  <h1 className="">{jobDetails.data[0]?.clientData?.first_name} {jobDetails.data[0]?.clientData?.last_name}</h1>
                  {/* <img src={link} alt="" />
                <span className="mt-1 text-bold ">Lead</span> */}
                </div>
                <div className="sub-head">
                  <p className="py-4">
                    {/* <h5 className="mb-2">Company Hr</h5> */}
                    {jobDetails.data[0]?.job_title}
                  </p>
                </div>
                <Row>
                  <Col md={6} lg={6} sm={12}>
                    <div className="sub-head propertyad">
                      <p>
                        <h5>Property Detail</h5>
                        <p>
                          {" "}
                          <div>{jobDetails.data[0]?.propertyData?.street_1}</div>
                          <div>{jobDetails.data[0]?.propertyData?.street_2}</div>
                          <div>{jobDetails.data[0]?.propertyData?.city}, {jobDetails.data[0]?.propertyData?.state}, {jobDetails.data[0]?.propertyData?.zipcode}</div>
                          <div>{jobDetails.data[0]?.propertyData?.country}</div>
                        </p>
                      </p>
                    </div>
                  </Col>
                  <Col md={6} lg={6} sm={12} className="email">
                    <div className="sub-head">
                      <p>
                        {" "}
                        <h5>Contact Details</h5>
                      </p>
                      <p>{jobDetails.data[0]?.clientData?.phone}</p>
                      <p>{jobDetails.data[0]?.clientData?.email}</p>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="box m-4">
                <Card style={{ padding: "20px", width: "100%" }}>
                  <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Contact info
                  </div>

                  <div className="table-responsive">
                    <table
                      className="table"
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      <tbody>
                        <tr style={{ borderTop: "2px solid #d9dfe1" }}>
                          <th className="text-start">Company name</th>
                          <td className="text-end">{jobDetails.data[0]?.clientData?.company_name}</td>
                        </tr>
                        <tr style={{ borderTop: "2px solid #d9dfe1" }}>
                          <th className="text-start">Number</th>
                          <td className="text-end">{jobDetails.data[0]?.clientData?.phone}</td>
                        </tr>
                        <tr style={{ borderTop: "2px solid #d9dfe1" }}>
                          <th className="text-start">Email</th>
                          <td className="text-end">{jobDetails.data[0]?.clientData?.email}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="row">
        <Col>
          <div className="py-4">
            <div className="d-flex align-items-center mt-3">
              {showText ? (
                <div>
                  <button
                    className="btn btn-primary ms-2"
                    style={{
                      color: "black",
                      backgroundColor: "transparent",
                      border: "none",
                      transition: "color 0.3s ease, background-color 0.3s ease",
                    }}
                    onClick={() => setShowText(false)}
                  >
                    Hide Profitability
                    <span style={{ paddingLeft: "10px" }}>
                      <FontAwesomeIcon icon={faChevronDown} className="me-2" />
                    </span>
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary ms-2"
                  style={{
                    color: "black",
                    backgroundColor: "transparent",
                    border: "none",
                    transition: "color 0.3s ease, background-color 0.3s ease",
                  }}
                // onClick={handleClick}
                >
                  Show Profitability
                  <span style={{ paddingLeft: "10px" }}>
                    <FontAwesomeIcon icon={faChevronDown} className="me-2" />
                  </span>
                </button>
              )}
            </div>
            {showText && (
              <>
                {" "}
                <div className="p-3">
                  <p>0% Profit margin</p>
                </div>
              </>
            )}
          </div>
        </Col>
      </div>
      <div className="row">
        <Col>
          <div className="py-4">
            <Card className="p-3">
              <div className="d-flex line-item-head justify-content-between align-items-center p-3">
                <h5
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "black",
                    margin: 0,
                  }}
                >
                  Line Items
                </h5>
                <Form onSubmit={(event) => addNewRow(event)}>
                <button
                  style={{
                    padding: "8px 16px",
                    // backgroundColor: "#262B40",
                    // color: "white",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    backgroundColor:colors.red,color:colors.white
                  }}
                  type="submit"
                  
                >
                  New Line Item
                </button>
                </Form>
              </div>

              <div>
                <div className="d-flex align-items-center mt-3 p-3">
                  <div className="table-responsive">
                  <div >
                  <Table
                    className="table table-bordered"
                    style={{
                      borderCollapse: "collapse",
                      border: "1px solid #ddd",
                    }}
                  >
                    <tbody>
                      {entries?.map((entry, index) => (
                        <tr key={index}>
                          <td>
                            <select
                              value={entry.name}
                              onChange={(e) => updateEntry(e, index)}
                              name="name"
                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '200px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            >
                              <option value="" disabled>Select Name</option>
                              {products.map((product) => (
                                <option key={product._id} name={"name"} value={product.product_name}>
                                  {product.product_name}
                                </option>
                              ))}
                           
                            </select>
                            <textarea class="form-control" id="floatingTextarea" style={{  width: "200px",}}       value={entry.description}
                              onChange={(e) => updateEntry(e, index)}
                              name="description"></textarea>

                          </td>
                          {/* <td>
                            <input
                              type="text"
                              value={entry.description}
                              onChange={(e) => updateEntry(e, index)}
                              name="description"
                              placeholder="Description"
                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '150px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            />
                          </td> */}


                          <td>
                            <input
                              type="text"
                              value={entry.part_qty}
                              onChange={(e) => updateEntry(e, index)}
                              name="part_qty"
                              placeholder="Quantity"
                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '150px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={entry.part_price}
                              onChange={(e) => updateEntry(e, index)}
                              name="part_price"
                              placeholder="Unit Price"
                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '150px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={entry.part_qty * entry.part_price || ''}
                              readOnly
                              placeholder="Total"
                              onChange={(e) => updateEntry(e, index)}
                              name="total_amount"

                              className="form-control mb-2 mr-sm-2"
                              style={{
                                borderRadius: '5px',
                                padding: '10px',
                                border: '1px solid #ced4da',
                                width: '150px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                              }}
                            />
                          </td>
                          <td>
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => deleteEntry(index)}
                              style={{ fontSize: "20px" }}
                              className="mt-2"
                            />
                          </td>
                        </tr>
                      ))}
                     
                    </tbody>
                  </Table>
                </div>
                
                  </div>
                  
                </div>
                <Row className="my-3">
                <Col xs={8}>
                  
                </Col>
                <Col xs={2}>
                  <p>Grand Total</p>
                 
                </Col>
                <Col xs={2} className='text-right'>
                  <p>{calculateTotalPartPrice()}</p>

                </Col>
              </Row>
              </div>
            </Card>
          </div>
        </Col>
      </div>
      {/* <div className="row">
        <Col>
          <div className="py-4">
            <Card className="p-3">
              <div className="d-flex Labour-head justify-content-between align-items-center p-3">
                <h5
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "black",
                    margin: 0,
                  }}
                >
                  Labour
                </h5>
                <button
                  style={{
                    padding: "8px 16px",
                    // backgroundColor: "#262B40",
                    // color: "white",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    backgroundColor:colors.red,color:colors.white
                  }}
                >
                  New Time Entry
                </button>
              </div>

              <div>
                <div className="d-flex align-items-center mt-3 p-3">
                  <div>
                    <p>
                      Time tracked to this job by you or your team will show
                      here
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Col>
      </div>
      <div className="row">
        <Col>
          <div className="py-4">
            <Card className="p-3">
              <div className="d-flex Expenses-head justify-content-between align-items-center p-3">
                <h5
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "black",
                    margin: 0,
                  }}
                >
                  Expenses
                </h5>
                <button
                  style={{
                    padding: "8px 16px",
                    // backgroundColor: "#262B40",
                    // color: "white",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    backgroundColor:colors.red,color:colors.white
                  }}
                >
                  New Expenses
                </button>
              </div>

              <div>
                <div className="d-flex align-items-center mt-3 p-3">
                  <div>
                    <p>
                      Get an accurate picture of various job costs by recording
                      expenses
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Col>
      </div>
      <div className="row">
        <Col>
          <div className="py-4">
            <Card className="p-3">
              <div className="d-flex Visits-head justify-content-between align-items-center p-3">
                <h5
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "black",
                    margin: 0,
                  }}
                >
                  Visits
                </h5>
                <button
                  style={{
                    padding: "8px 16px",
                    // backgroundColor: "#262B40",
                    // color: "white",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    backgroundColor:colors.red,color:colors.white
                  }}
                >
                  New Visit
                </button>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="">
                    <tr>
                      <th>Completed Today</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Dec 20, 2023 08:00</td>
                      <td>Job Title</td>
                      <td>Assigned to Temp User</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        </Col>
      </div> */}
      {/* <div className="row">
        <Col>
          <div className="py-4">
            <Card className="p-3">
              <div className="d-flex Billing-head justify-content-between align-items-center p-3">
                <h5
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "black",
                    margin: 0,
                  }}
                >
                  Billing
                </h5>
                <button
                  style={{
                    padding: "8px 16px",
                    // backgroundColor: "#262B40",
                    // color: "white",
                    border: "none",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    backgroundColor:colors.red,color:colors.white
                  }}
                >
                  New
                </button>
              </div>

              <div>
                <div className="d-flex billing-content-round align-items-center p-3">
                  <div
                    className="p-3 rounded-circle d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#eef0f2",
                      // border: "1px solid #3ead92",
                      width: "60px", // Adjust width and height for responsiveness
                      height: "60px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {" "}
                      No billing history{" "}
                    </span>{" "}
                    <br />
                    <span>This client hasn't been billed yet</span>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Col>
      </div> */}
      <div className="row">
        <Card>
          <Card.Body>
            <Row className="mb-2">
              <Col>
                <Form.Label>Internal notes & attachments</Form.Label>
                <InputGroup>
                   <InputGroup className="my-3">
      <p style={{ fontSize: '16px',  color: 'gray'}}>{jobDetails?.data[0]?.internal_notes_detais}</p>
    </InputGroup>
                </InputGroup>
              </Col>
            </Row>
            {/* <Row style={{ marginTop: "10px" }}>
              <Col>
                <div
                  style={{ border: "1px dashed #000" }}
                  className="mb-3 my-3"
                >
                  <input
                    type="file"
                    className="form-control"
                    aria-label="file example"
                    required
                    multiple // Enable multiple file selection
                  />
                  <div className="invalid-feedback">
                    Example invalid form file feedback
                  </div>
                </div>
              </Col>
            </Row> */}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default JobDetail;
