import React, { useState } from 'react';
import { Pagination, Form ,Spinner} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencilAlt, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'; // Make sure to import the necessary icons
import { useHistory } from 'react-router-dom';

const ClientTable = ({ userClients = [], deleteRow, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState('first_name');
  const [sortOrder, setSortOrder] = useState('asc');

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const sortedItems = Array.isArray(userClients)
    ? [...userClients].sort((a, b) =>
        sortOrder === 'asc'
          ? a[sortColumn].localeCompare(b[sortColumn])
          : b[sortColumn].localeCompare(a[sortColumn])
      )
    : [];

  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const history = useHistory();

  const handleSort = (column) => {
    // Toggle sorting order when the user clicks on the column header
    if (column === sortColumn) {
      setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const handleNewRequest = () => {
      history.push(`/client/new/${rowData?.client_id}`);
    };

    const handleSelect = () => {
      deleteRow(rowData._id);
    };

    return (
      <td className='icon-tr'>
        <FontAwesomeIcon
          style={{ cursor: 'pointer', marginRight: '10px' }}
          onClick={handleNewRequest}
          icon={faPencilAlt}
        />

        <FontAwesomeIcon
          style={{ cursor: 'pointer', marginLeft: '10px' }}
          onClick={handleSelect}
          icon={faTrash}
        />
      </td>
    );
  };

  const handleRowClick = (rowData) => {
    const clientId = rowData.client_id;
    localStorage.setItem('selectedClientId', clientId);
    history.push(`/properties/${clientId}`);
  };

  const renderTableRows = () => {
    return currentItems.map((rowData) => (
      <tr key={rowData?.client_id} >
        <td onClick={() => handleRowClick(rowData)}>{rowData?.first_name} {rowData?.last_name}</td>
        <td onClick={() => handleRowClick(rowData)}>{rowData?.company_name}</td>
        <td onClick={() => handleRowClick(rowData)}>{rowData?.phone}</td>
        <ActionCell rowData={rowData} dataKey="client_id" />
      </tr>
    ));
  };

  // const handlePaginationClick = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  
  // };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);
  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
      <section className="ftco-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              
              <div className="table-wrap">
                <table className="table table-striped">
                  <thead style={{borderTop:"1px solid black"}}>
                    <tr>
                      <th onClick={() => handleSort('first_name')}>
                        Name
                        <span style={{marginLeft:"10px"}}>
                          {sortOrder === 'asc' ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </span>
                      </th>
                      <th onClick={() => handleSort('company_name')}>
                        Company Name
                        <span style={{marginLeft:"10px"}}>
                          {sortOrder === 'asc' ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </span>
                      </th>
                      <th onClick={() => handleSort('phone')}>
                        Phone
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentItems) && currentItems.length > 0 ? (
                      renderTableRows()
                    ) : (
                      <tr>
                        <td colSpan="4">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination-table d-flex justify-content-between">
                <div>
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePaginationClick(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from({ length: totalPages }, (_, index) => (
                      <Pagination.Item
                        key={index}
                        active={index + 1 === currentPage}
                        onClick={() => handlePaginationClick(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePaginationClick(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
                <div className="drop-count col-2">
                  <Form.Group
                    controlId="itemsPerPageSelect"
                    className="mb-3 d-flex align-items-center"
                  >
                    <Form.Label>Show</Form.Label>
                    <Form.Control
                      as="select"
                      className='mx-2'
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ width: "30%" }}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      )}
    </div>
  );
};

export default ClientTable;
