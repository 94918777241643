import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
// import "../properties/Properties.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCashRegister,
  faChartLine,
  faCloudUploadAlt,
  faPlus,
  faRocket,
  faTasks,
  faUserShield,
  faEnvelope,
  faArrowLeft,
  faAddressBook,
  faPen,
  faEllipsisH,
  faBuilding,
  faLocationArrow,
  faChevronDown,
  faFileInvoice,
  faDownload,
  faBriefcase,
  faEquals,
  faCalendar,
  faTag,
  faCreditCard,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Nav, Form
} from "@themesberg/react-bootstrap";
import { Table } from "rsuite";
import { Routes } from "../routes";
import axiosInstance from "../config/AxiosInstance";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "universal-cookie";
import {colors} from "../config/colors";

const Properties = ({ userClients }) => {
  const { clientId } = useParams();
  const [requests, setRequests] = useState([]);
  const [quotes, setquotes] = useState([]);
  const [jobs, setjobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoices, setinvoices] = useState([])

  const [selectedColumn, setSelectedColumn] = useState(null);
  // console.log(quotes[0]?.createdAt, "ok");
  // console.log(quotes[2]?.product_servise[0]?.total_amount, "ok");
  const handleColumnClick = (columnId) => {
    setSelectedColumn(columnId);
  };

  const [activeTab, setActiveTab] = React.useState(1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const TabControl = () => { };

  const getBorderStyle = (column) => {
    return {
      borderBottom: selectedColumn === column ? "2px solid green" : "none",
    };
  };

  const history = useHistory();
  const [properties, setProperties] = useState([]);
  const [clientData, setClientData] = useState([]);


  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const response = await axiosInstance.get(
        `/properties/properties_data/${clientId}`
      );
      setProperties(response.data.data); // Set properties with the array from response.data.data
      // console.log(
      //   "response.data.data in properties",
      //   `/properties/properties_data/${clientId}`,
      //   response.data
      // );
      const response2 = await axiosInstance.get(
        `/client/client_record/${clientId}`
      );
      setClientData(response2.data.data[0]); // Set properties with the array from response.data.data
    } catch (error) {
      console.log("error in fetchdata in company details:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data (whether success or error)
    }
  };
  const fetchRequests = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(
        `/request/request_data/${clientId}`
      );
      setRequests(response.data.data);
      // console.log("dhruvi", response.data.data);
    } catch (error) {
      console.error("Error fetching requests:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchQuotes = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(
        `/quotes/quotes_client/${clientId}`
      );
      setquotes(response.data.data);
    } catch (error) {
      console.error("Error fetching quotes:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchJob = async () => {
    try {
      const response = await fetch(`https://jobmanager.cloudpress.host/api/job/job_client/${clientId}`);


      if (response.ok) {
        const result = await response.json();
        setjobs(result.data);
      } else {
        const errorData = await response.json();
        console.log("errro");
      }
    } catch (error) {
      // setError(`Error: ${error.message}`);
    }
  };
  const fetchInvoices = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(
        `/invoice/invoice_client/${clientId}`
      );
      setinvoices(response.data.data);
    } catch (error) {
      console.error("Error fetching quotes:", error);
    } finally {
      setLoading(false);
    }
  };

  const ScrollableTable = ({ quotes }) => {
    return (
      <div style={{ maxHeight: "40px", overflowY: "auto" }}>
        <table className="table table-hover" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Job Title</th>
              <th>Location</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {quotes.length === 0 || quotes === undefined ? (
              <tr>
                <td colSpan={4} className="text-center text-dark text-bold">
                  No Requests found
                </td>
              </tr>
            ) : (
              quotes.map((quote, index) => (
                <tr key={index}>
                  <td>
                    {new Intl.DateTimeFormat("en-GB").format(
                      new Date(quote.createdAt)
                    )}
                  </td>
                  <td>{quote.job_title}</td>
                  <td>
                    {quote.property.city + ", " + quote.property.state}
                    <br />
                    {quote.property.zipcode +
                      ", " +
                      quote.property.country +
                      "."}
                  </td>
                  <td>{quote.total_amount}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    fetchData();
    fetchRequests();
    fetchQuotes();
    fetchJob();
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendMail = async (email) => {
    try {
      console.log("Email to be sent:", email);
      toast.promise(axiosInstance.post("/client/client_mail", { email }), {
        loading: "Sending email...",
        success: (response) => {
          console.log("API Response:", response);
          if (response.data.statusCode === 200) {
            return "Email sent successfully!";
          } else {
            alert("server error!");

            return null;
          }
        },

        error: (error) => {
          console.error("API Error:", error);
          return "Failed to Send Email..!";
        },
      });
    } catch (error) {
      console.error("Error in handleSendMail:", error);
      toast.error(error.message);
    }
  };

  const handleRowClick = () => {
    history.push(`/properties/newproperties/form/${clientId}`);
  };
  const cookies = new Cookies();
  const [tag, setTag] = useState([]);
  console.log(tag, "tag t")
  const [newTag, setNewTag] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [userData, setUserData] = useState(null);

  const fetchTag = async () => {
    try {
      const res = await axiosInstance.get(`https://jobmanager.cloudpress.host/api/tag/tag/${clientId}`);
      console.log('api call');

      if (res.data.statusCode === 200) {
        setTag(res.data.data);
        console.log('Fetched Tag:', res.data);


      } else {
        console.log('Error fetching tag:', res.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    fetchTag();
  }, [clientId]);


  // console.log(tag, 'tag is =====  ');

  useEffect(() => {
    setUserData(cookies.get('userDetails'));
  }, []);

  const handleAddTagClick = () => {
    setShowInput(true);
  };
  const handleAddButtonClick = async () => {
    console.log(newTag, "yash")
    if (newTag.trim() !== '') {
      try {
        const userId = userData?.user_id || 'defaultUserId';

        const postData = {
          client_id: clientId,
          user_id: userId,
          tag: newTag.trim(),
        };

        const res = await axiosInstance.post('https://jobmanager.cloudpress.host/api/tag/tag', postData);

        if (res.data.statusCode === 200) {
          setNewTag('');
          setShowInput(false);

          console.log('Posted Data:', postData);
        } else {
          alert('Error is ' + res.data.message);
          console.log('Error is ', res.data.message);
        }
        fetchTag();
      } catch (error) {
        console.error('Error:', error);
        fetchTag();
      }
    }
  };
  const [loadingDelete, setLoadingDelete] = useState(false);
  const deleteTag = async (tag_id) => {
    try {
      setLoadingDelete(true);

      const url = `https://jobmanager.cloudpress.host/api/tag/tag/${tag_id}`;
      const response = await fetch(url, {
        method: 'DELETE',
      });

      if (response.ok) {
        console.log(`Tag with ID ${tag_id} deleted successfully`);

        setTag([]);
        fetchTag();

      } else {
        console.error('Error deleting tag:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <>
      <div
        className="main"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          padding: "20px",
          borderRadius: "10px",
          padding: "20px",
          margin: "auto",
        }}
      >
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <Dropdown className="btn-toolbar">
            <Dropdown.Toggle as={Button} variant="primary" size="sm">
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              Back To
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
              <Dropdown.Item
                onClick={() => {
                  history.push(Routes.ClientDashBoard.path);
                }}
                className="fw-bold"
              >
                <FontAwesomeIcon icon={faAddressBook} className="me-2" /> Client
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div className="d-flex gap-3">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link}>
                <Button variant="outline-primary" size="sm">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                {/* <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faEllipsisH} className="me-2" /> Settings
              </Dropdown.Item>

              <Dropdown.Divider /> */}

                <Dropdown.Item
                  className="fw-bold"
                  onClick={() => {
                    const userData = cookies.get("userDetails");
                    if (userData) {
                      handleSendMail(clientData?.email);
                    } else {
                      toast.error("please try again later!");
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMailBulk}
                    className="text-danger me-2"
                  />{" "}
                  Send Mail
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="container-fluid">
          <div class="row">
            <div
              className="col-md-12 order-md-1"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                width: "100%",
                padding: "10px",
                marginBottom: "20px",
                borderTopRightRadius: "20px",
                borderBottomLeftRadius: "20px",
              }}
            >
              <div className="d-flex align-items-center">
                <div
                  className="p-3 bg-dark rounded-circle d-flex justify-content-center align-items-center"
                  style={{
                    width: "60px",
                    height: "60px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBuilding}
                    style={{ color: "white" }}
                  />
                </div>
                <div
                  style={{
                    paddingLeft: "20px",
                    fontSize: "35px",
                    fontWeight: "bold",
                  }}
                  className="text-dark"
                >
                  {clientData.company_name}
                </div>
              </div>
              <div className="table-responsive" style={{ padding: "20px" }}>
                <table
                  className="table table-hover "
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <thead>
                    <tr className="table-active">
                      <th
                        scope=""
                        className="text-left"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Properties
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th scope="" className="text-right">
                        <Button
                          className="float-right"
                          variant="primary"
                          size="sm"
                          onClick={(rowData) => handleRowClick(rowData)}
                          style={{backgroundColor:colors.red,color:colors.white,border:"none"}}
                        >
                          + Add Properties
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {properties?.length == 0 || properties == undefined ? (
                      <tr>
                        <td
                          colSpan={5}
                          className="text-center text-dark text-bold"
                        >
                          No Properties found
                        </td>
                      </tr>
                    ) : (
                      properties?.map((property, index) => (
                        <tr key={index}>
                          <td>{property.street_1}</td>
                          <td>{property.street_2}</td>
                          <td>{property.city}</td>
                          <td>{property.state}</td>
                          <td>{property.zipcode}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="table-responsive" style={{ height: "auto", padding: "20px" }}>
                <table
                  class="table table-hover"
                  style={{
                    width: "100%",
                    marginTop: "50px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <thead>
                    <tr class="table-active">
                      <th
                        scope="col-12"
                        className="text-left"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        Overview
                      </th>{" "}
                      <th></th>
                      <th></th>
                      <th></th>
                      <th scope="col" className="text-right">
                        <Dropdown drop="start">
                          <Dropdown.Toggle
                            as={Button}
                            variant="primary"
                            size="sm"
                            className="me-2"
                          >
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              className="me-2"
                            />
                            New
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="dashboard-dropdown dropdown-menu-left mt-2"
                            style={{ marginLeft: "30px" }}
                          >
                            <Dropdown.Item
                              className="fw-bold"
                              onClick={() =>
                                history.push(Routes.NewRequest.path)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faDownload}
                                className="me-2"
                                style={{ color: "#556acb" }}
                              />
                              Request
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="fw-bold"
                              onClick={() =>
                                history.push(Routes.NewQuotes.path)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faEquals}
                                className="me-2"
                                style={{ color: "#b36096" }}
                              />{" "}
                              Quote
                            </Dropdown.Item>
                            <Dropdown.Item className="fw-bold">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="me-2"
                                style={{ color: "#bbc520" }}
                              />{" "}
                              Job
                            </Dropdown.Item>
                            <Dropdown.Item className="fw-bold">
                              <FontAwesomeIcon
                                icon={faFileInvoice}
                                className="me-2"
                                style={{ color: "#7db00e" }}
                              />{" "}
                              Invoice
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </th>
                    </tr>
                  </thead>
                </table>
                <div
                  style={{
                    fontFamily: "Arial, sans-serif",
                    borderTop: "1px solid black",
                    marginTop: "-18px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      // marginBottom: "10px",
                    }}
                  >
                    <button
                      onClick={() => handleTabClick(1)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor: activeTab === 1 ? "#EBEBED" : "white",
                        color: activeTab === 1 ? "black" : "gray",
                        cursor: "pointer",
                        marginBottom: "10px", // Add some space between buttons
                      }}
                    >
                      Active Work
                    </button>
                    <button
                      onClick={() => handleTabClick(2)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor: activeTab === 2 ? "#EBEBED" : "white",
                        color: activeTab === 2 ? "black" : "gray",
                        cursor: "pointer",
                        // marginBottom: "10px",
                      }}
                    >
                      Requests
                    </button>
                    <button
                      onClick={() => handleTabClick(3)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor: activeTab === 3 ? "#EBEBED" : "white",
                        color: activeTab === 3 ? "black" : "gray",
                        cursor: "pointer",
                        // marginBottom: "10px",
                      }}
                    >
                      Quotes
                    </button>
                    <button
                      onClick={() => handleTabClick(4)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor: activeTab === 4 ? "#EBEBED" : "white",
                        color: activeTab === 4 ? "black" : "gray",
                        cursor: "pointer",
                        // marginBottom: "10px",
                      }}
                    >
                      Jobs
                    </button>
                    <button
                      onClick={() => handleTabClick(5)}
                      style={{
                        flex: "1",
                        padding: "10px 15px",
                        border: "none",
                        borderRadius: "5px 5px 0 0",
                        backgroundColor: activeTab === 5 ? "#EBEBED" : "white",
                        color: activeTab === 5 ? "black" : "gray",
                        cursor: "pointer",
                        // marginBottom: "10px",
                      }}
                    >
                      Invoices
                    </button>
                  </div>

                  <div>
                    {activeTab === 1 && (
                      <div style={{ textAlign: "center" }}>
                        No activework found{" "}
                        <hr style={{ width: "100%", margin: "10px auto" }} />
                      </div>
                    )}

                    {activeTab === 2 && (
                      <div style={{ overflowY: "scroll", }}>
                        <table
                          className="table table-hover"
                          style={{ width: "100%", }}
                        >
                          <tr >
                            <th>Number</th>
                            <th>Status</th>
                            <th>Requested Date</th>
                            <th>Address</th>



                          </tr>
                          <tbody className="col-12">
                            {requests.length === 0 || requests === undefined ? (
                              <tr>
                                <td
                                  colSpan={2}
                                  className="text-center text-dark text-bold"
                                >
                                  No Requests found
                                </td>
                              </tr>
                            ) : (
                              requests.map((request, index) => (
                                <tr
                                  key={index}
                                  onClick={() => {
                                    history.push(
                                      `/request/overview/${request.request_id}`
                                    );
                                  }}
                                >
                                  <td >{`Request #${index + 1} `}</td>

                                  <td >Request</td>
                                  <td>
                                    {/* <div style={{ marginBottom: "5px" }}>REQUESTED ON</div> */}
                                    <div>
                                      {request.request_date
                                        ? new Intl.DateTimeFormat('en-US', {
                                          month: 'short',
                                          day: 'numeric',
                                          year: 'numeric',
                                        }).format(new Date(request.request_date.substring(0, 10)))
                                        : ''}
                                    </div>
                                  </td>

                                  <td >
                                    {request.property.city + ", " + request.property.state}
                                    <br />
                                    {request.property.zipcode + (request?.property?.country ? ", " + request.property.country : ".")}
                                  </td>
                                  {/* Render other request properties as needed */}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {activeTab === 3 && (
                      <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                        <table
                          className="table table-hover"
                          style={{ width: "100%" }}
                        >
                          <tr>
                            <th> number</th>
                            <th> Date</th>
                            <th>Address</th>
                            <th>Total</th>
                          </tr>
                          <tbody>
                            {quotes.length === 0 || quotes === undefined ? (
                              <tr>
                                <td
                                  colSpan={4}
                                  className="text-center text-dark text-bold"
                                >
                                  No Requests found
                                </td>
                              </tr>
                            ) : (
                              quotes.map((quote, index) => (
                                <tr
                                  key={index}
                                  onClick={() =>
                                    history.push(
                                      `/quotes/overview/${quote.quotes_id}`
                                    )
                                  }
                                >
                                  <td>{`Quote #${index + 1} `}</td>
                                  <td>
                                    {quote.createdAt
                                      ? new Intl.DateTimeFormat('en-GB', {
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric',
                                      }).format(new Date(quote.createdAt))
                                      : ''}
                                  </td>

                                  {/* Render other quote properties as needed */}
                                  <td>
                                    {quote.property.city + ", " + quote.property.state}
                                    <br />
                                    {quote.property.zipcode + (quote?.property?.country ? ", " + quote.property.country : ".")}
                                  </td>

                                  <td>{quote.product_servise_total ? `$ ${quote.product_servise_total}` : ''}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {activeTab === 4 && (
                      <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                        <table className="table table-hover" style={{ width: "100%" }}>
                          <tr>
                            <th> number</th>
                            <th> Subject</th>
                            <th>Date</th>
                            <th>Address</th>
                            <th>Total </th>

                          </tr>
                          <tbody>
                            {jobs.length === 0 || jobs === undefined ? (
                              <tr>
                                <td colSpan={3} className="text-center text-dark text-bold">
                                  No Requests found
                                </td>
                              </tr>
                            ) : (
                              jobs.map((job, index) => (
                                <tr
                                  key={index}
                                  onClick={() =>
                                    history.push(`/job/jobdetail/${job.job_id}`)
                                  }
                                >

                                  <td>{`Job #${index + 1} `}</td>
                                  <td>{job.job_title}</td>

                                  <td>
                                    {/* <div style={{ marginBottom: "5px" }}>SCHEDULE FOR</div> */}
                                    <div>
                                      {job.schedule_start_date
                                        ? new Intl.DateTimeFormat('en-US', {
                                          month: 'short',
                                          day: 'numeric',
                                          year: 'numeric',
                                        }).format(new Date(job.schedule_start_date))
                                        : ''}
                                    </div>
                                  </td>



                                  <td>
                                    {job.property.city + ", " + job.property.state}
                                    <br />
                                    {job.property.zipcode + (job?.property?.country ? ", " + job.property.country : ".")}
                                  </td>


                                  <td>{job.line_items_total ? `$ ${job.line_items_total}` : ''}</td>

                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {activeTab === 5 && (
                      <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                        <table className="table table-hover" style={{ width: "100%" }}>
                          <tr>
                            <th> number</th>
                            <th> Subject</th>
                            <th>Date</th>
                            <th>Total </th>

                          </tr>
                          <tbody>
                            {invoices.length === 0 || invoices === undefined ? (
                              <tr>
                                <td colSpan={4} className="text-center text-dark text-bold">
                                  No Requests found
                                </td>
                              </tr>
                            ) : (
                              invoices.map((invoice, index) => (
                                <tr
                                  key={index}
                                  onClick={() =>
                                    history.push(`/invoice/overview/${invoice.invoice_id}`)
                                  }
                                >
                                  <td>{`Invoice #${index + 1} `}</td>
                                  <td>{invoice.invoice_subject}</td>
                                  <td>
                                    {/* <div style={{ marginBottom: "5px" }}>SCHEDULE FOR</div> */}
                                    <div>
                                      {new Intl.DateTimeFormat('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric',
                                      }).format(new Date(invoice.invoice_issued_date))}
                                    </div>                                  </td>
                                  <td>{invoice.product_servise_total ? `$ ${invoice.product_servise_total}` : ''}</td>

                                </tr>

                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}

                  </div>
                </div>
              </div>

              {/* ===================================================do not remove this code============================================ */}

              {/* <div>
                <div className="table-responsive" style={{ padding: "20px" }}>
                  <table
                    class="table table-hover "
                    style={{
                      marginTop: "50px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <thead>
                      <tr class="table-active">
                        <th
                          scope="col"
                          className="text-left"
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          Schedule
                        </th>

                        <th scope="col" className="text-right">
                          <Dropdown>
                            <Dropdown.Toggle
                              as={Button}
                              variant="primary"
                              size="sm"
                              className="me-2"
                            >
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                className="me-2"
                              />
                              New
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
                              <Dropdown.Item className="fw-bold">
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  className="me-2"
                                  style={{ color: "#556acb" }}
                                />
                                Request
                              </Dropdown.Item>
                              <Dropdown.Item className="fw-bold">
                                <FontAwesomeIcon
                                  icon={faTasks}
                                  className="me-2"
                                  style={{ color: "#b6a96" }}
                                />{" "}
                                Task
                              </Dropdown.Item>
                              <Dropdown.Item className="fw-bold">
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  className="me-2"
                                  style={{ color: "#ddc30f" }}
                                />{" "}
                                Calender Event
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div
                              className="p-3 rounded-circle d-flex justify-content-center align-items-center"
                              style={{
                                backgroundColor: "#eef0f2",
                                // border: "1px solid #3ead92",
                                width: "60px", // Adjust width and height for responsiveness
                                height: "60px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCalendar}
                                style={{ color: "black" }}
                              />
                            </div>
                            <div
                              style={{
                                paddingLeft: "20px",
                              }}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "20px" }}
                              >
                                {" "}
                                No scheduled items{" "}
                              </span>{" "}
                              <br />
                              <span>
                                Nothing is scheduled for this client yet
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
            </div>

            <div
              className="col-md-4 order-md-2 p-3"
              style={{
                // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderTopRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                width: "100%",
                padding: "20px",
                marginBottom: "20px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <div
                style={{
                  paddingLeft: "20px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Contact info
              </div>
              <div className="table-responsive" style={{ padding: "20px" }}>
                <table className="table " style={{
                  padding: "20px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}>
                  <tbody>
                    <tr style={{ borderTop: "2px solid #d9dfe1" }}>
                      <th>Name</th>
                      <td>
                        {clientData?.first_name + " " + clientData?.last_name}
                      </td>
                    </tr>
                    <tr style={{ borderTop: "2px solid #d9dfe1" }}>
                      <th>{clientData?.contact_info}</th>
                      <td>{clientData?.phone}</td>
                    </tr>
                    <tr style={{ borderTop: "2px solid #d9dfe1" }}>
                      <th>{clientData?.email_info}</th>
                      <td>{clientData?.email}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* ===================================================do not remove this code============================================ */}
              <div className="table-responsive" style={{ padding: "20px" }}>
                <table
                  class="table "
                  style={{
                    marginTop: "20px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <thead>
                    <tr class="table-active">
                      <th
                        scope="col"
                        className="text-left"
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Tags
                      </th>{" "}

                      <th scope="col" className="text-right">
                        {" "}
                        <Button
                          className="float-right"
                          variant="primary"
                          size="sm"
                          onClick={handleAddTagClick}
                          style={{backgroundColor:colors.red,color:colors.white,border:"none"}}
                        >
                          + Add Tag
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {showInput ? (
                          <div className="d-flex">
                            <Form.Control
                              type="text"
                              placeholder="Enter tag"
                              value={newTag}
                              onChange={(e) => setNewTag(e.target.value)}
                            />
                            <Button
                              className="float-right mx-2"
                              variant="primary"
                              size="sm"
                              onClick={handleAddButtonClick}
                            />
                            <Button className="float-right mx-5"
                          variant="primary"
                          size="sm" onClick={handleAddButtonClick}   style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
                              Add
                            </Button>
                          </div>
                        ) : (
                          <>
                            <div className="d-row" style={{ whiteSpace: 'normal' }}>
                              {tag.length > 0 ? (
                                tag.map((tagItem) => (
                                  <span
                                    key={tagItem.tag_id}
                                    style={{
                                      backgroundColor: 'gray',
                                      padding: '5px',
                                      margin: '2px 5px 2px 0',
                                      borderRadius: '5px',
                                      color: 'white',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {tagItem.tag}
                                    <button
                                      style={{
                                        backgroundColor: 'gray',
                                        cursor: 'pointer', // Optional: Show hand cursor on hover
                                      }}
                                      onClick={() => deleteTag(tagItem.tag_id)}
                                      className="mx-1"
                                      onMouseEnter={(e) => {
                                        e.target.style.backgroundColor = '#9e9e9e';
                                      }}
                                      onMouseLeave={(e) => {
                                        e.target.style.backgroundColor = 'gray';
                                      }}
                                    >
                                      X
                                    </button>
                                  </span>
                                ))
                              ) : loadingDelete ? (
                                <div>Loading...</div>
                              ) : (
                                'This client has no tag'
                              )}
                            </div>



                          </>
                        )}
                      </td>


                    </tr>
                  </tbody>
                </table>
              </div>
              {/* ===================================================do not remove this code============================================ */}

              {/* <div className="table-responsive" style={{ padding: "20px", }}>
                <table
                  class="table table-hover"
                  style={{
                    marginTop: "20px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <thead>
                    <tr class="table-active">
                      <th
                        scope="col"
                        className="text-left"
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Last client communication
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>You haven't sent any client communications yet</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              {/* ===================================================do not remove this code============================================ */}

              {/* <div className="table-responsive" style={{ padding: "20px" }}>
                <table class="table table-hover" style={{ marginTop: "50px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", }}>
                  <thead>
                    <tr class="table-active">
                      <th
                        scope="col"
                        className="text-left"
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Billing history
                      </th>{" "}
                      <th scope="col" className="text-right">
                        {" "}
                        <Button
                          className="float-right"
                          variant="primary"
                          size="sm"
                        >
                          New
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div
                            className="p-3 rounded-circle d-flex justify-content-center align-items-center"
                            style={{
                              backgroundColor: "#eef0f2",
                              width: "60px",
                              height: "60px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCreditCard}
                              style={{ color: "black" }}
                            />
                          </div>
                          <div
                            style={{
                              paddingLeft: "10px",
                            }}
                          >
                            <span
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            >
                              {" "}
                              No billing history{" "}
                            </span>{" "}
                            <br />
                            <span>This client hasn't been billed yet</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr class="table-active">
                      <th
                        scope="col"
                        className="text-left"
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Current balance
                      </th>
                      <th
                        scope="col"
                        className="text-left"
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        €0.00
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
};

export default Properties;
