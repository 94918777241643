import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
// import pencilcursor from "../assets/img/pencilcursor.png";
import axios from "axios";

import {
  Button,
  Form,
  Col,
  Row,
  InputGroup,
  FormControl,
  DropdownButton,
  Table,
  Dropdown,
  Modal,
  OverlayTrigger,
} from "@themesberg/react-bootstrap";
// import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SignatureCanvas from "react-signature-canvas";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import html2canvas from "html2canvas";
import "./style.css";
import axiosClientInstance from "../config/axiosClientInstance.js";
import {colors} from "../config/colors.js";
function QuotesDetails() {
  const { quotes_id, status } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const handleBtnBack = () => {
    history.goBack();
  };

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);

  const [changeText, setChangeText] = useState("");
  const [signatureRef, setSignatureRef] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [savedSignature, setSavedSignature] = useState(null);
  const [typedSignature, setTypedSignature] = useState("");

  const openModal = () => {
    setShowModal(true);
    setActiveButton("DrawSignature");
  };

  const closeModal = () => {
    setShowModal(false);
    setSignatureRef(null);
    setActiveButton(null);
  };

  const clearSignature = () => {
    signatureRef?.clear();
  };
  const [signatureSaved, setSignatureSaved] = useState(false);
  const [approveCount, setApproveCount] = useState(0);

  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const saveSignature = async () => {
    const labelElement = document.getElementById("typeSignatureLabel");
    let image = "";
    if (activeButton === "TypeSignature" && typedSignature && labelElement) {
      html2canvas(labelElement).then(async (canvas) => {
        // Get the data URL of the signature
        const signatureData = canvas.toDataURL();
        console.log(signatureData, "janak2");

        // Convert data URL to Blob
        const signatureBlob = dataURLtoBlob(signatureData);

        // Create a FormData object and append the signature Blob data
        const imageData = new FormData();
        imageData.append("files", signatureBlob, "signature.png"); // 'signature.png' is a filename for the signature

        const url = "https://jobmanager.cloudpress.host/api/images/upload";

        try {
          const result = await axios.post(url, imageData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(result.data.files[0].url, "imgs");
          setSavedSignature(signatureData);
          image = result.data.files[0].url;
        } catch (error) {
          console.error(error, "imgs");
        }
        closeModal();
      });
    } else if (activeButton === "DrawSignature" && signatureRef) {
      const signatureCanvas = signatureRef; // Ensure signatureRef points to the canvas

      const signatureDataURL = signatureCanvas.toDataURL();

      const signatureBlob = await fetch(signatureDataURL).then((res) =>
        res.blob()
      );

      const imageData = new FormData();
      imageData.append("files", signatureBlob, "signature.png");

      const url = `https://jobmanager.cloudpress.host/api/images/upload`;

      try {
        const result = await axios.post(url, imageData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(result.data.files[0].url, "imgs");
        setSavedSignature(signatureDataURL);
        image = result.data.files[0].url;
      } catch (error) {
        console.error(error, "imgs");
      }
      closeModal();
    } else {
      closeModal();
    }
    setSignatureSaved(true);
    try {
      const response = await axios.put(
        `https://jobmanager.cloudpress.host/api/quotes/quotes_status/${quoteData.quotes_id}`,
        {
          status: image !== "" ? "Approved" : "Awaiting response",
          signature: image,
        }
      );
      console.log(response.data.data, "jack");

      if (response.data.success) {
        // Update the local state to reflect the approved status
        fetchData();

        // Optionally, you can do something else after a successful status update
        console.log("Quote Status Updated Successfully!");
      } else {
        console.error("Failed to update quote status:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating quote status:", error);
    }
    fetchData();
  };

  const handleOpenModal = () => {
    setShowModal1(true);
  };

  const handleCloseModal = () => {
    setShowModal1(false);
  };

  const handleRequestChanges = () => {
    history.push("/client/quoterequestchange");
  };
  // const downloadPdf = () => {
  //     const cardElement = document.getElementById("quoteCard");

  //     if (cardElement) {
  //         html2pdf(cardElement);
  //     }
  // };
  const downloadPdf = () => {
    const pdf = new jsPDF();

    // Set initial y-coordinate
    let y = 10;

    // Add content to the PDF
    pdf.text(quoteData?.clientData?.company_name || "Temp Company", 10, y);
    y += 5;

    // Add quote details
    const tableintro = [
      // [`Quote#${quoteData?._id}`],
      [`Quote#2`],

      [`Sent on ${new Date(quoteData?.createdAt)?.toLocaleDateString()}`],
      // Add more rows as needed
    ];

    // Set column widths manually
    const columnWidths = [40]; // Adjust the width of the first column
    const theme = "striped"; // You can customize the theme if needed

    // Create autoTable for quote details
    pdf.autoTable({
      startY: y,
      head: [tableintro[0]],
      body: tableintro.slice(1),
      theme: theme,
      tableWidth: "auto",
      margin: { left: 10 }, // Set left margin to 0 to align with the left side
      columnStyles: {
        0: { cellWidth: columnWidths[0] },
      },
    });
    y += 25;

    // Increment y for the next content
    // y += pdf.previousAutoTable.finalY + 1;

    // Add customer details
    pdf.text(
      `Recipient: ${quoteData?.clientData?.first_name} ${quoteData?.clientData?.last_name}`,
      10,
      y
    );
    y += 10;
    pdf.text(
      `Address: ${quoteData?.propertyData?.city}, ${quoteData?.propertyData?.state} ${quoteData?.propertyData?.zipcode}`,
      10,
      y
    );
    y += 10;
    pdf.text(`Phone: ${quoteData?.clientData?.phone}`, 10, y);
    y += 10;

    // Add quote details
    const tableData = [
      ["Product/Service", "Description", "Qty", "Unit Price", "Total"],
      ...(quoteData && quoteData.product_servise
        ? quoteData.product_servise.map((product) => [
          product.name,
          product.description,
          product.part_qty,
          `€${product.part_price}`,
          `€${product.total_amount || 0}`,
        ])
        : []),
      // Add more rows as needed
    ];

    // Create autoTable for quote details
    pdf.autoTable({
      startY: y,
      head: [tableData[0]],
      body: tableData.slice(1),
      theme: "striped", // You can customize the theme if needed
      tableWidth: "auto", // Adjust the width automatically
      margin: { left: 10 }, // Set margin to align with the left side
    });
    y += 20;

    // Increment y for the next content
    // y += pdf.previousAutoTable.finalY + 1;

    // Add more content as needed...

    // Add total auto box on the right side
    pdf.autoTable({
      startY: y,
      head: [["Total"]],
      body: [
        [
          `€${quoteData?.product_servise
            ?.reduce(
              (total, product) => total + parseFloat(product.total_amount || 0),
              0
            )
            .toFixed(2)}`,
        ],
      ], // Updated total value to €10.10
      theme: "striped",
      tableWidth: "auto",
      margin: { left: 155 },
      columnStyles: { 0: { cellWidth: 40 } }, // Adjust the width of the total box
    });
    y += 10;
    // Add date
    pdf.text(
      `Date ${new Date(quoteData?.createdAt)?.toLocaleDateString()}`,
      10,
      y
    );
    y += 10;

    // Add saved signature
    if (savedSignature) {
      const signatureWidth = 50;
      const signatureHeight = 20;

      // Add border
      pdf.rect(10, y, signatureWidth, signatureHeight, "S");

      // Add image
      pdf.addImage(
        savedSignature,
        "PNG",
        10,
        y,
        signatureWidth,
        signatureHeight
      );

      // Adjust the position and size as needed
      y += signatureHeight;
    }

    y += 10; // Adjust the spacing after the signature

    // Add label after the signature
    pdf.text("Client Signature", 10, y);
    y += 15; // Adjust the spacing after the label
    const text1 = "Here will be some data";
    const text2 =
      "This quote is valid for the next 30 days, after which values may subject to change.";

    const fontSize1 = 10;
    const fontSize2 = 8;

    // Add the first text with word wrapping
    const text1Lines = pdf.splitTextToSize(
      text1,
      pdf.internal.pageSize.width - 20
    );
    pdf.text(text1Lines, 10, y, { fontSize: fontSize1 });

    // Adjust the y-coordinate for the next text
    y += text1Lines.length * fontSize1 + 1;

    // Add the second text with word wrapping and decreased font size
    const text2Lines = pdf.splitTextToSize(
      text2,
      pdf.internal.pageSize.width - 20
    );
    pdf.text(text2Lines, 10, y, { fontSize: fontSize2 });

    // Adjust the y-coordinate for the next content
    y += text2Lines.length * fontSize2 + 10;

    // Save the PDF
    pdf.save("quote.pdf");
  };

  const signatureAreaStyle = {
    backgroundColor: "#f2f2f2",
    width: "200px",
    height: "auto",
  };

  const [quoteData, setQuoteData] = useState();
  console.log(quoteData, "quoteData");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosClientInstance.get(
        `/quotes/quotes_get/${quotes_id}`
      );
      setQuoteData(response.data.data[0]);
      console.log(response.data.data, "repos");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Step 2: Set loading to false when data is received or an error occurs
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      {loading ? ( // Step 3: Conditionally render the loader
        <div className="loader-container">
          {/* Add your loader component or styling here */}
          <p>Loading...</p>
        </div>
      ) : (
        <Row className="d-flex">
          {/* <div className="d-flex justify-content-center">
                        <div className="d-flex justify-content"> */}
          <div
            className={`col-lg-8 d-flex maincard ${signatureSaved ? "full-width" : ""
              }`}
          >
            <div>
              <div className="d-flex justify-content-between">
                <Button
                  variant=""
                  style={{
                    minWidth: "50px",
                    // border: "2px solid black",
                    height: "44px",
                    marginRight: "10px",
                    marginTop: "10px",
                    color: "white",
                    backgroundColor:colors.red,
                  }}
                  onClick={handleBtnBack}
                >
                  <i className="fa-solid fa-arrow-left"></i> Back
                </Button>

                <Button
                  variant=""
                  style={{
                    minWidth: "50px",
                    // border: "2px solid black",
                    height: "44px",
                    marginRight: "10px",
                    marginTop: "10px",
                    color: "white",
                    backgroundColor:colors.red,
                  }}
                  onClick={downloadPdf}
                >
                  <i className="fa-regular fa-circle-down"></i> Download Pdf
                </Button>
              </div>

              <div
                // id="quoteCard"
                className="card shadow borderlarge mb-3"
                style={{
                  width: "auto",
                  borderRadius: "1",
                  borderColor: colors.red,
                  color: " #262b40",
                  padding: "10px",
                }}
              >
                <div className="card-body">
                  <div className="copy-section">
                    <div className="d-flex justify-content-between mb-2">
                      <div>
                        <label>Quote</label>
                      </div>
                      <div>
                        <label>{quoteData?.status}</label>
                      </div>
                    </div>
                    <div className="req  d-flex justify-content-between ">
                      <div className="firs-sec">
                        <div className="main-heading d-flex namelead">
                          <h3>
                            {quoteData?.clientData?.first_name}{" "}
                            {quoteData?.clientData?.last_name}
                          </h3>
                        </div>
                        <div className="sub-head">
                          <p className="mb-2">
                            {quoteData?.propertyData?.city},{" "}
                            {quoteData?.propertyData?.state}{" "}
                            {quoteData?.propertyData?.zipcode}
                          </p>
                          <p className="mb-2"> {quoteData?.clientData?.phone}</p>
                        </div>
                      </div>

                      <div className="boxsendon ">
                        <div className="contain d-flex mt-4">
                          <p>
                            Send on &nbsp;{" "}
                            {new Date(quoteData?.createdAt)?.toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <Col>
                    <div className="">
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-light">
                            <tr md={3} lg={3} sm={12}>
                              <th colSpan="2">PRODUCT / SERVICE</th>
                              <th>QTY.</th>
                              <th>UNIT PRICE</th>
                              <th>TOTAL</th>
                            </tr>
                          </thead>
                          {quoteData?.product_servise ? (
                            <tbody style={{}}>
                              {quoteData.product_servise.map((product, index) => (
                                <tr key={index}>
                                  <td colSpan="2" md={3} lg={3} sm={12}>
                                    <label
                                      className="label"
                                      name={`productservice_${index}`}
                                      value={`productservice_${index}`}
                                    >
                                      <p>{product.name}</p>
                                      <p>{product.description}</p>
                                    </label>
                                  </td>

                                  <td md={3} lg={3} sm={12}>
                                    <label
                                      className="label"
                                      name={`Qty_${index}`}
                                      value={`Qty_${index}`}
                                    >
                                      {product.part_qty}
                                    </label>
                                  </td>
                                  <td md={3} lg={3} sm={12}>
                                    <label
                                      className="label"
                                      name={`Unitprice_${index}`}
                                      value={`Unitprice_${index}`}
                                    >
                                      {product.part_price}
                                    </label>
                                  </td>
                                  <td md={3} lg={3} sm={12}>
                                    <label
                                      className="label"
                                      name={`Total_${index}`}
                                      value={`Total_${index}`}
                                    >
                                      {product.total_amount || 0}
                                    </label>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : null}
                        </table>
                      </div>
                    </div>
                  </Col>
                </div>

                <hr
                  style={{ width: "", backgroundColor: "gray", height: "3px" }}
                />

                <div className="row d-flex 3 justify-content-end ">
                  <Col md={6} lg={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table bordered striped hover responsive>
                        <tbody>
                          <tr style={{ borderBottom: "1px solid black" }}>
                            <th className="py-3 pl-3">Subtotal</th>
                            <td
                              className="py-3"
                              style={{ textAlign: "right", paddingRight: "20px" }}
                            >
                             <FontAwesomeIcon icon={faEuroSign} />

                              {quoteData?.product_servise
                                ?.reduce(
                                  (subtotal, product) =>
                                    subtotal +
                                    parseFloat(product.total_amount || 0),
                                  0
                                )
                                .toFixed(2)}
                              {/* <span>{quoteData?.product_servise_total}</span> */}
                            </td>
                          </tr>

                          <tr style={{ borderBottom: "1px solid black" }}>
                            <th className="py-3">Total</th>
                            <td
                              className="py-3 mb-3"
                              style={{ textAlign: "right", paddingRight: "20px" }}
                            >
                              <FontAwesomeIcon icon={faEuroSign} />
                              {" "}
                              {quoteData?.product_servise
                                ?.reduce(
                                  (total, product) =>
                                    total + parseFloat(product.total_amount || 0),
                                  0
                                )
                                .toFixed(2)}
                              {/* <span>{quoteData?.product_servise_total}</span> */}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  <label className="mb-2">here will be some data</label>
                  <label className="mb-3">
                    This quote is valid for the next 30 days, after which values
                    may subject to change.
                  </label>

                  <label className="mb-1">Signature</label>
                  <div className="d-flex">
                    {savedSignature && (
                      <div style={signatureAreaStyle} className="mb-3">
                        <img
                          className="signature-image"
                          src={savedSignature}
                          alt="Saved Signature"
                        />
                      </div>
                    )}
                    <div className="" style={{ marginLeft: "20px" }}>
                      {savedSignature && (
                        <>
                          <label>Date:</label>
                          <p>22/12/2023</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!signatureSaved && quoteData?.status !== "Approved" && (
            <Col
              md={4}
              lg={4}
              sm={12}
              className=" mainapprov justify-content-center align-item-center p-5"
            >
              <div className="Approve">
                <label className="mb-1" style={{ color: "#262B40" }}>
                  Quote Total  <FontAwesomeIcon icon={faEuroSign} />
                              {" "}
                              {quoteData?.product_servise
                                ?.reduce(
                                  (total, product) =>
                                    total + parseFloat(product.total_amount || 0),
                                  0
                                )
                                .toFixed(2)}
                </label>
                <Button className="mb-3 btnapprove" onClick={openModal} style={{backgroundColor:colors.red}}>
                  Approve
                </Button>

                <Modal
                  show={showModal}
                  onHide={closeModal}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Approve Quote</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <InputGroup className="mb-2">
                        <Button
                          className={`mb-2 drawbutton ${activeButton === "DrawSignature" ? "active" : ""
                            }`}
                          onClick={() => {
                            setSignatureRef(null);
                            setActiveButton("DrawSignature");
                          }}
                        >
                          Draw Signature
                        </Button>

                        <Button
                          className={`mb-2 drawbutton ${activeButton === "TypeSignature" ? "active" : ""
                            }`}
                          onClick={() => {
                            setSignatureRef(null);
                            setActiveButton("TypeSignature");
                          }}
                        >
                          Type Signature
                        </Button>
                        {activeButton === "DrawSignature" && (
                          <div className="flex-grow-1 d-flex justify-content-end">
                            <Button
                              className=""
                              variant="" /*secondary*/
                              onClick={clearSignature}
                              style={{ height: "50px",backgroundColor:colors.red,color:colors.white }}
                            >
                              Clear
                            </Button>
                          </div>
                        )}
                      </InputGroup>
                      <InputGroup className="mb-2">
                        {activeButton === "TypeSignature" ? (
                          <FormControl
                            placeholder="Type your name"
                            value={typedSignature}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              // Set a maximum limit of 30 characters
                              const limitedInput =
                                inputValue.length <= 30
                                  ? inputValue
                                  : inputValue.slice(0, 30);
                              setTypedSignature(limitedInput);
                            }}
                            style={{
                              width: "100%",
                              border: "2px solid #262B40",

                              fontSize: "20px", // Set your desired fixed font size
                              // textAlign: "center",
                              padding: "10px",
                              boxSizing: "border-box",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          />
                        ) : (
                          <SignatureCanvas
                            ref={(ref) => setSignatureRef(ref)}
                            canvasProps={{
                              width: 400,
                              height: 200,
                              className: "signature-canvas",
                              style: {
                                border: "2px solid #ccc",
                                //   cursor: `url(${pencilcursor}), auto`,
                              },
                            }}
                          />
                        )}
                      </InputGroup>
                      {/* <hr /> */}
                      {activeButton === "TypeSignature" && (
                        <label
                          id="typeSignatureLabel"
                          style={{
                            width: "100%",
                            height: "100px",
                            border: "1px solid #262B40",
                            fontSize: "50px", // Set your desired fixed font size
                            textAlign: "center",
                            padding: "13px",
                            boxSizing: "border-box",
                            fontFamily: "'Dancing Script', cursive",
                            // backgroundColor: "#CCC",
                            color: "blue", // Set text color to blue
                            fontStyle: "italic",
                          }}
                        >
                          {typedSignature}
                        </label>
                      )}
                      <div>
                        {/* <Button className="mx-2" variant="outline-secondary" onClick={clearSignature} >
                                                Clear
                                            </Button> */}
                        {/* <Button variant="primary" onClick={saveSignature}>
                                                Save Signature
                                            </Button> */}
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="" onClick={closeModal}style={{backgroundColor:colors.red, color:colors.white}} /*secondary*/>  
                      Cancel
                    </Button>
                    <Button variant="" onClick={saveSignature} style={{backgroundColor:colors.red,color:colors.white}} /*primary*/>
                      Approve
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Button className="mb-3 btnapprove" onClick={handleOpenModal} style={{backgroundColor:colors.red}}>
                  Request Change
                </Button>
                <Modal
                  show={showModal1}
                  onHide={handleCloseModal}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Request Changes</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="changeRequestText">
                        <Form.Label>Enter your change request:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={changeText}
                          onChange={(e) => setChangeText(e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}  style={{ backgroundColor:colors.red,color:colors.white}}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleRequestChanges} style={{ backgroundColor:colors.red,color:colors.white}}>
                      Request Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
}

export default QuotesDetails;
