import React from "react";
import { Container } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Col } from "@themesberg/react-bootstrap";
const AnotherPage = ({ email }) => {
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <div
        style={{
          cursor: "pointer",
          alignItems: "flex-start",
          paddingTop: "40px",
          boxShadow: "0px 0px 2 0px 0px rgba(0, 0, 0, 0.2)",
          backgroundColor: "white",
          padding: "50px",
        }}
      >
        <div>
          <h3 className="text-center">
            <div className="d-flex">
              <div
                className="bg-dark bg-gradient rounded-circle justify-content-center align-items-center "
                style={{
                  width: "55px",
                  height: "55px",
                }}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ color: "white", fontSize: "20px" }}
                />
              </div>
              <div style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                Check your email
              </div>
            </div>
          </h3>
          <Col xs={12} className="mb-2">
            <hr />
          </Col>
          <p className="text-center pt-1">
            We have sent an email to <br />{" "}
            <span style={{ fontWeight: "bold" }}> {email} </span> <br /> with a
            link that will securely log you in.
          </p>
          {/* Add functionality to resend email if needed */}
          <p className="text-center" style={{color:"#7db00e"}}>Resend email</p>
        </div>
      </div>
    </Container>
  );
};

export default AnotherPage;
