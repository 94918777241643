import React, { useState, useEffect, useMemo } from "react";
import { Pagination, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import axiosInstance from "../config/AxiosInstance";
import {
  Col,
  Card,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";

const JobTable = () => {
  const [userId, setUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState("job_title");
  const [sortOrder, setSortOrder] = useState("asc");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const cookies = new Cookies();

  const handleSort = (column) => {
    if (column === "createdAt") {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      if (column === sortColumn) {
        setSortOrder((prevSortOrder) =>
          prevSortOrder === "asc" ? "desc" : "asc"
        );
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
    }
  };

  const handleRowClick = (rowData) => {
    const job_id = rowData.job_id;
    localStorage.setItem("selectedJobId", job_id);
    history.push(`/job/jobdetail/${job_id}`);
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true before making the request

        const userData = cookies.get("userDetails");
        setUserId(userData.user_id);

        const response = await axiosInstance.get(
          `/job/job/${userData.user_id}`
        );

        if (response.status === 200) {
          setData(response?.data?.data);
        } else {
          setError(`Error: ${response.status} - ${response.data.message}`);
        }
      } catch (error) {
        setError(`Error: ${error.message}`);
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    // Call fetchData function
    fetchData();
  }, [currentPage, itemsPerPage, sortColumn, sortOrder, searchTerm]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const sortedData = useMemo(() => {
    const sorted = [...data].sort((a, b) => {
      if (sortColumn === "createdAt") {
        const aValue = new Date(a.createdAt);
        const bValue = new Date(b.createdAt);

        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      } else {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
    });

    return sorted;
  }, [sortColumn, sortOrder]);

  const totalPages = Math.ceil(sortedData.length / itemsPerPage) || 1;

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderTableRows = () => {
    const filteredData = sortedData.filter((rowData) => {
      const jobTitle = rowData?.job_title.toLowerCase();
      const jobInstructions = rowData?.job_instructions.toLowerCase();

      return (
        jobTitle.includes(searchTerm.toLowerCase()) ||
        jobInstructions.includes(searchTerm.toLowerCase())
      );
    });

    return filteredData
      .slice(indexOfFirstItem, indexOfLastItem)
      .map((rowData, index) => (
        <tr key={index} onClick={() => handleRowClick(rowData)}>
          <td>{rowData?.job_title}</td>
          <td>{rowData?.job_instructions}</td>
          <td>{new Date(rowData?.createdAt).toLocaleDateString()}</td>
        </tr>
      ));
  };

  return (
    <div style={{ justifyContent: "center", alignItems: "center" }}>
      <section className="ftco-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {loading && (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {!loading && (
                <>
                  <div className="w-100 align-items-end justify-content-end d-flex mb-4">
                    <InputGroup
                      style={{ maxWidth: 200, alignSelf: "flex-end" }}
                      className="mx-2 mb-1"
                    >
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        onChange={handleSearchChange}
                        value={searchTerm}
                        type="text"
                        name="search"
                        placeholder="Search"
                      />
                    </InputGroup>
                  </div>
                  <div className="table-wrap">
                    <table className="table table-striped">
                      <thead style={{ borderTop: "1px solid black" }}>
                        <tr>
                          <th onClick={() => handleSort("job_title")}>
                            Title
                            <span style={{ marginLeft: "10px" }}>
                              {sortOrder === "asc" ? (
                                <FontAwesomeIcon icon={faArrowUp} />
                              ) : (
                                <FontAwesomeIcon icon={faArrowDown} />
                              )}
                            </span>
                          </th>
                          <th onClick={() => handleSort("job_instructions")}>
                            Description
                            <span style={{ marginLeft: "10px" }}>
                              {sortOrder === "asc" ? (
                                <FontAwesomeIcon icon={faArrowUp} />
                              ) : (
                                <FontAwesomeIcon icon={faArrowDown} />
                              )}
                            </span>
                          </th>
                          <th onClick={() => handleSort("createdAt")}>
                            Created Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(sortedData) && sortedData.length > 0 ? (
                          renderTableRows()
                        ) : (
                          <tr>
                            <td colSpan="3">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-table d-flex justify-content-between">
                    <div>
                      <Pagination>
                      <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePaginationClick(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from({ length: totalPages }, (_, index) => (
                      <Pagination.Item
                        key={index}
                        active={index + 1 === currentPage}
                        onClick={() => handlePaginationClick(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePaginationClick(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                      </Pagination>
                    </div>
                    <div className="drop-count col-2">
                      <Form.Group
                        controlId="itemsPerPageSelect"
                        className="mb-3 d-flex align-items-center"
                      >
                        <Form.Label>Show</Form.Label>
                        <Form.Control
                          as="select"
                          className="mx-2"
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                          style={{ width: "30%" }}
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JobTable;
