import {
  Table,
  Popover,
  Whisper,
  Checkbox,
  Dropdown,
  IconButton,
  Progress,
  Loader,
} from "rsuite";
import MoreIcon from "@rsuite/icons/legacy/More";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import teamMembers from "../data/teamMembers";
import teamMembers from "../../data/teamMembers";
import "rsuite/dist/rsuite.min.css";
import React, { useState } from "react";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Button } from "@themesberg/react-bootstrap";
import { Pagination, Form } from "react-bootstrap";
import {
  faTrash,
  faStar,
  faPencilAlt,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons"; // Make sure to import the necessary icons
// import { useHistory } from 'react-router-dom';

const { Column, Row, HeaderCell, Cell } = Table;
// const data = teamMembers;

// const ImageCell = ({ rowData, dataKey, ...props }) => (
//   <Cell {...props} style={{ padding: 0 }}>
//     <div
//       className="text-center justify-content-center"
//       style={{
//         background: "#f5f5f5",
//         borderRadius: 6,
//         marginTop: 2,
//         overflow: "hidden",
//         display: "inline-block",
//         margin: 10,
//       }}
//     >
//       <img src={rowData.image} width="30" />
//     </div>
//   </Cell>
// );

// const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
//   <Cell {...props} style={{ padding: 0 }}>
//     <div style={{ lineHeight: "46px" }}>
//       <Checkbox
//         value={rowData[dataKey]}
//         inline
//         onChange={onChange}
//         checked={checkedKeys.some((item) => item === rowData[dataKey])}
//       />
//     </div>
//   </Cell>
// );

// const renderMenu = ({ onClose, left, top, className }, ref) => {
//   const handleSelect = (eventKey) => {
//     onClose();
//   };
//   return (
//     <Popover ref={ref} className={className} style={{ left, top }} full>
//       <Dropdown.Menu onSelect={handleSelect}>
//         <Dropdown.Item eventKey={1}>Delete</Dropdown.Item>
//       </Dropdown.Menu>
//     </Popover>
//   );
// };
// const handleSort = (column) => {
//   // Toggle sorting order when the user clicks on the column header
//   if (column === sortColumn) {
//     setSortOrder((prevSortOrder) => (prevSortOrder === 'asc' ? 'desc' : 'asc'));
//   } else {
//     setSortColumn(column);
//     setSortOrder('asc');
//   }
// };
const QuotesTable = ({ userClients = [], deleteRow, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState("first_name");
  const [sortOrder, setSortOrder] = useState("asc");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const sortedItems = Array.isArray(userClients)
    ? [...userClients].sort((a, b) => {
        const keyA = a[sortColumn] || "";
        const keyB = b[sortColumn] || "";

        return sortOrder === "asc"
          ? keyA.localeCompare(keyB)
          : keyB.localeCompare(keyA);
      })
    : [];

  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const history = useHistory();

  const handleSort = (column) => {
    // Toggle sorting order when the user clicks on the column header
    if (column === sortColumn) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const StarRating = ({ rating }) => {
    const stars = Array.from({ length: rating }).map((_, i) => (
      <FontAwesomeIcon key={i} icon={faStar} style={{ color: "#ffc107" }} />
    ));

    return <div>{stars}</div>;
  };
  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const handleNewRequest = () => {
      history.push(`/quotes/overview/${rowData.quotes_id}`);
    };
    const handleSelect = () => {
      deleteRow(rowData._id);
    };
    return (
      <td className="icon-tr">
        <FontAwesomeIcon
          style={{ cursor: "pointer", marginRight: "10px" }}
          onClick={handleNewRequest}
          icon={faPencilAlt}
        />

        <FontAwesomeIcon
          style={{ cursor: "pointer", marginLeft: "10px" }}
          onClick={handleSelect}
          icon={faTrash}
        />
      </td>
    );
  };

  const handleRowClick = (rowData) => {
    const clientId = rowData.client_id;
    localStorage.setItem("selectedClientId", clientId);
    history.push(`/quotes/overview/${rowData.quotes_id}`);
  };

  const renderTableRows = () => {
    return currentItems.map((rowData,index) => (
      <tr key={index} onClick={() => handleRowClick(rowData)}>
        {console.log(rowData, "rowData?.client?.job_title")}
        <td>
          {rowData?.client?.first_name} {rowData?.client?.last_name}
        </td>
        <td>{rowData?.client?.company_name}</td>
        <td> {rowData?.createdAt.substring(0, 10)}</td>
        <td>{rowData?.job_title}</td>
        <td>
          <StarRating rating={rowData?.rating} />
        </td>
        <td>                                                    
          <StatusCell rowData={rowData} dataKey="status" />
        </td>
      </tr>
    ));
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    console.log(pageNumber,"pageNum3ber")
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  

  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

  // const handleRowClick = (rowData) => {
  //   history.push(`/quotes/overview/${rowData.quotes_id}`);
  // };

  // const NameCell = ({ rowData, dataKey, dataKey2, ...props }) => {
  //   const speaker = (
  //     <Popover title="Description">
  //       <p>
  //         <b>Name:</b> {rowData?.client?.first_name ?? "-"} {rowData?.client?.last_name ?? "-"}
  //       </p>
  //       <p>
  //         <b>Email:</b> {rowData?.client?.email ?? "-"}
  //       </p>
  //     </Popover>
  //   );

  //   return (
  //     <Cell
  //       {...props}
  //       onClick={() => {
  //         handleRowClick(rowData);
  //       }}
  //     >
  //       <Whisper placement="top" speaker={speaker}>
  //         {dataKey2 === undefined ? (
  //           <a>{rowData?.client?.[dataKey] ?? "-"}</a>
  //         ) : (
  //           <a className="text-blue">
  //             {rowData?.client?.[dataKey] ?? "-"} {rowData?.client?.[dataKey2] ?? "-"}
  //           </a>
  //         )}
  //       </Whisper>
  //     </Cell>
  //   );
  // };

  // const DataCell = ({ rowData, dataKey, dataKey2, ...props }) => {
  //   const speaker = (
  //     <Popover title="Description">
  //       <p>
  //         <b>Name:</b> {rowData?.client?.first_name ?? "-"} {rowData?.client?.last_name ?? "-"}
  //       </p>
  //       <p>
  //         <b>Email:</b> {rowData?.client?.email ?? "-"}
  //       </p>
  //     </Popover>
  //   );

  //   return (
  //     <Cell
  //       {...props}
  //       onClick={() => {
  //         handleRowClick(rowData);
  //       }}
  //     >
  //       <Whisper placement="top" speaker={speaker}>
  //           <a>{rowData?.[dataKey] ?? "-"}</a>
  //       </Whisper>
  //     </Cell>
  //   );
  // };

  const StatusCell = ({ rowData, dataKey, ...props }) => {
    const speaker = (
      <Popover title="Description">
        <p>
          <b>Name:</b> {rowData?.client?.first_name ?? "-"}{" "}
          {rowData?.client?.last_name ?? "-"}
        </p>
        <p>
          <b>Email:</b> {rowData?.client?.email ?? "-"}
        </p>
      </Popover>
    );

    const getStatusTagStyle = () => {
      if (rowData?.status == "Draft") {
        return {
          color: "#5c5c5c", // Green color for success
          border: "1px solid #5c5c5c",
          borderRadius: "20px",
          padding: "4px 8px",
        };
      } else if (rowData?.status == "Approved") {
        return {
          color: "#109c3e", // Yellow color for today
          border: "1px solid #109c3e",
          borderRadius: "20px",
          padding: "4px 8px",
        };
      } else {
        return {
          color: "#dc3545", // Red color for overdue
          border: "1px solid #dc3545",
          borderRadius: "20px",
          padding: "4px 8px",
        };
      }
    };

    return (
      <Cell
        {...props}
        onClick={() => {
          handleRowClick(rowData);
        }}
      >
        <Whisper placement="top" speaker={speaker}>
          {rowData?.status == "Draft" ? (
            <a style={getStatusTagStyle()}>{rowData?.status}</a>
          ) : rowData?.status == "Approved" ? (
            <a style={getStatusTagStyle()}>{rowData?.status}</a>
          ) : (
            <a style={getStatusTagStyle()}>{rowData?.status}</a>
          )}
        </Whisper>
      </Cell>
    );
  };

  // return (
  //   <Table
  //     height={500}
  //     data={userClients}
  //     className="mx-2 px-2"
  //     id="table"
  //     loading={loading}
  //   >
  //     <Column flexGrow={2}>
  //       <HeaderCell>Name</HeaderCell>
  //       <NameCell dataKey="first_name" dataKey2="last_name" />
  //     </Column>

  //     <Column flexGrow={2}>
  //       <HeaderCell>Company Name</HeaderCell>
  //       <NameCell dataKey="company_name" />
  //     </Column>

  //     {/* <Column flexGrow={2}>
  //       <HeaderCell>Phone</HeaderCell>
  //       <NameCell dataKey="phone" />
  //     </Column> */}

  //     <Column flexGrow={2}>
  //       <HeaderCell>Job Title</HeaderCell>
  //       <DataCell dataKey="job_title" />
  //     </Column>

  //     <Column flexGrow={2}>
  //       <HeaderCell>Rating</HeaderCell>
  //       <Cell>
  //         {rowData =>
  //           Array.from({ length: rowData.rating }).map((_, i) => <span key={i}>⭐️</span>)
  //         }
  //       </Cell>
  //     </Column>

  //     <Column flexGrow={2}>
  //       <HeaderCell>Created Date</HeaderCell>
  //       <Cell>
  //         {(rowData) => `${rowData.createdAt.substring(0, 10)}`}
  //       </Cell>
  //     </Column>

  //     {/* <Column flexGrow={1}>
  //       <HeaderCell height={20}>Action</HeaderCell>
  //       <ActionCell dataKey="client_id" />
  //     </Column> */}

  //     <Column flexGrow={2}>
  //       <HeaderCell>Status</HeaderCell>
  //       <StatusCell dataKey="status" />
  //     </Column>
  //   </Table>
  // );

  if (loading) {
    return(
      <div className="d-flex justify-content-center ">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )
  }
  return (
    <div style={{ justifyContent: "center", alignItems: "center" }}>
      <section className="ftco-section p-2">
        {/* <div className="container"> */}
        <div className="row">
          <div className="col-md-12 col-xl-12">
            <div className="table-wrap w-100  ">
            <table className="table table-striped table-responsive ">
              <thead style={{ borderTop: "1px solid black" }}>
                <tr>
                  <th onClick={() => handleSort("first_name")}>
                    Name
                    <span style={{ marginLeft: "10px" }}>
                      {sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                      ) : (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )}
                    </span>
                  </th>
                  <th onClick={() => handleSort("company_name")}>
                    Company Name
                    <span style={{ marginLeft: "10px" }}>
                      {sortOrder === "asc" ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                      ) : (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )}
                    </span>
                  </th>
                  <th>Created Date</th>
                  <th onClick={() => handleSort("job_title")}>Job Title</th>
                  <th>Rating</th>
                  {/* <Column flexGrow={2}>
        <HeaderCell>Rating</HeaderCell>
        <Cell>
          {rowData =>
            Array.from({ length: rowData.rating }).map((_, i) => <span key={i}>⭐️</span>)
          } 
        </Cell>
      </Column> */}
                  <th onClick={() => handleSort("status")}>status&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(currentItems) && currentItems.length > 0 ? (
                  renderTableRows()
                ) : (
                  <tr>
                    <td colSpan="4">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination-table d-flex justify-content-between">
                <div>
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePaginationClick(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from({ length: totalPages }, (_, index) => (
                      <Pagination.Item
                        key={index}
                        active={index + 1 === currentPage}
                        onClick={() => handlePaginationClick(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePaginationClick(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
                <div className="drop-count col-2">
                  <Form.Group
                    controlId="itemsPerPageSelect"
                    className="mb-3 d-flex align-items-center"
                  >
                    <Form.Label>Show</Form.Label>
                    <Form.Control
                      as="select"
                      className='mx-2'
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      style={{ width: "30%" }}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
        </div>
        </div>
        {/* </div> */}
      </section>
    </div>
  );
};

export default QuotesTable;