import {
  Col,
  Form,
  Card,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import React, { useState, useEffect } from "react";
import { Button } from "@themesberg/react-bootstrap";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Routes } from "../routes";
import { useHistory } from "react-router-dom";
import ClientTable from "./ClientTable";
import Cookies from "universal-cookie";
import axiosInstance from "../config/AxiosInstance";
import Swal from "sweetalert2";

function ClientDashboard() {
  const history = useHistory();
  const cookies = new Cookies();
  const [userClients, setUserClients] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredData, setFilteredData] = useState("");

  const handleSearch = (keyword) => {
    const filteredResults = userClients?.filter((item) => {
      // Customize this logic based on your search requirements
      const phoneNumberString = String(item.phone);

      console.log(keyword);
      return (
        item.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
        item.last_name.toLowerCase().includes(keyword.toLowerCase()) ||
        item.company_name.toLowerCase().includes(keyword.toLowerCase()) ||
        phoneNumberString.includes(keyword)
      );
    });

    setSearchKeyword(keyword);
    setFilteredData(filteredResults);
  };

  const fetchData = async () => {
    try {
      const userData = cookies.get("userDetails");
      const response = await axiosInstance.get(
        `/client/client/${userData.user_id}`
      );
      if (response.data.data && Array.isArray(response.data.data)) {
        setUserClients(response.data.data);
      } else {
        setUserClients([]); // or set it to an empty array if data is not an array
      }
    } catch (error) {
      console.log("error in fetchdata in company details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteRow = async (id) => {
    try {
      setLoading(true);

      // Display a confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this client!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // User clicked "Yes"
        const response = await axiosInstance.delete(
          `/client/client/delete/${id}`
        );
        fetchData();

        // Display a success message
        Swal.fire(
          "Deleted!",
          "Client has been deleted successfully.",
          "success"
        );
      }
    } catch (error) {
      console.log("error in fetchdata in company details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <div className="d-flex quotes-firstt-button w-100 mb-1 justify-content-between">
        <p className="heading-form text-dark">Clients</p>
        <Button
          onClick={() => {
            history.push(Routes.Client.path);
          }}
          variant="success"
          size="sm"
          style={{ maxHeight: 40, backgroundColor: "#e91818", border: "none" }}
          className="me-2"
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          New Client
        </Button>
      </div>

      <div className="w-100 align-items-end justify-content-end d-flex">
        <InputGroup
          style={{ maxWidth: 200, alignSelf: "flex-end" }}
          className="mx-2 mb-1"
        >
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control
            onChange={(event) => handleSearch(event.target.value)}
            type="text"
            name="search"
            placeholder="Search"
          />
        </InputGroup>
      </div>

      <div className="m-2 pt-4  border rounded">
        <ClientTable
          loading={loading}
          userClients={searchKeyword == "" ? userClients : filteredData}
          deleteRow={deleteRow}
        />
      </div>
    </div>
  );
}

export default ClientDashboard;
