export const ClientProjectRoutes = {
  // pages
  Presentation: { path: "/client/project/presentation" },
  DashboardOverview: { path: "/client/user" },
  LandPage: { path: "/client/" },
  Transactions: { path: "/client/transactions" },
  Settings: { path: "/client/settings" },
  Upgrade: { path: "/client/upgrade" },
  BootstrapTables: { path: "/client/tables/bootstrap-tables" },
  Billing: { path: "/client/billing" },
  Invoice: { path: "/client/invoice" },
  Signin: { path: "/client/" },
  Signup: { path: "/client/sign-up" },
  ForgotPassword: { path: "/client/forgot-password" },
  ResetPassword: { path: "/client/reset-password" },
  Lock: { path: "/client/lock" },
  NotFound: { path: "/client/404" },
  ServerError: { path: "/client/500" },

  // client
  Client: { path: "/client/client/new" },
  // ClientDashBoard: { path: "/client/client/dashboard" },

  // properties
  // Properties: {path: "/properties"},
  // NewProperties: {path: "/properties/new"},

  Properties: { path: "/client/properties/:clientId" },
  NewProperties: { path: "/client/properties/newproperties/form/:clientId" },

  // RequestDashboard: { path: "/client/request" },
  RequestDashboard: { path: "/client/request/:client_id" },
  NewRequest: { path: "/client/newrequest" },
  RequestDetail: { path: "/client/request/:clientId/:requestId" },

  //quotes
  QuotesDashboard: { path: "/client/quotedash" },
  QuotesDetails: { path: "/client/quoteoverview/:quotes_id/:status" },
  QuoteRequestchange: { path: "/client/quoterequestchange" },

  //appointment
  AppointmentDashboard: { path: "/client/appointmentdash" },
  AppointmentDetails: { path: "/client/appointmentdetails" },

  //invoice
  ClientInvoiceDash:{path: "/client/invoicedashboard"},
  ClientInvoiceDetail:{path: "/client/invoicedetail/:invoice_id"},

  // docs
  DocsOverview: { path: "/client/documentation/overview" },
  DocsDownload: { path: "/client/documentation/download" },
  DocsQuickStart: { path: "/client/documentation/quick-start" },
  DocsLicense: { path: "/client/documentation/license" },
  DocsFolderStructure: { path: "/client/documentation/folder-structure" },
  DocsBuild: { path: "/client/documentation/build-tools" },
  DocsChangelog: { path: "/client/documentation/changelog" },

  // components
  Accordions: { path: "/client/components/accordions" },
  Alerts: { path: "/client/components/alerts" },
  Badges: { path: "/client/components/badges" },
  Widgets: { path: "/client/widgets" },
  Breadcrumbs: { path: "/client/components/breadcrumbs" },
  Buttons: { path: "/client/components/buttons" },
  Forms: { path: "/client/components/forms" },
  Modals: { path: "/client/components/modals" },
  Navs: { path: "/client/components/navs" },
  Navbars: { path: "/client/components/navbars" },
  Pagination: { path: "/client/components/pagination" },
  Popovers: { path: "/client/components/popovers" },
  Progress: { path: "/client/components/progress" },
  Tables: { path: "/client/components/tables" },
  Tabs: { path: "/client/components/tabs" },
  Tooltips: { path: "/client/components/tooltips" },
  Toasts: { path: "/client/components/toasts" },
  WidgetsComponent: { path: "/client/components/widgets" },
};
