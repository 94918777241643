import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  Form,
  Col,
  Row,
  InputGroup,
  DropdownButton,
  Table,
  Dropdown,
  OverlayTrigger,
  Nav,
} from "@themesberg/react-bootstrap";
import {colors} from "../../config/colors";
import { ButtonGroup } from "@themesberg/react-bootstrap";
import down from "../../assets/img/icons/arrow-down.png";
import link from "../../assets/img/icons/link (1).png";
import homeimage from "../../assets/img/home-bg.jpeg";
import "./style.css";
import { Routes } from "../../routes";
import axiosInstance from "../../config/AxiosInstance";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faArrowLeft,
  faAddressBook,
  faPen,
  faEllipsisH,
  faStar,
  faCheck,
  faFilePdf,
  faPencilAlt,faCogs, faEuroSign
} from "@fortawesome/free-solid-svg-icons";
import { Rating } from "@mui/material";
function QuotesDetails() {
  const { quotes_id } = useParams();
  const [quoteData, setQuoteData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/quotes/quotes_get/${quotes_id}`);

        setQuoteData(response.data.data[0]);
        console.log(response.data.data[0], 'response.data.data[0]');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [quotes_id]);

  const handleEditClick = () => {
    // navigate to edit screen of request
    history.push(`/quotes/new?quotes_id=${quotes_id}`)
  }

  const handleConverjob = () => {
    // navigate to edit screen of request
    // history.push(`/job/new/${quotes_id}`);
    history.push(`/job/new?quotes_id=${quotes_id}`)

  }

  if (!quoteData) {
    return <div>Loading...</div>;
  }
  
  return (
    <div
      className="m-5 main-quates"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <div className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <Dropdown className="btn-toolbar">
            <Dropdown.Toggle
              as={Button}
              variant="primary"
              size="sm"
              className="me-2"
              style={{backgroundColor:"#e91818",border:"none"}}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              Back To
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
              <Dropdown.Item
                onClick={() => {
                  history.push(Routes.QuotesDashboard.path);
                }}
                className="fw-bold"
                
              >
                <FontAwesomeIcon icon={faAddressBook} className="me-2" /> Quote
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link}>
              <Button variant="outline-primary" size="sm"   style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
              {/* <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faEllipsisH} className="me-2" /> Settings
              </Dropdown.Item>*/}

              <Dropdown.Item className="fw-bold" onClick={handleEditClick}>
                <FontAwesomeIcon icon={faPencilAlt} className="text-dark me-2" />{" "}
                Edit Quote
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="fw-bold" onClick={handleConverjob}>
                <FontAwesomeIcon icon={faCogs} className="text-danger me-2" />{" "}
                Convert to Job
              </Dropdown.Item>

              {/* <Dropdown.Divider /> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="main-sec">
        <div className="first-table d-flex">
          <div className="first-head col-6  align-items-center">
            <h2 className="">
              {quoteData.clientData?.first_name}{" "}
              {quoteData.clientData?.last_name}{" "}
            </h2>
            <div>
              {
                quoteData?.status == "Approved" ? <h5 className="text-success">{quoteData?.status}</h5> : quoteData?.status == "Draft" ? <h5 className="text-secondary">{quoteData?.status}</h5> : <h5 className="text-danger">{quoteData?.status}</h5>
              }

            </div>
          </div>
          <div className="tab table-responsive col-6 star-table d-flex justify-content-center align-items-center text-center">
            <table class="table table-bordered">
              <tbody style={{ border: "2px solid grey" }}>
                <tr>
                  <th scope="row"> Created At :&nbsp; </th>
                  <td>
                    {" "}
                    {quoteData &&
                      new Date(quoteData.createdAt).toLocaleDateString()}
                  </td>
                </tr>
                <tr>
                  <th scope="row"> Rating&nbsp;</th>
                  <td>
                    {" "}
                    <Rating name="read-only" value={quoteData.rating} readOnly />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="main-sec" style={{ borderTop: "none" }}>
        <div
          className="address "
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="col-3 ">
            <div className="sub-head propertyad">
              <p>
                <h5>Property Detail</h5>
                <p>
                  {" "}
                  <div>{quoteData.propertyData?.street_1}</div>
                  <div>{quoteData.propertyData?.street_2}</div>
                  <div>
                    {quoteData.propertyData?.city},{" "}
                    {quoteData.propertyData?.state}, {" "}
                    {quoteData.propertyData?.zipcode}
                  </div>
                  <div>{quoteData.propertyData?.country}</div>
                </p>
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="sub-head">
              <p>
                {" "}
                <h5>Contact Details</h5>
              </p>
              <p>{quoteData.clientData?.phone}</p>
              <p>{quoteData.clientData?.email}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tables my-5 table-responsive"
        style={{
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
          borderRadius: "10px",
        }}
      >
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">PRODUCT / SERVICE</th>
              <th scope="col">QTY.</th>
              <th scope="col">UNIT PRICE</th>
              <th scope="col">TOTAL</th>
              {/* <th scope="col">IMAGE</th> */}
            </tr>
          </thead>
          <tbody>
            {quoteData.product_servise.map((item, index) => (
              <tr key={index}>
                <th scope="">
                  <span style={{ fontWeight: "bold" }}> Name :</span> {item.name}
                  <span style={{ fontWeight: "bold" }}>
                    <br /> Description :
                  </span>{" "}
                  {item.description}
                </th>
                <td>{item.part_qty}</td>
                <td>€{item.part_price}</td>
                <td>€{item.total_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>

      
      <div className="row clientmsg-main  d-flex my-5">
        <Col md={6} lg={6} sm={12} className="clientmsg">
          <div>
            <label className="form-control " style={{ height: "100px" }}>
              <p>
                <span style={{ fontWeight: "bold" }}>Client Message :</span>{" "}
                {quoteData?.client_message}
              </p>
              {quoteData.client_view.map((time, index) => (
                <p key={index} style={{ color: "gray" }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "8px" }}
                  />
                  {time}
                </p>
              ))}
            </label>
          </div>
        </Col>
        <Col md={12} lg={6} sm={12}>
          <table
            class="table table-bordered client-table"
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
            }}
          >
            <tbody>
              {/* <tr>
                <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Subtotal :{" "}
                </th>
                <td className="text-end">€0.00</td>
              </tr>
              <tr>
                <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Descount :{" "}
                </th>
                <td className="text-end"> Add Discount</td>
              </tr>
              <tr>
                <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Tax :{" "}
                </th>
                <td colspan="2" className="text-end">
                  Add Tax
                </td>
              </tr> */}
              <tr>
                <th scope="row" style={{ backgroundColor: "#c5c5c5" }}>
                  Total :{" "}
                </th>
                <td colspan="2" className="text-end">
                <FontAwesomeIcon icon={faEuroSign} />

                  {quoteData.product_servise_total} 
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </div>

      <div
        style={{
          backgroundColor: "#eef0f2",
          padding: "20px",
          borderRadius: "10px",
        }}
        className="attachments"
      >
        <div
          style={{ border: "1px solid #b5b5b5", padding: "20px" }}
          className="last-sec my-5"
        >
          <div className="first-card d-flex">
            <div className="logi d-flex justify-content-between text-center mb-3">
              <p>
                {quoteData.clientData?.first_name?.charAt(0).toUpperCase()}
                {quoteData.clientData?.last_name?.charAt(0).toUpperCase()}
              </p>
            </div>
            <div className="des">
              <h5>
                {quoteData.clientData?.first_name}{" "}
                {quoteData.clientData?.last_name}{" "}
              </h5>
              <p>
                Created At :&nbsp;{" "}
                {quoteData &&
                  new Date(quoteData.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#fff", padding: "20px" }}
            className="main-content"
          >
            <p className="pb-4"> {quoteData?.client_message}</p>
            <div className="pb-4">
              <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "30px" }} />
              <p>temp.pdf</p>
            </div>
            <p>
              {quoteData.internal_link_note_to_related.map((time, index) => (
                <p key={index} style={{ color: "gray" }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "8px" }}
                  />
                  {time}
                </p>
              ))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuotesDetails;
