

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

import {
    Button,
    Form,
    Col,
    Row,
    InputGroup,
    FormControl,
    DropdownButton,
    Table,



} from "@themesberg/react-bootstrap";
// import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import 'jspdf-autotable';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faMapMarkerAlt, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import axiosClientInstance from "../config/axiosClientInstance.js";
import {colors} from "../config/colors.js";
import "./style.css";
function ClientInvoiceDetail() {
    const history = useHistory();
    const { invoice_id } = useParams();
    const [invoiceData, setInvoiceData] = useState(null);
    const handleBtnBack = () => {
        history.goBack();
    };






    const downloadPdf = () => {
        const pdf = new jsPDF();

        // Set initial y-coordinate
        let y = 10;

        // Add content to the PDF
        pdf.text("Temp Company", 10, y);
        y += 5;

        // Add quote details
        const tableintro = [
            ["Quote#2"],
            ["Issued Dec 21,2023"],
            ["Due Dec 21,2023"]


            // Add more rows as needed
        ];
        const columnWidths = [40]; // Adjust the width of the first column
        const theme = "striped"; // You can customize the theme if needed


        // Create autoTable for quote details
        pdf.autoTable({
            startY: y,
            head: [tableintro[0]],
            body: tableintro.slice(1),
            theme: theme,
            tableWidth: "auto",
            margin: { left: 10 }, // Set left margin to 0 to align with the left side
            columnStyles: {
                0: { cellWidth: columnWidths[0] },
            },
        });
        y += 35;

        // Add customer details
        pdf.text("Recipient: John Doe", 10, y);
        y += 10;
        pdf.text("Address: San Francisco, California 94128", 10, y);
        y += 10;
        pdf.text("Phone: 1234567890", 10, y);
        y += 10;
        pdf.setFont("helvetica", "bold"); // Set the font to bold
        pdf.text("For Services rendered", 10, y);
        pdf.setFont("helvetica", "normal"); // Reset the font style to normal
        y += 2;
        // Add quote details
        const tableData = [
            ["Product/Service", "Description", "Qty", "Unit Price", "Total"],
            ["Hp laptop", "Hp laptop are easy to use", "2", "€5.00", "€10.00"],
            ["Hp laptop", "Hp laptop are easy to use", "2", "€5.00", "€10.00"]

            // Add more rows as needed
        ];

        // Create autoTable for quote details
        pdf.autoTable({
            startY: y,
            head: [tableData[0]],
            body: tableData.slice(1),
            theme: 'striped', // You can customize the theme if needed
            tableWidth: 'auto', // Adjust the width automatically
            margin: { left: 10 }, // Set margin to align with the left side
        });

        // Increment y for the next content
        y += 35;

        // Add total auto box on the right side
        pdf.autoTable({
            startY: y,
            head: [['Total']],
            body: [['€10.00']], // Add the total value here
            theme: "striped",
            tableWidth: "auto",
            margin: { left: 155 },
            columnStyles: { 0: { cellWidth: 40 } }, // Adjust the width of the total box
        });
        y += 10;

        // Add more content as needed...

        // Save the PDF
        pdf.save("invoice.pdf");
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosClientInstance.get(
                    `invoice/invoice_details/${invoice_id}`
                );
                setInvoiceData(response.data.data[0]);
                console.log("Invoice Details:", response.data.data);
            } catch (error) {
                console.error("Error fetching invoice details:", error);
            }
        };

        fetchData();
    }, [invoice_id]);

    if (!invoiceData) {
        // Render loading state or return null
        return <p>Loading invoice details...</p>;
    }







    return (
        <div className="container-fluid">
            <Row>
                <div className="col-lg-12">

                    <div>
                        <div className="d-flex justify-content-between">
                            <Button
                                variant=""
                                style={{
                                    minWidth: "50px",
                                    // border: "2px solid black",
                                    height: "44px",
                                    marginRight: "10px",
                                    marginTop: "10px",
                                    // color: "white",
                                    backgroundColor:colors.red,
                                    color:colors.white,
                                }}
                                onClick={handleBtnBack}
                            >
                                <i className="fa-solid fa-arrow-left"></i> Back
                            </Button>

                            <Button
                                variant=""
                                style={{
                                    minWidth: "50px",
                                    // border: "2px solid black",
                                    height: "44px",
                                    marginRight: "10px",
                                    marginTop: "10px",
                                    // color: "white",
                                    backgroundColor:colors.red,
                                    color:colors.white,
                                }}
                                onClick={downloadPdf}
                            >
                                <i className="fa-regular fa-circle-down"></i> Download Pdf
                            </Button>
                        </div>

                        <div
                            // id="quoteCard"
                            className="card shadow borderlarge mb-3"
                            style={{
                                width: "auto",
                                borderRadius: "1",
                                borderColor: colors.red,
                                color: " #262b40",
                                padding: "10px",
                            }}
                        >
                            <div className="card-body">
                                <div className="copy-section">
                                    <div className="d-flex justify-content-between mb-2">
                                        <div>
                                            <label>Invoice#2</label>

                                        </div>
                                        <div>
                                            <label>Awaiting Payment</label>
                                        </div>
                                    </div>
                                    <div className="req  d-flex justify-content-between ">
                                        <div className="firs-sec">
                                            <div className="main-heading  namelead">
                                                <h5>For Services rendered</h5>
                                                <h4>
                                                    {invoiceData?.clientData?.first_name}{" "}
                                                    {invoiceData?.clientData?.last_name}
                                                </h4>
                                            </div>
                                            <div className="sub-head">
                                                <p className="mb-2">  {invoiceData?.propertyData?.street_1}</p>
                                                <p className="mb-2"> {invoiceData?.clientData?.phone} </p>
                                            </div>

                                        </div>

                                        <div className="boxsendon ">
                                            <div className="contain  mt-3">
                                                <p >Issued on &nbsp;  {invoiceData?.invoice_issued_date}
                                                    <hr
                                                        style={{ width: "auto" }}
                                                    />
                                                    <p className="datecenter">Due &nbsp; {invoiceData?.invoice_due_date}</p>
                                                </p>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <Col>
                                    <div className="">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr md={3} lg={3} sm={12}>
                                                        <th colSpan="2">PRODUCT / SERVICE</th>
                                                        <th>QTY.</th>
                                                        <th>UNIT PRICE</th>
                                                        <th>TOTAL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {invoiceData.product_servise.map((product, index) => (
                                                        <tr key={index}>
                                                            <td colSpan="2" md={3} lg={3} sm={12}>
                                                                <label className="label">
                                                                    <p style={{ fontSize: "10px", fontWeight: "bold" }}>  {invoiceData?.invoice_issued_date}</p>
                                                                    <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                                                                        {product.name}
                                                                    </p>
                                                                    <p>{product.description}</p>
                                                                </label>
                                                            </td>
                                                            <td md={3} lg={3} sm={12}>
                                                                <label className="label">{product.part_qty}</label>
                                                            </td>
                                                            <td md={3} lg={3} sm={12}>
                                                                <label className="label">{product.part_price}</label>
                                                            </td>
                                                            <td md={3} lg={3} sm={12}>
                                                                <label className="label">{product.total_amount}</label>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </Col>
                            </div>

                            <hr
                                style={{ width: "", backgroundColor: "gray", height: "3px" }}
                            />

                            <div className="row d-flex 3 justify-content-end ">
                                <Col md={3} lg={3} sm={12}>
                                    <div className="table-responsive mb-3">
                                        <Table bordered striped hover responsive>
                                            <tbody>
                                                <tr style={{ borderBottom: "1px solid black" }}>
                                                    <th className="py-3 pl-3" style={{ textAlign: "center", paddingRight: "20px" }}>Subtotal</th>
                                                    <td
                                                        className="py-3"
                                                        style={{ textAlign: "center", paddingRight: "20px",fontWeight:"700" }}
                                                    >
                                                        <FontAwesomeIcon icon={faEuroSign} />
                                                        {invoiceData.product_servise_total}
                                                    </td>
                                                </tr>

                                                <tr style={{ borderBottom: "1px solid black" }}>
                                                    <th className="py-3" style={{ textAlign: "center", paddingRight: "20px" }}>Total</th>
                                                    <td
                                                        className="py-3 mb-3"
                                                        style={{ textAlign: "center", paddingRight: "20px",fontWeight:"700" }}
                                                    >
                                                        <FontAwesomeIcon icon={faEuroSign} />
                                                        {invoiceData.product_servise_total}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <label className="mb-2">Client Message</label>
                                <label className="mb-2">{invoiceData?.client_message}</label>


                                <label className="mb-2">
                                    Thank you for your business. Please contact us with any questions regarding this invoice.
                                </label>





                            </div>

                        </div>
                    </div>
                </div>

            </Row>
        </div>
    );
}

export default ClientInvoiceDetail;
