import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Table } from 'react-bootstrap';
import {
  faPlus,
  faArrowLeft,
  faAddressBook,
  faBuilding,
  faStar,
  faFileAlt,
  faTextWidth,
  faCamera,
  faTimesCircle,
  faEye,
  faUser,
  faAngleRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import {
  Col,
  Row,
  Button,
  Table,
  Form,
  Modal,
  Spinner,
  InputGroup,
} from "@themesberg/react-bootstrap";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

import { Routes } from "../../routes";
import { useHistory, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "universal-cookie";
import axiosInstance from "../../config/AxiosInstance";
import {colors} from "../../config/colors";
import RequestRightSidebar from "./RequestRightSidebar";

function NewQuotes({
  handleInput,
  handleSubmit,
  data,
  rating,
  setRating,
  handleInputCheckbox,
  isButtonDisable,
  setIsButtonDisable,
  selectedProperty,
  setSelectedProperty,
}) {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState("");
  const [loadingProperties, setLoadingProperties] = useState(true);
  const [clients, setClients] = useState([]);
  const [properties, setProperties] = useState([]);
  const [loadingClients, setLoadingClients] = useState(true);
  const [userData, setUserData] = useState([]);

  const location = useLocation();
  // const requestData = location.state != undefined ? location?.state : [];
  const [requestData, setRequestData] = useState([]);

  const cookies = new Cookies();

  const [showModal, setShowModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowPropertyModal(false);
  };

  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
  };

  const handleShowEmailModal = () => {
    setShowEmailModal(true);
  };

  const handleSendEmail = () => {
    // Add your logic for sending email here
    // For example, you can use a library like nodemailer or an API endpoint.
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchpropertyChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredClients = clients?.filter(
    (client) =>
      client.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (client.last_name &&
        client.last_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      client.company_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredProperties = properties?.filter(
    (property) =>
      property.street_1.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (property.street_2 &&
        property.street2.toLowerCase().includes(searchTerm.toLowerCase())) ||
      property.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.zipcode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const initialProduct_service = {
    name: "",
    description: "",
    part_qty: "",
    part_price: "",
    total_amount: "",
    image: null,
  };

  // https://jobmanager.cloudpress.host/api/product/product_service/user_id

  const fetchProductsServices = async () => {
    try {
      const userData = cookies.get("userDetails");

      const response = await axiosInstance.get(
        `/product/product_service/${userData.user_id}`
      );
      if (response.data.data) {
        setProducts(response.data.data);
      }
    } catch (error) {
      console.log("error in getting fetch products in new quotes form:", error);
    } finally {
      setLoadingProperties(false);
    }
  };

  useEffect(() => {
    fetchProductsServices();
  }, []);

  const [product_servise, setProduct_service] = useState([
    initialProduct_service,
  ]);

  const handleNameChange = (e, index) => {
    const updatedProduct_service = [...product_servise];
    updatedProduct_service[index].name = e.target.value;
    setProduct_service(updatedProduct_service);
  };

  const handleQuantityChange = (e, index) => {
    const updatedProduct_service = [...product_servise];
    updatedProduct_service[index].part_qty = e.target.value;
    updateTotalAmount(updatedProduct_service, index);
  };

  const handlePriceChange = (e, index) => {
    const updatedProduct_service = [...product_servise];
    updatedProduct_service[index].part_price = e.target.value;
    updateTotalAmount(updatedProduct_service, index);
  };

  const updateTotalAmount = (updatedProduct_service, index) => {
    const quantity = parseFloat(updatedProduct_service[index].part_qty);
    const price = parseFloat(updatedProduct_service[index].part_price);
    updatedProduct_service[index].total_amount =
      isNaN(quantity) || isNaN(price) ? "" : (quantity * price).toFixed(2);

    setProduct_service(updatedProduct_service);
  };
  const handleImageChange = (event, index) => {
    const selectedImage = event.target.files[0];
    const updatedProduct_service = [...product_servise];
    updatedProduct_service[index].image = selectedImage;
    setProduct_service(updatedProduct_service);
  };

  const handleRemoveImage = (index) => {
    const updatedProduct_service = [...product_servise];
    updatedProduct_service[index].image = null;
    setProduct_service(updatedProduct_service);
  };

  const handleAddRow = () => {
    setProduct_service([...product_servise, initialProduct_service]);
  };

  //------------------------------------------------------
  useEffect(() => {
    if (data.product_servise.length === 0) {
      handleAddProductService();
    }
  }, [data.product_servise]);
  const [grandTotal, setGrandTotal] = useState(0);
  // const handleProductServiceChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedProductService = [...data.product_servise];
  //   updatedProductService[index][name] = value;
  //   console.log(updatedProductService, "updatedProductService")

  //   handleInput({
  //     target: {
  //       name: "product_servise",
  //       value: updatedProductService,
  //     },
  //   });

  //   // Calculate grand total
  //   const newGrandTotal = updatedProductService.reduce((total, entry) => {
  //     return total + (entry.part_qty * entry.part_price || 0);
  //   }, 0);

  //   setGrandTotal(newGrandTotal);
  // };

  const handleProductServiceChange = (e, index) => {
    const { name, value } = e.target;
    const updatedProductService = [...data.product_servise];
    updatedProductService[index][name] = value;
    // Calculate total amount for the current entry
    const totalAmount =
      updatedProductService[index].part_qty *
        updatedProductService[index].part_price || 0;
    updatedProductService[index].total_amount = totalAmount;

    handleInput({
      target: {
        name: "product_servise",
        value: updatedProductService,
      },
    });

    // Calculate grand total
    const newGrandTotal = updatedProductService.reduce((total, entry) => {
      return total + (entry.total_amount || 0);
    }, 0);

    handleInput({
      target: {
        name: "product_servise_total",
        value: newGrandTotal,
      },
    });

    setGrandTotal(newGrandTotal);
  };

  const handleProductServiceChangeDropdown = (e, index) => {
    const { value } = e.target;
    const updatedProductService = [...data.product_servise];
    updatedProductService[index]["name"] = value;
    // Calculate total amount for the current entry
    const totalAmount =
      updatedProductService[index].part_qty *
        updatedProductService[index].part_price || 0;
    updatedProductService[index].total_amount = totalAmount;

    handleInput({
      target: {
        name: "product_servise",
        value: updatedProductService,
      },
    });

    // Calculate grand total
    const newGrandTotal = updatedProductService.reduce((total, entry) => {
      return total + (entry.total_amount || 0);
    }, 0);

    handleInput({
      target: {
        name: "product_servise_total",
        value: newGrandTotal,
      },
    });

    setGrandTotal(newGrandTotal);
  };

  useEffect(() => {
    handleInput({
      target: {
        name: "product_servise_total",
        value: grandTotal,
      },
    });
  }, []);

  const handleAddProductService = (event) => {
    if (event) {
      event.preventDefault();
    }
    const updatedProductService = [
      ...data.product_servise,
      {
        name: "",
        description: "",
        part_qty: "",
        part_price: "",
        total_amount: "",
        image: null,
      },
    ];

    handleInput({
      target: {
        name: "product_servise",
        value: updatedProductService,
      },
    });
  };

  const handleRemoveProductService = (index) => {
    const updatedProductService = [...data.product_servise];

    updatedProductService.splice(index, 1);

    handleInput({
      target: {
        name: "product_servise",
        value: updatedProductService,
      },
    });
  };

  const [quotes_id, setQuotes_id] = useState(null);
  const [request_id, setrequest_id] = useState(null);
  // const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const quotes_idFromUrl = queryParams.get("quotes_id");
    const request_idFromUrl = queryParams.get("request_id");

    setQuotes_id(quotes_idFromUrl);
    setrequest_id(request_idFromUrl);

    // if (quotes_idFromUrl) {
    //  alert(`Quotes ID: ${quotes_idFromUrl}`);
    // } else if (request_idFromUrl) {
    //   alert(`request ID: ${request_idFromUrl}`);
    // } else {
    //   alert('Quotes ID or Job ID not found in the URL.');
    // }
  }, [location.search]);

  const [otherName, setOtherName] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `/request/request_clientdata/${request_id}`
      );
      setOtherName(response?.data?.data[0]);
      // console.log(response?.data?.data,"response?.data?.data====================================================this is data from reduest")
      setRequestData(response?.data?.data);
      const property = {
        ...response?.data?.data[0].propertyData,
        client: response?.data?.data[0].clientData,
      };
      setSelectedProperty(property);
    } catch (error) {
      console.log("error in fetchdata in company details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [request_id]);

  //-----------------------------------------------------

  const [showText, setShowText] = useState(false);

  const handleClick = () => {
    setShowText(!showText);
  };
  const handleOpenPropertyModal = (id) => {
    // Open the modal
    setShowModal(false);
    setShowPropertyModal(true);

    fetchProperties(id);
  };
  const renderTable = () => {
    if (loadingClients) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            <Spinner animation="grow" />;
          </td>
        </tr>
      );
    }
    if (clients?.length == 0) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            Please Create Client to Create Request
          </td>
        </tr>
      );
    }
    if (filteredClients?.length === 0 && searchTerm == "") {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            Clients not Found
          </td>
        </tr>
      );
    }
    if (filteredClients?.length === 0) {
      return (
        <tr>
          <td colSpan={3} className="text-center">
            No results for "{searchTerm}"
          </td>
        </tr>
      );
    }

    return filteredClients?.map((client) => (
      <tr
        key={client.client_id}
        style={{ cursor: "pointer" }}
        onClick={() => handleOpenPropertyModal(client.client_id)}
      >
        <td style={{ paddingLeft: 40 }}>
          {client.company_name ? (
            <FontAwesomeIcon icon={faBuilding} className="me-2" />
          ) : (
            <FontAwesomeIcon icon={faUser} className="me-2" />
          )}
        </td>
        <td>
          {client.company_name ? (
            <>
              <div>
                {client.company_name} ({client.first_name})
              </div>
              <div>
                {client.properties} Properties | {client.propertyCount}
              </div>
            </>
          ) : (
            <>
              <div>{client.first_name}</div>
              <div>
                {client.properties} Properties | {client.propertyCount}
              </div>
            </>
          )}
        </td>
        <td>{client.updatedAt}</td>
      </tr>
    ));
  };

  const [services, SetServices] = useState("");

  const fetchClients = async () => {
    try {
      const userData = cookies.get("userDetails");

      setUserData(userData);
      const response = await axiosInstance.get(
        `/request/request_client/${userData.user_id}`
      );
      if (response.data.data) {
        setClients(response.data.data);
      }
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error);
    } finally {
      setLoadingClients(false);
    }
  };

  const [selectedClientId, setSelectedClientId] = useState("");
  // const [selectedProperty, setSelectedProperty] = useState([]);
  const [
    selectedClientDataFromRequestData,
    setSelectedClientDataFromRequestData,
  ] = useState([]);

  const fetchProperties = async (clientId) => {
    try {
      setSelectedClientId(clientId);
      const response = await axiosInstance.get(
        `/request/request_property/${clientId}`
      );
      if (response.data.data) {
        setProperties(response.data.data);
      }
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error);
    } finally {
      setLoadingProperties(false);
    }
  };

  const fetchProperty = async (properyId) => {
    try {
      const response = await axiosInstance.get(
        `/request/property/${properyId}`
      );
      const data = response.data.data[0];

      setSelectedProperty(data);
      handleInputCheckbox({ name: "client_id", value: data?.client_id });
      handleInputCheckbox({ name: "property_id", value: data?.property_id });
      // handleInputCheckbox({"name": "first_name", value: data[0].first_name})
      // handleInputCheckbox({"name": "last_name", value: data[0].last_name})
      // handleInputCheckbox({"name": "email", value: data[0].email})
    } catch (error) {
      console.log("error in getting fetchclients in new reuest form:", error);
      toast.error("We are Troubleshooting... try again later");
    } finally {
      setLoadingProperties(false);
      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (requestData.length != 0 && requestData[0].propertyData) {
      setSelectedProperty(requestData[0].propertyData);
      setSelectedClientDataFromRequestData(requestData[0].clientData);

      handleInputCheckbox({
        name: "client_id",
        value: requestData[0]?.clientData?.client_id,
      });
      handleInputCheckbox({
        name: "property_id",
        value: requestData[0]?.propertyData?.property_id,
      });
    }
  }, [requestData]);

  const renderProperty = () => {
    if (loadingProperties) {
      return (
        <div className="w-100 align-items-center d-flex justify-content-center py-2">
          <Spinner animation="grow" />
        </div>
      );
    }

    if (properties?.length === 0 || properties == undefined) {
      return (
        <div className="w-100 align-items-center d-flex justify-content-center py-2">
          Properties Not found - Please Create Property
        </div>
      );
    }

    if (filteredProperties?.length === 0) {
      return (
        <div className="w-100 align-items-center d-flex justify-content-center py-2">
          No results for "{searchTerm}"
        </div>
      );
    }

    return filteredProperties?.map((property, index) => (
      <div
        key={property.id}
        style={{ marginBottom: "10px", marginTop: "5px", cursor: "pointer" }}
        onClick={() => {
          fetchProperty(property.property_id);
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              paddingLeft: 20,
              paddingTop: 10,
              paddingBottom: 5,
            }}
          >
            <span>{`${property.street_1}${
              property.street_2 ? `, ${property.street_2}` : ""
            }, ${property.city} - ${property.zipcode}, ${property.state}, ${
              property.country
            }`}</span>
          </div>
          <div style={{ marginRight: "10px", paddingRight: 20 }}>
            <span>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </div>
        </div>
        {index < filteredProperties?.length - 1 && (
          <hr
            style={{
              width: "100%",
              margin: "5px 0",
              border: "1px solid #ccc",
            }}
          />
        )}
      </div>
    ));
  };
  const clientName =
    selectedProperty && selectedProperty.client
      ? `${selectedProperty.client.first_name} ${selectedProperty.client.last_name}`
      : "Client Name";

  const [selectedClientView, setSelectedClientView] = useState([]);
  const [internalLinks, setInternalLinks] = useState([]);

  const handleInputClientView = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // If the checkbox is checked, add the value to the array
      setSelectedClientView((prevSelected) => [...prevSelected, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setSelectedClientView((prevSelected) =>
        prevSelected?.filter((time) => time !== value)
      );
    }
  };

  const handleInputInternalLinks = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // If the checkbox is checked, add the value to the array
      setInternalLinks((prevSelected) => [...prevSelected, value]);
    } else {
      // If the checkbox is unchecked, remove the value from the array
      setInternalLinks((prevSelected) =>
        prevSelected?.filter((time) => time !== value)
      );
    }
  };

  useEffect(() => {
    handleInputCheckbox({
      name: "client_view",
      value: selectedClientView,
    });
    handleInputCheckbox({
      name: "internal_link_note_to_related",
      value: internalLinks,
    });
  }, [selectedClientView, internalLinks]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisibility(!isSidebarVisible);
  };

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [productDescription, setProductDescription] = useState("");

  // console.log(selectedProperty, "this is selected porperty")

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        // Use axios.get to fetch data from the API
        const response = await axiosInstance.get(
          "/product/product_service/user_id"
        );

        // Update the state with the fetched products
        setProducts(response.data.data);
        console.log("Products Description:", response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  const handleProductChange = (event) => {
    // Update the selected product state when the user selects an option
    setSelectedProduct(event.target.value);
  };
  const [isDirty, setIsDirty] = useState(false);

  const handleBeforeUnload = (event) => {
    console.log("event", event);
    if (isDirty) {
      const message =
        "You have unsaved changes. Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    }
  };

  const handlePopState = () => {
    if (!leaveConfirmationCanceled) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );

      if (confirmLeave) {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        history.push("/quotes");
      } else {
        leaveConfirmationCanceled = true;
        history.push("/quotes/new");
      }
    } else {
      leaveConfirmationCanceled = false;
    }
  };

  let leaveConfirmationCanceled = false;

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isDirty, history]);
  return (
    <div className="container p-4">
      <h3
        style={{ cursor: "pointer" }}
        className="d-flex align-items-center text-dark"
        onClick={handleOpenModal}
      >
        {/* <FontAwesomeIcon icon={faUser} /> &nbsp; */}
        Request for {"client Name"} &nbsp;
        <Button type="button" className="btn btn" size={"sm"}   style={{backgroundColor:colors.red,color:colors.white,border:"none"}}>
          +
        </Button>
      </h3>

      {requestData.length != 0 && (
        <RequestRightSidebar
          isVisible={isSidebarVisible}
          requestData={requestData}
          onClose={toggleSidebar}
        />
      )}

      {selectedProperty.length != 0 ? (
        <Row>
          <Col xs={12} className="mt-2">
            <hr />
          </Col>
          <Col md={6} lg={6} sm={12}>
            <Form.Label style={{ fontSize: "large" }} className="text-dark">
              Property Address
            </Form.Label>
            <Form.Group>
              <Form.Label>
                {selectedProperty?.street_1}
                {selectedProperty.street_2
                  ? ", " + selectedProperty?.street_2
                  : null}
                ,<br /> {selectedProperty?.city}, {selectedProperty?.state},{" "}
                {selectedProperty?.zipcode}
              </Form.Label>
              <br />
              <br />
              <Form.Label
                onClick={() => {
                  handleOpenPropertyModal();
                }}
                className="ml-2 text-dark"
                style={{ cursor: "pointer" }}
              >
                Change
              </Form.Label>
            </Form.Group>
          </Col>
          <Col md={6} lg={6} sm={12}>
            <Form.Label style={{ fontSize: "large" }} className="text-dark">
              Client Details
            </Form.Label>
            <Form.Group>
              <Form.Label className="text-dark">
                {(selectedProperty?.client?.first_name ||
                  selectedClientDataFromRequestData?.first_name) +
                  " " +
                  (selectedProperty?.client?.last_name ||
                    selectedClientDataFromRequestData?.last_name ||
                    "lastname")}
              </Form.Label>

              <br />
              <Form.Label>
                {selectedProperty?.client?.phone ||
                  selectedClientDataFromRequestData?.phone}{" "}
              </Form.Label>
              <br />
              <Form.Label>
                {selectedProperty?.client?.email ||
                  selectedClientDataFromRequestData?.email}{" "}
              </Form.Label>
              <br />
            </Form.Group>
          </Col>
        </Row>
      ) : null}

      <Modal
        className=""
        show={showModal}
        onHide={handleCloseModal}
        style={{ height: "auto" }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select or create a client</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "scroll", maxHeight: "80vh" }}>
          {/* Add your content for client selection here */}

          <div className="p-4">
            <p className="mb-2 ml-2">
              Which client would you like to create this for?
            </p>
            <div className="border border-dark">
              <div
                style={{
                  height: 70,
                  justifyContent: "center",
                  backgroundColor: "lightgray",
                  alignItems: "center",
                }}
                className="d-flex align-items-center mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Search client..."
                  style={{
                    border: "none",
                    width: "130px",
                    borderRadius: "0",
                  }}
                  onChange={handleSearchChange}
                />
                <p className="mx-2 my-2">OR</p>
                <Button
                  variant="primary"
                  className=""
                  onClick={() => history.push(Routes.Client.path)}
                  style={{backgroundColor:colors.red,color:colors.white,border:"none"}}
                >
                  Create New Client
                </Button>
              </div>
              <tr className="my-3">
                <label style={{ marginLeft: "10px" }}> Leads</label>{" "}
              </tr>
              <Col xs={12} className="mb-2">
                <hr />
              </Col>

              <table className="table table-striped">
                <tbody>{renderTable()}</tbody>
              </table>
              {/* <tr>
                {" "}
                <label style={{ marginLeft: "10px" }}> Archived </label>{" "}
              </tr>
              <Col xs={12} className="mb-2">
                <hr />
              </Col>
              <table className="table table-striped">
                <tbody>{renderTable()}</tbody>
              </table> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showEmailModal} centered onHide={handleCloseEmailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Compose Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add your content for composing email here */}
          <div className="p-4">
            <Form.Group controlId="formSubject" className="f-flex">
              <Form.Label>Email Address:-</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                className="mb-3"
              />
              <Form.Label>Subject:-</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter subject"
                className="mb-3"
              />
            </Form.Group>
            <Form.Group controlId="formBody">
              <Form.Label>Email Body</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Type your message..."
                className="mb-3"
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEmailModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSendEmail}>
            Send Email
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPropertyModal} centered onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select or create a property...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add your content for client selection here */}

          <div className="p-4">
            <p className="mb-2 ml-2">
              Which property would you like to use for this?
            </p>
            <div className="border border-dark">
              <div
                style={{
                  height: 70,
                  justifyContent: "center",
                  backgroundColor: "lightgray",
                  alignItems: "center",
                }}
                className="d-flex align-items-center"
              >
                <Form.Control
                  type="text"
                  placeholder="Search properties..."
                  style={{
                    border: "none",
                    width: "150px",
                    fontSize: "13px",
                    borderRadius: "0",
                  }}
                  onChange={handleSearchpropertyChange}
                />
                <p className="mx-2 my-2">OR</p>
                <Button
                  variant="primary"
                  className=""
                  style={{
                    fontSize: "14px",
                    backgroundColor:colors.red,color:colors.white,border:"none"
                  }}
                  onClick={() =>
                    history.push(`/properties/${selectedClientId}`)
                  }
                >
                  Create New Property
                </Button>
              </div>

              <div>{renderProperty()}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Col xs={12} className="mb-2">
        <hr />
      </Col>
      <Form onSubmit={handleSubmit}>
        <Row className="my-4">
          <Col md={6} sm={12}>
            <Form.Label style={{ fontSize: "large" }} className="text-dark">
              Job title
            </Form.Label>
            <InputGroup>
              <Form.Control
                autoFocus
                required
                type="text"
                name="job_title"
                value={data?.job_title}
                onChange={handleInput}
                placeholder="Job title"
              />
            </InputGroup>
          </Col>
          <Col md={6} sm={12} cln>
            <Form.Label
              style={{ fontSize: "large", paddingLeft: 5 }}
              className="text-dark"
            >
              Rate for Quote
            </Form.Label>
            <InputGroup>
              <Rating
                name="simple-controlled"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Col xs={12} className="mb-2">
          <hr />
        </Col>
        <Row>
          <Col>
            <div className="py-4">
              <div className="table-responsive">
                <Table
                  className="table table-bordered"
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid #ddd",
                  }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th>PRODUCT / SERVICE</th>
                      <th>QTY.</th>
                      <th>UNIT PRICE</th>
                      <th>TOTAL</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.product_servise.map((entry, index) => (
                      <tr key={index}>
                        <td>
                          {/* <input
                            type="text"
                            value={entry.name}
                            onChange={(e) =>
                              handleProductServiceChange(e, index)
                            }
                            name="name"
                            placeholder="Name"
                            className="form-control mb-2 mr-sm-2"
                            style={{
                              borderRadius: "5px",
                              padding: "10px",
                              border: "1px solid #ced4da",
                              width: "150px",
                              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            }}
                          /> */}

                          {/* <div className="col-md-4"> */}
                          <select
                            style={{
                              borderRadius: "5px",
                              padding: "10px",
                              border: "1px solid #ced4da",
                              width: "200px",
                              marginBottom:"10px",
                              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            }}
                            id="inputState"
                            className="form-select"
                            value={data.product_servise[index].name}
                            onChange={(e) =>
                              handleProductServiceChangeDropdown(e, index)
                            }
                          >
                            <option value="" disabled>
                              Choose...
                            </option>
                            {products.map((product) => (
                              <option
                                key={product._id}
                                name={"name"}
                                value={product.product_name}
                              >
                                {product.product_name}
                              </option>
                            ))}
                          </select>
                          <textarea class="form-control" id="floatingTextarea" style={{  width: "200px",}}
                           value={entry.description}
                           onChange={(e) =>
                             handleProductServiceChange(e, index)
                           }
                           name="description"
                          placeholder="Description"></textarea>
                          {/* </div> */}
                        </td>
                        {/* <td>
                          <input
                            type="text"
                            value={product.product_description}
                            onChange={(e) =>
                              handleProductServiceChange(e, index)
                              
                            }
                            
                            name="description"
                            placeholder="Description"
                            className="form-control mb-2 mr-sm-2"
                            style={{
                              borderRadius: "5px",
                              padding: "10px",
                              border: "1px solid #ced4da",
                              width: "150px",
                              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            }}
                          />
                          <h1>{product.product_name}</h1>
                          <input
                            type="text"
                            value={entry.description}
                            onChange={(e) =>
                              handleProductServiceChange(e, index)
                            }
                            name="description"
                            placeholder="Description"
                            className="form-control mb-2 mr-sm-2"
                            style={{
                              borderRadius: "5px",
                              padding: "10px",
                              border: "1px solid #ced4da",
                              width: "150px",
                              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            }}
                          />
                        </td> */}
                        <td>
                          <input
                            type="text"
                            value={entry.part_qty}
                            onChange={(e) =>
                              handleProductServiceChange(e, index)
                            }
                            name="part_qty"
                            placeholder="Quantity"
                            className="form-control mb-2 mr-sm-2"
                            style={{
                              borderRadius: "5px",
                              padding: "10px",
                              border: "1px solid #ced4da",
                              width: "150px",
                              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={entry.part_price}
                            onChange={(e) =>
                              handleProductServiceChange(e, index)
                            }
                            name="part_price"
                            placeholder="Price"
                            className="form-control mb-2 mr-sm-2"
                            style={{
                              borderRadius: "5px",
                              padding: "10px",
                              border: "1px solid #ced4da",
                              width: "150px",
                              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={entry.part_qty * entry.part_price || ""}
                            readOnly
                            placeholder="Total"
                            onChange={(e) =>
                              handleProductServiceChange(e, index)
                            }
                            name="total_amount"
                            className="form-control mb-2 mr-sm-2"
                            style={{
                              borderRadius: "5px",
                              padding: "10px",
                              border: "1px solid #ced4da",
                              width: "150px",
                              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            }}
                          />
                        </td>
                        {/* <td>
                          {entry.image ? (
                            <div>
                              <img
                                src={URL.createObjectURL(entry.image)}
                                alt="Selected"
                                width="100"
                                height="100"
                              />
                              <span
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  onClick={() => handleRemoveImage(index)}
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="input-group">
                              <input
                                id={`fileInput${index}`}
                                type="file"
                                accept="image/*"
                                className="form-control"
                                onChange={(e) => handleImageChange(e, index)}
                                style={{ display: "none" }}
                              />
                              <label
                                htmlFor={`fileInput${index}`}
                                className="input-group-text"
                                style={{ cursor: "pointer" }}
                              >
                                <FontAwesomeIcon icon={faCamera} />
                              </label>
                            </div>
                          )}
                        </td> */}
                        <td>
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => handleRemoveProductService()}
                            style={{ fontSize: "20px" }}
                            className="mt-2"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex flex-wrap align-items-center justify-content-between mt-3">
                <div>
                  <button
                    className="btn btn-dark btn-sm mb-2 me-2"
                    onClick={handleAddProductService}
                    style={{backgroundColor:colors.red,color:colors.white,border:"none"}}
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-1" /> Add Line
                    Item
                  </button>
                  {/* <button
                    className="btn btn-outline-dark btn-sm mb-2 me-2"
                    onClick={() => console.log("Button 2")}
                  >
                    <FontAwesomeIcon icon={faFileAlt} className="me-1" /> Add
                    Optional Line Item
                  </button> */}
                  {/* <button
                    className="btn btn-outline-dark btn-sm mb-2"
                    onClick={() => console.log("Button 3")}
                  >
                    <FontAwesomeIcon icon={faTextWidth} className="me-1" /> Add
                    Text
                  </button> */}
                </div>
                <div>
                  <h6 className="text-right">
                    Grand Total:
                    {grandTotal == 0 ? data.product_servise_total : grandTotal}{" "}
                  </h6>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Col xs={12} className="mb-2">
          <hr />
        </Col>
        <Row>
          <Col xs={12} md={12}>
            <div>
              <Form.Label style={{ fontSize: "large" }} className="text-dark">
                Client Message
              </Form.Label>
              <InputGroup>
                <Form.Control
                  autoFocus
                  required
                  as="textarea"
                  name="client_message"
                  value={data.client_message}
                  onChange={handleInput}
                />
              </InputGroup>
            </div>
            <div className="d-flex align-items-center mt-3 mb-2">
              <FontAwesomeIcon icon={faEye} className="me-2" />
              <span>Client view</span>
              {showText ? (
                <div>
                  <button
                    className="btn btn-primary ms-2"
                    style={{
                      color: "black",
                      backgroundColor: "transparent",
                      border: "none",
                      transition: "color 0.3s ease, background-color 0.3s ease",
                    }}
                    onClick={() => setShowText(false)}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary ms-2"
                  style={{
                    color: "black",
                    backgroundColor: "transparent",
                    border: "none",
                    transition: "color 0.3s ease, background-color 0.3s ease",
                  }}
                  onClick={handleClick}
                >
                  Change
                </button>
              )}
            </div>
            {showText && (
              <>
                {" "}
                <div>
                  <p>
                    Adjust what your client will see on this quote. To change
                    the default for all future quotes, visit the PDF Style.
                  </p>
                </div>
                <Row className="mb-2 d-flex mt-2">
                  <InputGroup>
                    <Form.Check
                      type="checkbox"
                      label="Quantities"
                      name="client_view"
                      value="Quantities"
                      onChange={handleInputClientView}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Form.Check
                      type="checkbox"
                      label="Unit prices"
                      name="client_view"
                      value="Unit prices"
                      onChange={handleInputClientView}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Form.Check
                      type="checkbox"
                      label="Line item totals"
                      name="client_view"
                      value="Line item totals"
                      onChange={handleInputClientView}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Form.Check
                      type="checkbox"
                      label="Totals"
                      name="client_view"
                      value="Totals"
                      onChange={handleInputClientView}
                    />
                  </InputGroup>
                </Row>
              </>
            )}
          </Col>
        </Row>
        <div className="row">
          <hr
            style={{ width: "100%", backgroundColor: "gray", height: "3px" }}
          />
        </div>
        <div className="row">
          <Row className="mb-2">
            <Col xs={12}>
              <Form.Label>Internal notes & attachments</Form.Label>
              <InputGroup>
                <Form.Control
                  autoFocus
                  as="textarea"
                  name="internal_notes_detais"
                  value={data?.internal_notes_detais}
                  onChange={handleInput}
                  placeholder="Notes Details"
                />
              </InputGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col>
              <div style={{ border: "1px dashed #000" }} className="mb-3 my-3">
                <input
                  type="file"
                  className="form-control"
                  aria-label="file example"
                  multiple
                  onChange={handleFileChange}
                />
                <div className="invalid-feedback">
                  Example invalid form file feedback
                </div>

                {/* Display selected files */}
                {selectedFiles.length > 0 && (
                  <div className="p-3">
                    <ul>
                      {selectedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Col xs={12} className="mb-2">
            <hr />
          </Col>
        </div>
        <div className="row m-3">
          <Col>
            <div className="d-flex justify-content-between">
              <Button
                variant=""
                // type="submit"
                className="mt-4"
                style={{backgroundColor:colors.red,color:colors.white}}
                onClick={() => {
                  history.push(Routes.QuotesDashboard.path);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={
                  quotes_id
                    ? handleSubmit
                    : data.client_id
                    ? handleSubmit
                    : handleOpenModal
                }
                variant="="
                type="submit"
                className="mt-4"
                disabled={isButtonDisable}
                style={{backgroundColor:colors.red,color:colors.white}}
              >
                {quotes_id
                  ? "Update Quote"
                  : data.client_id
                  ? isButtonDisable
                    ? "Submitting..."
                    : "Submit Client"
                  : "Select Client"}
              </Button>
            </div>
          </Col>
        </div>
      </Form>
    </div>
  );
}

export default NewQuotes;
