// Import necessary libraries and modules
import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Routes } from "../../routes";
import { Button, Form } from "@themesberg/react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import axiosInstance from "../../config/AxiosInstance";
import NewQuotes from "./NewQuotes";
import Cookies from "universal-cookie";

function Quotes() {
  const history = useHistory();
  const cookies = new Cookies();
  const location = useLocation();
  const requestData = location.state !== undefined ? location.state : [];

  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [rating, setRating] = useState(2);
  // const { quotes_id } = useParams();
  const [quotes_id, setQuotes_id] = useState(null);
  const [request_id, setrequest_id] = useState(null);
  // const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const quotes_idFromUrl = queryParams.get('quotes_id');
    const request_idFromUrl = queryParams.get('request_id');

    setQuotes_id(quotes_idFromUrl);
    setrequest_id(request_idFromUrl);

    // if (quotes_idFromUrl) { 
    //  alert(`Quotes ID: ${quotes_idFromUrl}`);
    // } else if (request_idFromUrl) {
    //   alert(`request ID: ${request_idFromUrl}`);
    // } else {
    //   alert('Quotes ID or Job ID not found in the URL.');
    // }
  }, [location.search]);
  const [loading, setLoading] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [data, setData] = useState({
    client_id: "",
    property_id: "",
    job_title: "",
    product_servise: [],
    client_message: "",
    client_view: [],
    internal_link_note_to_related: [],
  });

  const fetchQuoteData = async () => {
    try {
      const response = await axiosInstance.get(`/quotes/quotes_get/${quotes_id}`);
      if (response.data.data) {
        setData(response.data.data[0]);
        setRating(response.data.data[0]?.rating);
        const apiData = response.data.data;

        const property = {
          ...apiData[0]?.propertyData,
          client: apiData[0]?.clientData,
        };

        setSelectedProperty(property);
        handleInputCheckbox({ name: "client_id", value: apiData?.client_id });
        handleInputCheckbox({ name: "property_id", value: apiData?.property_id });
      }
    } catch (error) {
      console.log("Error in getting fetch quote:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (quotes_id) {
      fetchQuoteData();
    }
  }, [quotes_id]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputCheckbox = (e) => {
    const value = e.value;
    const name = e.name;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  console.log(selectedProperty, "this is selected porperty")


  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsButtonDisable(true);

    try {
      const userData = cookies.get("userDetails");

      const requestId = requestData.length !== 0 ? requestData[0]?.request_id : "";

      const apiData = { ...data, user_id: userData.user_id, rating, request_id: requestId };

      const api = quotes_id ? `/quotes/quotes/${quotes_id}` : "/quotes/quotes";

      const method = quotes_id ? "put" : "post";

      const response = await axiosInstance({
        method: method,
        url: api,
        data: apiData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (requestData.length !== 0) {
        const requestUpdate = await axiosInstance.put(`/request/request_status/${requestData[0]?.request_id}`, {
          status: "Converted",
        });

        console.log("Success:", requestUpdate.data);
      }

      if (response.status === 200) {
        toast.success("Request submitted successfully");
        setTimeout(() => {
          history.push(Routes.QuotesDashboard.path);
        }, 2000);
      } else {
        toast.error("Error submitting request");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An unexpected error occurred");
    }

    setIsButtonDisable(false);
  };

  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <div className="container-form mt-4">
        <p className="heading-form text-dark mx-2">Quote Details</p>
        <div className="container-form-inputs">
          <NewQuotes
            handleSubmit={handleSubmit}
            handleInputCheckbox={handleInputCheckbox}
            handleInput={handleInput}
            data={data}
            isButtonDisable={isButtonDisable}
            setIsButtonDisable={setIsButtonDisable}
            rating={rating}
            quotes_id={quotes_id}
            setRating={setRating}
            selectedProperty={selectedProperty}
            setSelectedProperty={setSelectedProperty}
            requestData={requestData}
          />
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default Quotes;
