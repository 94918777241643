import React from 'react'
import SidebarSetting from './SidebarSetting'

function Clienthubsetting() {
  return (
    <>
      <div className='main d-flex'>
        <SidebarSetting />
        <div className='headccc text-center'>
          <h5>Clienthubsetting</h5>
        </div>
      </div>
    </>
  )
}

export default Clienthubsetting
